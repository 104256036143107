<template>
  <div>
    <Modal :title="$t('add')" v-if="add_active" @close="add_active = $event">
      <form class="space-y-4 p-6">
        <div class="form-group space-y-3">
          <label for="payment_option">{{ $t("payment-option") }}</label>
          <select
            name="payment_option"
            id="payment_option"
            v-model="add_data.payment_option"
            class="custom-select"
          >
            <option selected disabled value="">-- select --</option>
            <option
              v-for="payment in payments"
              :key="payment.key"
              :value="payment.key"
            >
              {{ $t(payment.label) }}
            </option>
          </select>
          <p class="form-error">{{ add_errors["payment_option"] }}</p>
        </div>
        <div class="form-group space-y-3">
          <label for="amount">{{ $t("amount") }}</label>
          <input
            type="number"
            name="amount"
            id="amount"
            v-model.number="add_data.amount"
          />
          <p class="form-error">{{ add_errors["amount"] }}</p>
        </div>
        <ButtonWithLoader
          btnClass="w-full py-3 px-4 rounded bg-primary text-white text-sm"
          :disabled="add_disabled"
          @click="addItem"
        >
          {{ $t("submit") }}
        </ButtonWithLoader>
      </form>
    </Modal>
  </div>
</template>

<script>
import ButtonWithLoader from "../basics/ButtonWithLoader.vue";
import Modal from "../basics/Modal.vue";

export default {
  name: "AddPayment",
  components: { Modal, ButtonWithLoader },
  props: {
    refetch: {
      type: Function,
      default: undefined,
    },
    id: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      add_data: {
        payment_option: "",
      },
      payments: [
        {
          key: "cash",
          label: "cash",
        },
        {
          key: "card",
          label: "card",
        },
      ],
      add_disabled: false,
      add_errors: {},
      add_active: false,
    };
  },
  methods: {
    async addItem() {
      try {
        this.add_disabled = true;
        this.add_errors = {};

        const { data } = await this.axios.post("orders/add_payment", {
          ...this.add_data,
          order_id: this.id,
        });
        this.add_active = false;
        this.createAlert(data?.message);
        this?.refetch?.();
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.createAlert(responseError, "error");
        }
      } finally {
        this.add_disabled = false;
      }
    },
    show() {
      this.add_active = true;
    },
    hide() {
      this.add_active = false;
    },
  },
};
</script>
