<template>
  <AlertsContainerWrapper class="p-6">
    <section class="w-full space-y-6">
      <Table
        :title="$t('stock-counts')"
        :records="`${pagination.total || 0} `"
        :pagination="pagination"
        :total_pages="pagination.total_pages"
        v-model="pagination.page"
        :has_search="false"
      >
        <template #actions>
          <div v-if="hasPermission('add stock_count')">
            <router-link
              to="/settings/stock-count/add"
              class="py-1.5 px-3 text-sm flex items-center gap-2 text-gray-600 font-medium rtl:font-semibold"
            >
              <i class="fa-solid fa-plus"></i>
              <span>
                {{ $t("add") }}
              </span>
            </router-link>
          </div>
          <div v-if="report_data.items.length === 2">
            <button
              class="py-1.5 px-3 text-sm flex items-center gap-2 text-gray-600 font-medium rtl:font-semibold ring-1 ring-gray-200 bg-white"
              @click.prevent="GenerateReportBetweenTwoItems"
              :disabled="generate_disabled"
            >
              <spinner size="w-4 h-4 inline-block" v-if="generate_disabled" />
              <i class="fa-regular fa-file-lines" v-else></i>

              <span>{{ $t("generate-report") }}</span>
            </button>
          </div>
        </template>
        <template #head>
          <th></th>
          <th>{{ $t("location") }}</th>
          <th>{{ $t("user") }}</th>
          <th>{{ $t("products") }}</th>
          <th>{{ $t("created-date") }}</th>
          <th></th>
        </template>

        <template #tbody v-if="!load">
          <TableLoad :rows="6" v-for="i in 4" :key="i" />
        </template>
        <template #tbody v-else-if="load && items.length">
          <tr v-for="(item, index) in items" :key="index">
            <td>
              <div class="checkbox">
                <input
                  type="checkbox"
                  :name="item.id"
                  :id="item.id"
                  :value="item.id"
                  v-model="report_data.items"
                  :disabled="
                    report_data.items.length >= 2 &&
                    !report_data.items.includes(item.id)
                  "
                />
              </div>
            </td>
            <td>{{ (item.store || {})["name_" + $i18n.locale] }}</td>
            <td>{{ (item.user || {})["name"] }}</td>
            <td>
              <button
                class="py-2 px-3 text-xs font-medium bg-sky-600 text-white inline-flex items-center gap-2 rounded"
                type="button"
                @click="showItemProducts(item)"
              >
                <span> ({{ item.products.length }}) {{ $t("view") }} </span>
                <i class="fa-solid fa-angle-right rtl:hidden"></i>
                <i class="fa-solid fa-angle-left hidden rtl:inline"></i>
              </button>
            </td>
            <td v-html="createTime(item.created_at)"></td>
            <td>
              <div class="action-group">
                <button
                  class="py-2 px-3 text-xs font-medium bg-gray-200 inline-flex items-center gap-2 rounded text-gray-600"
                  :disabled="
                    disables[[(item.store || {}).id, item.id].join('-')]
                  "
                  @click.prevent="
                    GenerateItemReport(
                      [(item.store || {}).id, item.id].join('-'),
                      (item.store || {}).id,
                      item.id
                    )
                  "
                  v-if="index !== items.length - 1"
                >
                  <i
                    class="fa-solid fa-spinner fa-spin"
                    v-if="disables[[(item.store || {}).id, item.id].join('-')]"
                  ></i>
                  <i class="fa-regular fa-file-lines" v-else></i>
                  <span>{{ $t("generate-report") }}</span>
                </button>
              </div>
            </td>
          </tr></template
        >

        <template #tbody v-else>
          <tr>
            <td colspan="100%" class="!text-center">
              {{ $t("no-data") }}
            </td>
          </tr>
        </template>
      </Table>
      <Modal
        v-if="show_active"
        @close="show_active = $event"
        :title="$moment(item.created_at).format('lll')"
      >
        <template>
          <ul class="divide-y divide-gray-200">
            <li
              v-for="(product, index) in item.products"
              :key="index"
              class="p-4"
            >
              <div class="flex items-center gap-3 justify-between">
                <div class="inline-flex flex-1 gap-4 items-center">
                  <div>
                    <div class="w-10 h-10">
                      <img
                        :src="
                          product.image ||
                          require('@/assets/images/placeholder.png')
                        "
                        @error="
                          $event.target.src = require('@/assets/images/placeholder.png')
                        "
                        alt="product image"
                        class="w-full h-full rounded-md border object-cover border-gray-100"
                      />
                    </div>
                  </div>
                  <div class="flex-1 space-y-0.5 max-w-sm whitespace-normal">
                    <p class="line-clamp-2">
                      {{ product["name_" + $i18n.locale] }}
                    </p>
                  </div>
                </div>
                <p class="text-sm font-semibold text-gray-600">
                  <span v-if="product.unit_type === 'weight'">
                    {{ GenerateUnit(product.value || 0) }}
                  </span>
                  <span v-else>
                    {{ product.value || 0 }} {{ $t("price") }}
                  </span>
                </p>
              </div>
            </li>
          </ul>
        </template>
      </Modal>
      <Modal
        v-if="show_report"
        @close="show_report = $event"
        size="!max-w-screen-2xl"
        :title="(report_item.store || {})?.['name_' + $i18n.locale]"
      >
        <template>
          <div class="p-4 space-y-4">
            <div class="flex items-center gap-4 flex-wrap">
              <p
                class="py-2 px-3 rounded bg-gray-200 text-sm font-semibold text-gray-600 flex items-center gap-3 shrink-0"
                v-tippy
                content="Opening date"
              >
                <i class="fa-solid fa-play"></i>
                <span>{{ report_item.opening_date | moment("lll") }}</span>
              </p>
              <p
                class="py-2 px-3 rounded bg-gray-200 text-sm font-semibold text-gray-600 flex items-center gap-3 shrink-0"
                v-tippy
                content="Closing date"
              >
                <i class="fa-solid fa-stop"></i>
                <span>{{ report_item.closing_date | moment("lll") }}</span>
              </p>
            </div>
            <Table
              title="Report products"
              :records="`${(report_item.products || []).length || 0} `"
              :has_search="false"
              :pagination="{}"
              has_export
              :exportPathname="'stock_counts/report/export_excel/' + store_id"
              :sendEmailPathname="'stock_counts/report/send_excel/' + store_id"
              :fields="report_item.export_fields"
            >
              <template #head>
                <th>{{ $t("product") }}</th>
                <th>{{ $t("category") }}</th>
                <th>{{ $t("opening-stock") }}</th>
                <th>{{ $t("closing-stock") }}</th>
                <th>{{ $t("sold") }}</th>
                <th>{{ $t("processed") }}</th>
                <th>{{ $t("wastages") }}</th>
                <th>{{ $t("unknown-wastages") }}</th>
              </template>
              <!-- <template #actions>
                <div>
                  <button
                    class="py-1.5 px-3 text-sm flex items-center gap-2 text-gray-600 font-medium rtl:font-semibold ring-1 ring-gray-200 bg-white"
                    @click.prevent="
                      ExportItems({}, 'stock_counts/report/export_excel/' + store_id)
                    "
                  >
                    <i class="fa-solid fa-cloud-arrow-down"></i>
  
                    <span>Export excel</span>
                  </button>
                </div>
              </template> -->
              <template #tbody v-if="!load">
                <TableLoad :rows="8" v-for="i in 4" :key="i" />
              </template>
              <template
                #tbody
                v-else-if="load && (report_item.products || []).length"
              >
                <tr
                  v-if="
                    (report_item.products || []).filter((e) => e.is_inventory)
                      .length
                  "
                >
                  <td colspan="100%">{{ $t("inventory") }}</td>
                </tr>
                <tr
                  v-for="product in (report_item.products || []).filter(
                    (e) => e.is_inventory
                  )"
                  :key="product.id"
                >
                  <td>
                    <router-link
                      :to="
                        hasPermission('edit inventory')
                          ? {
                              name: 'UpdateInventoryProduct',
                              params: {
                                slug: `${product.id}-${
                                  product.name_ar ||
                                  product.name_en ||
                                  `product-${product.created_at}`
                                }`,
                              },
                            }
                          : '#'
                      "
                      class="flex items-center gap-3"
                    >
                      <div>
                        <div class="w-10 h-10">
                          <img
                            :src="
                              product.image ||
                              require('@/assets/images/placeholder.png')
                            "
                            @error="
                              $event.target.src = require('@/assets/images/placeholder.png')
                            "
                            alt="product image"
                            class="w-full h-full rounded-md border object-cover border-gray-100"
                          />
                        </div>
                      </div>
                      <div class="space-y-1 max-w-sm whitespace-normal">
                        <p class="line-clamp-2">
                          {{ product["name_" + $i18n.locale] }}
                        </p>
                        <p class="text-sm font-medium text-gray-600">
                          {{ product.item_code || product.barcode }}
                        </p>
                      </div>
                    </router-link>
                  </td>

                  <td>
                    {{ (product.category || {})["name_" + $i18n.locale] }}
                  </td>
                  <td>
                    <span v-if="product.unit_type === 'weight'">
                      {{ GenerateUnit(product.opening_stock || 0) }}
                    </span>
                    <span v-else>
                      {{ product.opening_stock || 0 }} {{ $t("piece") }}
                    </span>
                  </td>
                  <td>
                    <span v-if="product.unit_type === 'weight'">
                      {{ GenerateUnit(product.closing_stock || 0) }}
                    </span>
                    <span v-else>
                      {{ product.closing_stock || 0 }} {{ $t("piece") }}
                    </span>
                  </td>
                  <td>
                    <span v-if="product.unit_type === 'weight'">
                      {{ GenerateUnit(product.sold || 0) }}
                    </span>
                    <span v-else>
                      {{ product.sold || 0 }} {{ $t("piece") }}
                    </span>
                  </td>
                  <td>
                    <span v-if="product.unit_type === 'weight'">
                      {{ GenerateUnit(product.processed || 0) }}
                    </span>
                    <span v-else>
                      {{ product.processed || 0 }} {{ $t("piece") }}
                    </span>
                  </td>
                  <td>
                    <span v-if="product.unit_type === 'weight'">
                      {{ GenerateUnit(product.wastage || 0) }}
                    </span>
                    <span v-else>
                      {{ product.wastage || 0 }} {{ $t("piece") }}
                    </span>
                  </td>
                  <td
                    :class="
                      product.unknown_wastage > 0
                        ? 'text-red-500 font-semibold'
                        : ''
                    "
                  >
                    <span v-if="product.unit_type === 'weight'">
                      {{ GenerateUnit(product.unknown_wastage || 0) }}
                    </span>
                    <span v-else>
                      {{ product.unknown_wastage || 0 }} {{ $t("piece") }}
                    </span>
                  </td>
                </tr>
                <tr
                  v-if="
                    (report_item.products || []).filter((e) => !e.is_inventory)
                      .length
                  "
                >
                  <td colspan="100%">{{ $t("products") }}</td>
                </tr>
                <tr
                  v-for="product in (report_item.products || []).filter(
                    (e) => !e.is_inventory
                  )"
                  :key="product.id"
                >
                  <td>
                    <router-link
                      :to="
                        hasPermission('edit inventory')
                          ? {
                              name: 'UpdateInventoryProduct',
                              params: {
                                slug: `${product.id}-${
                                  product.name_ar ||
                                  product.name_en ||
                                  `product-${product.created_at}`
                                }`,
                              },
                            }
                          : '#'
                      "
                      class="flex items-center gap-3"
                    >
                      <div>
                        <div class="w-10 h-10">
                          <img
                            :src="
                              product.image ||
                              require('@/assets/images/placeholder.png')
                            "
                            @error="
                              $event.target.src = require('@/assets/images/placeholder.png')
                            "
                            alt="product image"
                            class="w-full h-full rounded-md border object-cover border-gray-100"
                          />
                        </div>
                      </div>
                      <div class="space-y-1 max-w-sm whitespace-normal">
                        <p class="line-clamp-2">
                          {{ product["name_" + $i18n.locale] }}
                        </p>
                        <p class="text-sm font-medium text-gray-600">
                          {{ product.item_code || product.barcode }}
                        </p>
                      </div>
                    </router-link>
                  </td>

                  <td>
                    {{ (product.category || {})["name_" + $i18n.locale] }}
                  </td>
                  <td>
                    <span v-if="product.unit_type === 'weight'">
                      {{ GenerateUnit(product.opening_stock || 0) }}
                    </span>
                    <span v-else>
                      {{ product.opening_stock || 0 }} {{ $t("piece") }}
                    </span>
                  </td>
                  <td>
                    <span v-if="product.unit_type === 'weight'">
                      {{ GenerateUnit(product.closing_stock || 0) }}
                    </span>
                    <span v-else>
                      {{ product.closing_stock || 0 }} {{ $t("piece") }}
                    </span>
                  </td>
                  <td>
                    <span v-if="product.unit_type === 'weight'">
                      {{ GenerateUnit(product.sold || 0) }}
                    </span>
                    <span v-else>
                      {{ product.sold || 0 }} {{ $t("piece") }}
                    </span>
                  </td>
                  <td>
                    <span v-if="product.unit_type === 'weight'">
                      {{ GenerateUnit(product.processed || 0) }}
                    </span>
                    <span v-else>
                      {{ product.processed || 0 }} {{ $t("piece") }}
                    </span>
                  </td>
                  <td>
                    <span v-if="product.unit_type === 'weight'">
                      {{ GenerateUnit(product.wastage || 0) }}
                    </span>
                    <span v-else>
                      {{ product.wastage || 0 }} {{ $t("piece") }}
                    </span>
                  </td>
                  <td
                    :class="
                      product.unknown_wastage > 0
                        ? 'text-red-500 font-semibold'
                        : ''
                    "
                  >
                    <span v-if="product.unit_type === 'weight'">
                      {{ GenerateUnit(product.unknown_wastage || 0) }}
                    </span>
                    <span v-else>
                      {{ product.unknown_wastage || 0 }} {{ $t("piece") }}
                    </span>
                  </td>
                </tr>
              </template>

              <template #tbody v-else>
                <tr>
                  <td colspan="100%" class="!text-center">
                    {{ $t("no-data") }}
                  </td>
                </tr>
              </template>
            </Table>
          </div>
        </template>
      </Modal>
    </section>
  </AlertsContainerWrapper>
</template>
<script>
import AlertsContainerWrapper from "@/components/Inventory/AlertsContainerWrapper.vue";
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";
import Modal from "@/components/basics/Modal.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "StockCount",
  data() {
    return {
      load: false,
      items: [],
      add_data: {
        is_inventory: 1,
      },
      add_errors: {},
      add_error: null,
      add_disabled: false,
      show_active: false,
      pagination: {
        is_active: null,
        page: 1,
        sort_key: null,
        sort_type: "asc",
      },
      item: {},
      stock_counts: [],
      disables: {},
      show_report: false,
      report_item: {},
      report_data: { items: [] },
      generate_disabled: false,
    };
  },
  async created() {
    await Promise.all([this.getItems()]).finally(() => {
      this.load = true;
      this.changeIsCalled(false);
    });
  },
  watch: {
    "pagination.page"() {
      this.getItems();
    },
    is_called(val, _) {
      if (val) {
        this.getItems();
      }
    },
    show_report(val) {
      if (!val) {
        this.report_item = {};
      }
    },
  },
  computed: {
    ...mapGetters("table", ["search", "is_called"]),
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("table", ["changeIsCalled"]),

    async getItems() {
      try {
        const { total, total_pages, ...others } = this.pagination;
        const user = this.$store.state.user;

        const { result } = await this.getRoute({
          name: "stock_counts",
          options: {
            ...others,
            search_key: this.search || this.$route.query.search,
            vendor_id: user.user_type === "vendor" ? user?.id : null,
            store_id: this.store_id,
            is_inventory: 1,
          },
        });

        const { data, pagination } = result.stock_counts;
        this.items = data;
        this.$set(this.pagination, "total", pagination.total);
        this.$set(this.pagination, "total_pages", pagination.total_pages);
      } catch (error) {
        console.log(error);
        // this.createAlert(result?.message, type);
      }
    },

    showItemProducts(product) {
      this.item = product;
      this.show_active = true;
    },

    async addValues() {
      try {
        this.add_disabled = true;
        thia.add_errors = {};
        const mapData = this.items
          .map((e) => ({
            value: e.value,
            id: e.id,
          }))
          .filter((e) => e.value);
        const { data } = await this.axios.post("stock_counts/add", {
          products: mapData,
          store_id: this.store_id,
        });
      } catch (error) {
        console.log(error);
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.createAlert(responseError, "error");
        }
      } finally {
        this.add_disabled = false;
      }
    },
    async GenerateItemReport(key, id, itemId) {
      try {
        // this.disables[key] = true;
        this.$set(this.disables, key, true);
        const { data } = await this.axios.get(
          "stock_counts/report/" + this.store_id,
          {
            params: { closing_id: itemId },
          }
        );
        this.show_report = true;
        this.report_item = data.result;
      } catch (error) {
        console.log(error);
      } finally {
        delete this.disables[key];
      }
    },
    async GenerateReportBetweenTwoItems() {
      try {
        // this.disables[key] = true;
        this.generate_disabled = true;
        const { data } = await this.axios.get(
          "stock_counts/report/" + this.store_id,
          {
            params: {
              closing_id: Math.max(...this.report_data?.items),
              opening_id: Math.min(...this.report_data?.items),
            },
          }
        );
        this.show_report = true;
        this.report_item = data.result;
      } catch (error) {
        console.log(error);
      } finally {
        this.generate_disabled = false;
      }
    },
  },
  components: { Modal, AlertsContainerWrapper, ButtonWithLoader },
};
</script>
