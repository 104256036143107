<template>
  <div>
    <div class="space-y-6">
      <div class="w-full h-auto rounded-lg bg-primary p-8 text-center relative">
        <h1 class="text-2xl font-extrabold text-white">
          {{ $t("pricing") }}
        </h1>
        <p class="text-white">
          {{ $t("pricing-content") }}
        </p>
        <i
          class="fa-solid fa-cube absolute -bottom-2 -right-2 rtl:right-auto rtl:-left-2 text-6xl text-white/20"
        ></i>
      </div>
      <div class="grid">
        <ul class="flex gap-4 overflow-x-auto w-full">
          <li
            class="border border-gray-200 rounded-lg block bg-white flex-1"
            v-for="item in items"
            :key="item.id"
          >
            <div
              class="flex items-center flex-col justify-center p-4 text-center space-y-1"
            >
              <h3 class="text-gray-600 font-medium">
                {{ item["name_" + $i18n.locale] }}
              </h3>
              <h1 class="text-red-600 text-xl font-extrabold">
                {{
                  item.total_price
                    ? generateCurrency(item.total_price || 0)
                    : $t("free")
                }}
              </h1>
              <p class="text-gray-500 text-sm">
                {{ item.trial_days || 0 }} {{ $t("package-trial-use") }}
              </p>
            </div>
          </li>
        </ul>
      </div>

      <div class="grid">
        <Table
          :title="$t('price-comparison')"
          :has_search="false"
          :records="items.length || 0"
        >
          <template #head>
            <tr>
              <th>
                {{ $t("name") }}
              </th>
              <th>
                {{ $t("price") }}
              </th>
              <th>
                {{ $t("discount") }}
              </th>
              <th>
                {{ $t("users") }}
              </th>
              <th>
                {{ $t("installments") }}
              </th>
              <th>
                {{ $t("months") }}
              </th>
              <th>
                {{ $t("devices") }}
              </th>
            </tr>
          </template>
          <template #tbody>
            <tr v-for="item in items" :key="item.id">
              <td>{{ item["name_" + $i18n.locale] }}</td>
              <td>
                {{
                  item.total_price
                    ? generateCurrency(item.total_price || 0)
                    : $t("free")
                }}
              </td>
              <td>
                {{ item.discount_value || 0 }}
              </td>
              <td>
                {{ item.users_limit || 0 }}
              </td>
              <td>{{ item.installments || 0 }}</td>
              <td>
                {{ item.months || 0 }}
              </td>
              <td>
                {{ item.devices?.length || 0 }}
                <a
                  href="javascript:void(0)"
                  @click.prevent="showDevices(item.id)"
                  v-if="(item.devices || []).length"
                  class="text-blue-600 inline-flex items-center gap-2 underline"
                >
                  <span>{{ $t("view") }}</span>
                  <i class="fa-solid fa-circle-info"></i>
                </a>
              </td>
            </tr>
          </template>
        </Table>
      </div>
    </div>
    <Modal
      :title="$t('devices')"
      v-if="devices_active"
      @close="devices_active = $event"
    >
      <template>
        <div class="p-4">
          <ul class="list-none space-y-3 divide-y divide-gray-200">
            <li
              class="flex gap-4 pt-3 first:p-0"
              v-for="(device, index) in infoPackage.devices"
              :key="index"
            >
              <div
                class="shrink-0 w-14 h-14 rounded flex items-center justify-center bg-gray-100 border border-gray-200"
              >
                <img
                  :src="
                    device.image || require('@/assets/images/placeholder.png')
                  "
                  @error="
                    (e) =>
                      (e.target.src = require('@/assets/images/placeholder.png'))
                  "
                  alt="Device image"
                  class="max-w-full max-h-full shrink-0 object-contain"
                />
              </div>
              <div class="flex-1 space-y-1">
                <p class="font-semibold rtl:font-bold text-sm">
                  {{ device["name_" + $i18n.locale] }}
                </p>
                <p class="font-medium rtl:font-semibold text-sm text-gray-600">
                  {{ device["desc_" + $i18n.locale] }}
                </p>
              </div>
              <div class="shrink-0">
                <p class="text-sm font-bold text-primary">
                  {{ (+device.price || 0).toFixed(2) }} SAR
                </p>
              </div>
            </li>
          </ul>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/basics/Modal.vue";
import Table from "@/components/basics/Table.vue";
import { mapActions } from "vuex";

export default {
  name: "Pricing",
  components: { Table, Modal },
  data() {
    return {
      items: [],
      devices_active: false,
    };
  },
  created() {
    this.getItems();
  },
  watch: {
    devices_active(val) {
      if (!val) {
        this.infoPackage = {};
      }
    },
  },
  computed: {
    selectedPackage() {
      return this.items?.find((e) => e.id === this.add_data.package_id);
    },
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),

    async getItems() {
      try {
        const { result } = await this.getRoute("packages");
        this.items = result.packages;
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },

    showDevices(id) {
      const item = this.items.find((e) => e.id === id);
      if (!item?.devices?.length) {
        this.createAlert(
          "There are no devices available into this package",
          "info"
        );
        return;
      }
      this.infoPackage = item;
      this.devices_active = true;
    },
  },
};
</script>
