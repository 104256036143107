<template>
  <div>
    <SubPagesHeader :title="'#' + item.id">
      <template #action>
        <t-dropdown
          :classes="{
            dropdown:
              'absolute right-0 rtl:left-0 rtl:right-auto w-screen !max-w-fit rounded ring-1 ring-gray-200 mt-2 overflow-hidden !top-full !bottom-auto bg-white whitespace-nowrap',
          }"
        >
          <div
            slot="trigger"
            slot-scope="{
              mousedownHandler,
              focusHandler,
              blurHandler,
              keydownHandler,
            }"
          >
            <button
              class="w-full p-4 flex items-center justify-center border border-gray-300 text-gray-600 rounded gap-3 text-sm font-semibold"
              aria-label="Actions"
              aria-haspopup="true"
              @mousedown="mousedownHandler"
              @focus="focusHandler"
              @blur="blurHandler"
              @keydown="keydownHandler"
            >
              <i class="fa-solid fa-ellipsis fa-sm"></i>
            </button>
          </div>

          <div slot-scope="{ hide }" class="!w-full !right-0 !left-0">
            <ul
              class="list-none ring-1 ring-gray-300 divide-y divide-gray-200 w-full"
            >
              <li class="py-2 px-4">
                <a
                  href="javascript:void(0)"
                  class="inline-flex gap-4 text-gray-600 items-center text-sm"
                  @click.prevent="show_products = true"
                >
                  <span class="shrink-0">
                    <i class="fa-solid fa-pen"></i>
                  </span>
                  <p class="font-semibold flex-1">Refund products</p>
                </a>
              </li>
              <li class="py-2 px-4">
                <a
                  href="javascript:void(0)"
                  class="inline-flex gap-4 text-gray-600 items-center text-sm"
                  @click.prevent="DeleteInvoice"
                >
                  <span class="shrink-0">
                    <i class="fa-solid fa-trash"></i>
                  </span>
                  <p class="font-semibold flex-1">Delete invoice</p>
                </a>
              </li>
            </ul>
          </div>
        </t-dropdown>
      </template>
      <section
        class="w-full flex flex-col md:flex-row gap-4 flex-wrap"
        v-if="load"
      >
        <!-- <div class="w-full flex item-center justify-between gap-2 flex-wrap">
          <p class="text-xl text-gray-600 font-semibold rtl:font-bold">
            #{{ item.id }}
          </p>
          <div>
            <p
              class="text-sm inline-flex gap-2 items-center text-gray-600 font-semibold rtl:font-bold"
            >
              <i class="fa-solid fa-clock"></i>
              <span>{{
                item.created_at | moment("DD-MM-yyyy hh:mm:ssA")
              }}</span>
            </p>
          </div>
        </div> -->
        <div class="w-full flex item-center justify-end gap-2 flex-wrap">
          <button
            class="py-1.5 px-3 text-sm flex items-center gap-2 text-gray-600 font-medium rtl:font-semibold ring-1 ring-gray-200 bg-white rounded shrink-0"
            @click.prevent="ExportPDF(item.id)"
            :disabled="disables['download_pdf_' + item.id]"
          >
            <spinner
              size="w-4 h-4 inline-block"
              v-if="disables['download_pdf_' + item.id]"
            />
            <i class="fa-solid fa-file-pdf" v-else></i>

            <span>Download PDF</span>
          </button>
        </div>
        <aside class="w-full md:max-w-sm space-y-4">
          <figure class="w-full bg-white ring-1 ring-gray-200 rounded">
            <header class="border-b border-b-gray-100 p-3">
              <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                Invoice details
              </p>
            </header>
            <blockquote class="px-4 py-2">
              <ul class="list-none w-full divide-y divide-gray-100 space-y-2">
                <li class="grid grid-cols-2 py-2">
                  <div class="w-full">
                    <p
                      class="text-sm text-gray-600 font-semibold rtl:font-bold"
                    >
                      Total products
                    </p>
                  </div>
                  <div class="w-full">
                    <p
                      class="text-sm text-gray-600 font-semibold rtl:font-bold"
                    >
                      {{ item.products.length }}
                    </p>
                  </div>
                </li>
                <li class="grid grid-cols-2 py-2">
                  <div class="w-full">
                    <p
                      class="text-sm text-gray-600 font-semibold rtl:font-bold"
                    >
                      Subtotal
                    </p>
                  </div>
                  <div class="w-full">
                    <p
                      class="text-sm text-gray-600 font-semibold rtl:font-bold"
                    >
                      {{ item.sub_total }} SAR
                    </p>
                  </div>
                </li>

                <li class="grid grid-cols-2 py-2">
                  <div class="w-full">
                    <p
                      class="text-sm text-gray-600 font-semibold rtl:font-bold"
                    >
                      Vat
                    </p>
                  </div>
                  <div class="w-full">
                    <p
                      class="text-sm text-gray-600 font-semibold rtl:font-bold"
                    >
                      {{ item.vat }} SAR
                    </p>
                  </div>
                </li>
                <li class="grid grid-cols-2 py-2">
                  <div class="w-full">
                    <p
                      class="text-sm text-gray-600 font-semibold rtl:font-bold"
                    >
                      Discount
                    </p>
                  </div>
                  <div class="w-full">
                    <p
                      class="text-sm text-gray-600 font-semibold rtl:font-bold"
                    >
                      {{ item.discount }} SAR
                    </p>
                  </div>
                </li>
                <li class="grid grid-cols-2 py-2">
                  <div class="w-full">
                    <p
                      class="text-sm text-gray-600 font-semibold rtl:font-bold"
                    >
                      Extra discount
                    </p>
                  </div>
                  <div class="w-full">
                    <p
                      class="text-sm text-gray-600 font-semibold rtl:font-bold"
                    >
                      {{ item.extra_discount }} SAR
                    </p>
                  </div>
                </li>

                <li class="grid grid-cols-2 py-2">
                  <div class="w-full">
                    <p
                      class="text-sm text-gray-600 font-semibold rtl:font-bold"
                    >
                      Total
                    </p>
                  </div>
                  <div class="w-full">
                    <p
                      class="text-sm text-gray-600 font-semibold rtl:font-bold"
                    >
                      {{ item.total }} SAR
                    </p>
                  </div>
                </li>
                <li class="grid grid-cols-2 py-2">
                  <div class="w-full">
                    <p
                      class="text-sm text-gray-600 font-semibold rtl:font-bold"
                    >
                      Card amount
                    </p>
                  </div>
                  <div class="w-full">
                    <p class="text-sm inline-flex gap-2 items-center">
                      <span
                        class="text-gray-600 font-semibold rtl:font-bold inline-block"
                        >{{ item.card_amount || 0 }} SAR</span
                      >
                      <a
                        href="javascript:void(0)"
                        @click="update_card_amount = !update_card_amount"
                        :class="
                          update_card_amount ? 'text-red-600' : 'text-sky-600'
                        "
                      >
                        <i
                          class="fa-solid fa-x fa-sm"
                          v-if="update_card_amount"
                        ></i>
                        <i class="fa-solid fa-pen fa-sm" v-else></i>
                      </a>
                    </p>
                  </div>
                  <slide-transition>
                    <div class="col-span-2 mt-4" v-if="update_card_amount">
                      <div class="form-group space-y-2">
                        <input
                          type="number"
                          v-model="card_amount"
                          placeholder="Card amount."
                          :disabled="disables['card_amount']"
                          @keydown.stop.enter="UpdateInvoice('card_amount')"
                        />
                        <p class="text-xs text-gray-600 font-medium">
                          Press enter to update new value
                        </p>
                        <p v-if="errors.card_amount" class="error">
                          {{ errors?.card_amount?.join(" ") }}
                        </p>
                      </div>
                    </div>
                  </slide-transition>
                </li>
                <li class="grid grid-cols-2 py-2">
                  <div class="w-full">
                    <p
                      class="text-sm text-gray-600 font-semibold rtl:font-bold"
                    >
                      Cash amount
                    </p>
                  </div>
                  <div class="w-full">
                    <p class="text-sm inline-flex gap-2 items-center">
                      <span
                        class="text-gray-600 font-semibold rtl:font-bold inline-block"
                        >{{ item.cash_amount || 0 }} SAR</span
                      >
                      <a
                        href="javascript:void(0)"
                        @click="update_cash_amount = !update_cash_amount"
                        :class="
                          update_cash_amount ? 'text-red-600' : 'text-sky-600'
                        "
                      >
                        <i
                          class="fa-solid fa-x fa-sm"
                          v-if="update_cash_amount"
                        ></i>
                        <i class="fa-solid fa-pen fa-sm" v-else></i>
                      </a>
                    </p>
                  </div>
                  <slide-transition>
                    <div class="col-span-2 mt-4" v-if="update_cash_amount">
                      <div class="form-group space-y-2">
                        <input
                          type="number"
                          v-model="cash_amount"
                          placeholder="Card amount."
                          :disabled="disables['cash_amount']"
                          @keydown.stop.enter="UpdateInvoice('cash_amount')"
                        />
                        <p class="text-xs text-gray-600 font-medium">
                          Press enter to update new value
                        </p>
                        <p v-if="errors.cash_amount" class="error">
                          {{ errors?.cash_amount?.join(" ") }}
                        </p>
                      </div>
                    </div>
                  </slide-transition>
                </li>
                <li class="grid grid-cols-2 py-2">
                  <div class="w-full">
                    <p
                      class="text-sm text-gray-600 font-semibold rtl:font-bold"
                    >
                      Invoice date
                    </p>
                  </div>
                  <div class="w-full">
                    <p class="text-sm inline-flex gap-2 items-center">
                      <span
                        class="text-gray-600 font-semibold rtl:font-bold inline-block"
                        >{{ item.invoice_date }}</span
                      >
                      <a
                        href="javascript:void(0)"
                        @click="update_invoice_date = !update_invoice_date"
                        :class="
                          update_invoice_date ? 'text-red-600' : 'text-sky-600'
                        "
                      >
                        <i
                          class="fa-solid fa-x fa-sm"
                          v-if="update_invoice_date"
                        ></i>
                        <i class="fa-solid fa-pen fa-sm" v-else></i>
                      </a>
                    </p>
                  </div>
                  <slide-transition>
                    <div class="col-span-2 mt-4" v-if="update_invoice_date">
                      <div class="form-group space-y-2">
                        <input
                          type="date"
                          v-model="invoice_date"
                          placeholder="Card amount."
                          :disabled="disables['invoice_date']"
                          @keydown.stop.enter="updateInvoiceDate"
                        />
                        <p class="text-xs text-gray-600 font-medium">
                          Press enter to update new value
                        </p>
                        <p v-if="errors.invoice_date" class="error">
                          {{ errors?.invoice_date?.join(" ") }}
                        </p>
                      </div>
                    </div>
                  </slide-transition>
                </li>
                <li class="grid grid-cols-2 py-2">
                  <div class="w-full">
                    <p
                      class="text-sm text-gray-600 font-semibold rtl:font-bold"
                    >
                      Creation date
                    </p>
                  </div>
                  <div class="w-full">
                    <p
                      class="text-sm text-gray-600 font-semibold rtl:font-bold"
                    >
                      {{ item.created_at | moment("DD-MM-yyyy hh:mm:ssA") }}
                    </p>
                  </div>
                </li>
              </ul>
            </blockquote>
          </figure>
          <figure class="w-full bg-white ring-1 ring-gray-200 rounded">
            <header class="border-b border-b-gray-100 p-3">
              <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                Customer details
              </p>
            </header>
            <blockquote class="px-4 py-2">
              <ul class="list-none w-full divide-y divide-gray-100 space-y-2">
                <li class="grid grid-cols-2 py-2">
                  <div class="w-full">
                    <p
                      class="text-sm text-gray-600 font-semibold rtl:font-bold"
                    >
                      Name
                    </p>
                  </div>
                  <div class="w-full">
                    <p
                      class="text-sm text-gray-600 font-semibold rtl:font-bold"
                    >
                      {{ item.customer_name || "-" }}
                    </p>
                  </div>
                </li>
                <li class="grid grid-cols-2 py-2">
                  <div class="w-full">
                    <p
                      class="text-sm text-gray-600 font-semibold rtl:font-bold"
                    >
                      Mobile
                    </p>
                  </div>
                  <div class="w-full">
                    <a
                      :href="`tel:${item.customer_mobile}`"
                      v-if="item.customer_mobile"
                      class="text-sky-600 text-sm break-all"
                    >
                      {{ item.customer_mobile || "-" }}
                    </a>
                    <span v-else>-</span>
                  </div>
                </li>
              </ul>
            </blockquote>
          </figure>
          <figure class="w-full bg-white ring-1 ring-gray-200 rounded">
            <header class="border-b border-b-gray-100 p-3">
              <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                User details
              </p>
            </header>
            <blockquote class="px-4 py-2">
              <ul class="list-none w-full divide-y divide-gray-100 space-y-2">
                <li class="grid grid-cols-2 py-2">
                  <div class="w-full">
                    <p
                      class="text-sm text-gray-600 font-semibold rtl:font-bold"
                    >
                      Name
                    </p>
                  </div>
                  <div class="w-full">
                    <p
                      class="text-sm text-gray-600 font-semibold rtl:font-bold"
                    >
                      {{ (item.user || {}).name || "-" }}
                    </p>
                  </div>
                </li>

                <li class="grid grid-cols-2 py-2">
                  <div class="w-full">
                    <p
                      class="text-sm text-gray-600 font-semibold rtl:font-bold"
                    >
                      Mobile
                    </p>
                  </div>
                  <div class="w-full">
                    <a
                      :href="`tel:${(item.user || {}).mobile}`"
                      v-if="(item.user || {}).mobile"
                      class="text-sky-600 text-sm break-all"
                    >
                      {{ (item.user || {}).mobile || "-" }}
                    </a>
                    <span v-else>-</span>
                  </div>
                </li>

                <li class="grid grid-cols-2 py-2">
                  <div class="w-full">
                    <p
                      class="text-sm text-gray-600 font-semibold rtl:font-bold"
                    >
                      Email
                    </p>
                  </div>
                  <div class="w-full">
                    <a
                      :href="`tel:${(item.user || {}).email}`"
                      v-if="(item.user || {}).email"
                      class="text-sky-600 text-sm break-all"
                    >
                      {{ (item.user || {}).email || "-" }}
                    </a>
                    <span v-else>-</span>
                  </div>
                </li>
              </ul>
            </blockquote>
          </figure>
        </aside>
        <main class="w-full md:flex-1 space-y-4">
          <figure class="w-full bg-white ring-1 ring-gray-200 rounded">
            <header class="border-b border-b-gray-100 p-3">
              <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                Store details
              </p>
            </header>
            <blockquote class="px-4 py-2">
              <ul class="list-none w-full divide-y divide-gray-100 space-y-2">
                <li class="grid grid-cols-2 py-2">
                  <div class="w-full">
                    <p
                      class="text-sm text-gray-600 font-semibold rtl:font-bold"
                    >
                      Name
                    </p>
                  </div>
                  <div class="w-full">
                    <p
                      class="text-sm text-gray-600 font-semibold rtl:font-bold"
                    >
                      {{ (item.store || {})["name_" + $i18n.locale] || "-" }}
                    </p>
                  </div>
                </li>
                <li class="grid grid-cols-2 py-2">
                  <div class="w-full">
                    <p
                      class="text-sm text-gray-600 font-semibold rtl:font-bold"
                    >
                      Address
                    </p>
                  </div>
                  <div class="w-full">
                    <a
                      :href="`https://maps.google.com/?q=${
                        (item.store || {}).lat
                      },${(item.store || {}).lng}`"
                      class="text-sm text-sky-600 font-semibold rtl:font-bold"
                      v-if="(item.store || {}).lat && (item.store || {}).lng"
                    >
                      {{ (item.store || {}).address || "-" }}
                    </a>
                    <span v-else>Location isn't not available</span>
                  </div>
                </li>
                <li class="grid grid-cols-2 py-2">
                  <div class="w-full">
                    <p
                      class="text-sm text-gray-600 font-semibold rtl:font-bold"
                    >
                      Store
                    </p>
                  </div>
                  <div class="w-full">
                    <p
                      class="text-sm text-gray-600 font-semibold rtl:font-bold"
                    >
                      {{ (item.store || {})["name_" + $i18n.locale] || "-" }}
                    </p>
                  </div>
                </li>
              </ul>
            </blockquote>
          </figure>
          <figure
            class="w-full rounded bg-white ring-1 ring-gray-200"
            v-for="(product, index) in item.products"
            :key="index"
          >
            <blockquote class="flex flex-col sm:flex-row gap-6 p-4">
              <div>
                <div>
                  <img
                    :src="
                      product.image
                        ? product.image
                        : require('@/assets/images/placeholder.png')
                    "
                    @error="
                      $event.target.src = require('@/assets/images/placeholder.png')
                    "
                    alt="product image"
                    class="w-20 h-20 rounded-full object-contain"
                  />
                </div>
              </div>
              <div class="flex-1">
                <ul class="list-none space-y-1">
                  <li class="flex items-start gap-2 justify-between">
                    <p class="text-primary font-semibold">
                      {{ product["name_" + $i18n.locale] }}
                    </p>
                    <p
                      class="status bg-red-500 text-white"
                      v-if="product.full_refund"
                    >
                      Refunded
                    </p>
                  </li>
                  <li>
                    <p class="text-gray-600 font-medium text-sm line-clamp-2">
                      {{ product["desc_" + $i18n.locale] }}
                    </p>
                  </li>
                  <li class="!mt-2">
                    <ul
                      class="list-none grid grid-cols-2 gap-2"
                      v-if="product.unit_type === 'weight'"
                    >
                      <li>
                        <p
                          class="text-xs text-gray-500 font-medium rtl:font-semibold"
                        >
                          Unit price:
                        </p>
                      </li>
                      <li>
                        <p
                          class="text-sm text-gray-600 font-semibold text-right rtl:text-left"
                        >
                          {{ product.price }} SAR
                        </p>
                      </li>
                      <li>
                        <p
                          class="text-xs text-gray-500 font-medium rtl:font-semibold"
                        >
                          Total weight:
                        </p>
                      </li>
                      <li>
                        <p
                          class="text-sm text-gray-600 font-semibold text-right rtl:text-left"
                        >
                          {{ GenerateUnit(product.weight) }}
                        </p>
                      </li>
                      <li
                        v-if="
                          product.refunded_weight &&
                          product.unit_type === 'weight'
                        "
                      >
                        <p
                          class="text-xs text-gray-500 font-medium rtl:font-semibold"
                        >
                          Refunded weight:
                        </p>
                      </li>
                      <li
                        v-if="
                          product.refunded_weight &&
                          product.unit_type === 'weight'
                        "
                      >
                        <p
                          class="text-sm text-gray-600 font-semibold text-right rtl:text-left"
                        >
                          {{ GenerateUnit(product.refunded_weight) }}
                        </p>
                      </li>
                      <li
                        v-if="
                          product.refunded_qty && product.unit_type === 'normal'
                        "
                      >
                        <p
                          class="text-xs text-gray-500 font-medium rtl:font-semibold"
                        >
                          Refunded QTY:
                        </p>
                      </li>
                      <li
                        v-if="
                          product.refunded_qty && product.unit_type === 'normal'
                        "
                      >
                        <p
                          class="text-sm text-gray-600 font-semibold text-right rtl:text-left"
                        >
                          {{ product.refunded_qty }} Piece
                        </p>
                      </li>
                      <li v-if="product.discount > 0">
                        <p
                          class="text-xs text-gray-500 font-medium rtl:font-semibold"
                        >
                          Discount:
                        </p>
                      </li>
                      <li v-if="product.discount > 0">
                        <p
                          class="text-sm text-gray-600 font-semibold text-right rtl:text-left"
                        >
                          {{
                            (
                              (product.discount || 0) *
                              (product.unit_type === "weight"
                                ? product.weight || 0
                                : product.qty || 0)
                            ).toFixed(2)
                          }}
                          SAR
                        </p>
                      </li>
                      <li>
                        <p
                          class="text-xs text-gray-500 font-medium rtl:font-semibold"
                        >
                          Total:
                        </p>
                      </li>
                      <li>
                        <p
                          class="text-sm text-gray-600 font-semibold text-right rtl:text-left"
                        >
                          <!-- // (product.price - (product.discount || 0)) *
                      // product.weight -->
                          {{ product.total.toFixed(2) }}
                          SAR
                        </p>
                      </li>
                    </ul>
                    <ul class="list-none grid grid-cols-2 gap-2" v-else>
                      <li>
                        <p
                          class="text-xs text-gray-500 font-medium rtl:font-semibold"
                        >
                          Unit price:
                        </p>
                      </li>
                      <li>
                        <p
                          class="text-sm text-gray-600 font-semibold text-right rtl:text-left"
                        >
                          {{ product.price }} SAR
                        </p>
                      </li>
                      <li>
                        <p
                          class="text-xs text-gray-500 font-medium rtl:font-semibold"
                        >
                          Total QTY:
                        </p>
                      </li>
                      <li>
                        <p
                          class="text-sm text-gray-600 font-semibold text-right rtl:text-left"
                        >
                          {{ product.qty }}
                        </p>
                      </li>
                      <li>
                        <p
                          class="text-xs text-gray-500 font-medium rtl:font-semibold"
                        >
                          Total:
                        </p>
                      </li>
                      <li>
                        <p
                          class="text-sm text-gray-600 font-semibold text-right rtl:text-left"
                        >
                          {{ generateCurrency(product.total) }}
                          SAR
                        </p>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </blockquote>
          </figure>
        </main>
      </section>
      <section class="w-full p-6 flex flex-col md:flex-row gap-4" v-else>
        <aside class="w-full lg:w-1/3 2xl:max-w-sm space-y-4">
          <figure
            class="w-full bg-white rounded ring-1 ring-gray-200"
            v-for="l in 3"
            :key="l"
          >
            <header class="p-4 border-b border-b-gray-100">
              <div class="w-1/2 h-4 rounded animate-pulse bg-gray-200"></div>
            </header>
            <blockquote class="p-4 space-y-4">
              <ul class="list-none w-full divide-y divide-gray-100 space-y-2">
                <li class="grid grid-cols-2 py-2" v-for="i in 2" :key="i">
                  <div class="w-full">
                    <div
                      class="w-2/3 h-4 rounded bg-gray-200 animate-pulse"
                    ></div>
                  </div>
                  <div class="w-full">
                    <div
                      class="w-2/3 h-4 rounded bg-gray-200 animate-pulse"
                    ></div>
                  </div>
                </li>
              </ul>
            </blockquote>
          </figure>
        </aside>
        <main class="w-full lg:w-2/3 2xl:flex-1 space-y-4">
          <figure class="w-full bg-white rounded ring-1 ring-gray-200">
            <header class="p-4 border-b border-b-gray-100">
              <div class="w-1/2 h-4 rounded animate-pulse bg-gray-200"></div>
            </header>
            <blockquote class="p-4 space-y-4">
              <ul class="list-none w-full divide-y divide-gray-100 space-y-2">
                <li class="grid grid-cols-2 py-2" v-for="i in 2" :key="i">
                  <div class="w-full">
                    <div
                      class="w-2/3 h-4 rounded bg-gray-200 animate-pulse"
                    ></div>
                  </div>
                  <div class="w-full">
                    <div
                      class="w-2/3 h-4 rounded bg-gray-200 animate-pulse"
                    ></div>
                  </div>
                </li>
              </ul>
            </blockquote>
          </figure>
        </main>
      </section>

      <modal
        title="Refund products"
        size="!max-w-screen-sm"
        v-if="show_products"
        @close="show_products = $event"
      >
        <template>
          <div class="space-y-4 p-6">
            <slide-transition>
              <div
                class="flex items-center justify-end gap-2"
                v-if="products.length"
              >
                <!-- <button
                  class="py-2 px-3 rounded bg-gray-200 text-gray-600 text-xs"
                  :disabled="disables['products']"
                  @click.prevent="UpdateInvoice('products')"
                >
                  Update products ({{ products.length }})
                </button> -->
                <button
                  class="py-2 px-3 rounded bg-red-500 text-white text-xs"
                  :disabled="disables['refund_products']"
                  @click.prevent="RefundProducts"
                >
                  Refund products ({{ products.length }})
                </button>
              </div>
            </slide-transition>

            <ul class="divide-y divide-gray-200">
              <li
                class="py-4 first:pt-0"
                v-for="(product, index) in invoice_products"
                :key="product.id"
              >
                <div class="flex gap-4 items-start">
                  <div class="self-center">
                    <div class="checkbox">
                      <input
                        type="checkbox"
                        :value="product.id"
                        v-model="products"
                      />
                    </div>
                  </div>
                  <img
                    :src="
                      product.image
                        ? product.image
                        : require('@/assets/images/placeholder.png')
                    "
                    @error="
                      $event.target.src = require('@/assets/images/placeholder.png')
                    "
                    alt="product name"
                    class="w-10 h-10 rounded-full object-cover shrink-0"
                  />
                  <div class="flex-1">
                    <ul class="list-none space-y-1">
                      <li>
                        <p class="text-primary font-semibold">
                          {{ product["name_" + $i18n.locale] }}
                        </p>
                      </li>

                      <li class="!my-2">
                        <ul
                          class="list-none grid grid-cols-2 gap-2"
                          v-if="product.unit_type === 'weight'"
                        >
                          <li>
                            <p
                              class="text-xs text-gray-500 font-medium rtl:font-semibold"
                            >
                              Price per kilo:
                            </p>
                          </li>
                          <li>
                            <p
                              class="text-sm text-gray-600 font-semibold text-right rtl:text-left"
                            >
                              {{ product.price }} SAR
                            </p>
                          </li>
                          <li>
                            <p
                              class="text-xs text-gray-500 font-medium rtl:font-semibold"
                            >
                              Weight:
                            </p>
                          </li>
                          <li>
                            <p
                              class="text-sm text-gray-600 font-semibold text-right rtl:text-left"
                            >
                              {{ GenerateUnit(product.weight) }}
                            </p>
                          </li>
                          <li
                            v-if="
                              product.refunded_weight &&
                              product.unit_type === 'weight'
                            "
                          >
                            <p
                              class="text-xs text-gray-500 font-medium rtl:font-semibold"
                            >
                              Refunded weight:
                            </p>
                          </li>
                          <li
                            v-if="
                              product.refunded_weight &&
                              product.unit_type === 'weight'
                            "
                          >
                            <p
                              class="text-sm text-gray-600 font-semibold text-right rtl:text-left"
                            >
                              {{ GenerateUnit(product.refunded_weight) }}
                            </p>
                          </li>
                          <li
                            v-if="
                              product.refunded_qty &&
                              product.unit_type === 'normal'
                            "
                          >
                            <p
                              class="text-xs text-gray-500 font-medium rtl:font-semibold"
                            >
                              Refunded QTY:
                            </p>
                          </li>
                          <li
                            v-if="
                              product.refunded_qty &&
                              product.unit_type === 'normal'
                            "
                          >
                            <p
                              class="text-sm text-gray-600 font-semibold text-right rtl:text-left"
                            >
                              {{ product.refunded_qty }} Piece
                            </p>
                          </li>
                          <li v-if="product.discount > 0">
                            <p
                              class="text-xs text-gray-500 font-medium rtl:font-semibold"
                            >
                              Discount:
                            </p>
                          </li>
                          <li v-if="product.discount > 0">
                            <p
                              class="text-sm text-gray-600 font-semibold text-right rtl:text-left"
                            >
                              {{
                                (
                                  (product.discount || 0) *
                                  (product.unit_type === "weight"
                                    ? product.weight || 0
                                    : product.qty || 0)
                                ).toFixed(2)
                              }}
                              SAR
                            </p>
                          </li>
                          <li>
                            <p
                              class="text-xs text-gray-500 font-medium rtl:font-semibold"
                            >
                              Total:
                            </p>
                          </li>
                          <li>
                            <p
                              class="text-sm text-gray-600 font-semibold text-right rtl:text-left"
                            >
                              <!-- // (product.price - (product.discount || 0)) *
                      // product.weight -->
                              {{ product.total.toFixed(2) }}
                              SAR
                            </p>
                          </li>
                        </ul>
                        <ul class="list-none grid grid-cols-2 gap-2" v-else>
                          <li>
                            <p
                              class="text-xs text-gray-500 font-medium rtl:font-semibold"
                            >
                              Price:
                            </p>
                          </li>
                          <li>
                            <p
                              class="text-sm text-gray-600 font-semibold text-right rtl:text-left"
                            >
                              {{ product.price }} SAR
                            </p>
                          </li>
                          <li>
                            <p
                              class="text-xs text-gray-500 font-medium rtl:font-semibold"
                            >
                              Quantity:
                            </p>
                          </li>
                          <li>
                            <p
                              class="text-sm text-gray-600 font-semibold text-right rtl:text-left"
                            >
                              {{ product.qty }}
                            </p>
                          </li>
                          <li>
                            <p
                              class="text-xs text-gray-500 font-medium rtl:font-semibold"
                            >
                              Total:
                            </p>
                          </li>
                          <li>
                            <p
                              class="text-sm text-gray-600 font-semibold text-right rtl:text-left"
                            >
                              {{
                                (product.price - (product.discount || 0)) *
                                product.weight
                              }}
                              SAR
                            </p>
                          </li>
                        </ul>
                      </li>
                      <li class="space-y-1">
                        <p
                          v-if="errors['products.' + index + '.id']"
                          class="error"
                        >
                          {{ errors["products." + index + ".id"].join(" ") }}
                        </p>
                        <p
                          v-if="errors['products.' + index + '.weight']"
                          class="error"
                        >
                          {{
                            errors["products." + index + ".weight"].join(" ")
                          }}
                        </p>
                        <p
                          v-if="errors['products.' + index + '.qty']"
                          class="error"
                        >
                          {{ errors["products." + index + ".qty"].join(" ") }}
                        </p>
                        <p v-if="errors.invoice_id" class="error">
                          {{ errors.invoice_id.join(" ") }}
                        </p>
                        <p v-if="errors.products" class="error">
                          {{ errors.products.join(" ") }}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </template>
      </modal>
      <modal
        @close="code_active = $event"
        v-if="code_active"
        title="Confirm deletion"
      >
        <template>
          <form class="space-y-4 p-6">
            <p class="text-sm text-center text-gray-600">
              Enter the verification code that was sent to you via e-mail to
              confirm the deletion process
            </p>
            <slide-transition>
              <div class="space-y-3">
                <div class="form-group">
                  <input
                    type="text"
                    placeholder="Type code .."
                    v-model="code_data.code"
                  />
                </div>
                <button
                  class="py-2 px-3 rounded bg-red-500 text-white text-xs"
                  :disabled="disables['delete_invoice']"
                  @click.prevent="DeleteInvoice"
                >
                  Submit
                </button>
              </div>
            </slide-transition>
          </form>
        </template>
      </modal>
    </SubPagesHeader>
  </div>
</template>

<script>
import Modal from "@/components/basics/Modal.vue";
import SubPagesHeader from "@/components/basics/SubPagesHeader.vue";
import { mapActions } from "vuex";

export default {
  name: "InvoiceDetails",
  components: { SubPagesHeader, Modal },
  data() {
    return {
      item: {},
      load: false,
      refund_data: {},
      refund_errors: {},
      refund_disabled: false,
      disables: {},
      update_cash_amount: false,
      cash_amount: 0,
      cash_amount_disabled: false,
      update_card_amount: false,
      card_amount: 0,
      code_data: {},
      code_active: false,
      code_errors: {},
      card_amount_disabled: false,
      disables: {},
      products: [],
      invoice_products: [],
      show_products: false,
      errors: {},
      update_invoice_date: false,
      invoice_date: null,
    };
  },
  computed: {
    itemId() {
      return this.$route.params.id;
    },
  },
  created() {
    this.getItem();
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    async getItem() {
      try {
        const { result } = await this.getRoute(`invoices/${this.itemId}`);
        this.item = {
          ...result.invoice,
        };
        this.cash_amount = this.item?.cash_amount || 0;
        this.card_amount = this.item?.card_amount || 0;
        this.invoice_products = this.item?.products || [];
        this.show_products = false;
        this.invoice_date = this.item?.invoice_date;
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
        // this.$router.push("/products");
      } finally {
        this.load = true;
      }
    },
    async ExportPDF(itemId) {
      try {
        this.$set(this.disables, `download_pdf_${itemId}`, true);

        const { data } = await this.axios.post(
          "invoices/download/" + itemId,
          {},
          {
            responseType: "blob",
            headers: {
              "Content-type": "blob",
            },
          }
        );
        let blob = new Blob([data]);
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${itemId}-${new Date().getTime()}.pdf`;
        link.click();
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        this.createAlert(responseError, "error");
      } finally {
        this.disables = {};
      }
    },

    async RefundProducts() {
      try {
        const { isConfirmed } = await this.$swal.fire({
          theme: "bulma",
          title: "Are you sure you want to do this process?",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          showCancelButton: true,
        });
        if (!isConfirmed) return;
        const products = this.products?.map((id) => {
          const product = this.invoice_products.find((item) => item.id == id);

          return {
            id,
            ...(product.unit_type === "weight"
              ? { weight: product.weight }
              : { qty: product.qty }),
          };
        });
        this.$set(this.disables, "refund_products", true);
        const { data } = await this.axios.post("invoices/refund", {
          products,
          invoice_id: this.itemId,
        });
        this.createAlert(data.message);
        this.getItem();
        this.show_products = false;
      } catch (error) {
        const err = error?.response?.data;
        const message = err.message;
        if (message instanceof Object) {
          this.errors = message;
        } else {
          this.createAlert(message);
        }
      } finally {
        this.disables = {};
      }
    },
    async updateInvoiceDate() {
      try {
        const { isConfirmed } = await this.$swal.fire({
          theme: "bulma",
          title: "Are you sure you want to do this process?",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          showCancelButton: true,
        });
        if (!isConfirmed) return;
        this.$set(this.disables, "invoice_date", true);
        const { data } = await this.axios.post("invoices/update", {
          invoice_date: this.invoice_date,
          id: this.itemId,
        });
        this.createAlert(data.message);
        this.getItem();
      } catch (error) {
        const err = error?.response?.data;
        const message = err.message;
        if (message instanceof Object) {
          this.errors = message;
        } else {
          this.createAlert(message);
        }
      } finally {
        this.disables = {};
      }
    },
    async UpdateInvoice(key) {
      try {
        const { isConfirmed } = await this.$swal.fire({
          theme: "bulma",
          title: "Are you sure you want to do this process?",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          showCancelButton: true,
        });
        if (!isConfirmed) return;
        this.$set(this.disables, key, true);
        const { data } = await this.axios.post("invoices/update", {
          [key]: this.$data[key],
          id: this.itemId,
        });
        this.createAlert(data.message);
        this.getItem();
      } catch (error) {
        const err = error?.response?.data;
        const message = err.message;
        if (message instanceof Object) {
          this.errors = message;
        } else {
          this.createAlert(message);
        }
      } finally {
        this.disables = {};
      }
    },
    async DeleteInvoice() {
      try {
        if (!this.code_data.code) {
          const { isConfirmed } = await this.$swal.fire({
            theme: "bulma",
            title: "Are you sure you want to delete this invoice?",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            showCancelButton: true,
          });

          if (!isConfirmed) return;
        }
        this.$set(this.disables, "delete_invoice", true);

        const { data } = await this.axios.post(
          `invoices/delete/${this.itemId}`,
          this.code_data
        );

        if (data.result.need_code) return (this.code_active = true);
        this.code_active = false;
        this.code_data = {};

        this.createAlert(data.message);

        // this.$router.push("/analytics/invoices");
      } catch (err) {
        const res = err?.response;
        const message = res?.data?.message;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        if (message instanceof Object) {
          return (this.code_errors = message);
        }
        this.createAlert(message, "error");
      } finally {
        this.disables = {};
      }
    },
  },
};
</script>
