<template>
  <div>
    <div class="shrink-0">
      <div class="w-12 h-12 rounded-full p-1 border border-gray-200 relative">
        <a target="_blank" :href="url">
          <img
            :src="url || require('@/assets/images/placeholder.png')"
            @error="
              $event.target.src = require('@/assets/images/placeholder.png')
            "
            alt="view image"
            class="w-full h-full object-cover rounded-full"
          />
        </a>
        <button
          class="w-5 h-5 rounded-full bg-red-500 text-white absolute -top-1 -right-1 rtl:right-auto rtl:-left-1 flex items-center justify-center text-[0.5rem]"
          @click.prevent="$emit('delete')"
          type="button"
        >
          <i class="fa-solid fa-plus align-middle"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ImageUploader from "../ImageUploader.vue";

export default {
  name: "ViewImageWithUploader",
  props: { file: { type: File, default: null } },
  components: { ImageUploader },
  computed: {
    url() {
      return this.file instanceof File
        ? URL.createObjectURL(this.file)
        : this.file;
    },
  },
};
</script>
