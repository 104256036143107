<template>
  <footer class="border-t border-t-gray-200 p-6 stink bottom-0 bg-white z-10">
    <div class="xl:container">
      <button
        class="py-3 px-4 rounded bg-primary text-sm text-white flex items-center gap-3 w-full max-w-xs mx-auto"
        type="button"
        v-bind="$attrs"
        @click.prevent="$emit('click')"
      >
        <span class="flex-1">{{ $t("next") }}</span>
        <i class="fa-solid fa-angle-right rtl:hidden"></i>
        <i class="fa-solid fa-angle-left hidden rtl:inline"></i>
      </button>
    </div>
  </footer>
</template>

<script>
export default {
  name: "BottomControlling",
};
</script>
