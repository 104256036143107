<template>
  <button
    class="w-full p-4 rounded-md border border-gray-100 flex gap-4 transition-all bg-white"
    :class="{
      'bg-primary/10 border-primary/30 hover:bg-primary/20': isComponent,
    }"
    @click.prevent="changeComponents"
  >
    <div>
      <div
        class="w-12 h-12 rounded-full bg-gray-100 flex items-center justify-center text-gray-600"
        :class="{
          'bg-primary/25 !text-primary': isComponent,
        }"
      >
        <i :class="icon"></i>
      </div>
    </div>
    <div class="flex-1 rtl:text-right text-left">
      <h1 class="text-base font-semibold rtl:font-bold text-primary">
        {{ title }}
      </h1>
      <p class="text-sm font-medium rtl:font-semibold text-gray-600">
        {{ content }}
      </p>
    </div>
  </button>
</template>
<script>
export default {
  name: "Button",
  props: {
    title: {
      type: String,
    },
    content: {
      type: String,
    },
    icon: {
      type: String,
    },
    component: {
      type: String,
    },
    isComponent: {
      type: Boolean,
    },
  },
  methods: {
    changeComponents() {
      this.$emit("change", this.component);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>
