<template>
  <div>
    <section class="w-full space-y-4 p-6" v-if="load">
      <!-- <header>
        <cards-container :cards="cards" />
      </header> -->
      <main class="w-full space-y-4">
        <div class="w-full max-w-sm mx-auto">
          <t-datepicker
            :placeholder="`From - To`"
            multiple
            :monthsPerView="2"
            v-model="pagination.date"
            range
            :clearable="false"
            userFormat="Y-m-d"
            :weekStart="6"
            @change="getDashboard"
          />
        </div>

        <!-- summary cards -->
        <div
          class="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4"
        >
          <figure class="w-full bg-white rounded ring-1 ring-gray-200">
            <header class="p-4 border-b border-b-gray-100">
              <p
                class="text-sm font-semibold rtl:font-bold text-center text-primary"
              >
                {{ $t("sales-summary") }}
              </p>
            </header>
            <blockquote class="px-4 py-2">
              <ul class="list-none w-full divide-y divide-gray-100 space-y-2">
                <li
                  class="grid grid-cols-2 pt-3 pb-1"
                  v-for="(item, index) in sales_summary"
                  :key="index"
                >
                  <div class="w-full">
                    <p
                      class="text-sm text-gray-600 font-semibold rtl:font-bold"
                    >
                      {{ $t(item.title) }}
                    </p>
                  </div>
                  <div class="w-full">
                    <p
                      class="text-sm text-gray-600 font-semibold rtl:font-bold text-right rtl:text-left"
                    >
                      <span v-if="!/count/.test(item.key)">
                        {{ generateCurrency(item.value) }}
                      </span>
                      <span v-else>
                        {{ item.value }}
                      </span>
                    </p>
                  </div>
                </li>
              </ul>
            </blockquote>
          </figure>
          <figure class="w-full bg-white rounded ring-1 ring-gray-200">
            <header class="p-4 border-b border-b-gray-100">
              <p
                class="text-sm font-semibold rtl:font-bold text-center text-primary"
              >
                {{ $t("payment-method") }}
              </p>
            </header>
            <blockquote class="px-4 py-2">
              <ul class="list-none w-full divide-y divide-gray-100 space-y-2">
                <li
                  class="grid grid-cols-2 pt-3 pb-1"
                  v-for="(item, index) in payment_methods"
                  :key="index"
                >
                  <div class="w-full">
                    <p
                      class="text-sm text-gray-600 font-semibold rtl:font-bold"
                    >
                      {{ $t(item.title) }}
                    </p>
                  </div>
                  <div class="w-full">
                    <p
                      class="text-sm text-gray-600 font-semibold rtl:font-bold text-right rtl:text-left"
                    >
                      {{ generateCurrency(item.value) }}
                    </p>
                  </div>
                </li>
              </ul>
            </blockquote>
          </figure>
          <div
            class="w-full bg-white rounded ring-1 ring-gray-200 overflow-hidden"
          >
            <header
              class="p-4 border-b border-b-gray-100 flex justify-between gap-2"
            >
              <p class="text-base font-semibold rtl:font-bold text-gray-600">
                {{ $t("recent-purchase-orders") }}
              </p>
              <router-link
                to="/inventory-actions"
                class="text-sm font-semibold rtl:font-bold text-primary"
              >
                {{ $t("view-all") }}
              </router-link>
            </header>
            <div class="max-h-[30rem] overflow-y-auto p-2">
              <ul class="divide-y divide-gray-100">
                <li v-if="!recent_orders.length" class="p-4">
                  <empty-card
                    icon="fa-solid fa-shopping-cart"
                    :content="$t('no-result')"
                  />
                </li>
                <li v-for="(item, index) in recent_orders" :key="index">
                  <router-link
                    :to="'/inventory-actions/orders/' + item.id"
                    class="flex gap-4 p-4"
                  >
                    <span
                      class="shrink-0 flex items-center justify-center bg-gray-100 w-10 h-10 text-gray-600 rounded-full"
                    >
                      <i class="fa-solid fa-shopping-cart"></i>
                    </span>
                    <div class="shrink-0 flex-1">
                      <p
                        class="text-sm font-semibold rtl:font-bold text-gray-600"
                      >
                        {{ generateCurrency(item.total) }}
                      </p>
                      <p class="text-xs text-gray-500">
                        {{ $moment(item.created_at).format("lll") }}
                      </p>
                    </div>
                    <span class="shrink-0">
                      <i class="fa-solid fa-angle-right rtl:hidden"></i>
                      <i class="fa-solid fa-angle-left hidden rtl:inline"></i>
                    </span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- summary cards -->
        <global-chart :charts="charts" />
        <!-- orders summary -->

        <!-- coverages summary -->
        <div>
          <Table
            :title="$t('locations')"
            :records="locations.length"
            :has_search="false"
            :isEmpty="!locations.length"
          >
            <template #head>
              <th>{{ $t("location") }}</th>
              <th>{{ $t("purchase-orders") }}</th>
              <th>{{ $t("invoices") }}</th>
              <th>{{ $t("refunded-orders") }}</th>
              <th>{{ $t("cancelled-orders") }}</th>
            </template>
            <template #tbody v-if="!load">
              <TableLoad :rows="5" v-for="i in 4" :key="i" />
            </template>
            <template #tbody v-else>
              <tr v-for="(item, index) in locations" :key="index">
                <td>{{ item["name_" + $i18n.locale] }}</td>
                <!-- <td>{{ item.order_code }}</td> -->
                <td>{{ item.orders_count }}</td>
                <td>{{ item.invoices_count }}</td>
                <!-- <td>{{ item.growth }}</td> -->
                <td>{{ item.refunded_orders_count }}</td>
                <td>{{ item.cancelled_orders_count }}</td>
              </tr>
            </template>
          </Table>
        </div>
        <!-- coverages summary -->
      </main>
    </section>
    <section class="py-10 px-6" v-else>
      <spinner
        size="w-6 h-6"
        class="table align-middle text-gray-600 mx-auto"
      />
    </section>
  </div>
</template>

<script>
import CardsContainer from "@/components/basics/CardsContainer.vue";
import EmptyCard from "@/components/basics/EmptyCard.vue";
import FilterWrapper from "@/components/basics/FilterWrapper.vue";
import GlobalChart from "@/components/charts/GlobalChart.vue";
import { mapActions, mapMutations } from "vuex";
export default {
  components: { CardsContainer, EmptyCard, FilterWrapper, GlobalChart },
  name: "Home",
  data() {
    return {
      load: false,
      cards: [
        {
          title: "Stores",
          key: "stores_count",
          icon: "fa-solid fa-store",
          value: 0,
          style: {
            icon: "text-yellow-600",
            parent: "bg-yellow-600/20",
          },
        },
        {
          title: "Orders",
          key: "orders_count",
          icon: "fa-solid fa-shopping-cart",
          value: 0,
          style: {
            icon: "text-sky-600",
            parent: "bg-sky-600/20",
          },
        },
        {
          title: "Incomes",
          key: "incomes",
          icon: "fa-solid fa-dollar-sign",
          value: 0,
          style: {
            icon: "text-teal-600",
            parent: "bg-teal-600/20",
          },
        },
        {
          title: "OUT OF STOCK Products",
          key: "out_of_stock_products_count",
          icon: "fa-solid fa-arrow-trend-down",
          value: 0,
          style: {
            icon: "text-red-600",
            parent: "bg-red-600/20",
          },
        },
        // {
        //   title: "Discount",
        //   key: "discount_count",
        //   icon: "fa-solid fa-percentage",
        //   value: 0,
        //   style: {
        //     icon: "text-teal-600",
        //     parent: "bg-teal-600/20",
        //   },
        // },
        {
          title: "Inventory purchases",
          key: "inventory_purchases_count",
          icon: "fa-solid fa-shopping-bag",
          value: 0,
          style: {
            icon: "text-gray-600",
            parent: "bg-gray-600/20",
          },
        },
        {
          title: "Inventory alerts",
          key: "inventory_alerts_count",
          icon: "fa-solid fa-bell-slash",
          value: 0,
          style: {
            icon: "text-gray-600",
            parent: "bg-gray-600/20",
          },
        },
        {
          title: "Inventory transfers",
          key: "inventory_transfers_count",
          icon: "fa-solid fa-right-left",
          value: 0,
          style: {
            icon: "text-gray-600",
            parent: "bg-gray-600/20",
          },
        },
      ],
      sales_summary: [
        // {
        //   title: "Hold orders",
        //   key: "hold",
        //   value: 0,
        //   count: 0,
        // },
        // {
        //   title: "Cancelled orders",
        //   key: "cancelled",
        //   value: 0,
        //   count: 0,
        // },
        // {
        //   title: "Refunds orders",
        //   key: "refunded",
        //   value: 0,
        //   count: 0,
        // },
        {
          title: "incomes",
          key: "income",
          value: 0,
          count: 0,
        },
        {
          title: "invoices",
          key: "invoices_count",
          value: 0,
          count: 0,
        },
        {
          title: "products",
          key: "products_count",
          value: 0,
          count: 0,
        },
        {
          title: "vat",
          key: "vat",
          value: 0,
          count: 0,
        },
        {
          title: "discounts",
          key: "discounts",
          value: 0,
          count: 0,
        },
      ],
      payment_methods: [
        {
          title: "card-amount",
          key: "card_payments",
          value: 0,
          count: 0,
        },
        {
          title: "cash-amount",
          key: "cash_payments",
          value: 0,
          count: 0,
        },
      ],
      inventory_alerts: [
        {
          name: null,

          count: 100,
        },
        {
          name: null,

          count: 20,
        },
        {
          name: null,

          count: 200,
        },
        {
          name: null,

          count: 100,
        },
        {
          name: null,

          count: 40,
        },
        {
          name: null,

          count: 10,
        },
        {
          name: null,

          count: 100,
        },
      ],
      stores_summary: [
        {
          name: null,
          count: 100,
        },
        {
          name: null,

          count: 20,
        },
        {
          name: null,

          count: 200,
        },
        {
          name: null,

          count: 100,
        },
        {
          name: null,

          count: 40,
        },
        {
          name: null,

          count: 10,
        },
        {
          name: null,

          count: 100,
        },
      ],
      item_status: {
        1: "bg-sky-500/10 text-sky-500",
        0: "bg-red-600/10 text-red-600",
        new: "bg-sky-500/10 text-sky-500",
        completed: "bg-gray-500/10 text-gray-500",
        preparing: "bg-yellow-500/10 text-yellow-500",
        ready: "bg-sky-700/10 text-sky-700",
        delivering: "bg-teal-500/10 text-teal-500",
        hold: "bg-gray-500/10 text-gray-500",
        cancelled: "bg-red-600/10 text-red-600",
        refunded: "bg-red-700/10 text-red-700",
      },

      locations: [],
      summary: {},
      recent_orders: [],
      pagination: {
        // from: new Date().toISOString().substring(0, 10),
      },
      charts: [],
    };
  },

  async created() {
    this.$set(this.pagination, "date", [
      this.$route.query.date ||
        this.$moment().add(-1, "days").format("YYYY-MM-DD"),
      this.$route.query.date || this.$moment().format("YYYY-MM-DD"),
    ]);
    await Promise.all([this.getDashboard()]).finally(() => {
      this.load = true;
    });
  },

  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("table", ["changeIsCalled"]),
    async getDashboard() {
      try {
        const { date } = this.pagination;
        const { result } = await this.getRoute({
          name: "dashboard",
          options: {
            from: date?.[0],
            to: date?.[1],
            store_id: this.store_id,
          },
        });
        const { recent_orders, dashboard, stores, charts, sales_summary } =
          result;
        this.locations = stores;
        // display sales summary
        // this.DisplayItemsInsideTheirObject(
        //   dashboard,
        //   "sales_summary",
        //   "key",
        //   "value"
        // );
        // this.DisplayItemsInsideTheirObject(
        //   dashboard,
        //   "payment_methods",
        //   "key",
        //   "value"
        // );
        this.sales_summary = this.sales_summary.map((ev) => ({
          ...ev,
          value: sales_summary?.[ev.key] || 0,
        }));
        this.payment_methods = this.payment_methods.map((ev) => ({
          ...ev,
          value: sales_summary?.[ev.key] || 0,
        }));

        this.recent_orders = recent_orders;
        this.charts = charts;
        // display mine dashboard cards
        this.DisplayItemsInsideTheirObject(dashboard, "cards", "key", "value");
      } catch (error) {
        console.log(error);
      } finally {
        const filterWrapper = this.$refs.filterWrapper;
        filterWrapper?.resetAll();
        this.changeIsCalled(false);
      }
    },

    handleReset() {
      const { page, ...others } = this.pagination;

      for (const key in others) {
        const item = others[key];
        if (typeof item === "number") {
          this.$set(this.pagination, key, 0);
        } else if (Array.isArray(item)) {
          this.$set(this.pagination, key, []);
        } else {
          this.$set(this.pagination, key, null);
        }
      }

      this.getDashboard();
    },

    DisplayItemsInsideTheirObject(object, array, selector, val) {
      Object.entries(object).forEach(([key, value]) => {
        const index = this[array].findIndex((e) => e[selector] === key);
        if (index < 0) return;
        const item = this[array][index];
        console.log(item);
        this.$set(this[array], index, {
          ...item,
          [val]: value,
        });
      });
    },
    getPaymentImage(image) {
      try {
        return require(`@/assets/images/${image}.png`);
      } catch (error) {
        return require(`@/assets/images/cash.png`);
      }
    },
  },
};
</script>
