<template>
  <figure
    class="w-full bg-white rounded ring-1 ring-gray-200 divide-y divide-gray-200"
  >
    <header class="p-4 flex items-center justify-between" v-if="title">
      <p class="text-base font-semibold rtl:font-bold flex-1">{{ title }}</p>
      <slot name="actions"></slot>
    </header>
    <blockquote class="w-full p-5 space-y-4 grid" v-bind="$attrs">
      <slot></slot>
    </blockquote>
  </figure>
</template>

<script>
export default {
  name: "Card",
  props: ["title"],
};
</script>
