export default {
  namespaced: true,
  state: {
    global_cart: {},
  },

  getters: {
    cart: (state) => (id) => state.global_cart[id],
  },
  mutations: {
    SET_ITEMS(state, data) {
      state.global_cart = data;
    },
    GENERATE_CART(state, key) {
      Object.assign(state.global_cart, {
        [key]: [],
      });
    },
  },
  actions: {
    addItemsInsideCart(
      { commit, getters, dispatch, state },
      { product, id, module_type }
    ) {
      return new Promise(async (resolve) => {
        const user = this.state.user;
        const cartKey = `${user.user_type}_${user.id}`;
        const findProduct = getters.cart(cartKey)?.find((e) => e.id === id);
        const cloneUserCart = JSON.parse(JSON.stringify(getters.cart(cartKey)));

        if (!findProduct) {
          const newProduct = await dispatch(
            "generateProduct",
            JSON.parse(
              JSON.stringify({
                ...product,
                module_type,
              })
            )
          );
          commit("SET_ITEMS", {
            ...state.global_cart,
            [cartKey]: [...cloneUserCart, newProduct],
          });
          resolve();
          return;
        }

        commit("SET_ITEMS", {
          ...state.global_cart,
          [cartKey]: cloneUserCart.map((e) => {
            if (e.id === id) {
              if (e.is_meat) {
                return {
                  ...e,
                  weight: product.type ? e.weight - 1 : e.weight + 1,
                };
              } else {
                return {
                  ...e,
                  qty: product.type ? e.qty - 1 : e.qty + 1,
                };
              }
            } else {
              return e;
            }
          }),
        });

        resolve();
      });
      // console.log(findProduct);
      /* ---------------------*/
    },
    removeItemFromCart({ commit, state, getters }, id) {
      return new Promise((resolve) => {
        const user = this.state.user;
        const cartKey = `${user.user_type}_${user.id}`;
        const cloneUserCart = JSON.parse(JSON.stringify(getters.cart(cartKey)));

        commit("SET_ITEMS", {
          ...state.global_cart,
          [cartKey]: cloneUserCart.filter((e) => e.id !== id),
        });
        resolve("Product deleted successfully");
      });
    },
    generateProduct({ dispatch }, product) {
      return new Promise((resolve) => {
        let result = {};
        const keys = [
          "id",
          "name_ar",
          "name_en",
          "image",
          "qty",
          "barcode",
          "price",
          "sku",
          "store",
          "weight",
          "module_type",
        ];
        const isNotButchery = ["qty"];
        const isButchery = ["weight", "approx_weight"];

        for (const key in product) {
          if (
            keys
              .filter(
                (e) =>
                  !(
                    product.unit_type === "weight" ? isNotButchery : isButchery
                  ).includes(e)
              )
              .includes(key)
          ) {
            result[key] = product[key];
          }
        }

        if (result.module_type === "butchery") {
          Object.assign(result, {
            weight: 1,
            actual_weight: result.weight || result.approx_weight,
            is_meat: true,
          });
        } else {
          Object.assign(result, {
            qty: 1,
            actual_qty: result.qty,
            is_meat: false,
          });
        }

        resolve(result);
      });
    },

    generateNewCart({ commit }, id) {
      return new Promise((resolve) => {
        commit("GENERATE_CART", id);
        resolve();
      });
    },
  },
  modules: {},
};
