import { pick } from "lodash";
export default {
  namespaced: true,
  state: {
    products: [],
    open_the_safe_data: {},
    payment_option: "cash",
    device: JSON.parse(localStorage.getItem("@pos-device")),
    device_id: JSON.parse(localStorage.getItem("@pos-device"))?.id,
    total: 0,
  },
  getters: {
    getProducts: (state) => state.products,
    getDevice: (state) => state.device,
    getPayment: (state) => state.payment_option,
    getTheOpenSafe: (state) => state.open_the_safe_data,
    getTotal: (state) => state.total,
    getProduct: (state) => (id) =>
      state.products.find((product) => product.id == id),
  },
  mutations: {
    addNewProduct(state, data) {
      let item = pick(data, [
        "name_ar",
        "name_en",
        "stock",
        "price",
        "image",
        "id",
        "sale_price",
        "unit_type",
        "tracking_stock",
      ]);

      item.price = data.discount_price ? data.discount_price : data.price;
      const findProduct = state.products.find(
        (product) => product.id == data.id
      );
      const result = [...state.products, { ...item, qty: 1 }];
      if (!findProduct)
        return this.dispatch("pos_system/checkProductsAmount", result)
          .then(() => {
            state.products = result;
          })
          .catch((message) => alert(message));

      this.commit("pos_system/updateProduct", {
        id: data.id,
        qty: findProduct.qty + 1,
      });
    },
    removeProduct(state, id) {
      const filterProducts = state.products.filter((e) => e.id !== id);
      state.products = filterProducts;
      return this.dispatch("pos_system/checkProductsAmount", filterProducts);
    },
    async updateProduct(state, { id, qty }) {
      const deepClone = JSON.parse(JSON.stringify(state.products));
      const updatedProducts = state.products.map((product) =>
        product.id == id ? { ...product, qty } : product
      );

      this.dispatch("pos_system/checkProductsAmount", updatedProducts)
        .then(() => {
          state.products = updatedProducts;
        })
        .catch((message) => {
          alert(message);
          state.products = deepClone;
        });
    },
    updateDevice(state, data) {
      state.device = data;
      state.device_id = data.id;
      localStorage.setItem("@pos-device", JSON.stringify(data));
    },
    updateTheOpenSafe(state, data) {
      state.open_the_safe_data = data;
    },
    updateAmount(state, data) {
      console.log(data);
      state.total = data;
    },
  },
  actions: {
    checkProductsAmount({ state, commit, dispatch }, payload) {
      return new Promise(async (resolve, reject) => {
        const { open_the_safe_data } = state;
        const total = await dispatch("getProductsTotal", payload);
        // const maxAmount = Number(open_the_safe_data?.amount || 0);

        // if (total > maxAmount)
        //   return reject("Sales amount is larger than the safe amount.");

        commit("updateAmount", total);
        resolve();
      });
    },
    async getProductsTotal({ state }, payload) {
      let total = 0;
      await Promise.all(
        payload.map((product) => {
          const { price, qty } = product;
          total += parseFloat(
            (Number(price || 0) * Number(qty || 0)).toFixed(2)
          );
        })
      );

      return total;
    },
  },
  modules: {},
};
