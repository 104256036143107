import axios from "axios";
import Vue from "vue";
import Vuex from "vuex";
import modules from "./modules";
import securePlugin from "./plugins/plugin";

Vue.use(Vuex);
// https://www.getpostman.com/collections/b0466209ce74e58ba757 - portal
// https://www.getpostman.com/collections/522e686a04fff72ee946 - admin

export default new Vuex.Store({
  state: {
    auth_type: "login",
    token: null,
    user: {},
    vendor_data: {},
    store: {},
    map_api_key: "AIzaSyBL9zlkm2YRyU_hN1yMGrVJ6_JlEHCzzig",
    BASE_URL:
      process.env.NODE_ENV != "production"
        ? "https://apis.flexicashier.com/portal"
        : "https://apis.flexicashier.com/portal",
  },
  mutations: {
    SET_AUTH_TYPE(state, data) {
      state.auth_type = data;
    },
    SET_TOKEN(state, data) {
      state.token = data;
    },
    SET_STORE(state, data) {
      state.store = data;
    },

    SET_REGISTER_DATA(state, data) {
      state.vendor_data = Object.assign(data, {
        mobile: data.mobile.replace(/\s/g, "").replace("+", ""),
      });
    },
    SET_USER_DATA(state, data) {
      state.user = data;
    },
  },

  modules: { ...modules },
  plugins: [securePlugin],
  getters: {
    getUserInfo(state) {
      return { ...state.user };
    },
  },
  actions: {
    async getStoreInfo({ commit }) {
      try {
        const { data } = await axios.get("store");
        if (data.error_flag === 0) {
          commit("SET_STORE", data.result);
          document.querySelector("title").innerHTML =
            data.result.store_info.name || "POS";
        }
      } catch ({ response }) {
        let { error_flag, message } = response.data;
        if (error_flag === 1) {
        }
      }
    },
  },
});
