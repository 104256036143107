<template>
  <div>
    <modal
      :title="$t('refund')"
      size="!max-w-screen-lg"
      @close="hide"
      v-show="active"
    >
      <template>
        <div class="space-y-2 p-6">
          <div class="form-group flex-1 space-y-3">
            <div class="flex items-center gap-4 justify-between">
              <label for="ref_no">{{$t("ref-no")}}</label>
              <a
                href="javascript:void(0)"
                class="text-teal-600 font-semibold flex items-center justify-start gap-2 text-xs"
                @click.prevent="ref_no = Date.now()"
              >
                <i class="fa-solid fa-sync fa-sm"></i>
                <span>{{$t("generate")}}</span>
              </a>
            </div>
            <input
              type="text"
              name="ref_no"
              id="ref_no"
              v-model="ref_no"
              placeholder="..."
            />
            <p class="error">
              {{ (add_errors["ref_no"] || []).join(" ") }}
            </p>
          </div>
          <expanded title="Select products">
            <ul class="flex items-center justify-start gap-2 flex-wrap">
              <li v-for="product in item.products" :key="product.id">
                <input
                  type="checkbox"
                  hidden
                  :name="'order-product-' + product.id"
                  :id="'order-product-' + product.id"
                  class="peer"
                  :value="product.id"
                  @change.prevent="onProductSelected"
                  :checked="isChecked(product.id)"
                />
                <label
                  :for="'order-product-' + product.id"
                  class="inline-flex gap-2 py-2 px-3 rounded bg-gray-100 text-gray-600 text-sm font-medium items-center cursor-pointer transition peer-checked:bg-primary peer-checked:text-white"
                >
                  <img
                    :src="
                      product.image ||
                      require('@/assets/images/placeholder.png')
                    "
                    @error="
                      $event.target.src = require('@/assets/images/placeholder.png')
                    "
                    class="w-6 h-6 rounded-full object-cover"
                    alt="product image"
                  />
                  <p class="line-clamp-1">
                    {{ product["name_" + $i18n.locale] }}
                  </p>
                </label>
              </li>
            </ul>
          </expanded>
          <table class="styled-table border border-gray-200">
            <thead>
              <tr>
                <th>#</th>
                <th>{{$t("product")}}</th>
                <th>{{$t("price")}}</th>
                <th>{{$t("weight")}}</th>
                <th>{{$t("qty")}}</th>
                <th>{{$t("actions")}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-show="!products.length">
                <td colspan="100%">
                  <p class="text-sm text-center text-gray-600 font-medium">
                    {{$t('no-data')}}
                  </p>
                </td>
              </tr>
              <tr v-for="(product, index) in products" :key="product.id">
                <td>{{ index + 1 }}</td>
                <td>
                  <p class="max-w-sm min-w-[15rem] line-clamp-1">
                    {{ product.product?.["name_" + $i18n.locale] }}
                  </p>
                </td>
                <td>
                  <!-- <Controllable v-model="product.price" /> -->
                  <p class="text-gray-800 font-semibold text-sm">
                    {{ product.price }} SAR
                  </p>
                </td>
                <td>
                  <Controllable
                    v-model="product.weight"
                    v-if="product.product?.unit_type === 'weight'"
                  />
                  <span v-else>N/A</span>
                </td>

                <td>
                  <Controllable v-model="product.qty" />
                </td>
                <td>
                  <button
                    type="button"
                    class="py-2 px-3 rounded bg-red-500 text-white font-medium text-sm inline-flex items-center gap-2"
                    @click.prevent="deleteProduct(product.id)"
                  >
                    <i class="fa-solid fa-trash fa-sm"></i>
                    <span>{{$t("delete")}}</span>
                  </button>
                </td>
              </tr>
              <tr>
                <td colspan="100%"></td>
                <td>
                  <p class="text-gray-800 font-semibold text-sm">
                    {{$t("total")}} {{ getTotal }} SAR
                  </p>
                </td>
                <td colspan="100%"></td>
              </tr>
            </tbody>
          </table>
          <button
            type="button"
            class="py-2 px-3 rounded bg-primary text-white font-medium text-sm inline-flex items-center gap-2"
            @click.prevent="onRefundSubmit"
            :disabled="add_disabled"
          >
            <i class="fa-solid fa-check fa-sm"></i>
            <span>{{$t("save-changes")}}</span>
          </button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from "../basics/Modal.vue";
import Card from "../Card.vue";
import Controllable from "../Controllable.vue";
import Expanded from "../Expanded.vue";
export default {
  components: { Modal, Expanded, Card, Controllable },
  name: "RefundComponent",
  props: {
    item: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      products: [],
      refund_data: {},
      add_disabled: false,
      add_errors: {},
      active: false,
      ref_no: Date.now(),
    };
  },
  computed: {
    isChecked() {
      return (id) => this.products.some((e) => e.id == id);
    },
    getTotal() {
      let total = 0;
      const products = this.products;

      for (const product of products) {
        const { price = 0, weight = 0, qty = 0 } = product;
        const { unit_type } = product.product;
        if (unit_type === "weight") {
          total += Number(price * weight);
          continue;
        }
        total += Number(price * qty);
      }

      return total;
    },
  },
  methods: {
    onProductSelected(ev) {
      const { value, checked } = ev.target;
      const product = this.item.products.find((e) => e.id == value);
      if (!product) return this.createAlert("Product not found", "error");

      const { name_ar, name_en, unit_type, weight, qty, price } = product;
      const item = {
        id: value,
        product: { name_ar, name_en, unit_type, weight, qty, price },
        qty,
        price,
        ...(unit_type === "weight" && { weight }),
      };
      const concatProducts = [...this.products, item];
      const filterProducts = this.products.filter((e) => e.id !== value);

      if (checked) {
        this.products = concatProducts;
        return this.createAlert("Product added");
      }
      this.products = filterProducts;
    },
    deleteProduct(id) {
      const filterProducts = this.products.filter((e) => e.id !== id);
      this.products = filterProducts;
    },
    hide() {
      this.add_data = {};
      this.products = [];
      this.active = false;
    },
    show() {
      this.active = true;
    },
    async onRefundSubmit() {
      try {
        this.add_disabled = true;
        const products = this.products.map((e) => {
          const { product, ...others } = e;
          return others;
        });
        const order = {
          order_id: this.item.id,
          store_id: this.store_id,
          ref_no: this.ref_no,
          products,
          order_type: "refund",
        };
        const { data } = await this.axios.post("orders/new", order);
        this.createAlert(data.message);
        this.$router.push("/inventory-actions/refunds");
      } catch (error) {
        const err = error?.response?.data;
        const message = err.message;
        if (message instanceof Object) {
          this.add_errors = message;
          return;
        }
        this.createAlert(message, "error");
      } finally {
        this.add_disabled = false;
      }
    },
  },
};
</script>
