<template>
  <div class="p-6 pt-2">
    <div class="mx-auto table" v-if="load">
      <spinner />
    </div>
    <div class="space-y-4" v-else>
      <card :title="$t('balance')">
        <div class="space-y-1">
          <p class="text-xl font-bold text-center">
            {{ generateCurrency(details.balance || 0) }}
          </p>
          <p class="text-sm text-gray-500 text-center">{{ details.name }}</p>
        </div>
      </card>
      <Table
        :title="$t('transactions')"
        :records="`${pagination.total || 0} `"
        :pagination="pagination"
        :total_pages="pagination.total_pages"
        v-model="pagination.page"
      >
        <template #head>
          <tr>
            <th>#</th>
            <th>{{ $t("account") }}</th>
            <th>{{ $t("type") }}</th>
            <th>{{ $t("debit") }}</th>
            <th>{{ $t("credit") }}</th>
            <th>{{ $t("balance") }}</th>
            <th>{{ $t("date") }}</th>
          </tr>
        </template>
        <template #tbody v-if="load">
          <TableLoad :rows="7" v-for="i in 4" :key="i" />
        </template>
        <template #tbody v-else-if="!!items.length">
          <tr v-for="transaction in items" :key="transaction.id">
            <td>{{ transaction.id }}</td>
            <td>
              <router-link
                :to="
                  '/accounting/chart/' + transaction.account?.id + '/details'
                "
                class="text-blue-500 hover:underline"
                >{{ transaction.account?.code }} -
                {{ transaction.account?.name }}</router-link
              >
            </td>
            <td>{{ $t(transaction.account?.type) }}</td>
            <td>{{ generateCurrency(transaction.debit || 0) }}</td>
            <td>{{ generateCurrency(transaction.credit || 0) }}</td>
            <td>{{ generateCurrency(transaction.balance) }}</td>
            <td
              v-html="
                createTime(transaction.updated_at || transaction.created_at)
              "
            ></td>
          </tr>
        </template>
        <template #tbody v-else>
          <tr>
            <td colspan="100%" class="!text-center">
              {{ $t("no-data") }}
            </td>
          </tr>
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
import Card from "@/components/Card.vue";
import TreeButton from "@/components/TreeButton.vue";
import AddAccount from "@/components/accounting/AddAccount.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: { Card, TreeButton, AddAccount },
  name: "AccountDetails",
  data() {
    return {
      load: true,
      details: {},
      items: [],
      pagination: {},
    };
  },
  computed: {
    ...mapGetters("table", ["search", "is_called"]),

    id() {
      return this.$route.params.id;
    },
  },
  watch: {
    "pagination.page"() {
      this.getItems();
    },
    is_called(val, _) {
      if (val) {
        this.getItems();
      }
    },
  },
  created() {
    this.load = true;
    Promise.all([this.getDetails(), this.getItems()]).finally(() => {
      this.load = false;
    });
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("table", ["changeIsCalled"]),

    async getItems() {
      try {
        const { total, total_pages, ...others } = this.pagination;

        const { result } = await this.getRoute({
          name: "accounts/" + this.id + "/transactions",
          options: {
            page: 1,
            ...others,
            search_key: this.search,
            store_id: this.store_id,
          },
        });
        const { data, pagination } = result.transactions;
        this.items = data;
        this.$set(this.pagination, "total", pagination.total);
        this.$set(this.pagination, "total_pages", pagination.total_pages);
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      } finally {
        this.changeIsCalled(false);
      }
    },
    async getDetails() {
      try {
        const { result } = await this.getRoute({
          name: "accounts/" + this.id,
        });

        this.details = result.account || {};
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
  },
};
</script>
