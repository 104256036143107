<template>
  <div
    class="w-full bg-gray-100 border border-gray-200 transition ps-4 flex items-center gap-4 rounded-lg overflow-hidden"
  >
    <i class="fa-solid fa-search text-gray-500 shrink-0"></i>
    <input
      type="text"
      name="searchbox"
      id="searchbox"
      :placeholder="$t('search')"
      class="bg-transparent w-full flex-1 px-4 py-3 ps-0"
      :value="value"
      @input.prevent="$emit('input', $event.target.value)"
    />
  </div>
</template>

<script>
export default {
  name: "Searchbox",
  props: {
    value: {
      type: String,
      default: undefined,
    },
  },
};
</script>
