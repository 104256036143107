import Pricing from "@/views/Pricing.vue";
import ForgotPassword from "@/views/account/ForgotPassword.vue";
import Register from "@/views/account/Register.vue";
import ResetPassword from "@/views/account/ResetPassword.vue";
import Login from "@/views/account/login.vue";
import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "@/views/Home.vue";
import AddStockCount from "@/views/AddStockCount.vue";
import Analytics from "@/views/Analytics.vue";
import Brands from "@/views/Brands.vue";
import Dashboard from "@/views/Dashboard.vue";
import Notifications from "@/views/Notifications.vue";
import OutOfStockAlerts from "@/views/OutOfStock.vue";
import Salesman from "@/views/Salesman.vue";
import StockCount from "@/views/StockCount.vue";
import Stores from "@/views/Stores.vue";
import Users from "@/views/Users.vue";
import PageNotFound from "@/views/access/PageNotFound.vue";
import PermissionsAllowed from "@/views/access/PermissionsAllowed.vue";
import Suppliers from "@/views/inventory/Suppliers.vue";
import Kitchens from "@/views/kitchens/index.vue";
import SingleTicket from "@/views/support/_id.vue";
import Support from "@/views/support/index.vue";

// customers
import StoreDetails from "@/views/StoreDetails.vue";
import CustomerGroups from "@/views/customers/Group.vue";
import customer from "@/views/customers/customer.vue";
import CustomerIndex from "@/views/customers/index.vue";
import StockPage from "@/views/inventory/Stock.vue";
import Categories from "@/views/products/Categories.vue";
import SubCategories from "@/views/products/SubCategories.vue";
import BundleProducts from "@/views/products/bundle/index.vue";
import CreateProduct from "@/views/products/create.vue";
import ProductsPage from "@/views/products/index.vue";
import NewCreateProductPage from "@/views/products/newCreate.vue";
import NewUpdateProductPage from "@/views/products/newUpdate.vue";
import UpdateProduct from "@/views/products/update.vue";
// import StockPage from "@/views/inventory/newStock.vue";
import CategoriesGroup from "@/views/CategoriesGroup.vue";
import Checkout from "@/views/Checkout.vue";
import Incomes from "@/views/Incomes.vue";
import OrderTypes from "@/views/OrderTypes.vue";
import PosShop from "@/views/POS/index.vue";
import Receipt from "@/views/Receipt.vue";
import Refunds from "@/views/Refunds.vue";
import Taxes from "@/views/Taxes.vue";
import Transfers from "@/views/Transfers.vue";
import Expired from "@/views/inventory/Expired.vue";
import NearExpiry from "@/views/inventory/NearExpiry.vue";
import Wastages from "@/views/inventory/Wastages.vue";
import Discounts from "@/views/inventory/discounts";
import InvoicesCategories from "@/views/invoices/Categories.vue";
import ProcessingTracks from "@/views/invoices/Processing.vue";
import InvoiceProducts from "@/views/invoices/Products.vue";
import InvoiceRefunds from "@/views/invoices/Refunds.vue";
import InvoicesInventory from "@/views/invoices/Stock.vue";
import InvoicesWastages from "@/views/invoices/Wastages.vue";
import InvoiceDetails from "@/views/invoices/_id.vue";
import Invoices from "@/views/invoices/index.vue";
import OrderDetails from "@/views/orders/_id.vue";
import Orders from "@/views/orders/index.vue";
import ItemProducts from "@/views/orders/items/_id.vue";
import OrderPayments from "@/views/orders/payments.vue";
// settings
import EmailConfiguration from "@/views/EmailConfiguration.vue";
import PriceList from "@/views/PriceList.vue";
import Profile from "@/views/Profile.vue";
import Reports from "@/views/Reports.vue";
import Roles from "@/views/Roles.vue";
import SafeTracks from "@/views/SafeTracks.vue";
import SettingsPage from "@/views/Settings.vue";

// accounting
import SalesSettings from "@/views/SalesSettings.vue";
import AccountDetails from "@/views/accounting/chart/details/index.vue";
import ChartOfAccounts from "@/views/accounting/chart/index.vue";
import AccountTransactions from "@/views/accounting/chart/transactions/index.vue";
import CostCenters from "@/views/accounting/cost_centers/index.vue";
import NewJournalEntries from "@/views/accounting/journal_entries/create.vue";
import JournalEntries from "@/views/accounting/journal_entries/index.vue";
import UpdateJournalEntries from "@/views/accounting/journal_entries/update.vue";
import ViewJournalEntryDetails from "@/views/accounting/journal_entries/viewDetails.vue";
import Ledger from "@/views/ledger/index.vue";

import NewOpeningBalances from "@/views/accounting/journal_entries/createOpening.vue";

import AddExpenses from "@/views/expenses/add.vue";
import Expenses from "@/views/expenses/index.vue";
import InvoiceTemplates from "@/views/templates/invoices/index.vue";

// pos system
import POSSystem from "@/views/pos_system/index.vue";

Vue.use(VueRouter);
// middleware & store
import auth from "@/middlewares/auth";
import can from "@/middlewares/can";
import shouldLogin from "@/middlewares/shouldLogin";
import store from "@/store";

const routes = [
  {
    path: "/pricing",
    name: "Pricing",
    component: Pricing,
    meta: {
      middleware: [auth],
      title: "Pricing",
    },
  },
  {
    path: "/account/login",
    name: "Login",
    component: Login,
    meta: {
      middleware: [auth],
      title: "Login",
    },
  },
  {
    path: "/account/register",
    name: "Register",
    component: Register,
    meta: {
      middleware: [auth],
      title: "Create an account",
    },
  },
  {
    path: "/account/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: {
      middleware: [auth],
      title: "Forgot password",
    },
  },
  {
    path: "/account/reset-password",
    name: "ResetPassword",
    component: ResetPassword,
    meta: {
      middleware: [auth],
      title: "Reset password",
    },
  },
  // {
  //   path: "/",
  //   name: "Home",
  //   component: Home,
  //   meta: {
  //     middleware: [auth, can],
  //   },
  // },
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      middleware: [auth, can],
      title: "Dashboard",
      can: ["show dashboard"],
    },
  },
  {
    path: "/pos-system",
    name: "POSSystem",
    component: POSSystem,
    meta: {
      middleware: [auth, can],
      title: "POS System",
      can: ["show pos_system"],
    },
  },
  {
    path: "/expenses",
    name: "Expenses",
    component: Expenses,
    meta: {
      middleware: [auth, can],
      title: "Expenses",
      can: ["show expenses"],
    },
  },
  {
    path: "/expenses/add",
    name: "AddExpenses",
    component: AddExpenses,
    meta: {
      middleware: [auth, can],
      title: "Add expense",
      can: ["add expenses"],
    },
  },
  {
    path: "/settings/receipt/settings",
    name: "InvoiceTemplates",
    component: InvoiceTemplates,
    meta: {
      middleware: [auth, can],
      title: "Invoice templates",
      can: ["update receipts"],
    },
  },
  {
    path: "/reports",
    name: "Reports",
    component: Reports,
    meta: {
      middleware: [auth, can],
      title: "Reports",
      can: [
        "show incomes",
        "show customers",
        "show oos_alerts",
        "show invoice_sales",
        "show item_sales",
        "show invoice_inventory",
        "show invoice_categories",
        "show invoice_refunds",
        "show invoice_wastage",
      ],
    },
  },
  {
    path: "/analytics",
    name: "Analytics",
    component: Analytics,
    meta: {
      middleware: [auth, can],
      // breadcrumb: {
      //   label: "analytics",
      // },
      title: "Analytics",
      can: [
        "show summary",
        // "show oos_alerts",
        // "show invoice_sales",
        // "show item_sales",
        // "show invoice_inventory",
        // "show invoice_categories",
        // "show invoice_refunds",
        // "show invoice_wastage",
        // "show incomes",
        // "show customers",
      ],
    },
  },

  {
    path: "/settings/categorizations/categories",
    name: "Categorizations",
    component: Categories,
    meta: {
      middleware: [auth, can],
      title: "Categorizations",
      breadcrumb: {
        parent: "CategoriesGroup",
        label: "categories",
      },
      can: ["show categories"],
    },
  },
  {
    path: "/settings/categorizations/sub-categories",
    name: "SubCategories",
    component: SubCategories,
    meta: {
      middleware: [auth, can],
      title: "Categorizations",
      breadcrumb: {
        parent: "CategoriesGroup",
        label: "sub-categories",
      },
      can: ["show sub_categories"],
    },
  },
  {
    path: "/products/bundle",
    name: "BundleProducts",
    component: BundleProducts,
    meta: {
      middleware: [auth, can],
      title: "Bundle products",
      endpoint_name: "products",
      template_name: "items-template.xlsx",

      // breadcrumb: {
      //   label: "products",
      // },
      can: ["show bundle_products"],
    },
  },
  {
    path: "/products/items",
    name: "ProductsPage",
    component: ProductsPage,
    meta: {
      middleware: [auth, can],
      title: "Products",
      endpoint_name: "products",
      template_name: "items-template.xlsx",

      // breadcrumb: {
      //   label: "products",
      // },
      can: ["show items"],
    },
  },
  {
    path: "/accounting/cost-centers",
    name: "CostCenters",
    component: CostCenters,
    meta: {
      middleware: [auth],
      title: "Cost centers",
      endpoint_name: "products",
    },
  },
  {
    path: "/accounting/journal-entries",
    name: "JournalEntries",
    component: JournalEntries,
    meta: {
      middleware: [auth],
      title: "Journal entries",
      endpoint_name: "journal_entries",
    },
  },
  {
    path: "/accounting/journal-entries/create",
    name: "NewJournalEntries",
    component: NewJournalEntries,
    meta: {
      middleware: [auth],
      title: "Journal entries",
      endpoint_name: "journal_entries",
    },
  },
  {
    path: "/accounting/journal-entries/opening",
    name: "NewOpeningBalances",
    component: NewOpeningBalances,
    meta: {
      middleware: [auth],
      title: "Journal entries",
      endpoint_name: "journal_entries",
    },
  },
  {
    path: "/sales-settings",
    name: "SalesSettings",
    component: SalesSettings,
    meta: {
      middleware: [auth],
      title: "Sales settings",
      endpoint_name: "sales_settings",
    },
  },
  {
    path: "/analytics/ledger",
    name: "Ledger",
    component: Ledger,
    meta: {
      middleware: [auth],
      title: "Ledger",
      endpoint_name: "accounts",
    },
  },
  {
    path: "/accounting/journal-entries/:id",
    name: "ViewJournalEntryDetails",
    component: ViewJournalEntryDetails,
    meta: {
      middleware: [auth],
      title: "Journal entry details",
      endpoint_name: "journal_entries",
    },
  },
  {
    path: "/accounting/journal-entries/update/:id",
    name: "UpdateJournalEntries",
    component: UpdateJournalEntries,
    meta: {
      middleware: [auth],
      title: "Journal entry details",
      endpoint_name: "journal_entries",
    },
  },
  {
    path: "/accounting/chart",
    name: "ChartOfAccounts",
    component: ChartOfAccounts,
    meta: {
      middleware: [auth],
      title: "Chart of accounts",
      endpoint_name: "accounts",
    },
  },
  {
    path: "/accounting/chart/:id/details",
    name: "AccountDetails",
    component: AccountDetails,
    meta: {
      middleware: [auth],
      title: "Account details",
    },
  },
  {
    path: "/accounting/chart/:id/transactions",
    name: "AccountTransactions",
    component: AccountTransactions,
    meta: {
      middleware: [auth],
      title: "Account transactions",
    },
  },
  {
    path: "/settings/kitchens",
    name: "Kitchens",
    component: Kitchens,
    meta: {
      middleware: [auth, can],
      breadcrumb: {
        parent: "SettingsPage",
        label: "kitchens",
      },
      title: "kitchens",
      can: ["show kitchens"],
    },
  },
  {
    path: "/settings/categorizations",
    name: "CategoriesGroup",
    component: CategoriesGroup,
    meta: {
      middleware: [auth, can],
      breadcrumb: {
        parent: "SettingsPage",
        label: "categorizations",
      },
      title: "Categorizations",
      can: ["show categories_group"],
    },
  },
  {
    path: "/settings/brands",
    name: "Brands",
    component: Brands,
    meta: {
      middleware: [auth, can],
      breadcrumb: {
        parent: "SettingsPage",
        label: "brands",
      },
      title: "Brands",
      can: ["show brands"],
    },
  },
  {
    path: "/discounts-offers",
    name: "Discounts",
    component: Discounts,
    meta: {
      middleware: [auth, can],

      title: "Discounts",
      can: ["show discounts"],
    },
  },
  {
    path: "/products/bundle/create",
    name: "CreateBundleProduct",
    component: CreateProduct,
    meta: {
      middleware: [auth, can],
      title: "Create new product",
      can: ["add bundle_products"],
    },
  },
  {
    path: "/products/bundle/:slug",
    name: "UpdateBundleProduct",
    component: UpdateProduct,
    meta: {
      middleware: [auth, can],
      title: "Update product",
      can: ["update bundle_products"],
    },
  },
  {
    path: "/products/create",
    name: "CreateProduct",
    component: CreateProduct,
    meta: {
      middleware: [auth, can],
      title: "Create new product",
      can: ["add items"],
    },
  },
  {
    path: "/products/:slug",
    name: "UpdateProduct",
    component: UpdateProduct,
    meta: {
      middleware: [auth, can],
      title: "Update product",
      can: ["update items"],
    },
  },
  {
    path: "/analytics/alerts/near-expiry",
    name: "NearExpiry",
    component: NearExpiry,
    meta: {
      middleware: [auth, can],
      // breadcrumb: {
      //   parent: "Analytics",
      //   label: "near-expiry",
      // },
      title: "Near expiry",
    },
  },

  {
    path: "/analytics/alerts/expired",
    name: "Expired",
    component: Expired,
    meta: {
      middleware: [auth, can],
      // breadcrumb: {
      //   parent: "Analytics",
      //   label: "expired",
      // },
      title: "Expired",
    },
  },
  {
    path: "/inventory-actions/payments",
    name: "OrderPayments",
    component: OrderPayments,
    meta: {
      middleware: [auth, can],

      title: "Payments",
      can: ["show order_payments"],
    },
  },
  {
    path: "/inventory-actions/suppliers",
    name: "Suppliers",
    component: Suppliers,
    meta: {
      middleware: [auth, can],
      // breadcrumb: {
      //   parent: "StockPage",
      //   label: "suppliers",
      // },
      title: "Inventory setup",
      can: ["show suppliers"],
    },
  },
  // {
  //   path: "/products",
  //   name: "Products",
  //   component: Inventory,
  //   meta: {
  //     middleware: [auth, can],
  //     endpoint_name: "products",
  //   },
  // },
  {
    path: "/inventory-setup",
    name: "StockPage",
    component: StockPage,
    meta: {
      middleware: [auth, can],
      // breadcrumb: {
      //   label: "inventory-setup",
      // },
      endpoint_name: "products",
      title: "Inventory",
      can: ["show inventory"],
    },
  },
  {
    path: "/inventory-actions/processing",
    name: "ProcessingTracks",
    component: ProcessingTracks,
    meta: {
      middleware: [auth, can],
      // breadcrumb: {
      //   label: "inventory-setup",
      // },
      title: "Processing tracks",
      can: ["show processing_track"],
    },
  },
  // {
  //   path: "/inventory-setup/update/:slug",
  //   name: "UpdateInventoryProduct",
  //   component: UpdateProduct,
  //   meta: {
  //     middleware: [auth, can],
  //     title: "Update product",
  //     can: ["update inventory"],
  //   },
  // },
  {
    path: "/inventory-setup/create",
    name: "CreateInventoryProduct",
    component: NewCreateProductPage,
    meta: {
      middleware: [auth, can],
      title: "Create new product",
      can: ["add inventory"],
    },
  },
  {
    path: "/inventory-setup/update/:slug",
    name: "UpdateInventoryProduct",
    component: NewUpdateProductPage,
    meta: {
      middleware: [auth, can],
      title: "Update product",
      can: ["update inventory"],
    },
  },
  // {
  //   path: "/analytics/alerts",
  //   name: "OutOfStock",
  //   component: OutOfStock,
  //   meta: {
  //     middleware: [auth, can],
  //   },
  // },

  {
    path: "/analytics/alerts",
    name: "OutOfStockAlerts",
    component: OutOfStockAlerts,
    meta: {
      middleware: [auth, can],
      // breadcrumb: {
      //   parent: "Analytics",
      //   label: "Alerts",
      // },
      title: "OOS Alerts",
      can: ["show oos_alerts"],
    },
  },
  {
    path: "/invoices",
    name: "Invoices",
    component: Invoices,
    meta: {
      endpoint_name: "invoices",
      middleware: [auth, can],
      // breadcrumb: {
      //   parent: "Analytics",
      //   label: "invoice-sales",
      // },
      title: "Invoice sales",
      can: ["show invoice_sales"],
    },
  },
  {
    path: "/analytics/invoices/refunds",
    name: "InvoiceRefunds",
    component: InvoiceRefunds,
    meta: {
      middleware: [auth, can],
      // breadcrumb: {
      //   parent: "Analytics",
      //   label: "item-sales",
      // },
      endpoint_name: "invoices/refunds",
      title: "Refunds",
      can: ["show invoice_refunds"],
    },
  },
  {
    path: "/analytics/invoices/products",
    name: "InvoiceProducts",
    component: InvoiceProducts,
    meta: {
      middleware: [auth, can],
      // breadcrumb: {
      //   parent: "Analytics",
      //   label: "item-sales",
      // },
      endpoint_name: "invoices/products",
      title: "Item sales",
      can: ["show item_sales"],
    },
  },
  {
    path: "/analytics/invoices/inventory",
    name: "InvoicesInventory",
    component: InvoicesInventory,
    meta: {
      middleware: [auth, can],
      // breadcrumb: {
      //   parent: "Analytics",
      //   label: "item-sales",
      // },
      endpoint_name: "invoices/inventory",
      title: "Inventory",
      can: ["show invoice_inventory"],
    },
  },
  {
    path: "/inventory-actions/wastages",
    name: "InvoicesWastages",
    component: InvoicesWastages,
    meta: {
      middleware: [auth, can],
      // breadcrumb: {
      //   parent: "Analytics",
      //   label: "item-sales",
      // },
      endpoint_name: "invoices/wastages",
      title: "Wastages",
      can: ["show inventory_wastages"],
    },
  },
  {
    path: "/analytics/invoices/categories",
    name: "InvoicesCategories",
    component: InvoicesCategories,
    meta: {
      middleware: [auth, can],
      // breadcrumb: {
      //   parent: "Analytics",
      //   label: "item-sales",
      // },
      endpoint_name: "invoices/categories",
      title: "Categories",
      can: ["show invoice_categories"],
    },
  },
  // {
  //   path: "/invoices/products",
  //   name: "ProductsInvoices",
  //   component: ProductsInvoices,
  //   meta: {
  //     middleware: [auth, can],
  //   },
  // },
  {
    path: "/analytics/invoices/:id",
    name: "InvoiceDetails",
    component: InvoiceDetails,
    meta: {
      middleware: [auth, can],
      // breadcrumb: {
      //   parent: "invoice-sales",
      // },
      title: "Item sale",
      can: ["show invoice_sales"],
    },
  },

  {
    path: "/incomes",
    name: "Incomes",
    component: Incomes,
    meta: {
      middleware: [auth, can],
      // breadcrumb: {
      //   parent: "Analytics",
      //   label: "incomes",
      // },
      title: "Incomes",
      can: ["show incomes"],
    },
  },

  {
    path: "/settings/wastages",
    name: "Wastages",
    component: Wastages,
    meta: {
      middleware: [auth, can],
      breadcrumb: {
        parent: "SettingsPage",
        label: "wastages",
      },
      title: "Wastages",
      can: ["show wastages"],
    },
  },

  {
    path: "/inventory-actions/transfers",
    name: "Transfers",
    component: Transfers,
    meta: {
      middleware: [auth, can],
      // breadcrumb: {
      //   parent: "Orders",
      //   label: "transfers",
      // },
      title: "Inventory actions",
      can: ["show transfer_orders"],
    },
  },
  {
    path: "/inventory-actions/refunds",
    name: "Refunds",
    component: Refunds,
    meta: {
      middleware: [auth, can],
      // breadcrumb: {
      //   parent: "Orders",
      //   label: "refunds",
      // },
      title: "Inventory actions",
      can: ["show refund_orders"],
    },
  },
  {
    path: "/inventory-actions",
    name: "Orders",
    component: Orders,
    meta: {
      middleware: [auth, can],
      // breadcrumb: {
      //   label: "inventory-actions",
      // },
      title: "Inventory actions",
      can: ["show purchase_orders"],
    },
  },

  {
    path: "/inventory-actions/orders/new",
    name: "ItemProducts",
    component: ItemProducts,
    meta: {
      middleware: [auth, can],
      // breadcrumb: {
      //   parent: "Orders",
      //   label: "new purchase order",
      // },
      title: "Inventory actions",
      can: ["add purchase_orders"],
    },
  },
  // {
  //   path: "/inventory-actions/orders/new/:id",
  //   name: "ItemProducts",
  //   component: ItemProducts,
  //   meta: {
  //     middleware: [auth, can],
  //     // breadcrumb: {
  //     //   parent: "WarehousesAndWholesalers",
  //     //   label: "Supplier products",
  //     // },
  //     title: "Inventory actions",
  //     can: ["add purchase_orders"],
  //   },
  // },
  {
    path: "/inventory-actions/orders/:id",
    name: "OrderDetails",
    component: OrderDetails,
    meta: {
      middleware: [auth, can],
      title: "Inventory actions",
      can: ["show purchase_orders"],
    },
  },
  {
    path: "/inventory-actions/transfers/:id",
    name: "TransferOrderDetails",
    component: OrderDetails,
    meta: {
      middleware: [auth, can],
      title: "Inventory actions",
      can: ["show transfer_orders"],
    },
  },
  {
    path: "/inventory-actions/refunds/:id",
    name: "RefundOrderDetails",
    component: OrderDetails,
    meta: {
      middleware: [auth, can],
      title: "Inventory actions",
      can: ["show refund_orders"],
    },
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: Notifications,
    meta: {
      middleware: [auth, can],
      title: "Notifications",
      can: ["show notifications"],
    },
  },
  {
    path: "/support",
    name: "Support",
    component: Support,
    meta: {
      middleware: [auth],
      title: "Support",
    },
  },
  {
    path: "/support/:id",
    name: "SingleTicket",
    component: SingleTicket,
    meta: {
      middleware: [auth],
      title: "Support",
    },
  },

  {
    path: "/locations",
    name: "Stores",
    component: Stores,
    meta: {
      middleware: [auth, can],
      title: "Locations",
      can: ["show locations"],
    },
  },
  {
    path: "/settings/devices",
    name: "PosShop",
    component: PosShop,
    meta: {
      middleware: [auth, can],
      breadcrumb: {
        parent: "SettingsPage",
        label: "Devices",
      },
      title: "Devices",
      can: ["show pos_devices"],
    },
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: Checkout,
    meta: {
      middleware: [shouldLogin],
      title: "Checkout",
      can: ["add purchase_orders"],
    },
  },
  // {
  //   path: "/settings/users",
  //   name: "Users",
  //   component: Users,
  //   meta: {
  //     middleware: [auth, can],
  //     breadcrumb: {
  //       parent: "SettingsPage",
  //       label: "Users",
  //     },
  //     endpoint_name: "users",
  //     title: "Users",
  //     can: ["show users"],
  //   },
  // },
  {
    path: "/settings/salesman",
    name: "Salesman",
    component: Salesman,
    meta: {
      middleware: [auth, can],
      breadcrumb: {
        parent: "SettingsPage",
        label: "Salesman",
      },
      title: "Salesman",
      can: ["show salesman"],
    },
  },

  {
    path: "/settings/users",
    name: "Users",
    component: Users,
    meta: {
      middleware: [auth, can],
      breadcrumb: {
        parent: "SettingsPage",
        label: "Users",
      },
      title: "Users",
      can: ["show users"],
    },
  },

  {
    path: "/inventory-actions/stocktaking",
    name: "StockCount",
    component: StockCount,
    meta: {
      middleware: [auth, can],

      title: "Stock count",
      can: ["show stock_count"],
    },
  },
  {
    path: "/inventory-actions/stocktaking/add",
    name: "AddStockCount",
    component: AddStockCount,
    meta: {
      middleware: [auth, can],
      breadcrumb: {
        parent: "StockCount",
        label: "add",
      },
      title: "Add stock",
      can: ["add stock_count"],
    },
  },

  {
    path: "/settings/prices-list",
    name: "PriceList",
    component: PriceList,
    meta: {
      middleware: [auth, can],
      title: "Price list",
      can: ["show price_list"],
    },
  },
  {
    path: "/customers",
    name: "CustomerIndex",
    component: CustomerIndex,
    meta: {
      middleware: [auth, can],
      title: "Customers",
      can: ["show customers"],
    },
  },
  {
    path: "/customers/group",
    name: "CustomerGroups",
    component: CustomerGroups,
    meta: {
      middleware: [auth, can],
      title: "Customer groups",
      can: ["show customer_groups"],
    },
  },
  {
    path: "/customers/:id",
    name: "customer",
    component: customer,
    meta: {
      middleware: [auth, can],
      title: "Customers",
      can: ["show customers"],
    },
  },
  {
    path: "/settings/taxes",
    name: "Taxes",
    component: Taxes,
    meta: {
      middleware: [auth, can],
      title: "Taxes",
      breadcrumb: {
        parent: "SettingsPage",
        label: "taxes",
        can: ["show taxes"],
      },
    },
  },
  {
    path: "/settings/receipt",
    name: "Receipt",
    component: Receipt,
    meta: {
      middleware: [auth, can],
      title: "Receipt",
      breadcrumb: {
        parent: "SettingsPage",
        label: "receipt",
        can: ["show receipts"],
      },
    },
  },
  {
    path: "/settings/order-types",
    name: "OrderTypes",
    component: OrderTypes,
    meta: {
      middleware: [auth, can],
      title: "Order types",
      breadcrumb: {
        parent: "SettingsPage",
        label: "Order types",
        can: ["show custom_order_types"],
      },
    },
  },
  {
    path: "/settings",
    name: "SettingsPage",
    component: SettingsPage,
    meta: {
      middleware: [auth],
      title: "Settings",
      breadcrumb: {
        label: "settings",
      },
    },
  },
  {
    path: "/settings/safe-track",
    name: "SafeTracks",
    component: SafeTracks,
    meta: {
      middleware: [auth, can],
      title: "Safe track",
      breadcrumb: {
        parent: "SettingsPage",
        label: "safe-track",
      },
      can: ["show safe_track"],
    },
  },
  {
    path: "/settings/email-configuration",
    name: "EmailConfiguration",
    component: EmailConfiguration,
    meta: {
      middleware: [auth, can],
      title: "Email configuration",
      breadcrumb: {
        parent: "SettingsPage",
        label: "email-configuration",
      },
      can: ["show email_configuration"],
    },
  },
  {
    path: "/settings/roles",
    name: "Roles",
    component: Roles,
    meta: {
      middleware: [auth, can],
      title: "Roles",
      breadcrumb: {
        parent: "SettingsPage",
        label: "roles",
      },
      can: ["show roles"],
    },
  },
  {
    path: "/settings/store-information",
    name: "StoreDetails",
    component: StoreDetails,
    meta: {
      middleware: [auth, can],
      title: "Store information",
      breadcrumb: {
        parent: "SettingsPage",
        label: "store-information",
      },
    },
  },
  {
    path: "/settings/profile",
    name: "Profile",
    component: Profile,
    meta: {
      middleware: [auth, can],
      title: "Personal information",
      breadcrumb: {
        parent: "SettingsPage",
        label: "profile",
      },
    },
  },
  {
    path: "/not-allowed",
    name: "PermissionsAllowed",
    component: PermissionsAllowed,
    meta: {
      middleware: [auth],
      title: "Forbidden",
    },
  },
  {
    path: "*",
    name: "PageNotFound",
    component: PageNotFound,
    meta: {
      title: "Page not found",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      to,
      store,
      $cookies,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);
    return middleware[0]({ ...context, next: nextMiddleware });
  } else if (store.state.token || Vue.$cookies.get("token")) {
    if (to.name === "Auth") {
      return next("/");
    }
  }
  return next();
});
export default router;
