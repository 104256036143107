<template>
  <div class="p-6 space-y-6">
    <Table
      :title="$t('kitchens')"
      :records="`${pagination.total || 0}`"
      :pagination="pagination"
      :total_pages="pagination.total_pages"
      v-model="pagination.page"
    >
      <template #actions>
        <div v-if="!hasTrialDays && hasPermission('add kitchens')">
          <button
            class="py-1.5 px-3 text-sm flex items-center gap-2 text-gray-600 font-medium rtl:font-semibold"
            @click.prevent="add_active = !add_active"
          >
            <i class="fa-solid fa-plus"></i>
            <span>{{ $t("add") }}</span>
          </button>
        </div>
      </template>
      <template #filter>
        <ul class="flex items-center">
          <li v-for="(item, index) in status" :key="index">
            <button
              class="py-4 px-6 text-center font-medium rtl:font-semibold text-sm capitalize"
              @click.prevent="
                changeFilterStatus(item === 'all' ? null : item, index)
              "
              :class="{
                'active-tab':
                  pagination.is_active === (item === 'all' ? null : item),
              }"
              :disabled="disables[index]"
            >
              <spinner
                size="w-4 h-4 inline-block"
                class="mr-2 rtl:mr-0 rtl:ml-2"
                v-if="disables[index]"
              />
              <!-- {{ $t(item.replace(/\s/g, "_")) }} -->
              {{ item }}
            </button>
          </li>
        </ul>
      </template>
      <template #head>
        <th>{{ $t("name") }}</th>
        <th>{{ $t("products") }}</th>
        <th>{{ $t("categories") }}</th>
        <th>{{ $t("status") }}</th>
        <th>{{ $t("latest-update") }}</th>
        <th>{{ $t("actions") }}</th>
      </template>
      <template #tbody v-if="!load">
        <TableLoad :rows="6" v-for="i in 4" :key="i" />
      </template>
      <template #tbody v-else-if="load && items.length">
        <tr v-for="(item, index) in items" :key="index">
          <td>
            {{ item["name_" + $i18n.locale] }}
          </td>
          <td>
            <button
              type="button"
              class="text-sm font-medium text-blue-600 underline hover:no-underline"
              @click.prevent="showAssignedProducts(item.id)"
            >
              <span>{{ $t("view") }} ({{ item.products?.length || 0 }})</span>
            </button>
          </td>
          <td>
            <button
              type="button"
              class="text-sm font-medium text-blue-600 underline hover:no-underline"
              @click.prevent="showAssignedCategories(item.id)"
            >
              <span>{{ $t("view") }} ({{ item.categories?.length || 0 }})</span>
            </button>
          </td>

          <td>
            <p class="status capitalize" :class="item_status[item.is_active]">
              {{ item.is_active === 1 ? $t("active") : $t("inactive") }}
            </p>
          </td>

          <td v-html="createTime(item.updated_at || item.created_at)"></td>

          <td>
            <div class="actions-group">
              <div v-if="hasPermission('update kitchens')">
                <button
                  class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded"
                  @click.prevent="changeStatus(item.id)"
                  :content="'Change status'"
                  v-tippy
                  :disabled="disables[`status_${item.id}`]"
                >
                  <spinner
                    size="w-4 h-4 inline-block"
                    v-if="disables[`status_${item.id}`]"
                  />
                  <i class="fa-solid fa-right-left" v-else></i>
                </button>
              </div>
              <div v-if="hasPermission('edit kitchens')">
                <button
                  class="w-8 h-8 bg-sky-500 text-white rounded"
                  @click.prevent="showUpdate(item.id)"
                  :content="'Update'"
                  v-tippy
                >
                  <i class="fa-solid fa-pen"></i>
                </button>
              </div>
              <div v-if="hasPermission('delete kitchens')">
                <button
                  class="p-2 px-3 text-red-500 font-medium rtl:font-semibold"
                  @click.prevent="deleteItem(item.id)"
                  :disabled="disables[`delete_${item.id}`]"
                >
                  <spinner
                    size="w-4 h-4 inline-block"
                    v-if="disables[`delete_${item.id}`]"
                  />
                  <span v-else> {{ $t("delete") }} </span>
                </button>
              </div>
            </div>
          </td>
        </tr>
      </template>

      <template #tbody v-else>
        <tr>
          <td colspan="100%" class="!text-center">{{ $t("no-data") }}</td>
        </tr>
      </template>
    </Table>
    <Modal
      :title="$t('add')"
      v-if="add_active"
      @close="
        (e) => {
          add_active = e;
        }
      "
    >
      <template>
        <form class="p-6 space-y-4">
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="add_errors.name_en">
                {{ add_errors.name_en.join(" ") }}
              </p>
              <input
                type="text"
                name="name_en"
                id="name_en"
                placeholder="Name"
                required
                autocomplete="off"
                v-model="add_data.name_en"
                v-ltr
              />
              <label for="name_en">{{ $t("name") }} | {{ $t("en") }}</label>
            </div>
          </div>
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="add_errors.name_ar">
                {{ add_errors.name_ar.join(" ") }}
              </p>

              <input
                type="text"
                name="name_ar"
                id="name_ar"
                placeholder="الاسم"
                required
                autocomplete="off"
                v-model="add_data.name_ar"
                v-rtl
              />
              <label for="name_ar">{{ $t("name") }} | {{ $t("ar") }}</label>
            </div>
          </div>

          <div>
            <button-with-loader
              :disabled="add_disabled"
              btnClass="bg-primary py-2 px-4 rounded text-xs font-medium rtl:font-semibold text-white"
              @click="addItem"
            >
              {{ $t("submit") }}
            </button-with-loader>
          </div>
        </form>
      </template>
    </Modal>
    <Modal
      :title="JSON.parse(JSON.stringify(edit_data['name_' + $i18n.locale]))"
      v-if="edit_active"
      @close="
        (e) => {
          edit_active = e;
        }
      "
    >
      <template>
        <form class="p-6 space-y-4">
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="edit_errors.name_en">
                {{ edit_errors.name_en.join(" ") }}
              </p>
              <input
                type="text"
                name="name_en"
                id="name_en"
                placeholder="Name"
                required
                autocomplete="off"
                v-model="edit_data.name_en"
                v-ltr
              />
              <label for="name_en">{{ $t("name") }} | {{ $t("en") }}</label>
            </div>
          </div>
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="edit_errors.name_ar">
                {{ edit_errors.name_ar.join(" ") }}
              </p>

              <input
                type="text"
                name="name_ar"
                id="name_ar"
                placeholder="الاسم"
                required
                autocomplete="off"
                v-model="edit_data.name_ar"
                v-rtl
              />
              <label for="name_ar">{{ $t("name") }} | {{ $t("ar") }}</label>
            </div>
          </div>

          <div>
            <button-with-loader
              :disabled="edit_disabled"
              btnClass="bg-primary py-2 px-4 rounded text-xs font-medium rtl:font-semibold text-white"
              @click="editItem"
            >
              {{ $t("save-changes") }}
            </button-with-loader>
          </div>
        </form>
      </template>
    </Modal>

    <Modal
      :title="$t('products')"
      v-if="assign_active"
      @close="
        (e) => {
          assign_active = e;
        }
      "
    >
      <template>
        <form class="p-6 space-y-4">
          <div class="form-group space-y-3">
            <label for="product_id"> {{ $t("products") }} </label>
            <t-rich-select
              placeholder="Search"
              :text-attribute="'name_' + $i18n.locale"
              value-attribute="id"
              :clearable="true"
              :closeOnSelect="false"
              :fetch-options="getProducts"
              v-model="assign_data.product_id"
              @change="checkSelectedProduct"
            />
            <p class="error">
              {{ (assign_errors.product_ids || []).join(" ") }}
            </p>
          </div>

          <ul class="divide-y divide-gray-200">
            <li v-for="product in show_products" :key="product.id">
              <div class="flex items-center gap-4 py-3">
                <div>
                  <div class="w-10 h-10">
                    <img
                      :src="
                        product.image ||
                        require('@/assets/images/placeholder.png')
                      "
                      @error="
                        $event.target.src = require('@/assets/images/placeholder.png')
                      "
                      alt="product image"
                      class="w-full h-full border object-cover border-gray-100"
                    />
                  </div>
                </div>
                <div class="flex-1">
                  <p class="text-sm">{{ product["name_" + $i18n.locale] }}</p>
                  <p class="text-sm">
                    {{ generateCurrency(product.price || 0) }}
                  </p>
                </div>
                <div>
                  <button
                    class="text-white w-8 h-8 rounded-full bg-red-700"
                    type="button"
                    @click.prevent="removeProduct(product.id)"
                  >
                    <i class="fa-solid fa-trash fa-sm"></i>
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <div>
            <button-with-loader
              :disabled="assign_disabled"
              btnClass="bg-primary py-2 px-4 rounded text-xs font-medium rtl:font-semibold text-white"
              @click="assignProduct"
            >
              {{ $t("save-changes") }}
            </button-with-loader>
          </div>
        </form>
      </template>
    </Modal>

    <Modal
      :title="$t('categories')"
      v-if="assign_categories_active"
      @close="
        (e) => {
          assign_categories_active = e;
        }
      "
    >
      <template>
        <form class="p-6 space-y-4">
          <div class="form-group space-y-3">
            <label for="category_id"> {{ $t("categories") }} </label>
            <t-rich-select
              placeholder="Search"
              :text-attribute="'name_' + $i18n.locale"
              value-attribute="id"
              :clearable="true"
              :closeOnSelect="false"
              :fetch-options="getCategories"
              v-model="assign_data.category_id"
              @change="checkSelectedCategory"
            />
            <p class="error">
              {{ (assign_errors.category_ids || []).join(" ") }}
            </p>
          </div>

          <ul class="divide-y divide-gray-200">
            <li
              v-for="category in show_categories"
              :key="category.id"
              :data-id="category.id"
            >
              <div class="flex items-center gap-4 py-3">
                <div>
                  <div class="w-10 h-10">
                    <img
                      :src="
                        category.image ||
                        require('@/assets/images/placeholder.png')
                      "
                      @error="
                        $event.target.src = require('@/assets/images/placeholder.png')
                      "
                      alt="category image"
                      class="w-full h-full border object-cover border-gray-100"
                    />
                  </div>
                </div>
                <div class="flex-1">
                  <p class="text-sm">{{ category["name_" + $i18n.locale] }}</p>
                </div>
                <div>
                  <button
                    class="text-white w-8 h-8 rounded-full bg-red-700"
                    type="button"
                    @click.prevent="removeCategory(category.id)"
                  >
                    <i class="fa-solid fa-trash fa-sm"></i>
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <div>
            <button-with-loader
              :disabled="assign_disabled"
              btnClass="bg-primary py-2 px-4 rounded text-xs font-medium rtl:font-semibold text-white"
              @click="assignCategories"
            >
              {{ $t("save-changes") }}
            </button-with-loader>
          </div>
        </form>
      </template>
    </Modal>
  </div>
</template>
<script>
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";
import Modal from "@/components/basics/Modal.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "Kitchens",
  data() {
    return {
      load: false,
      items: [],
      add_data: {},
      add_errors: {},
      add_disabled: false,
      add_active: false,

      edit_data: {},
      edit_errors: {},
      edit_disabled: false,
      edit_active: false,

      item_status: {
        1: "bg-sky-500/10 text-sky-500",
        0: "bg-red-500/10 text-red-500",
      },
      status: ["all", "active", "not active"],
      pagination: {
        is_active: null,
        page: 1,
        sort_key: null,
        sort_type: "asc",
      },
      disables: {},
      products: [],
      // assign products
      assign_data: {},
      assign_errors: {},
      assign_error: null,
      assign_disabled: false,
      assign_active: false,
      assign_categories_active: false,
      show_active: false,
      show_products: [],
      show_categories: [],
      categories: [],
    };
  },

  async created() {
    await Promise.all([this.getItems()]).finally(() => (this.load = true));
  },
  watch: {
    is_called(val, _) {
      if (val) {
        this.getItems();
      }
    },
    add_active(val, _) {
      if (!val) {
        this.add_data = {};
        this.add_errors = {};
      }
    },
    assign_active(val, _) {
      if (!val) {
        this.assign_data = {};
        this.assign_errors = {};
      }
    },
    show_active(val, _) {
      if (!val) {
        this.show_products = [];
      }
    },
  },
  computed: {
    ...mapGetters("table", ["search", "is_called"]),
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("table", ["changeIsCalled"]),

    async getItems() {
      try {
        const { total, total_pages, ...others } = this.pagination;

        const { result } = await this.getRoute({
          name: "kitchens",
          options: {
            ...others,
            is_active: others.is_active
              ? others.is_active === "active"
                ? 1
                : 0
              : null,
            search_key: this.search,
            with_products: 1,
            store_id: this.store_id,
          },
        });
        const { data, pagination } = result.kitchens;
        this.items = data;
        this.$set(this.pagination, "total", pagination.total);
        this.$set(this.pagination, "total_pages", pagination.total_pages);
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      } finally {
        this.changeIsCalled(false);
      }
    },
    async getCategories(q) {
      try {
        const user = this.$store.state.user;
        const { result } = await this.getRoute({
          name: "categories",
          options: {
            vendor_id: user.user_type === "vendor" ? user?.id : null,
            store_id: user.user_type === "cashier" ? user?.id : null,
            is_inventory: 1,
            page: 1,
            search_key: q,
          },
        });
        const categories = result.categories?.data || [];
        this.categories = categories;
        return { results: categories };
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },

    checkSelectedCategory(id) {
      const category = this.categories.find((item) => item.id == id);
      const isExisted = this.show_categories?.some((item) => item.id == id);
      if (isExisted)
        return this.createAlert("Category already existed", "info");

      this.show_categories = [...this.show_categories, category];
      this.createAlert("Category added", "success");
    },

    removeCategory(id) {
      const filterCategories = this.show_categories.filter(
        (category) => category.id !== id
      );
      console.log(filterCategories);
      this.show_categories = filterCategories;
      // this.assign_data.category_id = null;
    },
    async getProducts(q) {
      try {
        const user = this.$store.state.user;
        const { result } = await this.getRoute({
          name: "products",
          options: {
            vendor_id: user.user_type === "vendor" ? user?.id : null,
            store_id: user.user_type === "cashier" ? user?.id : null,
            is_inventory: 1,
            page: 1,
            search_key: q,
          },
        });
        const products = result.products?.data || [];
        this.products = products;
        return { results: products };
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },

    checkSelectedProduct(id) {
      const product = this.products.find((item) => item.id == id);
      const isExisted = this.show_products?.some((item) => item.id == id);
      if (isExisted) return this.createAlert("Product already existed", "info");

      this.show_products = [...this.show_products, product];
      this.createAlert("product added", "success");
    },

    removeProduct(id) {
      const filterProducts = this.show_products.filter(
        (product) => product.id !== id
      );
      this.show_products = filterProducts;
      // this.assign_data.product_id = null;
    },

    async changeStatus(id) {
      this.$set(this.disables, `status_${id}`, true);
      try {
        const { data } = await this.axios.post(
          `kitchens/update_is_active/${id}`
        );
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.add_errors = res?.data?.message;
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async deleteAssignedProduct(kitchen, product) {
      this.$set(this.disables, "delete-assigned-product-" + product, true);
      try {
        const { data } = await this.axios.post("kitchens/delete_product", {
          product_id: product,
          kitchen_id: kitchen,
        });
        this.createAlert(data.message);
        this.getItems().then(() => {
          const products =
            this.items.find((e) => e.id === kitchen).products || [];
          if (products.length >= 1) {
            this.showAssignedProducts(kitchen);
            return;
          }
          this.show_active = false;
        });
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.add_errors = res?.data?.message;
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async deleteItem(id) {
      this.$set(this.disables, `delete_${id}`, true);
      try {
        const { data } = await this.axios.post(`kitchens/delete/${id}`);
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.add_errors = res?.data?.message;
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async addItem() {
      try {
        this.add_disabled = true;
        this.add_errors = {};

        const { data } = await this.axios.post("kitchens/add", {
          ...this.add_data,
          store_id: this.store_id,
        });
        this.add_data = {};
        this.add_active = false;
        this.createAlert(data?.message);
        this.getItems();
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.error = responseError;
        }
      } finally {
        this.add_disabled = false;
      }
    },
    async editItem() {
      try {
        this.edit_disabled = true;
        this.edit_errors = {};

        const { data } = await this.axios.post(
          "kitchens/update",
          this.edit_data
        );
        this.edit_data = {};
        this.edit_active = false;
        this.createAlert(data?.message);
        this.getItems();
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.edit_errors = responseError;
        } else {
          this.error = responseError;
        }
      } finally {
        this.edit_disabled = false;
      }
    },
    async assignProduct() {
      try {
        this.assign_disabled = true;
        this.assign_errors = {};
        let body = {};
        body = {
          ...this.assign_data,
        };

        body.product_ids = this.show_products.map((product) => product.id);

        const { data } = await this.axios.post(
          "kitchens/update_products",
          body
        );
        this.assign_data = {};
        this.show_products = [];
        this.assign_active = false;
        this.createAlert(data?.message);
        this.getItems();
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.assign_errors = responseError;
        } else {
          this.assign_error = responseError;
        }
      } finally {
        this.assign_disabled = false;
      }
    },
    async assignCategories() {
      try {
        this.assign_disabled = true;
        this.assign_errors = {};
        let body = {};
        body = {
          ...this.assign_data,
        };

        body.category_ids = this.show_categories.map((product) => product.id);

        const { data } = await this.axios.post(
          "kitchens/update_categories",
          body
        );
        this.assign_data = {};
        this.show_categories = [];
        this.assign_categories_active = false;
        this.createAlert(data?.message);
        this.getItems();
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.assign_errors = responseError;
        } else {
          this.assign_error = responseError;
        }
      } finally {
        this.assign_disabled = false;
      }
    },

    showUpdate(id) {
      const item = this.items.find((e) => e.id === id);
      if (!item) {
        this.createAlert("Item does not existed", "info");
        return;
      }
      this.edit_data = {
        ...item,
      };
      this.edit_active = true;
    },

    sortTable(key) {
      if (this.pagination.sort_key != key) {
        this.pagination.sort_type = "asc";
      } else {
        this.pagination.sort_type =
          this.pagination.sort_type == "asc" ? "desc" : "asc";
      }
      this.pagination.sort_key = key;
      this.getItems().finally(() => {
        this.createAlert(`Table sorted by ${key}`);
      });
    },

    changeFilterStatus(status, index) {
      this.$set(this.disables, index, true);
      this.$set(this.pagination, "is_active", status);
      this.getItems().finally(() => {
        this.disables = {};
      });
    },

    showAssignedProducts(id) {
      const item = this.items.find((e) => e.id === id);

      this.show_products = item.products;
      this.assign_data.id = id;
      this.assign_active = true;
    },
    showAssignedCategories(id) {
      const item = this.items.find((e) => e.id === id);

      this.show_categories = item.categories;
      this.assign_data.id = id;
      this.assign_categories_active = true;
    },
    addNewProduct(id) {
      this.assign_data = {
        kitchen_id: id,
        value_type: "fixed",
        type: 0,
      };
      this.assign_active = true;
      this.assign_errors = {};
      this.assign_error = null;
    },

    showUpdateAssignedProduct(product) {
      const { id, kitchen_id, value, value_type } = product;
      this.assign_data = {
        kitchen_id,
        product_id: id,
        value,
        value_type,
        type: 1,
      };
      this.show_active = false;
      this.assign_active = true;
      this.assign_errors = {};
      this.assign_error = null;
    },
  },
  components: { Modal, ButtonWithLoader },
};
</script>
