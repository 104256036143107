<template>
  <!-- footer -->
  <footer class="w-full bg-white border-t border-t-gray-200">
    <div class="flex items-center p-6 justify-between flex-wrap gap-3">
      <ul class="flex items-center gap-2 flex-wrap">
        <li class="group">
          <router-link
            to="/pricing"
            class="flex items-center gap-2 text-gray-600 hover:underline"
          >
            <span>{{ $t("pricing") }}</span>
            <span
              class="w-1 h-1 rounded-full bg-gray-300 block group-last:hidden"
            ></span>
          </router-link>
        </li>

        <li class="group">
          <router-link
            to="/documentations"
            class="flex items-center gap-2 text-gray-600 hover:underline"
          >
            <span>{{ $t("documentations") }}</span>
            <span
              class="w-1 h-1 rounded-full bg-gray-300 block group-last:hidden"
            ></span>
          </router-link>
        </li>
        <template v-if="!$store.state.token">
          <li class="group">
            <router-link
              to="/account/login"
              class="flex items-center gap-2 text-gray-600 hover:underline"
            >
              <span>{{ $t("login") }}</span>
              <span
                class="w-1 h-1 rounded-full bg-gray-300 block group-last:hidden"
              ></span>
            </router-link>
          </li>
          <li class="group">
            <router-link
              to="/account/register"
              class="flex items-center gap-2 text-gray-600 hover:underline"
            >
              <span>{{ $t("sign-up") }}</span>
              <span
                class="w-1 h-1 rounded-full bg-gray-300 block group-last:hidden"
              ></span>
            </router-link>
          </li>
          <li class="group">
            <router-link
              to="/account/forgot-password"
              class="flex items-center gap-2 text-gray-600 hover:underline"
            >
              <span>{{ $t("forgot-password") }}</span>
              <span
                class="w-1 h-1 rounded-full bg-gray-300 block group-last:hidden"
              ></span>
            </router-link>
          </li>
        </template>
      </ul>
      <p class="text-sm text-gray-600">
        جميع الحقوق محفوظة © 2022 لدى
        <a
          href="https://appli-gate.com"
          class="text-primary underline font-semibold"
        >
          IA6G - بوابة التطبيقات العالمية
        </a>
      </p>
    </div>
  </footer>
  <!-- ./footer -->
</template>
<script>
export default {};
</script>
