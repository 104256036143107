<template>
  <div class="space-y-2 rounded-lg border border-gray-200 overflow-hidden">
    <div
      class="p-4 border-b-gray-200 flex items-center gap-4 justify-between"
      :class="show_details ? 'border-b' : 'border-b-0'"
    >
      <div class="form-group-with-check">
        <input
          type="checkbox"
          :name="generateHash(new Date().getTime().toString())"
          :id="generateHash(new Date().getTime().toString())"
          v-model="show_details"
        />
        <label
          :for="generateHash(new Date().getTime().toString())"
          class="block"
        >
          {{ package_item["name_" + $i18n.locale] || "Package info" }}
        </label>
      </div>
      <button
        class="shrink-0 py-1.5 px-3 rounded bg-red-500 text-white text-xs inline-flex gap-2 items-center"
        @click.prevent="$emit('delete')"
      >
        <i class="fa-solid fa-trash fa-xs align-middle"></i>
        <span> Remove</span>
      </button>
    </div>
    <slide-transition>
      <div class="space-y-4 p-4" v-if="show_details">
        <figure class="space-y-4">
          <p class="text-sm font-medium rtl:font-semibold">Image</p>
          <slide-transition>
            <figcaption v-if="package_item.image" class="relative table">
              <img
                :src="
                  isImage
                    ? convertImage(package_item.image)
                    : package_item.image
                "
                @error="
                  (e) =>
                    (e.target.src = require('@/assets/images/placeholder.png'))
                "
                alt="store image"
                class="w-auto max-h-[7rem] max-w-[5rem] object-contain"
              />
              <button
                class="w-6 h-6 rounded-full flex items-center justify-center bg-red-500 text-white absolute -top-2 -right-2 rtl:right-auto rtl:-left-2 ring-2 ring-white"
                type="button"
                @click.prevent="() => clearImage('package_item')"
                v-if="isImage"
              >
                <i class="fa-solid fa-xmark fa-sm"></i>
              </button>
            </figcaption>
          </slide-transition>
          <blockquote class="space-y-2">
            <label class="block">
              <span class="sr-only">Choose image</span>
              <input
                type="file"
                accept="image/*"
                class="custom-file"
                ref="uploader"
                @change="(e) => $set(package_item, 'image', e.target.files[0])"
              />
            </label>
            <p class="error" v-if="add_errors.image">
              {{ add_errors.image.join(" ") }}
            </p>
          </blockquote>
        </figure>
        <div class="w-full gap-4 grid grid-cols-1 sm:grid-cols-2">
          <div class="form-group space-y-3 flex-1">
            <label for="name_en"> Primary name</label>
            <input
              type="text"
              name="name_en"
              id="name_en"
              v-model="package_item.name_en"
              v-ltr
            />
            <p class="error">
              {{ (add_errors["name_en"] || []).join(" ") }}
            </p>
          </div>
          <div class="form-group space-y-3 flex-1">
            <label for="name_ar"> Secondary name </label>
            <input
              type="text"
              name="name_ar"
              id="name_ar"
              v-model="package_item.name_ar"
              v-rtl
            />
            <p class="error">
              {{ (add_errors["name_ar"] || []).join(" ") }}
            </p>
          </div>
          <div
            class="sm:col-span-2 grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3"
          >
            <div class="form-group space-y-3 flex-1">
              <div class="flex items-center gap-4 justify-between">
                <label for="barcode"> Barcode </label>
                <a
                  href="javascript:void(0)"
                  class="text-teal-600 font-semibold flex items-center justify-start gap-2 text-xs"
                  @click="generateRandomValue('barcode')"
                >
                  <i class="fa-solid fa-sync fa-sm"></i>
                  <span>Generate</span>
                </a>
              </div>
              <input
                type="text"
                name="barcode"
                id="barcode"
                v-model="package_item.barcode"
                placeholder="..."
              />
              <p class="error">
                {{ (add_errors["barcode"] || []).join(" ") }}
              </p>
            </div>
            <div class="form-group space-y-3 flex-1">
              <div class="flex items-center gap-4 justify-between">
                <label for="sku"> SKU </label>
                <a
                  href="javascript:void(0)"
                  class="text-teal-600 font-semibold flex items-center justify-start gap-2 text-xs"
                  @click="generateRandomValue('sku')"
                >
                  <i class="fa-solid fa-sync fa-sm"></i>
                  <span>Generate</span>
                </a>
              </div>
              <input
                type="text"
                name="sku"
                id="sku"
                v-model="package_item.sku"
                placeholder="..."
              />
              <p class="error">
                {{ (add_errors["sku"] || []).join(" ") }}
              </p>
            </div>
            <div
              class="form-group space-y-3 flex-1 col-span-1 sm:col-span-2 md:col-span-1"
            >
              <label for="weight"> Package weight (KG)</label>
              <input
                type="number"
                name="weight"
                id="weight"
                v-model="package_item.weight"
                placeholder="1"
                min="1"
              />
              <p class="error">
                {{ (add_errors["weight"] || []).join(" ") }}
              </p>
            </div>
          </div>
          <div v-if="false" class="flex-1 flex gap-4 flex-col sm:flex-row">
            <div class="form-group space-y-3 flex-1">
              <label for="weight"> Approximate weight (KG)</label>
              <input
                type="number"
                name="weight"
                id="weight"
                v-model="package_item.approx_weight"
                placeholder="1 kg"
                min="1"
              />
              <p class="error">
                {{ (add_errors["approx_weight"] || []).join(" ") }}
              </p>
            </div>
            <div
              class="form-group space-y-3 flex-1"
              v-if="package_item.unit_type === 'weight'"
            >
              <label for="weight"> Unit weight (G)</label>
              <input
                type="number"
                name="weight"
                id="weight"
                v-model.number="package_item.unit_weight"
                placeholder="1000 G"
                min="1"
                required
              />
              <p class="error">
                {{ (add_errors["unit_weight"] || []).join(" ") }}
              </p>
            </div>
          </div>
          <div class="flex flex-wrap gap-4">
            <div class="flex-[fit-content]">
              <div class="form-group-with-check">
                <input
                  type="checkbox"
                  :name="
                    generateHash(
                      'is_purchasable_' + new Date().getTime().toString()
                    )
                  "
                  :id="
                    generateHash(
                      'is_purchasable_' + new Date().getTime().toString()
                    )
                  "
                  v-model="package_item.is_purchasable"
                  :true-value="1"
                  :false-value="0"
                />
                <label
                  :for="
                    generateHash(
                      'is_purchasable_' + new Date().getTime().toString()
                    )
                  "
                >
                  Purchasable
                </label>
              </div>
            </div>
            <div class="flex-[fit-content]">
              <div class="form-group-with-check">
                <input
                  type="checkbox"
                  :name="
                    generateHash(
                      'is_sellable_' + new Date().getTime().toString()
                    )
                  "
                  :id="
                    generateHash(
                      'is_sellable_' + new Date().getTime().toString()
                    )
                  "
                  v-model="package_item.is_sellable"
                  :true-value="1"
                  :false-value="0"
                />
                <label
                  :for="
                    generateHash(
                      'is_sellable_' + new Date().getTime().toString()
                    )
                  "
                >
                  Sellable
                </label>
              </div>
            </div>
          </div>
          <div class="sm:col-span-2">
            <div class="form-group-with-check">
              <input
                type="checkbox"
                :name="generateHash(new Date().getTime().toString())"
                :id="generateHash(new Date().getTime().toString())"
                v-model="show_advanced_details"
              />
              <label :for="generateHash(new Date().getTime().toString())">
                Other details
              </label>
            </div>
          </div>
          <slide-transition>
            <div
              class="grid gap-4 grid-cols-1 sm:grid-cols-2 sm:col-span-2"
              v-show="show_advanced_details"
            >
              <div class="form-group space-y-3 flex-1">
                <label for="desc_en">
                  {{ $t("description") }} | {{ $t("en") }}
                </label>
                <textarea
                  name="desc_en"
                  id="desc_en"
                  placeholder="..."
                  v-ltr
                  v-model="package_item.desc_en"
                ></textarea>
                <p class="error">
                  {{ (add_errors["desc_en"] || []).join(" ") }}
                </p>
              </div>
              <div class="form-group space-y-3 flex-1">
                <label for="desc_ar">
                  {{ $t("description") }} | {{ $t("ar") }}
                </label>
                <textarea
                  name="desc_ar"
                  id="desc_ar"
                  placeholder="..."
                  v-rtl
                  v-model="package_item.desc_ar"
                ></textarea>
                <p class="error">
                  {{ (add_errors["desc_ar"] || []).join(" ") }}
                </p>
              </div>
            </div>
          </slide-transition>
        </div>
      </div>
    </slide-transition>
  </div>
</template>

<script>
import short from "shorthash";
export default {
  name: "SinglePackage",
  props: ["add_errors", "item"],
  data() {
    return {
      package_item: {
        image: null,

        ...this.item,
      },
      show_details: false,
      show_advanced_details: false,
    };
  },
  computed: {
    generateHash() {
      return (key) => short.unique(key);
    },
    isImage() {
      return this.package_item.image instanceof File;
    },
  },
  watch: {
    package_item() {
      this.$emit("input", this.package_item);
      console.log(this.package_item);
    },
  },

  methods: {
    convertImage(e) {
      return URL.createObjectURL(e);
    },
    clearImage(type) {
      const uploader = this.$refs.uploader;
      if (!uploader) return;
      uploader.value = null;
      this.$set(this[type], "image", null);
    },
    generateRandomValue(key) {
      this.$set(this.package_item, key, Date.now());
    },
  },
};
</script>
