<template>
  <div class="w-full gap-4 grid grid-cols-1 sm:grid-cols-2 p-6">
    <div
      class="grid grid-cols-1 sm:grid-cols-2 col-span-full gap-4 lg:grid-cols-3"
    >
      <div class="form-group space-y-3 col-span-full">
        <label for="initial_stock">
          Stock count {{ is_weight ? "( KG )" : "" }}</label
        >
        <input
          type="number"
          name="initial_stock"
          id="initial_stock"
          v-model="package_item.initial_stock"
          min="0"
          placeholder="0"
        />
      </div>
      <div class="form-group space-y-3 flex-1">
        <label for="price">
          Cost price
          {{
            !package_item.is_purchasable &&
            package_item.product_source !== "product_with_bundle" &&
            package_item.unit_type == "weight"
              ? "( " + package_item.unit_weight + "G )"
              : null
          }}
          (SAR)</label
        >
        <input
          type="number"
          name="price"
          id="price"
          v-model.number="package_item.cost_price"
          placeholder="1"
          min="1"
        />

        <p class="text-xs font-medium text-gray-600">
          {{
            GeneratePriceFromVat(
              "cost_price",
              package_item.cost_price_includes_vat
            )
          }}
        </p>
        <div class="form-group-with-check">
          <input
            type="checkbox"
            :name="
              generateHash('cost_price_includes_vat_' + new Date().getTime())
            "
            :id="
              generateHash('cost_price_includes_vat_' + new Date().getTime())
            "
            :true-value="1"
            :false-value="0"
            v-model="package_item.cost_price_includes_vat"
          />
          <label
            :for="
              generateHash('cost_price_includes_vat_' + new Date().getTime())
            "
            class="text-sm font-medium rtl:font-semibold capitalize space-y-1"
          >
            <p>Includes 15% VAT</p>
          </label>
        </div>
        <p class="error">
          {{ (errors["cost_price"] || []).join(" ") }}
        </p>
      </div>
      <div class="form-group space-y-3 flex-1">
        <label for="price">
          Sale price
          {{
            !package_item.is_purchasable &&
            package_item.product_source !== "product_with_bundle" &&
            package_item.unit_type == "weight"
              ? "( " + package_item.unit_weight + "G )"
              : null
          }}
          (SAR)</label
        >
        <input
          type="number"
          name="price"
          id="price"
          v-model.number="package_item.sale_price"
          placeholder="1"
          min="1"
          :disabled="!sellable"
          :readOnly="!sellable"
        />
        <p class="text-xs font-medium text-gray-600">
          {{
            GeneratePriceFromVat(
              "sale_price",
              package_item.sale_price_includes_vat
            )
          }}
        </p>

        <div class="form-group-with-check">
          <input
            type="checkbox"
            :name="
              generateHash('sale_price_includes_vat_' + new Date().getTime())
            "
            :id="
              generateHash('sale_price_includes_vat_' + new Date().getTime())
            "
            :true-value="1"
            :false-value="0"
            v-model="package_item.sale_price_includes_vat"
            :disabled="!sellable"
            :readOnly="!sellable"
          />
          <label
            :for="
              generateHash('sale_price_includes_vat_' + new Date().getTime())
            "
            class="text-sm font-medium rtl:font-semibold capitalize"
            >Includes vat 15%</label
          >
        </div>
        <p class="error">
          {{ (errors["sale_price"] || []).join(" ") }}
        </p>
      </div>
      <div class="form-group">
        <div class="with-label">
          <p class="error" v-if="errors['discount_value']">
            {{ errors["discount_value"].join(" ") }}
          </p>

          <p class="text-xs font-medium text-gray-600">
            Discount value is:
            {{
              package_item.discount_type === "fixed"
                ? package_item.discount_value || 0
                : (GetTotalPrice || {}).discount || 0
            }}
            SAR
          </p>
          <input
            type="number"
            name="discount_value"
            id="discount_value"
            placeholder="1"
            min="1"
            required
            autocomplete="off"
            v-model="package_item.discount_value"
          />
          <label for="discount_value">Discount value</label>
        </div>
      </div>
      <div class="form-group">
        <div class="with-label">
          <p class="error" v-if="errors['service_fees_value']">
            {{ errors["service_fees_value"].join(" ") }}
          </p>
          <p class="text-xs font-medium text-gray-600">
            Service fees is:
            {{
              package_item.service_fees_type === "fixed"
                ? package_item.service_fees_value || 0
                : (GetTotalPrice || {}).fees_value || 0
            }}
            SAR
          </p>
          <input
            type="number"
            name="service_fees_value"
            id="service_fees_value"
            placeholder="1"
            min="1"
            required
            autocomplete="off"
            v-model.number="package_item.service_fees_value"
          />
          <label for="service_fees_value">Service fees value</label>
        </div>
      </div>
      <div class="form-group">
        <div class="with-label">
          <p class="error" v-if="errors['discount_type']">
            {{ errors["discount_type"].join(" ") }}
          </p>
          <select
            name="discount_type"
            id="discount_type"
            v-model="package_item.discount_type"
            class="custom-select"
          >
            <option value="" selected disabled>-- select --</option>
            <option value="fixed">Fixed</option>
            <option value="percentage">Percentage</option>
          </select>
          <label for="discount_type">Discount type</label>
        </div>
      </div>
      <div class="form-group">
        <div class="with-label">
          <p class="error" v-if="errors['service_fees_type']">
            {{ errors["service_fees_type"].join(" ") }}
          </p>
          <select
            name="service_fees_type"
            id="service_fees_type"
            v-model="package_item.service_fees_type"
            class="custom-select"
          >
            <option value="" selected disabled>-- select --</option>
            <option value="fixed">Fixed</option>
            <option value="percentage">Percentage</option>
          </select>
          <div class="flex gap-4 flex-wrap" v-if="false">
            <div class="checkbox flex-[fit-content]">
              <input
                type="radio"
                :name="'service_fees_type-' + generateHash('service_fees_type')"
                :id="generateHash('service_fees_type')"
                class="self-start"
                value="fixed"
                v-model="package_item.service_fees_type"
              />
              <label
                :for="generateHash('service_fees_type')"
                class="text-sm font-medium rtl:font-semibold capitalize space-y-1"
              >
                <p>Fixed</p>
              </label>
            </div>
            <div class="checkbox flex-[fit-content]">
              <input
                type="radio"
                :name="'service_fees_type-' + generateHash('service_fees_type')"
                :id="generateHash('service_fees_type_percentage')"
                class="self-start"
                value="percentage"
                v-model.number="package_item.service_fees_type"
              />
              <label
                :for="generateHash('service_fees_type_percentage')"
                class="text-sm font-medium rtl:font-semibold capitalize space-y-1"
              >
                <p>Percentage</p>
              </label>
            </div>
          </div>
          <label for="service_fees_type">Service fees type</label>
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="with-label">
        <p class="error" v-if="errors['discount_start_date']">
          {{ errors["discount_start_date"].join(" ") }}
        </p>
        <t-datepicker
          placeholder="xxxx-xx-xx"
          v-model="package_item.discount_start_date"
          :clearable="false"
          id="discount_start_date"
          userFormat="Y-m-d"
          :weekStart="6"
        />
        <label for="discount_start_date">Discount start date</label>
      </div>
    </div>
    <div class="form-group">
      <div class="with-label">
        <p class="error" v-if="errors['discount_end_date']">
          {{ errors["discount_end_date"].join(" ") }}
        </p>
        <t-datepicker
          placeholder="xxxx-xx-xx"
          v-model="package_item.discount_end_date"
          :clearable="false"
          id="discount_end_date"
          userFormat="Y-m-d"
          :weekStart="6"
        />
        <label for="discount_end_date">Discount end date</label>
      </div>
    </div>

    <div
      class="col-span-2 grid grid-cols-2 gap-4 bg-teal-100 py-2 px-3 rounded"
    >
      <p class="text-sm text-gray-600 font-medium">Total price:</p>
      <p class="text-sm text-teal-600 font-bold">
        {{ (GetTotalPrice || {}).total || 0 }} SAR
      </p>
    </div>
    <div class="col-span-full">
      <button-with-loader
        btnClass="w-auto py-2 px-4 rounded bg-primary
      text-white text-xs "
        type="submit"
        @click="$emit('change', package_item)"
        >Save changes</button-with-loader
      >
    </div>
  </div>
</template>

<script>
import short from "shorthash";
import ButtonWithLoader from "../basics/ButtonWithLoader.vue";
export default {
  components: { ButtonWithLoader },
  name: "PricesComponent",
  props: {
    item: { type: Object, default: {} },
    errors: { type: Object, default: {} },
    purchaseable: { type: Boolean, default: true },
    sellable: { type: Boolean, default: true },
    is_weight: { type: Boolean, default: false },
  },
  data() {
    return {
      package_item: {
        cost_price_includes_vat: 1,
        sale_price_includes_vat: 1,
        ...this.item,
      },
      show_advanced_details: false,
    };
  },
  watch: {
    item: {
      handler(val) {
        this.package_item = val;
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    GetTotalPrice() {
      let result = {},
        value = 0,
        total = 0;
      const {
        sale_price = 0,
        sale_price_includes_vat,
        service_fees_type,
        service_fees_value = 0,
        discount_type,
        discount_value = 0,
      } = this.package_item;

      const pricePercent = parseFloat((sale_price * 15) / 100) || 0;

      const price =
        (sale_price_includes_vat ? +sale_price : +sale_price + +pricePercent) ||
        0;

      if (service_fees_type === "percentage") {
        const fees_value =
          parseFloat((+price * +service_fees_value) / 100) || 0;
        value = parseFloat(+price + fees_value) || 0;
        result.fees_value = fees_value.toFixed(2);
      } else {
        value = parseFloat(price + +service_fees_value) || 0;
      }

      if (discount_type === "percentage") {
        const dis_value = parseFloat((+value * +discount_value) / 100) || 0;
        total = parseFloat(+value - dis_value);
        result.total = total.toFixed(2);
        result.discount = dis_value.toFixed(2);
      } else {
        total = parseFloat(value - (discount_value || 0));
        result.total = total.toFixed(2);
      }

      return result;
    },
    generateHash() {
      return (key) => short.unique(key);
    },
  },

  methods: {
    GeneratePriceFromVat(key, checked) {
      let result,
        commission = 0,
        value = parseFloat(this.package_item[key] || 0);

      if (checked) {
        commission = parseFloat(+(value || 0) - +(value || 0) / 1.15) || 0;
        result =
          "The price includes 15% VAT ( " +
          commission.toFixed(2) +
          " SAR ) and total is " +
          value.toFixed(2) +
          " SAR";
      } else {
        commission = parseFloat((value * 15) / 100) || 0;
        result =
          "%15 VAT ( " +
          commission.toFixed(2) +
          " SAR ) and total is " +
          parseFloat((Number(value + commission) || 0).toFixed(2)) +
          " SAR";
      }

      return result;
    },
    GenerateUnitWeightPrice(value = 0, price = 0) {
      return ((value / price) * 1000 || 0).toFixed(2);
    },
  },
};
</script>
