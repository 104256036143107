<template>
  <div class="grid">
    <header class="w-full overflow-x-auto">
      <ul class="flex items-center w-full gap-6" v-if="link_type === 'button'">
        <li v-for="(item, index) in tabs" :key="index">
          <button
            class="
              py-2
              text-center
              font-medium
              rtl:font-semibold
              text-sm
              capitalize
              whitespace-nowrap
            "
            :class="{
              'active-tab-pills': component_name === item.name,
            }"
            @click.prevent="$emit('action', item.name)"
          >
            {{ item.key ? $t(item.key) : item.title }}
          </button>
        </li>
      </ul>
      <ul class="flex items-center w-full gap-6" v-if="link_type === 'link'">
        <li v-for="(item, index) in tabs" :key="index">
          <router-link
            class="
              py-2
              text-center
              font-medium
              rtl:font-semibold
              text-sm text-gray-600
              border-transparent
              block
              whitespace-nowrap
            "
            :to="item.url"
            exact-active-class="active-tab-pills"
          >
            {{ item.key ? $t(item.key) : item.title }}
          </router-link>
        </li>
      </ul>
    </header>
  </div>
</template>
<script>
export default {
  name: "TabsHeader",
  props: {
    link_type: String,
    component_name: [String, Number, Boolean],
    tabs: Array,
  },
};
</script>
