<template>
  <div class="min-h-screen flex flex-col">
    <div class="flex-1">
      <div class="xl:container">
        <div class="space-y-8 px-6 py-10">
          <div class="space-y-2 text-center">
            <p class="text-3xl font-bold">Open the safe</p>
            <p class="text-base text-gray-500">
              Enter the value of the safe in which the sales transactions are
              expected to be completed. This procedure will help the income
              accounting operations and also include reports correctly for you.
            </p>
          </div>
          <card>
            <div class="space-y-4">
              <div class="form-group space-y-3">
                <label for="amount">Amount</label>
                <input
                  type="number"
                  name="amount"
                  id="amount"
                  placeholder="0.00"
                  v-model="add_data.amount"
                />
                <!-- class="form-input" -->
                <p class="form-error">{{ add_errors["amount"] }}</p>
              </div>
              <div class="form-group space-y-3">
                <label for="notes">Notes</label>
                <textarea
                  name="notes"
                  id="notes"
                  placeholder="0.00"
                  v-model="add_data.notes"
                />
                <!-- class="form-textarea" -->
                <p class="form-error">{{ add_errors["notes"] }}</p>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
    <bottom-controlling
      :disabled="!add_data.amount"
      @click="updateOpenTheSafeData"
    />
  </div>
</template>

<script>
import Card from "@/components/Card.vue";
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";
import BottomControlling from "./BottomControlling.vue";
import { mapMutations } from "vuex";
export default {
  components: { Card, ButtonWithLoader, BottomControlling },
  name: "OpenTheSafe",
  data() {
    return {
      add_data: {},
      add_errors: {},
      add_disabled: false,
    };
  },
  methods: {
    ...mapMutations("pos_system", ["updateTheOpenSafe"]),
    updateOpenTheSafeData() {
      this.updateTheOpenSafe(this.add_data);
    },
  },
};
</script>
