<template>
  <SubPagesHeader>
    <section class="w-full">
      <form class="w-full mx-auto space-y-6">
        <!-- <p class="py-3 px-4 rounded bg-red-500 text-white text-base font-medium">
        All items includes VAT
      </p> -->
        <figure class="w-full bg-white rounded ring-1 ring-gray-200">
          <header class="p-4">
            <p class="text-base font-semibold rtl:font-bold">Product type</p>
          </header>
          <blockquote class="w-full p-5 border-t border-gray-200 space-y-4">
            <ul class="flex gap-4 flex-wrap">
              <li
                class="checkbox border border-gray-200 rounded-lg py-3 px-4 w-full flex-[fit-content] shrink-0"
                v-for="(item, index) in sources"
                :key="index"
              >
                <input
                  type="radio"
                  name="sourceGroup"
                  :id="item.key"
                  :value="item.key"
                  v-model="add_data.product_source"
                />
                <label :for="item.key" class="block cursor-pointer">
                  <p class="font-semibold text-base">{{ item.title }}</p>
                  <p class="font-medium text-gray-500 text-sm">
                    {{ item.content }}
                  </p>
                </label>
              </li>
            </ul>
          </blockquote>
        </figure>
        <figure
          class="w-full bg-white rounded ring-1 ring-gray-200"
          v-if="add_data.source === 'custom'"
        >
          <header class="p-4">
            <p class="text-base font-semibold rtl:font-bold">Product details</p>
          </header>
          <blockquote class="w-full p-5 border-t border-gray-200 space-y-4">
            <figure class="space-y-4">
              <p class="text-sm font-medium rtl:font-semibold">Image</p>
              <slide-transition>
                <figcaption v-if="add_data.image" class="relative table">
                  <img
                    :src="
                      add_data.image
                        ? convertImage(add_data.image)
                        : require('@/assets/images/placeholder.png')
                    "
                    @error="
                      (e) =>
                        (e.target.src = require('@/assets/images/placeholder.png'))
                    "
                    alt="store image"
                    class="w-auto max-h-[7rem] max-w-[5rem] object-contain"
                  />
                  <button
                    class="w-6 h-6 rounded-full flex items-center justify-center bg-red-500 text-white absolute -top-2 -right-2 rtl:right-auto rtl:-left-2 ring-2 ring-white"
                    type="button"
                    @click.prevent="() => clearImage('add_data')"
                  >
                    <i class="fa-solid fa-xmark fa-sm"></i>
                  </button>
                </figcaption>
              </slide-transition>
              <blockquote class="space-y-2">
                <label class="block">
                  <span class="sr-only">Choose image</span>
                  <input
                    type="file"
                    accept="image/*"
                    class="custom-file"
                    ref="uploader"
                    @change="(e) => $set(add_data, 'image', e.target.files[0])"
                  />
                </label>
                <p class="error" v-if="add_errors.image">
                  {{ add_errors.image.join(" ") }}
                </p>
              </blockquote>
            </figure>
            <div class="w-full">
              <div class="w-full flex gap-4 flex-col sm:flex-row">
                <div class="form-group space-y-3 flex-1">
                  <label for="name_en"> Primary name</label>
                  <input
                    type="text"
                    name="name_en"
                    id="name_en"
                    v-model="add_data.name_en"
                    v-ltr
                  />
                  <p class="error">
                    {{ (add_errors["name_en"] || []).join(" ") }}
                  </p>
                </div>
                <div class="form-group space-y-3 flex-1">
                  <label for="name_ar"> Secondary name </label>
                  <input
                    type="text"
                    name="name_ar"
                    id="name_ar"
                    v-model="add_data.name_ar"
                    v-rtl
                  />
                  <p class="error">
                    {{ (add_errors["name_ar"] || []).join(" ") }}
                  </p>
                </div>
              </div>
            </div>
            <div class="w-full">
              <div class="w-full flex gap-4 flex-col sm:flex-row">
                <div class="form-group space-y-3 flex-1">
                  <label for="desc_en">
                    {{ $t("description") }} | {{ $t("en") }}
                  </label>
                  <textarea
                    name="desc_en"
                    id="desc_en"
                    placeholder="..."
                    v-ltr
                    v-model="add_data.desc_en"
                  ></textarea>
                  <p class="error">
                    {{ (add_errors["desc_en"] || []).join(" ") }}
                  </p>
                </div>
                <div class="form-group space-y-3 flex-1">
                  <label for="desc_ar">
                    {{ $t("description") }} | {{ $t("ar") }}
                  </label>
                  <textarea
                    name="desc_ar"
                    id="desc_ar"
                    placeholder="..."
                    v-rtl
                    v-model="add_data.desc_ar"
                  ></textarea>
                  <p class="error">
                    {{ (add_errors["desc_ar"] || []).join(" ") }}
                  </p>
                </div>
              </div>
            </div>
            <div class="form-group-with-check">
              <div>
                <input
                  type="checkbox"
                  id="product_type"
                  name="product_type"
                  v-model="add_data.unit_type"
                  true-value="weight"
                  false-value="normal"
                />
              </div>
              <label for="product_type">
                <p class="font-semibold text-base">Product by weight?</p>
                <p class="font-medium text-gray-500 text-sm">
                  The product will be of a weight type
                </p>
              </label>
            </div>
            <div class="form-group-with-check">
              <div>
                <input
                  type="checkbox"
                  id="is_purchasable"
                  name="is_purchasable"
                  v-model="add_data.is_purchasable"
                  :true-value="1"
                  :false-value="0"
                />
              </div>
              <label for="is_purchasable">
                <p class="font-semibold text-base">Purchasable</p>
                <p class="font-medium text-gray-500 text-sm">
                  Prices and item code are required since you want to make it
                  purchasable.
                </p>
              </label>
            </div>
            <div class="form-group-with-check">
              <div>
                <input
                  type="checkbox"
                  id="is_sellable"
                  name="is_sellable"
                  v-model="add_data.is_sellable"
                  :true-value="1"
                  :false-value="0"
                />
              </div>
              <label for="is_sellable">
                <p class="font-semibold text-base">Sellable</p>
                <p class="font-medium text-gray-500 text-sm">
                  The product will be shown under Products
                </p>
              </label>
            </div>

            <div
              class="form-group space-y-3 flex-1"
              v-if="add_data.unit_type === 'normal'"
            >
              <div class="flex items-center gap-4 justify-between">
                <label for="barcode"> Barcode </label>
                <a
                  href="javascript:void(0)"
                  class="py-2 px-3 rounded bg-primary/10 text-primary font-semibold flex items-center justify-start gap-2 text-xs"
                  @click="generateRandomValue('barcode')"
                >
                  <i class="fa-solid fa-sync fa-sm"></i>
                  <span>Generate</span>
                </a>
              </div>
              <input
                type="text"
                name="barcode"
                id="barcode"
                v-model="add_data.barcode"
                placeholder="..."
              />
              <p class="error">
                {{ (add_errors["barcode"] || []).join(" ") }}
              </p>
            </div>
            <div class="form-group space-y-3 flex-1">
              <div class="flex items-center gap-4 justify-between">
                <label for="sku"> SKU </label>
                <a
                  href="javascript:void(0)"
                  class="py-2 px-3 rounded bg-primary/10 text-primary font-semibold flex items-center justify-start gap-2 text-xs"
                  @click="generateRandomValue('sku')"
                >
                  <i class="fa-solid fa-sync fa-sm"></i>
                  <span>Generate</span>
                </a>
              </div>
              <input
                type="text"
                name="sku"
                id="sku"
                v-model="add_data.sku"
                placeholder="..."
              />
              <p class="error">
                {{ (add_errors["sku"] || []).join(" ") }}
              </p>
            </div>

            <div
              class="form-group space-y-3 flex-1"
              v-if="
                add_data.is_sellable &&
                add_data.is_purchasable &&
                add_data.unit_type === 'weight'
              "
            >
              <div class="flex items-center gap-4 justify-between">
                <label for="item_code"> Item code </label>
                <!-- <a
                  href="javascript:void(0)"
                  class="py-2 px-3 rounded bg-primary/10 text-primary font-semibold flex items-center justify-start gap-2 text-xs"
                  @click="generateRandomValue('item_code')"
                >
                  <i class="fa-solid fa-sync fa-sm"></i>
                  <span>Generate</span>
                </a> -->
              </div>
              <input
                type="number"
                name="item_code"
                id="item_code"
                v-model="add_data.item_code"
                placeholder="12345"
              />
              <p class="error">
                {{ (add_errors["item_code"] || []).join(" ") }}
              </p>
            </div>
          </blockquote>
        </figure>
        <figure
          class="w-full bg-white rounded ring-1 ring-gray-200"
          v-if="add_data.product_source !== 'product_with_bundle'"
        >
          <header class="p-4">
            <p class="text-base font-semibold rtl:font-bold">Quantity</p>
          </header>
          <blockquote class="w-full p-5 border-t border-gray-200 space-y-4">
            <div class="w-full">
              <div class="w-full flex gap-4 flex-col sm:flex-row">
                <div
                  class="form-group space-y-3 flex-1"
                  v-if="add_data.unit_type === 'normal'"
                >
                  <label for="qty"> Quantity (Piece)</label>
                  <input
                    type="number"
                    name="qty"
                    id="qty"
                    v-model="add_data.qty"
                    placeholder="1"
                    min="1"
                  />
                  <p class="error">
                    {{ (add_errors["qty"] || []).join(" ") }}
                  </p>
                </div>
                <div v-else class="flex-1 flex gap-4 flex-col sm:flex-row">
                  <div class="form-group space-y-3 flex-1">
                    <label for="weight"> Approximate weight (KG)</label>
                    <input
                      type="number"
                      name="weight"
                      id="weight"
                      v-model="add_data.approx_weight"
                      placeholder="1 kg"
                      min="1"
                    />
                    <p class="error">
                      {{ (add_errors["approx_weight"] || []).join(" ") }}
                    </p>
                  </div>
                  <div
                    class="form-group space-y-3 flex-1"
                    v-if="add_data.unit_type === 'weight'"
                  >
                    <label for="weight"> Unit weight (G)</label>
                    <input
                      type="number"
                      name="weight"
                      id="weight"
                      v-model.number="add_data.unit_weight"
                      placeholder="1000 G"
                      min="1"
                      required
                    />
                    <p class="error">
                      {{ (add_errors["unit_weight"] || []).join(" ") }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="form-group space-y-3 flex-1"
              v-if="add_data.is_purchasable"
            >
              <label for="oos_limit"> Out of stock limit</label>
              <input
                type="number"
                name="oos_limit"
                id="oos_limit"
                v-model="add_data.oos_limit"
                placeholder="1"
                min="1"
              />
              <p class="error">
                {{ (add_errors["oos_limit"] || []).join(" ") }}
              </p>
            </div>
          </blockquote>
        </figure>
        <figure class="w-full bg-white rounded ring-1 ring-gray-200">
          <header class="p-4">
            <p class="text-base font-semibold rtl:font-bold">Price</p>
          </header>
          <blockquote class="w-full p-5 border-t border-gray-200 space-y-4">
            <div class="w-full flex gap-4 flex-col sm:flex-row">
              <div class="form-group space-y-3 flex-1">
                <label for="price">
                  Cost price
                  {{
                    !add_data.is_purchasable &&
                    add_data.product_source !== "product_with_bundle" &&
                    add_data.unit_type == "weight"
                      ? "( " + add_data.unit_weight + "G )"
                      : null
                  }}
                  (SAR)</label
                >
                <input
                  type="number"
                  name="price"
                  id="price"
                  v-model="add_data.cost_price"
                  placeholder="1"
                  min="1"
                />

                <p class="text-xs font-medium bg-slate-100 py-2 px-3 rounded">
                  {{
                    GeneratePriceFromVat(
                      "cost_price",
                      add_data.cost_price_includes_vat
                    )
                  }}
                </p>
                <div class="checkbox">
                  <input
                    type="checkbox"
                    name="cost_price_includes_vat"
                    id="cost_price_includes_vat"
                    class="self-start"
                    :true-value="1"
                    :false-value="0"
                    v-model="add_data.cost_price_includes_vat"
                  />
                  <label
                    for="cost_price_includes_vat"
                    class="text-sm font-medium rtl:font-semibold capitalize space-y-1"
                  >
                    <p>Includes 15% VAT</p>
                  </label>
                </div>
                <p class="error">
                  {{ (add_errors["cost_price"] || []).join(" ") }}
                </p>
              </div>
              <div class="form-group space-y-3 flex-1">
                <label for="price">
                  Sale price
                  {{
                    !add_data.is_purchasable &&
                    add_data.product_source !== "product_with_bundle" &&
                    add_data.unit_type == "weight"
                      ? "( " + add_data.unit_weight + "G )"
                      : null
                  }}
                  (SAR)</label
                >
                <input
                  type="number"
                  name="price"
                  id="price"
                  v-model="add_data.sale_price"
                  placeholder="1"
                  min="1"
                />
                <p class="text-xs font-medium bg-slate-100 py-2 px-3 rounded">
                  {{
                    GeneratePriceFromVat(
                      "sale_price",
                      add_data.sale_price_includes_vat
                    )
                  }}
                </p>

                <div class="checkbox">
                  <input
                    type="checkbox"
                    name="sale_price_includes_vat"
                    id="sale_price_includes_vat"
                    :true-value="1"
                    :false-value="0"
                    v-model="add_data.sale_price_includes_vat"
                  />
                  <label
                    for="sale_price_includes_vat"
                    class="text-sm font-medium rtl:font-semibold capitalize"
                    >Includes vat 15%</label
                  >
                </div>
                <p class="error">
                  {{ (add_errors["sale_price"] || []).join(" ") }}
                </p>
              </div>
            </div>
            <div class="w-full flex gap-4 flex-col sm:flex-row">
              <div class="flex-1">
                <div class="form-group">
                  <div class="with-label">
                    <p class="error" v-if="add_errors['service_fees_type']">
                      {{ add_errors["service_fees_type"].join(" ") }}
                    </p>
                    <select
                      name="service_fees_type"
                      id="service_fees_type"
                      v-model="add_data.service_fees_type"
                      class="custom-select"
                    >
                      <option value="" selected disabled>-- select --</option>
                      <option value="fixed">Fixed</option>
                      <option value="percentage">Percentage</option>
                    </select>
                    <label for="service_fees_type">Service fees type</label>
                  </div>
                </div>
              </div>
              <div class="flex-1">
                <div class="form-group">
                  <div class="with-label">
                    <p class="error" v-if="add_errors['service_fees_value']">
                      {{ add_errors["service_fees_value"].join(" ") }}
                    </p>
                    <p
                      class="text-xs font-medium bg-slate-100 py-2 px-3 rounded"
                    >
                      Service fees is:
                      {{
                        add_data.service_fees_type === "fixed"
                          ? add_data.service_fees_value || 0
                          : (GetTotalPrice || {}).fees_value || 0
                      }}
                      SAR
                    </p>
                    <input
                      type="number"
                      name="service_fees_value"
                      id="service_fees_value"
                      placeholder="1"
                      min="1"
                      required
                      autocomplete="off"
                      v-model="add_data.service_fees_value"
                    />
                    <label for="service_fees_value">Service fees value</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full flex gap-4 flex-col sm:flex-row">
              <div class="flex-1">
                <div class="form-group">
                  <div class="with-label">
                    <p class="error" v-if="add_errors['discount_type']">
                      {{ add_errors["discount_type"].join(" ") }}
                    </p>
                    <select
                      name="discount_type"
                      id="discount_type"
                      v-model="add_data.discount_type"
                      class="custom-select"
                    >
                      <option value="" selected disabled>-- select --</option>
                      <option value="fixed">Fixed</option>
                      <option value="percentage">Percentage</option>
                    </select>
                    <label for="discount_type">Discount type</label>
                  </div>
                </div>
              </div>
              <div class="flex-1">
                <div class="form-group">
                  <div class="with-label">
                    <p class="error" v-if="add_errors['discount_value']">
                      {{ add_errors["discount_value"].join(" ") }}
                    </p>
                    <input
                      type="number"
                      name="discount_value"
                      id="discount_value"
                      placeholder="1"
                      min="1"
                      required
                      autocomplete="off"
                      v-model="add_data.discount_value"
                    />
                    <label for="discount_value">Discount value</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full flex gap-4 flex-col sm:flex-row">
              <div class="flex-1">
                <div class="form-group">
                  <div class="with-label">
                    <p class="error" v-if="add_errors['discount_start_date']">
                      {{ add_errors["discount_start_date"].join(" ") }}
                    </p>
                    <t-datepicker
                      placeholder="xxxx-xx-xx"
                      v-model="add_data.discount_start_date"
                      :clearable="false"
                      id="discount_start_date"
                      userFormat="Y-m-d"
                      :weekStart="6"
                    />
                    <label for="discount_start_date">Start date</label>
                  </div>
                </div>
              </div>
              <div class="flex-1">
                <div class="form-group">
                  <div class="with-label">
                    <div
                      class="grid grid-cols-2 gap-4 bg-teal-100 py-2 px-3 rounded"
                    >
                      <p class="text-sm text-gray-600 font-medium">
                        Total price:
                      </p>
                      <p class="text-sm text-teal-600 font-bold">
                        {{ (GetTotalPrice || {}).total || 0 }} SAR
                      </p>
                    </div>
                    <p
                      class="text-xs font-medium bg-slate-100 py-2 px-3 rounded"
                    >
                      Discount value is:
                      {{
                        add_data.discount_type === "fixed"
                          ? add_data.discount_value || 0
                          : (GetTotalPrice || {}).discount || 0
                      }}
                      SAR
                    </p>
                    <p class="error" v-if="add_errors['discount_end_date']">
                      {{ add_errors["discount_end_date"].join(" ") }}
                    </p>
                    <t-datepicker
                      placeholder="xxxx-xx-xx"
                      v-model="add_data.discount_end_date"
                      :clearable="false"
                      id="discount_end_date"
                      userFormat="Y-m-d"
                      :weekStart="6"
                    />
                    <label for="discount_end_date">End date</label>
                  </div>
                </div>
              </div>
            </div>
          </blockquote>
        </figure>
        <div>
          <div class="form-group-with-check">
            <div>
              <input
                type="checkbox"
                id="has_packages"
                name="has_packages"
                v-model="has_packages"
              />
            </div>
            <label for="has_packages">
              This product is sold by packages?
            </label>
          </div>
        </div>
        <slide-transition>
          <figure
            v-if="has_packages"
            class="w-full bg-white rounded ring-1 ring-gray-200"
          >
            <header class="p-4 flex items-center gap-4 justify-between">
              <p class="text-base font-semibold rtl:font-bold shrink-0">
                Product packages
              </p>
              <a
                href="#"
                class="py-2 px-4 rounded flex items-center gap-2 text-sm bg-gray-200 text-gray-600 font-medium shrink-0"
                @click.prevent="addNewPackage"
                >New package</a
              >
            </header>
            <blockquote class="w-full p-5 border-t border-gray-200 space-y-4">
              <package-component
                :add_errors="{}"
                :item="{}"
                :packages="add_data.packages"
                @deletePackage="deletePackage"
                v-model="add_data.packages"
              />
            </blockquote>
          </figure>
        </slide-transition>
        <slide-transition>
          <figure
            v-if="has_packages"
            class="w-full bg-white rounded ring-1 ring-gray-200"
          >
            <header class="p-4 flex items-center gap-4 justify-between">
              <p class="text-base font-semibold rtl:font-bold shrink-0">
                Packages prices
              </p>
            </header>
            <blockquote class="w-full p-5 border-t border-gray-200 space-y-4">
              <store-prices
                :add_errors="{}"
                v-for="store in stores"
                :key="store.id"
                :store="store"
                :stores="stores"
                :packages="add_data.packages"
                :name="store['name_' + $i18n.locale]"
              />
            </blockquote>
          </figure>
        </slide-transition>
        <figure
          class="w-full bg-white rounded ring-1 ring-gray-200"
          v-if="add_data.product_source === 'product_with_options'"
        >
          <header class="p-4">
            <p class="text-base font-semibold rtl:font-bold">Option products</p>
          </header>
          <blockquote class="w-full p-5 border-t border-gray-200 space-y-4">
            <div class="form-group space-y-3 flex-1">
              <label for="option_products"> Products </label>
              <t-rich-select
                :fetch-options="getItems"
                placeholder="Select"
                text-attribute="name_with_price"
                value-attribute="id"
                :clearable="true"
                :closeOnSelect="false"
                v-model="add_data.option_products"
                multiple
              />
              <p class="error">
                {{ (add_errors["option_products"] || []).join(" ") }}
              </p>
            </div>
          </blockquote>
        </figure>
        <figure
          class="w-full bg-white rounded ring-1 ring-gray-200"
          v-if="add_data.product_source === 'product_with_bundle'"
        >
          <header class="p-4">
            <p class="text-base font-semibold rtl:font-bold">Products</p>
          </header>
          <blockquote class="w-full p-5 border-t border-gray-200 space-y-4">
            <ul class="list-none space-y-4">
              <li
                class="space-y-4"
                v-for="(product, index) in add_data.products"
                :key="index"
              >
                <div class="form-group space-y-3">
                  <label for="product_id"> Product </label>
                  <t-rich-select
                    :fetch-options="getItems"
                    placeholder="Select"
                    text-attribute="name_with_price"
                    value-attribute="id"
                    :clearable="true"
                    :closeOnSelect="true"
                    v-model="add_data.products[index].id"
                  />
                  <p class="error">
                    {{ (add_errors[`products.${index}.id`] || []).join(" ") }}
                  </p>
                </div>
                <div class="form-group space-y-3">
                  <label> Value </label>
                  <div class="flex items-stretch gap-3">
                    <input
                      type="number"
                      v-model.number="add_data.products[index].value"
                      placeholder="1"
                      min="1"
                      class="flex-1"
                    />
                    <button
                      class="w-10 rounded bg-red-500 text-white shrink-0"
                      @click.prevent="removeCurrentProduct(index)"
                    >
                      <i class="fa-solid fa-minus fa-sm"></i>
                    </button>
                  </div>
                  <p class="error">
                    {{
                      (add_errors[`products.${index}.value`] || []).join(" ")
                    }}
                  </p>
                </div>
              </li>
              <li>
                <button
                  class="bg-teal-600 text-white text-xs rounded font-semibold py-2 px-4"
                  @click.prevent="addNewProduct"
                >
                  Add new
                </button>
              </li>
            </ul>
          </blockquote>
        </figure>
        <figure class="w-full bg-white rounded ring-1 ring-gray-200">
          <header class="p-4">
            <p class="text-base font-semibold rtl:font-bold">Other details</p>
          </header>
          <blockquote class="w-full p-5 border-t border-gray-200 space-y-4">
            <div class="w-full" v-if="add_data.source === 'custom'">
              <div class="w-full flex gap-4 flex-col sm:flex-row">
                <div class="form-group space-y-3 flex-1">
                  <div class="flex items-center gap-4 justify-between">
                    <label for="category_id"> Category </label>
                    <router-link
                      to="/settings/categorizations/categories"
                      class="inline-flex gap-2 items-center text-sm text-teal-600 font-medium"
                    >
                      <i class="fa-solid fa-plus fa-xs align-middle"></i>
                      <span>New category</span>
                    </router-link>
                  </div>
                  <t-rich-select
                    :options="categories"
                    placeholder="Select"
                    :text-attribute="'name_' + $i18n.locale"
                    value-attribute="id"
                    :clearable="true"
                    :closeOnSelect="true"
                    v-model="add_data.category_id"
                  />
                  <p class="error">
                    {{ (add_errors["category_id"] || []).join(" ") }}
                  </p>
                </div>
                <div
                  class="form-group space-y-3 flex-1"
                  v-if="
                    add_data.product_source !== 'product_with_bundle' &&
                    !add_data.is_purchasable
                  "
                >
                  <div class="flex items-center gap-4 justify-between">
                    <label for="sub_category_ids"> Sub categories </label>
                    <router-link
                      to="/settings/categorizations/sub-categories"
                      class="inline-flex gap-2 items-center text-sm text-teal-600 font-medium"
                    >
                      <i class="fa-solid fa-plus fa-xs align-middle"></i>
                      <span>New sub category</span>
                    </router-link>
                  </div>
                  <select-with-limit
                    placeholder="Search in sub categories"
                    :text="'name_' + $i18n.locale"
                    id="id"
                    v-model="add_data.sub_category_ids"
                    :options="filterSubCategories"
                  />
                  <p class="error">
                    {{ (add_errors["sub_category_ids"] || []).join(" ") }}
                  </p>
                </div>
              </div>
            </div>
            <div class="form-group space-y-3 flex-1" v-if="false">
              <label for="meat_type_id"> Meat type </label>
              <t-rich-select
                :options="meatTypes"
                placeholder="Select"
                :text-attribute="'name_' + $i18n.locale"
                value-attribute="id"
                :clearable="true"
                :closeOnSelect="true"
                v-model="add_data.meat_type_id"
              />
              <p class="error">
                {{ (add_errors["meat_type_id"] || []).join(" ") }}
              </p>
            </div>
            <div class="form-group space-y-3 flex-1">
              <div class="flex items-center gap-4 justify-between">
                <label for="store_id"> Available in stores </label>

                <router-link
                  to="/locations"
                  class="inline-flex gap-2 items-center text-sm text-teal-600 font-medium"
                >
                  <i class="fa-solid fa-plus fa-xs align-middle"></i>
                  <span>New store</span>
                </router-link>
              </div>
              <select-with-limit
                placeholder="Search in stores"
                :text="'name_' + $i18n.locale"
                id="id"
                v-model="add_data.store_id"
                :options="stores"
              />

              <p class="error">
                {{ (add_errors["store_id"] || []).join(" ") }}
              </p>
            </div>
            <slide-transition>
              <div
                class="form-group space-y-3 flex-1"
                v-if="add_data.product_source === 'normal'"
              >
                <div class="flex items-center gap-4 justify-between">
                  <label for="supplier_id"> Supplier </label>

                  <router-link
                    to="/settings/suppliers"
                    class="inline-flex gap-2 items-center text-sm text-teal-600 font-medium"
                  >
                    <i class="fa-solid fa-plus fa-xs align-middle"></i>
                    <span>New supplier</span>
                  </router-link>
                </div>
                <t-rich-select
                  placeholder=".."
                  text-attribute="name"
                  value-attribute="id"
                  :clearable="true"
                  :closeOnSelect="true"
                  :fetch-options="getLocalSuppliers"
                  v-model="add_data.supplier_id"
                />
                <p class="error">
                  {{ (add_errors["supplier_id"] || []).join(" ") }}
                </p>
              </div>
            </slide-transition>
            <div class="form-group space-y-3 flex-1" v-if="false">
              <!-- v-if="add_data.source === 'supplier' && add_data.is_purchasable" -->
              <label for="filter_supplier_id"> Supplier </label>
              <t-rich-select
                placeholder="Search in suppliers"
                text-attribute="name"
                value-attribute="id"
                :clearable="true"
                :closeOnSelect="true"
                v-model="add_data.filter_supplier_id"
                :options="suppliers"
              />
              <p class="error">
                {{ (add_errors["supplier_id"] || []).join(" ") }}
              </p>
            </div>
            <div
              class="form-group space-y-3 flex-1"
              v-if="['store', 'supplier'].includes(add_data.source)"
            >
              <label for="product_id"> Product </label>
              <t-rich-select
                placeholder="Search in products"
                :text-attribute="'name_' + $i18n.locale"
                value-attribute="id"
                :clearable="true"
                :closeOnSelect="true"
                v-model="add_data.product_id"
                :options="products"
              />
              <p class="error">
                {{ (add_errors["product_id"] || []).join(" ") }}
              </p>
            </div>
          </blockquote>
        </figure>
        <div class="space-y-4">
          <div
            v-if="
              !add_data.is_purchasable &&
              add_data.product_source !== 'product_with_bundle' &&
              false
            "
          >
            <div class="form-group-with-check">
              <div>
                <input
                  type="checkbox"
                  id="has_track_inventory"
                  name="has_track_inventory"
                  v-model="add_data.has_track_inventory"
                  :true-value="1"
                  :false-value="0"
                />
              </div>
              <label for="has_track_inventory"> Track inventory? </label>
            </div>
          </div>

          <div v-if="add_data.is_purchasable && add_data.unit_type == 'weight'">
            <div class="form-group-with-check">
              <div>
                <input
                  type="checkbox"
                  id="has_wastage"
                  name="has_wastage"
                  v-model="has_wastage"
                />
              </div>
              <label for="has_wastage"> Has wastages? </label>
            </div>
          </div>
        </div>
        <slide-transition>
          <figure
            class="w-full bg-white rounded ring-1 ring-gray-200"
            v-if="add_data.has_track_inventory"
          >
            <header class="p-4">
              <p class="text-base font-semibold rtl:font-bold">
                Track inventory
              </p>
            </header>
            <blockquote class="w-full p-5 border-t border-gray-200 space-y-4">
              <div class="form-group space-y-3 flex-1">
                <label for="inventory_product_id"> Product </label>
                <t-rich-select
                  :fetch-options="getProducts"
                  placeholder="Select"
                  :text-attribute="'name_' + $i18n.locale"
                  value-attribute="id"
                  :clearable="true"
                  :closeOnSelect="false"
                  v-model="add_data.inventory_product_id"
                />
                <p class="error">
                  {{ (add_errors["inventory_product_id"] || []).join(" ") }}
                </p>
              </div>
            </blockquote>
          </figure>
        </slide-transition>
        <slide-transition>
          <figure
            class="w-full bg-white rounded ring-1 ring-gray-200"
            v-if="
              has_wastage &&
              add_data.is_purchasable &&
              add_data.unit_type == 'weight'
            "
          >
            <header class="p-4">
              <p class="text-base font-semibold rtl:font-bold">Wastages</p>
            </header>
            <blockquote class="w-full p-5 border-t border-gray-200 space-y-4">
              <ul class="list-none space-y-4">
                <li
                  class="space-y-4"
                  v-for="(wastage, index) in add_data.wastages"
                  :key="index"
                >
                  <div class="form-group space-y-3">
                    <label for="wastage_id"> Wastage </label>
                    <t-rich-select
                      :options="filterWastages"
                      placeholder="Select"
                      :text-attribute="'name_' + $i18n.locale"
                      value-attribute="id"
                      :clearable="true"
                      :closeOnSelect="true"
                      v-model="add_data.wastages[index].id"
                    />
                    <p class="error">
                      {{ (add_errors[`wastages.${index}.id`] || []).join(" ") }}
                    </p>
                  </div>
                  <div class="form-group space-y-3">
                    <label for="weight"> Weight (KG) </label>
                    <div class="flex items-stretch gap-3">
                      <input
                        type="number"
                        name="weight"
                        id="weight"
                        v-model="add_data.wastages[index].value"
                        placeholder="1"
                        min="1"
                        class="flex-1"
                      />
                      <button
                        class="w-10 rounded bg-red-500 text-white shrink-0"
                        :disabled="add_data.wastages.length <= 1"
                        @click.prevent="removeCurrentItem(index)"
                      >
                        <i class="fa-solid fa-minus fa-sm"></i>
                      </button>
                    </div>
                    <p class="error">
                      {{
                        (add_errors[`wastages.${index}.value`] || []).join(" ")
                      }}
                    </p>
                  </div>
                  <p class="text-sm text-gray-600 font-semibold">
                    Wastage type
                  </p>

                  <ul class="inline-flex flex-wrap gap-4">
                    <li>
                      <div class="form-group-with-check">
                        <div>
                          <input
                            type="radio"
                            :id="`fixed_${index}`"
                            :name="`wastage_${index}_value`"
                            value="fixed"
                            v-model="add_data.wastages[index].value_type"
                          />
                        </div>
                        <label :for="`fixed_${index}`"> Fixed </label>
                      </div>
                    </li>
                    <li>
                      <div class="form-group-with-check">
                        <div>
                          <input
                            type="radio"
                            :id="`percentage_${index}`"
                            :name="`wastage_${index}_value`"
                            value="percentage"
                            v-model="add_data.wastages[index].value_type"
                          />
                        </div>
                        <label :for="`percentage_${index}`"> Percentage </label>
                      </div>
                    </li>
                  </ul>
                  <p
                    class="error"
                    v-if="add_errors[`wastages.${index}.value_type`]"
                  >
                    {{
                      (add_errors[`wastages.${index}.value_type`] || []).join(
                        " "
                      )
                    }}
                  </p>
                </li>
                <li>
                  <button
                    class="bg-teal-600 text-white text-xs rounded font-semibold py-2 px-4"
                    @click.prevent="addNewItem"
                    :disabled="
                      !add_data.wastages[add_data.wastages.length - 1].id ||
                      !filterWastages.length
                    "
                  >
                    Add new
                  </button>
                </li>
              </ul>
            </blockquote>
          </figure>
        </slide-transition>
        <figure class="w-full bg-white rounded ring-1 ring-gray-200">
          <header class="p-4">
            <p class="text-base font-semibold rtl:font-bold">More images</p>
          </header>
          <blockquote class="w-full p-6 border-t border-gray-200 space-y-4">
            <div class="w-full space-y-3">
              <label
                for="images_uploader"
                class="w-full rounded-lg !py-12 px-8 bg-gray-400/10 text-gray-400 text-center border-dashed border-2 border-gray-400/50 block cursor-pointer"
                @dragover="
                  $event.target.classList.replace('border-dashed', 'border')
                "
                @dragleave="
                  $event.target.classList.replace('border', 'border-dashed')
                "
                @dragend="
                  $event.target.classList.replace('border', 'border-dashed')
                "
                @drop="dropControl($event)"
              >
                <span
                  class="w-14 h-14 rounded-full flex items-center justify-center mx-auto bg-gray-400/20 text-xl mb-4"
                >
                  <i class="fa-solid fa-arrow-up-from-bracket"></i>
                </span>
                <p class="text-sm font-medium rtl:font-semibold">
                  Drop and Drag or browse images
                </p>
              </label>
              <input
                type="file"
                name="images_uploader"
                id="images_uploader"
                hidden
                class="hidden"
                multiple
                @change="attachmentsUploader"
                accept="image/*"
              />
              <div class="block mt-6 w-auto">
                <div class="flex items-start gap-4 flex-wrap">
                  <div v-for="(img, imgIdx) in add_data.images" :key="imgIdx">
                    <div class="w-20 h-20 p-1 rounded ring-2 ring-gray-300">
                      <img
                        :src="convertImage(img)"
                        alt="upload"
                        class="w-full rounded h-full object-cover"
                      />
                    </div>
                    <button
                      class="w-8 h-8 rounded-full bg-white text-red-500 -mt-4 mx-auto block text-sm z-[1] relative"
                      @click.prevent="add_data.images.splice(imgIdx, 1)"
                    >
                      <i class="fa-solid fa-trash"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </blockquote>
        </figure>
      </form>
    </section>
    <template #action>
      <button-with-loader
        btnClass="w-auto py-2 px-4 rounded bg-primary
      text-white text-xs"
        :disabled="add_disabled"
        type="submit"
        @click="addProduct"
        >Save</button-with-loader
      >
    </template>
  </SubPagesHeader>
</template>
<script>
import StorePrices from "@/components/Inventory/packages/StorePrices.vue";
import PackageComponent from "@/components/Inventory/packages/index.vue";
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";
import SubPagesHeader from "@/components/basics/SubPagesHeader.vue";
import { mapActions } from "vuex";
export default {
  components: {
    ButtonWithLoader,
    SubPagesHeader,
    PackageComponent,
    StorePrices,
  },
  name: "CreateProduct",
  data() {
    return {
      add_data: {
        source: "custom",
        images: [],
        option_products: [],
        wastages: [{ id: null, value: null, value_type: "fixed" }],
        product_type: "normal",
        has_track_inventory: 0,
        is_sellable: 0,
        is_purchasable: 0,
        cost_price_includes_vat: 1,
        sale_price_includes_vat: 1,
        discount_type: "fixed",
        service_fees_type: "fixed",
        category_id: "",
        unit_weight: 1000,
        product_source: null,
      },
      add_errors: {},
      add_disabled: false,
      categories: [],
      sub_categories: [],
      products: [],
      stores: [],
      meatTypes: [],
      wastages: [],
      localeSuppliers: [],
      suppliers: [],
      has_wastage: false,
      has_packages: 1,
      sources: [
        { title: "One product", content: "Normal product", key: "normal" },
        {
          title: "Product with options",
          content: "Product included custom options",
          key: "product_with_options",
        },
        {
          title: "Bundle",
          content: "Add product with bundles",
          key: "product_with_bundle",
        },
      ],
      types: ["normal", "weight"],
    };
  },
  async created() {
    let type = this.$route.query.type;
    const existed = this.sources.some((source) => source.key == type);

    if (!existed) {
      type = "normal";
    }

    if (type == "product_with_bundle") {
      this.$set(this.add_data, "products", [{ id: null, value: null }]);
    }
    this.$set(this.add_data, "product_source", type);


    await Promise.all([
      this.getCategories(),
      this.getMeatTypes(),
      this.getWastages(),
      !this.add_data.is_purchasable && this.getProducts(),
      this.getSuppliers(),
      this.getSubCategories(),
      this.getStores(),
    ]);
  },
  watch: {
    "add_data.source"() {
      this.$set(this.add_data, "supplier_id", null);
      this.$set(this.add_data, "store_id", null);
    },
    "add_data.unit_type"(val) {
      this.add_data.approx_weight = null;
      this.add_data.qty = null;
      this.add_data.meat_type_id = null;
      if (val !== "weight") this.add_data.unit_weight = 0;
      else this.add_data.unit_weight = 1000;
    },
    "add_data.product_source"(val) {
      const products = this.add_data.products || [];
      this.add_data.option_products = [];
      this.add_data.products = [];
      if (val === "product_with_bundle" && !products.length)
        this.addNewProduct();
    },
  },
  computed: {
    filterWastages() {
      const selectedWastages = this.add_data.wastages;
      const mapWastages = selectedWastages.map((e) => e.id);
      const result = this.wastages.filter((e) => !mapWastages.includes(e.id));

      return result;
    },

    filterSubCategories() {
      return this.sub_categories?.filter((ev) =>
        this.add_data.category_id
          ? ev?.category?.id === this.add_data.category_id
          : ev
      );
    },
    GetTotalPrice() {
      let result = {},
        value = 0,
        total = 0;
      const {
        sale_price = 0,
        sale_price_includes_vat,
        service_fees_type,
        service_fees_value = 0,
        discount_type,
        discount_value = 0,
      } = this.add_data;

      const pricePercent = parseFloat((sale_price * 15) / 100) || 0;

      const price =
        (sale_price_includes_vat ? +sale_price : +sale_price + +pricePercent) ||
        0;

      if (service_fees_type === "fixed") {
        value = parseFloat(price + +service_fees_value) || 0;
      } else {
        const fees_value =
          parseFloat((+price * +service_fees_value) / 100) || 0;
        value = parseFloat(+price + fees_value) || 0;
        result.fees_value = fees_value.toFixed(2);
      }

      if (discount_type === "fixed") {
        total = parseFloat(value - (discount_value || 0));
        result.total = total.toFixed(2);
      } else {
        const dis_value = parseFloat((+value * +discount_value) / 100) || 0;
        total = parseFloat(+value - dis_value);
        result.total = total.toFixed(2);
        result.discount = dis_value.toFixed(2);
      }

      return result;
    },
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    async getCategories() {
      try {
        const { result } = await this.getRoute("categories");
        this.categories = result.categories;
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async getWastages() {
      try {
        const { result } = await this.getRoute("wastages");
        this.wastages = result.wastages;
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async getMeatTypes() {
      try {
        const { result } = await this.getRoute("meat_types");
        this.meatTypes = result.meat_types;
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },

    async getStores(q) {
      try {
        const { result } = await this.getRoute({
          name: "stores",
          options: {
            search_key: q,
          },
        });
        this.stores = result.stores;
        // return { results: result.stores };
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async getItems(q) {
      try {
        const { result } = await this.getRoute({
          name: "products",
          options: {
            search_key: q,
            store_id: this.add_data.store_id,
            is_inventory: 0,
            page: 1,
            is_bundle: 0,
          },
        });
        // this.products = result.products;
        return {
          results: result.products?.data?.map((e) => ({
            ...e,
            name_with_price:
              e["name_" + this.$i18n.locale] +
              " (" +
              (+e.price).toFixed(2) +
              "SAR)",
          })),
        };
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async getProducts(q) {
      try {
        const { result } = await this.getRoute({
          name: "products",
          options: {
            search_key: q,
            supplier_id: this.add_data.filter_supplier_id,
            store_id: this.add_data.store_id,
            is_inventory: 1,
            page: 1,
            is_bundle: 0,
          },
        });
        // this.products = result.products;
        return { results: result.products?.data };
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async getLocalSuppliers(q) {
      try {
        const { result } = await this.getRoute({
          name: "users",
          options: {
            user_type: "supplier",
            search_key: q,
          },
        });
        this.localeSuppliers = result.users;
        return { results: result.users };
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async getSuppliers(q) {
      try {
        const { result } = await this.getRoute({
          name: "suppliers",
          options: {
            search_key: q,
          },
        });
        this.suppliers = result.suppliers;
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async getSubCategories(q) {
      try {
        const { result } = await this.getRoute({
          name: "sub_categories",
          options: {
            search_key: q,
            category_id: this.add_data.category_id,
          },
        });
        this.sub_categories = result.sub_categories;
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },

    async addProduct() {
      if (
        this.add_data.unit_type === "weight" &&
        !this.add_data.unit_weight &&
        !this.add_data.is_purchasable &&
        !this.add_data.product_source === "product_with_bundle"
      )
        return this.createAlert("Unit weight value is required!!", "error");
      this.add_disabled = true;
      this.add_errors = {};

      let fd = new FormData();

      for (const key in this.add_data) {
        const item = this.add_data[key];
        if (item) {
          if (["supplier"].includes(this.add_data.source)) {
            if (
              [
                "qty",
                "price",
                "product_id",
                "store_id",
                "supplier_id",
                "source",
              ].includes(key)
            ) {
              fd.append(key, item);
            }
          } else {
            if (
              ["sub_category_ids", "store_id", "option_products"].includes(key)
            ) {
              item.forEach((elem, index) => {
                fd.append(`${key}[${index}]`, elem);
              });
            } else if (key === "image") {
              fd.append(key, item, item.name);
            } else if (key === "images") {
              item.forEach((img, index) => {
                if (typeof img === "object") {
                  fd.append(`${key}[${index}]`, img, img.name);
                } else {
                  fd.delete(`${key}[${index}]`);
                }
              });
            } else if (key === "item_code") {
              if (!this.add_data.is_sellable && this.add_data.is_purchasable) {
                fd.delete(key);
              } else {
                fd.append(key, item);
              }
            } else if (["cost_price", "sale_price"].includes(key)) {
              const price = this.GenerateUnitWeightPrice(
                Number(item) || 0,
                Number(this.add_data.unit_weight) || 0
              );
              fd.append(
                key,
                !this.add_data.is_purchasable &&
                  !this.add_data.product_source === "product_with_bundle" &&
                  this.add_data.unit_type == "weight"
                  ? price
                  : item
              );
            } else if (["discount_value"].includes(key)) {
              const price = this.GenerateUnitWeightPrice(
                Number(item) || 0,
                Number(this.add_data.unit_weight) || 0
              );

              fd.append(
                key,
                !this.add_data.is_purchasable &&
                  !this.add_data.product_source === "product_with_bundle" &&
                  this.add_data.discount_type == "fixed"
                  ? price
                  : item
              );
            } else if (["service_fees_value"].includes(key)) {
              const price = this.GenerateUnitWeightPrice(
                Number(item) || 0,
                Number(this.add_data.unit_weight) || 0
              );

              fd.append(
                key,
                !this.add_data.is_purchasable &&
                  !this.add_data.product_source === "product_with_bundle" &&
                  this.add_data.service_fees_type == "fixed"
                  ? price
                  : item
              );
            } else if (key === "unit_weight") {
              fd.delete(key);
            } else if (key === "wastages") {
              if (this.has_wastage && this.add_data.is_purchasable) {
                for (const wastage in item) {
                  fd.append(`${key}[${wastage}][id]`, item[wastage].id);
                  fd.append(`${key}[${wastage}][value]`, item[wastage].value);
                  fd.append(
                    `${key}[${wastage}][value_type]`,
                    item[wastage].value_type
                  );
                }
              } else {
                fd.delete(key);
              }
            } else if (key === "products") {
              if (this.add_data.product_source === "product_with_bundle") {
                for (const product in item?.filter((e) =>
                  Object.values(e).some((ev) => ev)
                )) {
                  fd.append(`products[${product}][id]`, item[product].id);
                  fd.append(`products[${product}][value]`, item[product].value);
                }
              } else {
                fd.delete(key);
              }
            } else {
              fd.append(key, item);
            }
          }
        }
      }

      if (this.add_data.is_purchasable) {
        fd.append("is_inventory", 1);
        // if (!this.add_data.is_sellable) {
        //   const keys = [
        //     "sale_price",
        //     "cost_price",
        //     "discount_type",
        //     "discount_value",
        //     "service_fees_type",
        //     "service_fees_value",
        //   ];
        //   for (const key of keys) {
        //     fd.delete(key);
        //   }
        // }
      }
      if (this.add_data.product_source === "product_with_bundle") {
        fd.append("is_bundle", 1);
      }

      try {
        const { data } = await this.axios.post("products/add", fd);
        // this.$router.push("/products");
        this.createAlert(data.message);
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.createAlert(responseError, "error");
        }
      } finally {
        this.add_disabled = false;

        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    },
    dropControl(e) {
      e.preventDefault();
      const files = e.dataTransfer.files;
      if (files) {
        files.forEach((file) => {
          this.add_data.images.push(file);
        });
        e.currentTarget.classList.replace("border", "border-dashed");
      }
    },
    attachmentsUploader(e) {
      e.preventDefault();
      e.target.files.forEach((file) => {
        this.add_data.images.push(file);
      });
    },
    uploadMainImage(e) {
      e.preventDefault();
      this.add_data.image = e.target.files[0];
    },
    convertImage(e) {
      return URL.createObjectURL(e);
    },
    clearImage(type) {
      const uploader = this.$refs.uploader;
      if (!uploader) return;
      uploader.value = null;
      this.$set(this[type], "image", null);
    },
    addNewProduct() {
      const products = this.add_data.products || [];
      products.push({ id: null, value: null });
      this.$set(this.add_data, "products", products);
    },
    removeCurrentProduct(index) {
      if (index === 0 && this.add_data.products.length === 1) {
        this.createAlert("One product at least should be added!!", "error");
        return;
      }
      this.add_data.products.splice(index, 1);
    },
    addNewItem() {
      if (!this.filterWastages.length) {
        this.createAlert("Wastages are not enough to add new one.", "error");
        return;
      }
      this.add_data.wastages.push({
        id: null,
        value: null,
        value_type: "fixed",
      });
    },
    removeCurrentItem(index) {
      if (index === 0 && this.add_data.wastages.length === 1) {
        this.createAlert("One wastage at least should be added!!", "error");
        return;
      }
      this.add_data.wastages.splice(index, 1);
    },
    GeneratePriceFromVat(key, checked) {
      let result,
        commission = 0,
        value = parseFloat(this.add_data[key] || 0);

      if (checked) {
        commission = parseFloat(+(value || 0) - +(value || 0) / 1.15) || 0;
        result =
          "The price includes 15% VAT ( " +
          commission.toFixed(2) +
          " SAR ) and total is " +
          value.toFixed(2) +
          " SAR";
      } else {
        commission = parseFloat((value * 15) / 100) || 0;
        result =
          "%15 VAT ( " +
          commission.toFixed(2) +
          " SAR ) and total is " +
          parseFloat((Number(value + commission) || 0).toFixed(2)) +
          " SAR";
      }

      return result;
    },
    GenerateUnitWeightPrice(value = 0, price = 0) {
      return ((value / price) * 1000 || 0).toFixed(2);
    },
    generateRandomValue(key) {
      this.$set(this.add_data, key, Date.now());
    },
    addNewPackage() {
      const packages = this.add_data.packages || [];
      const item = { image: null, prices: [] };
      if (!packages.length) {
        this.$set(this.add_data, "packages", [item]);
      } else {
        this.$set(this.add_data, "packages", [...packages, item]);
      }
    },
    deletePackage(index) {
      const packages = this.add_data.packages || [];
      const filterPackages = packages.filter((_, i) => i !== index);
      this.$set(this.add_data, "packages", filterPackages);
    },
  },
};
</script>
