<template>
  <div class="flex min-h-screen">
    <div class="w-96">
      <div
        class="min-h-screen max-h-screen flex flex-col divide-y divide-gray-200"
      >
        <header class="p-4">
          <div class="flex items-center justify-between gap-3">
            <button type="button" class="text-gray-500">
              <i class="fa-solid fa-arrow-left rtl:hidden"></i>
              <i class="fa-solid fa-arrow-right hidden rtl:inline"></i>
            </button>
            <p class="flex-1 font-semibold">Invoice/receipt templates</p>
            <button type="button" class="hidden">
              <i class="fa-solid fa-menu"></i>
            </button>
          </div>
        </header>
        <div class="flex-1 overflow-y-auto p-4 space-y-3">
          <p class="font-semibold text-sm">Print Layouts</p>
          <div class="flex flex-wrap gap-3">
            <div>
              <input
                type="radio"
                name="template_type"
                id="thermal"
                value="thermal"
                hidden
                class="peer"
                v-model="template_type"
              />
              <label
                for="thermal"
                class="flex items-center text-gray-600 text-sm gap-3 border border-gray-300 transition peer-checked:border-primary peer-checked:text-primary p-3 rounded cursor-pointer font-medium flex-[fit-content]"
              >
                <i class="fa-solid fa-receipt fa-lg"></i>
                <span>Thermal</span>
              </label>
            </div>
            <div>
              <input
                type="radio"
                name="template_type"
                id="invoice"
                value="invoice"
                hidden
                class="peer"
                v-model="template_type"
              />
              <label
                for="invoice"
                class="flex items-center text-gray-600 text-sm gap-3 border border-gray-300 transition peer-checked:border-primary peer-checked:text-primary p-3 rounded cursor-pointer font-medium flex-[fit-content]"
              >
                <i class="fa-solid fa-file fa-lg"></i>
                <span>Invoice a4</span>
              </label>
            </div>
          </div>
          <p class="font-semibold text-sm">Font size</p>
          <div class="flex flex-wrap gap-3">
            <div v-for="font in fonts[template_type]" :key="font">
              <input
                type="radio"
                name="add_data.font_size"
                :id="font"
                :value="font"
                hidden
                class="peer"
                v-model="add_data.font_size"
              />
              <label
                :for="font"
                class="flex items-center text-gray-600 text-sm gap-3 border border-gray-300 transition peer-checked:border-primary peer-checked:text-primary p-3 rounded cursor-pointer font-medium flex-[fit-content]"
              >
                <span>{{ font }}</span>
              </label>
            </div>
          </div>
          <p class="font-semibold text-sm">Language</p>

          <div class="flex flex-wrap gap-3">
            <div>
              <input
                type="radio"
                name="template_language"
                id="ar"
                value="ar"
                hidden
                class="peer"
                v-model="template_language"
              />
              <label
                for="ar"
                class="flex items-center text-gray-600 text-sm gap-3 border border-gray-300 transition peer-checked:border-primary peer-checked:text-primary p-3 rounded cursor-pointer font-medium flex-[fit-content]"
              >
                <span>Arabic - عربي</span>
              </label>
            </div>
            <div>
              <input
                type="radio"
                name="template_language"
                id="en"
                value="en"
                hidden
                class="peer"
                v-model="template_language"
              />
              <label
                for="en"
                class="flex items-center text-gray-600 text-sm gap-3 border border-gray-300 transition peer-checked:border-primary peer-checked:text-primary p-3 rounded cursor-pointer font-medium flex-[fit-content]"
              >
                <span>English - انجليزي</span>
              </label>
            </div>
          </div>
          <p class="font-semibold text-sm">Header</p>
          <ul class="space-y-3">
            <li v-for="item in header_items" :key="item.key">
              <div class="form-group-with-check">
                <input
                  type="checkbox"
                  :id="item.key"
                  data-type="switch"
                  v-model="add_data[item.key]"
                  :true-value="1"
                  :false-value="0"
                />
                <label :for="item.key" class="cursor-pointer text-sm">
                  <span>{{ item.title }}</span></label
                >
              </div>
            </li>
          </ul>
          <p class="font-semibold text-sm">Item content</p>
          <ul class="space-y-3">
            <li v-for="item in item_content_items" :key="item.key">
              <div class="form-group-with-check">
                <input
                  type="checkbox"
                  :id="item.key"
                  data-type="switch"
                  v-model="add_data[item.key]"
                  :true-value="1"
                  :false-value="0"
                />
                <label :for="item.key" class="cursor-pointer text-sm">
                  <span>{{ item.title }}</span></label
                >
              </div>
            </li>
          </ul>
          <p class="font-semibold text-sm">Footer</p>
          <ul class="space-y-3">
            <li v-for="item in footer_items" :key="item.key">
              <div class="form-group-with-check">
                <input
                  type="checkbox"
                  :id="item.key"
                  data-type="switch"
                  v-model="add_data[item.key]"
                  :true-value="1"
                  :false-value="0"
                />
                <label :for="item.key" class="cursor-pointer text-sm">
                  <span>{{ item.title }}</span></label
                >
              </div>
            </li>
          </ul>
        </div>
        <footer class="p-4">
          <button-with-loader
            btnClass="bg-primary py-3 px-4 rounded text-sm font-medium text-white w-full"
          >
            <!-- @click="updateSalesSettings"
        :disabled="add_disabled" -->
            <span>Save changes</span>
          </button-with-loader>
        </footer>
        <!--  -->
      </div>
    </div>
    <div class="w-full flex-1 bg-gray-300">
      <div class="grid">
        <div class="min-h-screen max-h-screen overflow-auto p-6">
          <!--  -->
          <div class="mx-auto table">
            <component :is="template_type" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";
import ReceiptTemplate from "@/components/ReceiptTemplate.vue";

export default {
  name: "InvoiceTemplates",
  data() {
    return {
      template_type: "thermal",
      template_language: "en",
      add_data: {
        font_size: "12",
      },
      fonts: {
        thermal: [10, 11, 12, 13],
        invoice: [11, 12, 13, 14, 15, 16],
      },
      header_items: [
        {
          title: "Company Logo",
          key: "company_logo",
        },
        {
          title: "Commercial Number",
          key: "cr_no",
        },
        {
          title: "Location Detail",
          key: "location_details",
        },
        {
          title: "Invoice number",
          key: "invoice_no",
        },
        {
          title: "Customer Name",
          key: "customer_name",
        },
        {
          title: "Customer Phone Number",
          key: "customer_mobile",
        },

        {
          title: "Customer address",
          key: "customer_address",
        },
      ],
      item_content_items: [
        {
          title: "SKU Barcode",
          key: "sku_barcode",
        },
        {
          title: "Include Price",
          key: "include_price",
        },
        {
          title: "Total (Tax Inclusive)",
          key: "total_inclusive_tax",
        },
        {
          title: "Total (Tax Exclusive)",
          key: "total_exclusive_tax",
        },
        {
          title: "Tax amount",
          key: "tax_amount",
        },
        {
          title: "Discount amount",
          key: "discount_amount",
        },
        {
          title: "Include weight unit",
          key: "include_weight",
        },
      ],
      footer_items: [
        {
          title: "Display Total Items",
          key: "total_items",
        },
        {
          title: "Hide Discount If Not Exist",
          key: "show_hide_discount",
        },
        {
          title: "Sales Note",
          key: "sales_note",
        },
        {
          title: "QR Code",
          key: "qr_code",
        },
        {
          title: "Comment",
          key: "comment",
        },
      ],
    };
  },

  components: { ButtonWithLoader, thermal: ReceiptTemplate },
};
</script>
