<template>
  <div class="w-fit">
    <div class="inline-flex items-center gap-2">
      <button
        type="button"
        class="w-6 h-6 text-center flex items-center justify-center bg-primary text-white rounded-full"
        @click.prevent="decrease"
        :disabled="disabled"
        v-if="type === 'normal'"
      >
        <i class="fa-solid fa-minus fa-xs"> </i>
      </button>
      <input
        type="number"
        placeholder="0"
        class="w-8 h-6 text-center text-xs bg-transparent"
        @change.prevent="onValueChange"
        v-model.number="count"
        step="any"
        :min="min"
        :max="max"
        :disabled="disabled"
      />
      <button
        type="button"
        class="w-6 h-6 text-center flex items-center justify-center bg-primary text-white rounded-full"
        @click.prevent="increase"
        :disabled="disabled"
        v-if="type === 'normal'"
      >
        <i class="fa-solid fa-plus fa-xs"> </i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Controllable",
  props: {
    value: { type: Number, default: 0 },
    min: { type: Number, default: 1 },
    max: { type: Number, default: 0 },
    disabled: { type: Boolean, default: false },
    type: { type: String, default: "normal", enum: ["normal", "weight"] },
  },
  data() {
    return {
      count: this.value,
    };
  },
  watch: {
    counter(val) {
      this.count = val;
    },
  },
  methods: {
    increase() {
      const value = this.count;
      this.updateValue(value + 1);
    },
    decrease() {
      const value = this.count;
      this.updateValue(value - 1);
    },
    onValueChange(ev) {
      const value = ev.target.value;
      this.updateValue(Number(value));
    },
    updateValue(value) {
      const min = this.min;
      const max = this.max;

      if (!!(min >= 0 && value < min) || value < 1) {
        this.count = 1;
        this.createAlert("Min value should be larger than " + min);
        return;
      }

      if (!!(max && value > max)) {
        this.count = max;
        this.createAlert("Max value should be less than " + max);
        return;
      }

      this.count = value;

      this.$emit("input", this.count);
    },
  },
};
</script>
