<template>
  <div class="p-6 space-y-6">
    <Table
      :title="$t('safe-track')"
      :records="`${pagination.total || 0} `"
      :pagination="pagination"
      :total_pages="pagination.total_pages"
      v-model="pagination.page"
    >
      <template #head>
        <th>{{ $t("user") }}</th>
        <th>{{ $t("location") }}</th>
        <th>{{ $t("amount") }}</th>
        <th>{{ $t("type") }}</th>
        <th>{{ $t("latest-update") }}</th>
      </template>
      <template #tbody v-if="!load">
        <TableLoad :rows="5" v-for="i in 4" :key="i" />
      </template>
      <template #tbody v-else-if="load && items.length">
        <tr v-for="(item, index) in items" :key="index">
          <td>{{ (item.user || {}).name || "-" }}</td>
          <td>{{ (item.store || {})["name_" + $i18n.locale] || "-" }}</td>
          <td>{{ item.amount || 0 }} SAR</td>
          <td>{{ item.track_type }}</td>

          <td v-html="createTime(item.updated_at)"></td>
          <!-- <td v-html="createTime((item.product || {}).prod_date)"></td> -->
        </tr>
      </template>

      <template #tbody v-else>
        <tr>
          <td colspan="100%" class="!text-center">{{ $t("no-data") }}</td>
        </tr>
      </template>
    </Table>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "SafeTracks",

  data() {
    return {
      items: [],
      load: false,

      pagination: {
        page: 1,
        total: 0,
        total_pages: 0,
        date: [],
      },
      disables: {},
    };
  },
  async created() {
    Promise.all([await this.getItems()]).finally(() => (this.load = true));
  },
  watch: {
    is_called(val, _) {
      if (val) {
        this.getItems();
      }
    },
  },
  computed: {
    ...mapGetters("table", ["search", "is_called"]),
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("table", ["changeIsCalled"]),
    async getItems() {
      try {
        const { total, total_pages, date, ...others } = this.pagination;
        const { result } = await this.getRoute({
          name: "safe_tracks",
          options: {
            ...others,
            search_key: this.search,
            from: date?.[0],
            to: date?.[1],
            store_id: this.store_id,
          },
        });
        const { data, pagination } = result.safe_tracks;
        this.items = data;
        this.$set(this.pagination, "total", pagination?.total);
        this.$set(this.pagination, "total_pages", pagination?.total_pages);
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      } finally {
        this.changeIsCalled(false);
      }
    },
  },
};
</script>
