<template>
  <div>
    <div class="grid">
      <Tabs link_type="link" :tabs="tabs" />
    </div>
    <slot></slot>
  </div>
</template>

<script>
import Tabs from "../basics/Tabs.vue";

export default {
  name: "ContainerWrapper",
  components: { Tabs },
  computed: {
    tabs(){
      const items  = [
        {
          title: "Inventory",
          url: "/inventory-setup",
          permissions: "show inventory"
        },

        {
          title: "Suppliers",
          url: "/inventory-setup/suppliers",
          permissions: "show suppliers"
        },
      ];

      return items.filter((ev) => this.hasPermission(ev.permissions))
    }
  }
};
</script>
