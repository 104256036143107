<template>
  <div>
    <!-- <div class="grid">
      <Tabs link_type="link" :tabs="tabs" />
    </div> -->
    <slot></slot>
  </div>
</template>

<script>
import Tabs from "../basics/Tabs.vue";

export default {
  name: "AlertsContainerWrapper",
  components: { Tabs },
  computed: {
    tabs() {
      const items = [
        {
          title: "Purchases",
          url: "/inventory-actions",
          permissions: "show purchase_orders",
        },
        {
          title: "Refunds",
          url: "/inventory-actions/refunds",
          permissions: "show refund_orders",
        },
        {
          title: "Transfers",
          url: "/inventory-actions/transfers",
          permissions: "show transfer_orders",
        },
        {
          title: "Processing",
          url: "/inventory-actions/processing",
          permissions: "show processing_tracks",
        },
        {
          title: "Stocktaking",
          url: "/inventory-actions/stocktaking",
          permissions: "show stock_count",
        },
        {
          title: "Suppliers",
          url: "/inventory-actions/suppliers",
          permissions: "show suppliers",
        },
        {
          title: "Wastages",
          url: "/inventory-actions/wastages",
          permissions: "show inventory_wastages",
        },
      ];

      return items.filter((ev) => this.hasPermission(ev.permissions));
    },
  },
};
</script>
