<template>
  <div>
    <div
      class="
        w-full
        grid
        sm:grid-cols-[repeat(auto-fill,minmax(16em,auto))]
        2xl:grid-cols-4
        gap-4
        items-stretch
      "
    >
      <single-card
        v-for="(card, index) in filterCards"
        :key="index"
        :item="card"
        class="h-full"
        :load="load"
      />
    </div>
  </div>
</template>

<script>
import SingleCard from "@/components/basics/SingleCard.vue";
export default {
  components: { SingleCard },
  name: "CardsContainer",
  computed: {
    filterCards() {
      return this.$route.params.visit_id
        ? this.cards.filter(
            (e) =>
              ![
                "visits_count",
                "company_stores_count",
                "company_branches_count",
              ].includes(e.key)
          )
        : this.cards;
    },
  },
  props: {
    cards: {
      type: Array,
      default: Array,
    },
    load: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
