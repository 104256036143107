<template>
  <div class="grid">
    <div class="flex flex-col w-full min-h-screen bg-white">
      <header
        class="bg-gray-100 px-6 py-4 border-b border-b-gray-200 sticky top-0 z-10"
      >
        <div :class="center ? 'container' : null">
          <div class="flex items-center gap-4 justify-between">
            <button
              class="shrink-0 py-2 px-3 flex items-center justify-center text-gray-600 rounded gap-3"
              @click.prevent="$router.go(-1)"
              v-tippy
              content="Back"
            >
              <i class="fa-solid fa-angle-left rtl:hidden fa-sm"></i>
              <i class="fa-solid fa-angle-right rtl:inline hidden fa-sm"></i>
            </button>
            <div class="grid flex-1">
              <p class="text-sm truncate text-gray-500 w-full">
                {{ title ? title : $route.meta.title }}
              </p>
            </div>
            <slot name="action"></slot>
          </div>
        </div>
      </header>
      <div class="flex-1 p-6">
        <div :class="center ? 'container' : null">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubPagesHeader",
  props: {
    title: {
      type: String,
      default: null,
    },
    center: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
