<template>
  <div>
    <label
      for="attachment_uploader"
      class="w-full rounded-lg !py-12 px-8 bg-gray-400/10 text-gray-400 text-center border-dashed border-2 border-gray-400/50 block cursor-pointer"
      @drop="dropControl($event)"
    >
      <span
        class="w-14 h-14 rounded-full flex items-center justify-center mx-auto bg-gray-400/20 text-xl mb-4"
      >
        <i class="fa-solid fa-arrow-up-from-bracket"></i>
      </span>
      <p class="text-sm font-medium rtl:font-semibold">
        Drop and Drag or browse images
      </p>
    </label>
    <input
      type="file"
      name="attachment_uploader"
      id="attachment_uploader"
      hidden
      class="hidden"
      :multiple="multiple"
      :accept="accept"
      @change="attachmentsUploader"
    />
    <div class="block mt-6 w-auto">
      <div class="flex items-start gap-2 flex-wrap">
        <div
          v-for="(attachment, index) in files"
          :key="index"
          class="flex items-center bg-gray-100 rounded py-2 px-3 group gap-3 border border-gray-200"
        >
          <a
            :href="convertFileToURL(attachment)"
            target="_black"
            class="flex-1 group-hover:underline line-clamp-1 shrink-0 text-sm text-gray-600"
          >
            {{ attachment.name }}
          </a>
          <button
            class="text-red-500"
            @click.prevent="removeAttachment(index)"
            type="button"
          >
            <i class="fa-solid fa-trash fa-sm"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AttachmentsUploader",
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      files: [],
      file: null,
    };
  },
  methods: {
    dropControl(e) {
      e.preventDefault();
      const files = e.dataTransfer.files;
      if (files) {
        this.files = [...files];

        this.$emit("input", this.multiple ? files : files[0]);
      }
    },
    attachmentsUploader(e) {
      e.preventDefault();
      const files = e.target.files;

      this.files = [...files];

      this.$emit("input", this.multiple ? files : files[0]);
    },
    convertFileToURL(e) {
      return URL.createObjectURL(e);
    },
    removeAttachment(index) {
      this.files.splice(index, 1);
      this.$emit("input", this.multiple ? files : files[0]);
    },
  },
};
</script>
