var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-6"},[_c('AlertsContainerWrapper',{staticClass:"space-y-6"},[_c('filter-wrapper',{ref:"filterWrapper",on:{"apply":_vm.getItems,"reset":_vm.handleReset}},[_c('div',[_c('t-datepicker',{attrs:{"placeholder":`From - To`,"multiple":"","monthsPerView":2,"range":"","clearable":false,"userFormat":"Y-m-d","weekStart":6},model:{value:(_vm.pagination.date),callback:function ($$v) {_vm.$set(_vm.pagination, "date", $$v)},expression:"pagination.date"}})],1)]),_c('Table',{attrs:{"title":_vm.$t('transfers'),"records":`${_vm.pagination.total || 0} `,"pagination":_vm.pagination,"total_pages":_vm.pagination.total_pages},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',[_c('button',{staticClass:"py-1.5 px-3 text-sm flex items-center gap-2 text-gray-600 font-medium rtl:font-semibold",on:{"click":function($event){$event.preventDefault();return _vm.newTransfer.apply(null, arguments)}}},[_c('i',{staticClass:"fa-solid fa-plus"}),_c('span',[_vm._v(_vm._s(_vm.$t("add")))])])])]},proxy:true},{key:"head",fn:function(){return [_c('th',[_vm._v("#")]),_c('th',[_vm._v(_vm._s(_vm.$t("from"))+" | "+_vm._s(_vm.$t("location")))]),_c('th',[_vm._v(_vm._s(_vm.$t("to"))+" | "+_vm._s(_vm.$t("location")))]),_c('th',[_vm._v(_vm._s(_vm.$t("products")))]),_c('th',[_vm._v(_vm._s(_vm.$t("total")))]),_c('th',[_vm._v(_vm._s(_vm.$t("tracking")))]),_c('th',[_vm._v(_vm._s(_vm.$t("actions")))])]},proxy:true},(!_vm.load)?{key:"tbody",fn:function(){return _vm._l((4),function(i){return _c('TableLoad',{key:i,attrs:{"rows":6}})})},proxy:true}:(_vm.load && _vm.items.length)?{key:"tbody",fn:function(){return _vm._l((_vm.items),function(item,index){return _c('tr',{key:index},[_c('td',[_c('router-link',{staticClass:"text-blue-600",attrs:{"to":_vm.hasPermission('show transfer_orders')
                  ? `/inventory-actions/transfers/${item.id}`
                  : '#'}},[_vm._v(" "+_vm._s(item.id)+" ")])],1),_c('td',[_c('p',[_vm._v(_vm._s((item.store || {})["name_" + _vm.$i18n.locale] || "-"))])]),_c('td',[_c('p',[_vm._v(_vm._s((item.to_store || {})["name_" + _vm.$i18n.locale] || "-"))])]),_c('td',[_vm._v(_vm._s(item.products.length))]),_c('td',[_vm._v(_vm._s(item.total)+" SAR")]),_c('td',[_c('div',[_c('ul',{staticClass:"flex gap-8 whitespace-nowrap w-full items-start"},_vm._l((item.tracking),function(track,idx){return _c('li',{directives:[{name:"tippy",rawName:"v-tippy"}],key:idx,staticClass:"flex items-center justify-center flex-col text-center",attrs:{"content":track.created_at
                      ? _vm.$moment(track.created_at).format('lll')
                      : 'N/A'}},[_c('span',{staticClass:"flex items-center justify-center w-6 h-6 rounded-full shrink-0",class:track.created_at
                        ? 'bg-primary text-white'
                        : 'bg-gray-200 text-gray-500'},[(track.created_at)?_c('i',{staticClass:"fa-solid fa-check fa-xs"}):_vm._e()]),_c('div',{staticClass:"text-center mt-2 space-y-1"},[_c('p',{staticClass:"capitalize font-medium text-slate-600 text-xs"},[_vm._v(" "+_vm._s(track["status_" + _vm.$i18n.locale])+" ")])])])}),0)])]),_c('td',[_c('div',{staticClass:"actions-group"},[(_vm.hasPermission('show transfer_orders'))?_c('div',[_c('router-link',{staticClass:"w-8 h-8 bg-sky-500 text-white rounded flex items-center justify-center",attrs:{"to":`/inventory-actions/transfers/${item.id}`}},[_c('i',{staticClass:"fa-solid fa-eye"})])],1):_vm._e()])])])})},proxy:true}:{key:"tbody",fn:function(){return [_c('tr',[_c('td',{staticClass:"!text-center",attrs:{"colspan":"100%"}},[_vm._v(" "+_vm._s(_vm.$t("no-data"))+" ")])])]},proxy:true}],null,true),model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1),_c('transfer',{ref:"newTransfer",attrs:{"refetch":_vm.getItems}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }