<template>
  <section class="w-full p-6 space-y-6">
    <Table
      title="Stock products"
      :records="`${items.length || 0} `"
      :pagination="{}"
    >
      <template #head>
        <th>Product</th>
        <th>Category</th>
        <th>Current stock</th>
        <th>Wastage processing</th>
      </template>
      <template #tbody v-if="!load">
        <TableLoad :rows="4" v-for="i in 4" :key="i" />
      </template>
      <template #tbody v-else-if="load && items.length">
        <tr v-for="(product, index) in filterItems" :key="product.id">
          <td>
            <router-link
              :to="
                hasPermission('edit inventory')
                  ? {
                      name: 'UpdateInventoryProduct',
                      params: {
                        slug: `${product.id}-${
                          product.name_ar ||
                          product.name_en ||
                          `product-${product.created_at}`
                        }`,
                      },
                    }
                  : '#'
              "
              class="flex items-center gap-3"
            >
              <div>
                <div class="w-10 h-10">
                  <img
                    :src="
                      product.image ||
                      require('@/assets/images/placeholder.png')
                    "
                    @error="
                      $event.target.src = require('@/assets/images/placeholder.png')
                    "
                    alt="product image"
                    class="w-full h-full rounded-md border object-cover border-gray-100"
                  />
                </div>
              </div>
              <div class="space-y-1 max-w-sm whitespace-normal">
                <p class="line-clamp-2">
                  {{ product["name_" + $i18n.locale] }}
                </p>
                <p class="text-sm font-medium text-gray-600">
                  {{ product.item_code || product.barcode }}
                </p>
              </div>
            </router-link>
          </td>

          <td>{{ (product.category || {})["name_" + $i18n.locale] }}</td>

          <td>
            <div class="space-y-2">
              <div
                class="flex rounded overflow-hidden border border-gray-200 bg-gray-100 items-center max-w-fit"
              >
                <input
                  type="number"
                  v-model="product.value"
                  min="1"
                  class="p-3 bg-transparent text-sm font-medium"
                />
                <p class="text-sm items-center font-medium text-gray-600 p-3">
                  {{ product.unit_type === "weight" ? "KG" : "Piece" }}
                </p>
              </div>
            </div>
            <p class="error" v-if="add_errors['products.' + index + '.id']">
              {{ add_errors["products." + index + ".id"].join(" ") }}
            </p>
            <p class="error" v-if="add_errors['products.' + index + '.value']">
              {{ add_errors["products." + index + ".value"].join(" ") }}
            </p>
          </td>
          <td>
            <button
              class="w-8 h-8 bg-gray-200 text-gray-600 rounded flex items-center justify-center"
              @click.prevent="showProductWastages(product)"
            >
              <i class="fa-solid fa-gear"></i>
            </button>
          </td>
        </tr>

        <tr>
          <td colspan="100%">
            <p class="error" v-if="add_errors['products']">
              {{ add_errors["products"].join(" ") }}
            </p>
          </td>
        </tr>
      </template>

      <template #tbody v-else>
        <tr>
          <td colspan="100%" class="!text-center">
            There are no data to display
          </td>
        </tr>
      </template>
    </Table>
    <div
      class="w-full bg-white sticky bottom-0 border border-gray-200 p-4 z-10 rounded flex items-center justify-end"
    >
      <button-with-loader
        btnClass="w-auto py-2 px-4 rounded bg-primary
                text-white text-xs"
        :disabled="add_disabled"
        @click="addValues"
        >Save changes</button-with-loader
      >
    </div>
    <Modal
      title="Wastage processing"
      v-if="show_active"
      @close="show_active = $event"
    >
      <template>
        <form class="p-6 space-y-4">
          <ul class="list-none space-y-4 mt-4">
            <li
              class="space-y-4"
              v-for="(wastage, index) in process_data.processing_wastages"
              :key="index"
            >
              <div class="form-group space-y-3">
                <label for="wastage_id"> Wastage </label>
                <t-rich-select
                  :options="filterWastages"
                  placeholder="Select"
                  :text-attribute="'name_' + $i18n.locale"
                  value-attribute="id"
                  :clearable="true"
                  :closeOnSelect="true"
                  v-model="process_data.processing_wastages[index].id"
                />
                <p class="error">
                  {{ (process_errors[`wastages.${index}.id`] || []).join(" ") }}
                </p>
              </div>
              <div class="form-group space-y-3">
                <label for="weight"> Weight (KG) </label>
                <div class="flex items-stretch gap-3">
                  <input
                    type="number"
                    name="weight"
                    id="weight"
                    v-model="process_data.processing_wastages[index].value"
                    placeholder="1"
                    min="1"
                    class="flex-1"
                  />
                  <button
                    class="w-10 rounded bg-red-500 text-white shrink-0"
                    :disabled="process_data.processing_wastages.length <= 1"
                    @click.prevent="removeCurrentItem(index)"
                  >
                    <i class="fa-solid fa-minus fa-sm"></i>
                  </button>
                </div>
                <p class="error">
                  {{
                    (process_errors[`wastages.${index}.value`] || []).join(" ")
                  }}
                </p>
              </div>
            </li>
            <li
              v-if="
                (
                  process_data.processing_wastages[
                    process_data.processing_wastages.length - 1
                  ] || {}
                ).id || filterWastages.length
              "
            >
              <button
                class="bg-teal-600 text-white text-xs rounded font-semibold py-2 px-4"
                @click.prevent="addNewItem"
              >
                Add new
              </button>
            </li>
          </ul>

          <button-with-loader
            btnClass="w-auto py-2 px-4 rounded bg-primary
          text-white text-xs [margin-inline-start:auto]"
            @click="addWastage"
            >Save changes</button-with-loader
          >
        </form>
      </template>
    </Modal>
  </section>
</template>
<script>
import ContainerWrapper from "@/components/Inventory/ContainerWrapper.vue";
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";
import Modal from "@/components/basics/Modal.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "AddStockCountItems",
  data() {
    return {
      load: false,
      items: [],
      add_data: {
        is_inventory: 1,
      },
      add_errors: {},
      add_error: null,
      add_disabled: false,
      show_active: false,
      process_data: {},
      stock_counts: [],
      wastages: [],
      process_errors: {},
    };
  },
  async created() {
    await Promise.all([this.getItems(), this.getWastages()]).finally(() => {
      this.load = true;
      this.changeIsCalled(false);
    });
  },
  watch: {
    is_called(val, _) {
      if (val) {
        this.getItems();
      }
    },
  },
  computed: {
    ...mapGetters("table", ["search", "is_called"]),
    filterItems() {
      const items = this.items;
      const search = this.search;
      return items.filter((e) =>
        this.search
          ? new RegExp(search).test(e["name_" + this.$i18n.locale])
          : e
      );
    },
    filterWastages() {
      const selectedWastages = this.process_data.wastages || [];
      const mapWastages = selectedWastages.map((e) => e.id);
      const result = this.wastages.filter((e) => !mapWastages.includes(e.id));

      return result;
    },
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("table", ["changeIsCalled"]),

    async getItems() {
      try {
        const user = this.$store.state.user;

        const { result } = await this.getRoute({
          name: "products",
          options: {
            search_key: this.search || this.$route.query.search,
            vendor_id: user.user_type === "vendor" ? user?.id : null,
            // store_id: user.user_type === "cashier" ? user?.id : null,
            store_id: this.store_id,
          },
        });

        this.items = result.products?.map((ev) => ({
          ...ev,
          processing_wastages: [{ id: null, product_id: ev.id, value: null }],
        }));
      } catch (error) {
        console.log(error);
        // this.createAlert(result?.message, type);
      }
    },

    async getWastages() {
      try {
        const { result } = await this.getRoute("wastages");
        this.wastages = result.wastages;
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },

    async addValues() {
      try {
        this.add_disabled = true;
        this.add_errors = {};
        const mapData = this.items
          .map((e) => ({
            value: e.value,
            id: e.id,
          }))
          .filter((e) => e.value >= 0);
        const mapWastages = new Array()
          .concat(...this.items.map((e) => e.processing_wastages))
          .filter((e) => e.value >= 0 && e.id);

        const { data } = await this.axios.post("stock_counts/add", {
          products: mapData,
          store_id: this.store_id,
          wastages: mapWastages,
        });

        this.createAlert(data?.message);
        this.getItems();
        this.$router.push("/settings/stock-count");
      } catch (error) {
        console.log(error);
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.createAlert(responseError, "error");
        }
      } finally {
        this.add_disabled = false;
      }
    },

    showProductWastages(product) {
      this.process_data = product;
      this.show_active = true;
    },
    addNewItem() {
      if (!this.filterWastages.length) {
        this.createAlert("Wastages are not enough to add new one.", "error");
        return;
      }
      this.process_data.processing_wastages.push({
        id: null,
        product_id: this.process_data.id,
        value: null,
      });
    },
    removeCurrentItem(index) {
      if (index === 0 && this.process_data.processing_wastages.length === 1) {
        this.createAlert("One wastage at least should be added!!", "error");
        return;
      }
      this.process_data.processing_wastages.splice(index, 1);
    },
    addWastage() {
      const mapItems = this.items.map((ev) => {
        if (ev.id === this.process_data.id) {
          return {
            ...ev,
            processing_data: this.process_data.processing_data,
          };
        }
        return ev;
      });
      this.items = mapItems;
      this.process_data = {};
      this.process_errors = {};
      this.show_active = false;
    },
  },
  components: { Modal, ContainerWrapper, ButtonWithLoader },
};
</script>
