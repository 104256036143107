<template>
  <form class="grid grid-cols-1 sm:grid-cols-2 gap-4 p-6">
    <div class="form-group space-y-3">
      <label for="name_en"> Primary name</label>
      <input
        type="text"
        name="name_en"
        id="name_en"
        v-model="add_option_data.name_en"
        v-ltr
      />
      <p class="error">
        {{ (errors["name_en"] || []).join(" ") }}
      </p>
    </div>
    <div class="form-group space-y-3">
      <label for="name_ar"> Secondary name </label>
      <input
        type="text"
        name="name_ar"
        id="name_ar"
        v-model="add_option_data.name_ar"
        v-rtl
      />
      <p class="error">
        {{ (errors["name_ar"] || []).join(" ") }}
      </p>
    </div>
    <div class="form-group-with-check h-fit">
      <div>
        <input
          type="checkbox"
          :id="generateHash('is_purchasable')"
          :name="generateHash('is_purchasable')"
          v-model="add_option_data.is_purchasable"
          :true-value="1"
          :false-value="0"
        />
      </div>
      <label :for="generateHash('is_purchasable')" class="block">
        <p class="font-semibold text-base">Purchasable</p>
        <p class="font-medium text-gray-500 text-sm">
          Prices and item code are required since you want to make it
          purchasable.
        </p>
      </label>
    </div>
    <div class="form-group-with-check h-fit">
      <div>
        <input
          type="checkbox"
          :id="generateHash('is_sellable')"
          :name="generateHash('is_sellable')"
          v-model="add_option_data.is_sellable"
          :true-value="1"
          :false-value="0"
        />
      </div>
      <label :for="generateHash('is_sellable')" class="block">
        <p class="font-semibold text-base">Sellable</p>
        <p class="font-medium text-gray-500 text-sm">
          The product will be shown under Products
        </p>
      </label>
    </div>
    <div class="col-span-full">
      <button
        type="button"
        class="py-2 px-3 text-blue-600 bg-blue-50 border border-blue-200 text-xs rounded ms-auto table"
        @click.prevent="addNewValue"
      >
        Add value
      </button>
    </div>
    <slide-transition>
      <expanded
        title="Values"
        v-show="add_option_data.values?.length"
        class="col-span-full"
      >
        <ul class="space-y-3">
          <li v-for="(value, index) in add_option_data.values" :key="index">
            <CardView
              :title="value['name_' + $i18n.locale]"
              hasDelete
              @delete="removeValue(value.random)"
            />
          </li>
        </ul>
      </expanded>
    </slide-transition>
    <div class="col-span-full">
      <button-with-loader
        btnClass="w-auto py-2 px-4 rounded bg-primary
      text-white text-xs "
        type="submit"
        @click="$emit('change', add_option_data)"
        >Save changes</button-with-loader
      >
    </div>
  </form>
</template>

<script>
import ButtonWithLoader from "../basics/ButtonWithLoader.vue";
import Expanded from "../Expanded.vue";
import CardView from "./CardView.vue";
import short from "shorthash";

export default {
  components: { ButtonWithLoader, Expanded, CardView },
  name: "AddUpdateOption",
  props: ["errors", "item"],
  data() {
    return {
      add_option_data: { ...this.item },
    };
  },
  watch: {
    item(val) {
      this.add_option_data = val;
    },
  },
  computed: {
    generateHash() {
      return (key) => short.unique(key);
    },
  },
  methods: {
    addNewValue() {
      const values = this.add_option_data?.values || [];
      const length = values.length;
      const size = length ? length + 1 : 1;
      const item = {
        image: null,
        name_ar: ["اسم القيمة", size].join(" "),
        name_en: ["Value name", size].join(" "),
        random: Date.now(),
      };
      this.$set(this.add_option_data, "values", [...values, item]);
    },
    removeValue(time) {
      const option = this.add_option_data;
      const values = option.values.filter((val) => val.random !== time);
      this.add_option_data = { ...option, values };
    },
  },
};
</script>
