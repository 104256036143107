<template>
  <div>
    <button
      class="text-center font-medium rtl:font-semibold flex items-center justify-center gap-2"
      :class="btnClass"
      :disabled="disabled"
      @click.prevent="$emit('click')"
      v-bind="$attrs"
    >
      <!-- <spinner
        size="w-4 h-4"
        class="inline-block align-middle"
        v-if="disabled"
      /> -->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        v-if="disabled"
      >
        <g transform="translate(0 24) scale(1 -1)">
          <path
            fill="currentColor"
            d="M12 2A10 10 0 1 0 22 12A10 10 0 0 0 12 2Zm0 18a8 8 0 1 1 8-8A8 8 0 0 1 12 20Z"
            opacity=".5"
          />
          <path
            fill="currentColor"
            d="M20 12h2A10 10 0 0 0 12 2V4A8 8 0 0 1 20 12Z"
          >
            <animateTransform
              attributeName="transform"
              dur="0.5s"
              from="0 12 12"
              repeatCount="indefinite"
              to="360 12 12"
              type="rotate"
            />
          </path>
        </g>
      </svg>
      <slot v-else></slot>
    </button>
  </div>
</template>

<script>
export default {
  name: "ButtonWithLoader",
  props: {
    disabled: { type: Boolean, default: false },
    btnClass: {
      type: String,
      default: null,
    },
  },
};
</script>
