<template>
  <div>
    <Wrapper v-if="!$store.state.token">
      <PricingContent />
    </Wrapper>
    <div class="p-6 grid" v-else>
      <PricingContent />
    </div>
  </div>
</template>

<script>
import PricingContent from "@/components/PricingContent.vue";
import Wrapper from "@/components/auth/Wrapper.vue";

export default {
  name: "Pricing",
  components: { Wrapper, PricingContent },
};
</script>
