<template>
  <div>
    <form class="px-6 space-y-4">
      <slide-transition>
        <div class="form-group" v-show="add_discounts_active">
          <div class="with-label">
            <p class="error" v-if="add_errors['products']">
              {{ add_errors["products"].join(" ") }}
            </p>
            <select-with-limit
              :fetch="getProducts"
              placeholder="Select"
              :text="'name_' + $i18n.locale"
              id="id"
              v-model="product_ids"
              @input="onProductsSelected"
              ref="productsRef"
            />
            <label for="product_ids">{{ $t("products") }}</label>
          </div>
        </div>
      </slide-transition>

      <div>
        <Table
          :title="$t('products')"
          :records="`${selected_products.length || 0} `"
          :has_search="false"
        >
          <template #head>
            <th>{{ $t("product") }}</th>
            <th>{{ $t("price") }}</th>
            <th>{{ $t("discount") }}</th>
            <th>{{ $t("start-date") }}</th>
            <th>{{ $t("end-date") }}</th>
            <th>{{ $t("actions") }}</th>
          </template>
          <template #actions>
            <div v-if="hasPermission('add discounts')">
              <button
                class="py-1.5 px-3 text-sm flex items-center gap-2 text-gray-600 font-medium rtl:font-semibold"
                @click.prevent="add_discounts_active = !add_discounts_active"
              >
                <template v-if="add_discounts_active">
                  <i class="fa-solid fa-eye"></i>
                  <span>{{ $t("products") }}</span>
                </template>
                <template v-else>
                  <i class="fa-solid fa-plus"></i>
                  <span>{{ $t("add") }}</span>
                </template>
              </button>
            </div>
          </template>
          <template #tbody v-if="selected_products.length">
            <tr v-for="(product, index) in selected_products" :key="product.id">
              <td>
                <router-link
                  :to="
                    hasPermission('edit inventory')
                      ? {
                          name: 'UpdateInventoryProduct',
                          params: {
                            slug: product.product.main_product?.id,
                          },
                        }
                      : '#'
                  "
                  class="flex items-center gap-3"
                >
                  <div>
                    <div class="w-10 h-10">
                      <img
                        :src="
                          product.product.image ||
                          require('@/assets/images/placeholder.png')
                        "
                        @error="
                          $event.target.src = require('@/assets/images/placeholder.png')
                        "
                        alt="product image"
                        class="w-full h-full rounded-md border object-cover border-gray-100"
                      />
                    </div>
                  </div>
                  <div class="flex-1 max-w-fit">
                    <p class="line-clamp-2 whitespace-normal text-sky-600">
                      {{ product.product["name_" + $i18n.locale] }}
                    </p>
                    <p class="error">
                      {{
                        (add_errors["products." + index + ".id"] || []).join(
                          " "
                        )
                      }}
                    </p>
                  </div>
                </router-link>
              </td>
              <td>
                <span
                  :class="
                    product.discount_value ? 'line-through text-red-500' : ''
                  "
                  >{{ generateCurrency(product.product.price) }}</span
                >
                <span v-if="product.discount_value">
                  {{
                    generateCurrency(
                      getDiscountPrice(
                        product.product.price,
                        product.discount_value,
                        product.discount_type
                      )
                    )
                  }}</span
                >
              </td>
              <td>
                <div class="flex gap-2">
                  <div class="form-group">
                    <input
                      type="number"
                      class="text-sm"
                      placeholder="0"
                      min="0"
                      max="100"
                      required
                      style="width: fit-content"
                      v-model.number="product.discount_value"
                      @blur.prevent="
                        (e) => {
                          if (product.discount_type === 'percentage') {
                            product.discount_value =
                              e.target.valueAsNumber >= 100
                                ? 100
                                : e.target.value;
                          }
                        }
                      "
                    />
                  </div>
                  <select
                    v-model="product.discount_type"
                    class="custom-select !w-fit"
                    required
                  >
                    <option value="" selected disabled>-- select --</option>
                    <option value="fixed">{{ $t("fixed") }}</option>
                    <option value="percentage">{{ $t("percentage") }}</option>
                  </select>
                </div>
                <p class="error">
                  {{
                    (
                      add_errors["products." + index + ".discount_value"] ||
                      add_errors["products." + index + ".discount_type"] ||
                      []
                    ).join(" ")
                  }}
                </p>
              </td>
              <td>
                <div class="form-group">
                  <input
                    type="date"
                    class="text-sm"
                    :min="new Date().toISOString().substring(0, 10)"
                    v-model="product.discount_start_date"
                  />
                  <p class="error">
                    {{
                      (
                        add_errors[
                          "products." + index + ".discount_start_date"
                        ] || []
                      ).join(" ")
                    }}
                  </p>
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    type="date"
                    class="text-sm"
                    :min="
                      product.discount_start_date ||
                      new Date().toISOString().substring(0, 10)
                    "
                    v-model="product.discount_end_date"
                  />
                  <p class="error">
                    {{
                      (
                        add_errors[
                          "products." + index + ".discount_end_date"
                        ] || []
                      ).join(" ")
                    }}
                  </p>
                </div>
              </td>
              <td>
                <div class="flex gap-2">
                  <button-with-loader
                    :disabled="add_disabled"
                    btnClass="bg-primary p-3 py-2 rounded text-xs font-medium rtl:font-semibold text-white"
                    @click="updateProduct(product.id)"
                  >
                    {{ $t("save") }}
                  </button-with-loader>
                  <button-with-loader
                    :disabled="add_disabled"
                    btnClass="bg-red-500 p-3 py-2 rounded text-xs font-medium rtl:font-semibold text-white"
                    @click="cancelProduct(product.id)"
                    v-if="!add_discounts_active"
                  >
                    {{ $t("cancel") }}
                  </button-with-loader>
                </div>
              </td>
            </tr>
          </template>

          <template #tbody v-else>
            <tr>
              <td colspan="100%" class="!text-center">{{ $t("no-data") }}</td>
            </tr>
          </template>
        </Table>
      </div>
      <!-- <div>
        <button-with-loader
          :disabled="add_disabled"
          btnClass="bg-primary py-2 px-4 rounded text-xs font-medium rtl:font-semibold text-white"
          @click="updateProducts"
        >
          Save changes
        </button-with-loader>
      </div> -->
    </form>
  </div>
</template>
<script>
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";
import Modal from "@/components/basics/Modal.vue";
import { mapActions } from "vuex";
export default {
  name: "Discounts",
  data() {
    return {
      load: false,
      products: [],
      add_data: {
        products: [],
      },
      add_errors: {},
      add_disabled: false,
      selected_products: [],
      product_ids: [],
      add_discounts_active: false,
    };
  },
  async created() {
    await Promise.all([
      this.getProducts(),
      this.getDiscountedProducts(),
    ]).finally(() => (this.load = true));
  },
  watch: {
    add_discounts_active(value) {
      if (value) {
        // this.selected_products = [];
        this.$set(this.$data, "selected_products", []);
      } else {
        this.getDiscountedProducts();
      }
    },
  },

  methods: {
    ...mapActions("getting", ["getRoute"]),

    async getDiscountedProducts() {
      try {
        const { result } = await this.getRoute({
          name: "products",
          options: {
            has_discount: 1,
          },
        });
        const data = result.products;
        this.selected_products = this.generateProducts(data);
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async getProducts(q) {
      try {
        const { result } = await this.getRoute({
          name: "products",
          options: {
            search_key: q,
            page: 1,
            has_discount: 0,
          },
        });
        const data = result.products?.data;
        this.products = data;
        return {
          results: data,
        };
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },

    async updateProducts() {
      try {
        this.add_disabled = true;
        this.add_errors = {};

        const products = this.selected_products
          .map((e) => {
            const {
              id,
              discount_value,
              discount_type,
              discount_start_date,
              discount_end_date,
            } = e;
            return {
              id,
              discount_value,
              discount_type,
              discount_start_date,
              discount_end_date,
            };
          })
          .filter((e) => e.discount_value);
        const body = {
          products,
        };
        const { data } = await this.axios.post(
          "products/update_discount",
          body
        );
        // this.add_data = {products: []};
        // this.selected_products = [];
        //   this.product_ids = [];
        this.createAlert(data?.message);
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.createAlert(responseError, "error");
        }
      } finally {
        this.add_disabled = false;
      }
    },

    async updateProduct(id) {
      try {
        this.add_disabled = true;
        this.add_errors = {};

        const products = this.selected_products
          .map((e) => {
            const {
              id,
              discount_value,
              discount_type,
              discount_start_date,
              discount_end_date,
            } = e;
            return {
              id,
              discount_value,
              discount_type,
              discount_start_date,
              discount_end_date,
            };
          })
          .filter((e) => e.discount_value)
          .filter((e) => e.id == id);
        const body = {
          products,
        };
        const { data } = await this.axios.post(
          "products/update_discount",
          body
        );
        // this.add_data = {products: []};
        // this.selected_products = [];
        //   this.product_ids = [];
        this.createAlert(data?.message);
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.createAlert(responseError, "error");
        }
      } finally {
        this.add_disabled = false;
      }
    },
    async cancelProduct(id) {
      try {
        this.add_disabled = true;
        this.add_errors = {};

        const products = this.selected_products
          .map((e) => {
            const {
              id,
              discount_type,
              discount_start_date,
              discount_end_date,
            } = e;
            return {
              id,
              discount_value: 0,
              discount_type,
              discount_start_date,
              discount_end_date,
            };
          })
          .filter((e) => e.id == id);
        const body = {
          products,
        };
        const { data } = await this.axios.post(
          "products/update_discount",
          body
        );
        // this.add_data = {products: []};
        // this.selected_products = [];
        //   this.product_ids = [];
        this.getDiscountedProducts();
        this.createAlert(data?.message);
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.createAlert(responseError, "error");
        }
      } finally {
        this.add_disabled = false;
      }
    },

    onProductsSelected() {
      const parentRef = this.$refs.productsRef;
      const selectedRef = parentRef.$refs.TRichSelectComponent;
      const selectedOptions = (selectedRef.selectedOptions || []).map(
        (option) => option.raw
      );
      const selectedProducts = this.generateProducts(selectedOptions);

      this.selected_products = selectedProducts;
    },

    generateProducts(data) {
      return data.map((product) => {
        const selectedProduct = this.selected_products?.find(
          (e) => e.id == product.id
        );
        const object = {
          discount_value: product.discount_value,
          discount_type: product.discount_type || "fixed",
          discount_start_date: product.discount_start_date,
          discount_end_date: product.discount_end_date,
        };
        return selectedProduct
          ? selectedProduct
          : {
              product,
              id: product.id,
              ...object,
            };
      });
    },
    getDiscountPrice(price, discount, type) {
      let total = 0;
      if (type === "fixed") {
        total = price - discount;
      } else if (type === "percentage") {
        total = Math.abs(price - price * (discount / 100));
      } else {
        total = 0;
      }

      return total;
    },
  },
  components: { Modal, ButtonWithLoader },
};
</script>
