<template>
  <wrapper>
    <slide-transition>
      <div v-if="!currentComponent">
        <h1 class="text-primary text-4xl capitalize text-center font-bold">
          {{ $t("sign-up-title") }}
        </h1>
        <p
          class="text-gray-600 text-center font-medium rtl:font-semibold text-sm mt-2"
        >
          {{ $t("sign-up-content") }}
        </p>

        <ul class="space-y-2 mt-8">
          <li v-for="(component, index) in types" :key="index">
            <button
              class="w-full py-3 px-4 rounded bg-gray-100 font-medium text-center text-sm"
              @click.prevent="currentComponent = component"
            >
              {{ component.name }}
            </button>
          </li>
        </ul>
      </div>
      <component
        v-else
        :is="currentComponent.component"
        :user_type="currentComponent.user_type"
      />
    </slide-transition>
  </wrapper>
</template>

<script>
import Register from "@/components/account/Register.vue";
import SupplierRegister from "@/components/account/SupplierRegister.vue";
import Wrapper from "@/components/auth/Wrapper.vue";
export default {
  components: { Wrapper, Register, SupplierRegister },
  name: "RegisterPage",
  data() {
    return {
      currentComponent: {
        name: "Register as vendor",
        component: "Register",
        user_type: "vendor",
      },
      types: [
        {
          name: "Register as vendor",
          component: "Register",
          user_type: "vendor",
        },
        {
          name: "Register as supplier",
          component: "SupplierRegister",
          user_type: "supplier",
        },
        {
          name: "Register as wholesaler",
          component: "SupplierRegister",
          user_type: "wholesaler",
        },
      ],
    };
  },
};
</script>
