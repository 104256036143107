<template>
  <div class="w-[8cm] bg-white p-4 py-6 space-y-3">
    <div class="space-y-2">
      <img
        src="@/assets/images/placeholder.png"
        alt="company logo"
        class="w-full max-w-[150px] text-center mx-auto object-contain"
      />
      <p class="text-center">Company name</p>
      <p class="text-center">Print date</p>
      <p class="text-center">{{ new Date().toISOString().substring(0, 10) }}</p>
      <p class="text-center table mx-auto p-2 border border-gray-200">
        {{ Date.now() }}
      </p>
    </div>
    <hr class="border-black border-dashed" />
    <div class="space-y-2">
      <div class="grid grid-cols-3 gap-3">
        <p></p>
        <p>QTY</p>
        <p>Price</p>
      </div>

      <hr class="border-black border-dashed" />

      <div class="grid grid-cols-3 gap-3" v-for="i in 4" :key="i">
        <p>
          <span>Product name</span><br />
          <span>SKU: {{ Date.now() }}</span
          ><br />
          <span>Barcode: {{ Date.now() }}</span
          ><br />
          <span>Weight: {{ GenerateUnit(0) }}</span>
        </p>
        <p>x{{ i }}</p>
        <p>
          <span>{{ generateCurrency(0) }}</span
          ><br />
          <span>Discount: {{ generateCurrency(0) }}</span
          ><br />
          <span>Tax: {{ generateCurrency(0) }}</span
          ><br />
        </p>
      </div>
    </div>
    <hr class="border-black border-dashed" />
    <div class="grid grid-cols-2 gap-3">
      <p>Total items</p>
      <p class="text-end">{{ 4 }}</p>
      <p>Tax amount</p>
      <p class="text-end">{{ generateCurrency(0) }}</p>
      <p>Tax amount</p>
      <p class="text-end">{{ generateCurrency(0) }}</p>
      <p>Discount</p>
      <p class="text-end">{{ generateCurrency(0) }}</p>
      <hr class="border-black border-dashed col-span-full" />

      <p>Total (exclusive tax)</p>
      <p class="text-end">{{ generateCurrency(0) }}</p>
      <p>Total (inclusive tax)</p>
      <p class="text-end">{{ generateCurrency(0) }}</p>
    </div>
    <hr class="border-black border-dashed" />
    <div class="space-y-2">
      <p class="text-center">Notes</p>
      <div class="px-4">
        <qrcode-vue
          :value="generateQRCode('name', '13245', '100', '1021')"
          level="H"
          :size="300"
          class="mx-auto !w-full !h-full"
        />
      </div>

      <p class="text-center">Comment</p>
    </div>
  </div>
</template>

<script>
import invoice from "@/mixins/invoice";
import QrcodeVue from "qrcode.vue";

export default {
  components: { QrcodeVue },
  mixins: [invoice],
};
</script>
