export default async function can(ctx) {
  const { store, next, to } = ctx;

  const permissions = store.state?.user?.permissions;
  const access = to?.meta?.can;
  const hasPermissions = permissions?.some((e) => access.includes(e));

  if (!permissions) return next();

  if (!access) return next();

  if (!hasPermissions) return next({ name: "PermissionsAllowed" });

  next();
}
