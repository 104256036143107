<template>
  <form class="grid grid-cols-1 sm:grid-cols-2 gap-4 p-6">
    <figure class="space-y-4 col-span-full">
      <p class="text-sm font-medium rtl:font-semibold">Image</p>
      <slide-transition>
        <figcaption v-if="add_package_data.image" class="relative table">
          <img
            :src="
              typeof add_package_data.image === 'object'
                ? convertImage(add_package_data.image)
                : add_package_data.image
            "
            @error="
              (e) => (e.target.src = require('@/assets/images/placeholder.png'))
            "
            class="w-auto max-h-[7rem] max-w-[5rem] object-contain"
          />
          <button
            class="w-6 h-6 rounded-full flex items-center justify-center bg-red-500 text-white absolute -top-2 -right-2 rtl:right-auto rtl:-left-2 ring-2 ring-white"
            type="button"
            @click.prevent="() => clearImage('add_package_data', 'uploader')"
            v-show="typeof add_package_data.image === 'object'"
          >
            <i class="fa-solid fa-xmark fa-sm"></i>
          </button>
        </figcaption>
      </slide-transition>
      <blockquote class="space-y-2">
        <label class="block">
          <span class="sr-only">Choose image</span>
          <input
            type="file"
            accept="image/*"
            class="custom-file"
            ref="uploader"
            @change="(e) => $set(add_package_data, 'image', e.target.files[0])"
          />
        </label>
        <p class="error" v-if="add_package_errors.image">
          {{ add_package_errors.image.join(" ") }}
        </p>
      </blockquote>
    </figure>
    <div class="form-group space-y-3 col-span-full">
      <label for="package_size"> Package size </label>
      <input
        type="number"
        name="package_size"
        id="package_size"
        v-model="add_package_data.package_size"
        min="0"
        placeholder="0"
      />
      <p class="error">
        {{ (add_package_errors["package_size"] || []).join(" ") }}
      </p>
    </div>
    <div class="form-group space-y-3">
      <label for="name_en"> Primary name</label>
      <input
        type="text"
        name="name_en"
        id="name_en"
        v-model="add_package_data.name_en"
        v-ltr
      />
      <p class="error">
        {{ (add_package_errors["name_en"] || []).join(" ") }}
      </p>
    </div>
    <div class="form-group space-y-3">
      <label for="name_ar"> Secondary name </label>
      <input
        type="text"
        name="name_ar"
        id="name_ar"
        v-model="add_package_data.name_ar"
        v-rtl
      />
      <p class="error">
        {{ (add_package_errors["name_ar"] || []).join(" ") }}
      </p>
    </div>
    <div class="form-group space-y-3">
      <div class="flex items-center gap-4 justify-between">
        <label for="sku"> SKU </label>
        <a
          href="javascript:void(0)"
          class="text-teal-600 font-semibold flex items-center justify-start gap-2 text-xs"
          @click="generateRandomValue('sku', 'add_package_data')"
        >
          <i class="fa-solid fa-sync fa-sm"></i>
          <span>Generate</span>
        </a>
      </div>
      <input
        type="text"
        name="sku"
        id="sku"
        v-model="add_package_data.sku"
        placeholder="..."
      />
      <p class="error">
        {{ (add_package_errors["sku"] || []).join(" ") }}
      </p>
    </div>
    <div
      class="form-group space-y-3"
      v-if="
        is_weight &&
        add_package_data.is_sellable &&
        add_package_data.is_purchasable
      "
    >
      <div class="flex items-center gap-4 justify-between">
        <label for="item_code"> Barcode </label>
      </div>
      <input
        type="text"
        name="item_code"
        id="item_code"
        v-model="add_package_data.item_code"
        placeholder="..."
      />
      <p class="error">
        {{ (add_package_errors["item_code"] || []).join(" ") }}
      </p>
    </div>
    <div class="form-group space-y-3" v-else>
      <div class="flex items-center gap-4 justify-between">
        <label for="barcode"> Barcode </label>
        <a
          href="javascript:void(0)"
          class="text-teal-600 font-semibold flex items-center justify-start gap-2 text-xs"
          @click="generateRandomValue('barcode', 'add_package_data')"
        >
          <i class="fa-solid fa-sync fa-sm"></i>
          <span>Barcode</span>
        </a>
      </div>
      <input
        type="text"
        name="barcode"
        id="barcode"
        v-model="add_package_data.barcode"
        placeholder="..."
      />
      <p class="error">
        {{ (add_package_errors["barcode"] || []).join(" ") }}
      </p>
    </div>
    <div class="form-group space-y-3">
      <label for="desc_en"> {{ $t("description") }} | {{ $t("en") }} </label>
      <textarea
        name="desc_en"
        id="desc_en"
        placeholder="..."
        v-ltr
        v-model="add_package_data.desc_en"
      ></textarea>
      <p class="error">
        {{ (add_package_errors["desc_en"] || []).join(" ") }}
      </p>
    </div>
    <div class="form-group space-y-3">
      <label for="desc_ar"> {{ $t("description") }} | {{ $t("ar") }} </label>
      <textarea
        name="desc_ar"
        id="desc_ar"
        placeholder="..."
        v-rtl
        v-model="add_package_data.desc_ar"
      ></textarea>
      <p class="error">
        {{ (add_package_errors["desc_ar"] || []).join(" ") }}
      </p>
    </div>
    <div class="form-group-with-check h-fit">
      <div>
        <input
          type="checkbox"
          :id="generateHash('is_purchasable')"
          :name="generateHash('is_purchasable')"
          v-model="add_package_data.is_purchasable"
          :true-value="1"
          :false-value="0"
        />
      </div>
      <label :for="generateHash('is_purchasable')" class="block">
        <p class="font-semibold text-base">Purchasable</p>
        <p class="font-medium text-gray-500 text-sm">
          Prices and item code are required since you want to make it
          purchasable.
        </p>
      </label>
    </div>
    <div class="form-group-with-check h-fit">
      <div>
        <input
          type="checkbox"
          :id="generateHash('is_sellable')"
          :name="generateHash('is_sellable')"
          v-model="add_package_data.is_sellable"
          :true-value="1"
          :false-value="0"
        />
      </div>
      <label :for="generateHash('is_sellable')" class="block">
        <p class="font-semibold text-base">Sellable</p>
        <p class="font-medium text-gray-500 text-sm">
          The product will be shown under Products
        </p>
      </label>
    </div>
    <div class="col-span-full">
      <button-with-loader
        btnClass="w-auto py-2 px-4 rounded bg-primary
      text-white text-xs "
        type="submit"
        @click="$emit('change', add_package_data)"
        >Save changes</button-with-loader
      >
    </div>
  </form>
</template>

<script>
import short from "shorthash";

import ButtonWithLoader from "../basics/ButtonWithLoader.vue";
export default {
  components: { ButtonWithLoader },
  name: "AddUpdatePackage",
  props: {
    item: { type: Object, default: {} },
    errors: { type: Object, default: {} },
    is_weight: { type: Boolean, default: false },
  },
  data() {
    return {
      add_package_data: { ...this.item },
      add_package_errors: { ...this.errors },
    };
  },
  watch: {
    item(val) {
      this.add_package_data = val;
    },
    errors(val) {
      this.add_package_errors = val;
    },
  },
  computed: {
    generateHash() {
      return (key) => short.unique(key);
    },
  },
  methods: {
    generateRandomValue(key, path) {
      this.$set(this[path], key, Date.now());
    },
    convertImage(e) {
      return URL.createObjectURL(e);
    },
    clearImage(type, ref) {
      const uploader = this.$refs[ref];
      if (!uploader) return;
      uploader.value = null;
      this.$set(this[type], "image", null);
    },
  },
};
</script>
