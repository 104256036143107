<template>
  <div class="sticky top-0 z-10 py-4 px-6 bg-white border-b border-b-gray-100">
    <ul class="list-none flex items-center gap-4">
      <li class="shrink-0 block xl:hidden">
        <button
          type="button"
          @click.prevent="openCloseSideBar"
          class="w-9 h-9 rounded-full bg-white flex items-center justify-center text-gray-600"
        >
          <i class="fa-solid fa-bars fa-md"></i>
        </button>
      </li>
      <li class="flex-1">
        <div>
          <p class="text-base font-bold text-black capitalize">
            {{ $route.meta.title }}
          </p>

          <nav v-if="$breadcrumbs.length > 1" aria-label="breadcrumb">
            <ul class="inline-flex items-center flex-wrap">
              <li
                v-for="(crumb, key) in $breadcrumbs"
                :key="key"
                class="breadcrumb-item"
                aria-current="page"
              >
                <span
                  v-if="key !== 0"
                  class="text-sm font-medium text-gray-600 mx-0.5"
                >
                  /
                </span>
                <router-link
                  :to="{ path: crumb.path }"
                  class="text-sm font-medium text-gray-500"
                  exact-active-class="!text-primary !font-semibold"
                  >{{
                    crumb.meta.breadcrumb.label
                      ? crumb.meta.breadcrumb.label
                      : crumb.name
                  }}</router-link
                >
                <!-- getBreadcrumb(crumb.meta.breadcrumb) -->
              </li>
            </ul>
          </nav>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  methods: {
    openCloseSideBar() {
      const sidebar = this.$parent.$refs.sideBar?.$el;
      if (!sidebar) return;
      if (sidebar.classList.contains("opacity-0")) {
        sidebar.classList.remove(
          ...["opacity-0", "pointer-events-none", "invisible", "left-full"]
        );
      } else {
        sidebar.classList.add(
          ...["opacity-0", "pointer-events-none", "invisible", "left-full"]
        );
      }
    },
  },
};
</script>
