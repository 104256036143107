var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('t-rich-select',_vm._b({ref:"TRichSelectComponent",attrs:{"multiple":true,"value":_vm.value,"fetch-options":_vm.fetch,"options":_vm.options,"text-attribute":_vm.text,"value-attribute":_vm.id,"placeholder":_vm.placeholder,"clearable":true,"closeOnSelect":false},on:{"change":function($event){return _vm.$emit('input', $event)}},scopedSlots:_vm._u([{key:"label",fn:function({ options }){return [_c('div',{staticClass:"actions-group flex-wrap !gap-1 items-center"},[_vm._l((_vm.limit && _vm.limit > 0
            ? options.slice(0, _vm.limit)
            : options),function(item,index){return _c('button',{key:index,staticClass:"bg-primary text-white py-[0.170rem] px-1.5 rounded flex gap-2 text-left rtl:text-right items-center",attrs:{"tabindex":"-1","type":"button"}},[_c('span',{staticClass:"text-sm font-medium rtl:font-semibold"},[_vm._v(_vm._s(item.raw[_vm.text]))]),_c('button',{staticClass:"w-3 h-3 block",attrs:{"tabindex":"-1"},on:{"click":function($event){$event.preventDefault();_vm.$emit(
                'input',
                options
                  .filter((e) => e.raw.id !== item.raw.id)
                  .map((e) => e.raw.id)
              )}}},[_c('svg',{staticClass:"w-full h-full",attrs:{"fill":"currentColor","viewBox":"0 0 20 20","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","evenodd":"evenodd","d":"M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"}})])])])}),(_vm.limit && options.length > _vm.limit)?_c('span',{staticClass:"text-xs font-medium rtl:font-semibold"},[_vm._v(" +"+_vm._s(options.length - _vm.limit)+" ")]):_vm._e()],2)]}}])},'t-rich-select',_vm.$attrs,false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }