<template>
  <div class="grid">
    <!-- <Tabs link_type="link" :tabs="tabs" /> -->
    <div class="grid" v-if="$route.query.from !== 'incomes'">
      <header class="w-full overflow-x-auto" v-if="false">
        <ul class="flex items-center w-full gap-6">
          <!-- <li v-if="hasPermission('show summary')">
            <router-link
              class="py-2 text-center font-medium rtl:font-semibold text-sm text-gray-600 border-transparent block whitespace-nowrap"
              to="/analytics"
              exact-active-class="active-tab-pills"
            >
              Summary
            </router-link>
          </li> -->
          <li v-if="hasPermission('show oos_alerts')">
            <router-link
              class="py-2 text-center font-medium rtl:font-semibold text-sm text-gray-600 border-transparent block whitespace-nowrap"
              to="/analytics/alerts"
              active-class="active-tab-pills"
            >
              OOS Alerts
            </router-link>
          </li>
          <li v-if="hasPermission('show invoice_sales')">
            <router-link
              class="py-2 text-center font-medium rtl:font-semibold text-sm text-gray-600 border-transparent block whitespace-nowrap"
              to="/analytics/invoices"
              exact-active-class="active-tab-pills"
            >
              Invoice sales
            </router-link>
          </li>
          <li v-if="hasPermission('show item_sales')">
            <router-link
              class="py-2 text-center font-medium rtl:font-semibold text-sm text-gray-600 border-transparent block whitespace-nowrap"
              to="/analytics/invoices/products"
              exact-active-class="active-tab-pills"
            >
              Item sales
            </router-link>
          </li>
          <li v-if="hasPermission('show invoice_categories')">
            <router-link
              class="py-2 text-center font-medium rtl:font-semibold text-sm text-gray-600 border-transparent block whitespace-nowrap"
              to="/analytics/invoices/categories"
              exact-active-class="active-tab-pills"
            >
              Categories
            </router-link>
          </li>
          <li v-if="hasPermission('show invoice_inventory')">
            <router-link
              class="py-2 text-center font-medium rtl:font-semibold text-sm text-gray-600 border-transparent block whitespace-nowrap"
              to="/analytics/invoices/inventory"
              exact-active-class="active-tab-pills"
            >
              Inventory
            </router-link>
          </li>
          <li v-if="hasPermission('show invoice_refunds')">
            <router-link
              class="py-2 text-center font-medium rtl:font-semibold text-sm text-gray-600 border-transparent block whitespace-nowrap"
              to="/analytics/invoices/refunds"
              exact-active-class="active-tab-pills"
            >
              Refunds
            </router-link>
          </li>
          <li v-if="hasPermission('show inventory_wastages')">
            <router-link
              class="py-2 text-center font-medium rtl:font-semibold text-sm text-gray-600 border-transparent block whitespace-nowrap"
              to="/analytics/invoices/wastages"
              exact-active-class="active-tab-pills"
            >
              Wastages
            </router-link>
          </li>
        </ul>
      </header>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "AnalyticsContainerWrapper",
};
</script>
