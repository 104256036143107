<template>
  <div class="flex items-center flex-wrap gap-4">
    <p class="text-sm flex-1 text-gray-500">
      {{ $t("show") }} {{ pagination.current_page || 0 }} {{ $t("from") }}
      {{ pagination.total_pages || 0 }} {{ $t("of") }}
      {{ pagination.total || 0 }} {{ $t("items") }}
    </p>
    <div class="flex items-center gap-2">
      <button
        class="py-2 px-4 rounded bg-gray-100 text-gray-500 border border-gray-200 text-sm font-medium"
        type="button"
        :disabled="
          pagination.total_pages === 1 ||
          pagination.current_page === 1 ||
          disabled
        "
        @click.prevent="$emit('input', pagination.current_page - 1)"
      >
        <span>{{ $t("previous") }}</span>
      </button>
      <button
        class="py-2 px-4 rounded bg-primary text-white text-sm font-medium"
        type="button"
        :disabled="
          pagination.total_pages <= 1 ||
          pagination.total_pages === pagination.current_page ||
          disabled
        "
        @click.prevent="$emit('input', pagination.current_page + 1)"
      >
        <span>{{ $t("next") }}</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    pagination: {
      type: Object,
      default: {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
