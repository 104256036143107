<template>
  <div class="p-6">
    <!-- <AnalyticsContainerWrapper class="space-y-6">
      <cards-container :cards="cards" :load="!load"></cards-container>
    </AnalyticsContainerWrapper> -->
    <Table
      :title="$t('incomes')"
      :records="`${pagination.total || 0} `"
      :pagination="pagination"
      :total_pages="pagination.total_pages"
      v-model="pagination.page"
    >
      <template #head>
        <th>{{$t('day')}}</th>
        <th>{{$t('total')}}</th>
        <th>{{$t('qty')}}</th>
        <th>{{$t('weight')}}</th>
        <th>{{$t('card-amount')}}</th>
        <th>{{$t('cash-amount')}}</th>
        <th>{{$t('invoices')}}</th>
        <th>{{$t('products')}}</th>
        <!-- <th>Wastage</th> -->
      </template>
      <template #tbody v-if="!load">
        <TableLoad :rows="8" v-for="i in 4" :key="i" />
      </template>
      <template #tbody v-else-if="load && items.length">
        <tr v-for="(item, index) in items" :key="index">
          <td class="space-y-1">
            <p class="text-sm font-semibold rtl:font-bold">{{ item.day }}</p>
            <p class="text-xs font-medium rtl:font-semibold">
              {{ item.date | moment("DD-MM-YYYY") }}
            </p>
          </td>
          <td>{{ item.income }} SAR</td>
          <td>{{ item.total_qty || 0 }} Piece(s)</td>
          <td>{{ GenerateUnit(item.total_weight || 0) }}</td>
          <td>{{ item.card_total || 0 }} SAR</td>
          <td>{{ item.cash_total || 0 }} SAR</td>
          <td>
            <router-link
              :to="{
                path: '/analytics/invoices',
                query: {
                  date: item.date,
                  from: 'incomes',
                },
              }"
              class="w-8 h-8 rounded bg-sky-600 text-white flex items-center justify-center text-sm font-semibold"
              content="View invoices"
              v-if="hasPermission('show invoice_sales')"
              v-tippy
            >
              {{ item.invoices_count }}
            </router-link>
            <span v-else>-</span>
          </td>
          <td>
            <router-link
              :to="{
                path: '/analytics/invoices/products',
                query: {
                  date: item.date,
                  from: 'incomes',
                },
              }"
              content="View products"
              v-if="hasPermission('show item_sales')"
              v-tippy
              class="w-8 h-8 rounded bg-sky-600 text-white flex items-center justify-center text-sm font-semibold"
            >
              {{ item.products_count }}
            </router-link>
            <span v-else>-</span>
          </td>
          <!-- <td>{{ item.wastage }}</td> -->
        </tr>
      </template>

      <template #tbody v-else>
        <tr>
          <td colspan="100%" class="!text-center">{{$t('no-data')}}</td>
        </tr>
      </template>
    </Table>
  </div>
</template>

<script>
import AnalyticsContainerWrapper from "@/components/AnalyticsContainerWrapper.vue";
import CardsContainer from "@/components/basics/CardsContainer.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "refunds",
  components: {
    CardsContainer,
    AnalyticsContainerWrapper,
  },
  data() {
    return {
      items: [],
      load: false,

      pagination: {
        page: 1,
        total: 0,
        total_pages: 0,
      },
      summary: {},
    };
  },
  async created() {
    Promise.all([await this.getItems()]).finally(() => (this.load = true));
  },
  watch: {
    is_called(val, _) {
      if (val) {
        this.getItems();
      }
    },
    "pagination.page"() {
      this.getItems();
    },
  },
  computed: {
    ...mapGetters("table", ["search", "is_called"]),
    cards() {
      const data = [
        {
          title: "Incomes",
          key: "income",
          icon: "fa-solid fa-dollar-sign",
          value: 0,
          style: {
            icon: "text-sky-600",
            parent: "bg-sky-600/20",
          },
        },
        {
          title: "Products",
          key: "products_count",
          icon: "fa-solid fa-shopping-cart",
          value: 0,
          style: {
            icon: "text-orange-500",
            parent: "bg-orange-500/20",
          },
        },
        {
          title: "Invoices",
          key: "invoices_count",
          icon: "fa-solid fa-square-poll-vertical",
          value: 0,
          style: {
            icon: "text-teal-500",
            parent: "bg-teal-500/20",
          },
        },
      ];

      return data.map((e) => {
        return {
          ...e,
          value: this.summary[e.key] || 0,
        };
      });
    },
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("table", ["changeIsCalled"]),
    async getItems() {
      try {
        const { total, total_pages, ...others } = this.pagination;
        const { result } = await this.getRoute({
          name: "sales",
          options: {
            ...others,
            search_key: this.search,
            store_id: this.store_id,
          },
        });
        const { sales, summary } = result;
        this.items = sales.data;
        this.summary = summary;
        this.$set(this.pagination, "total", sales.pagination?.total);
        this.$set(
          this.pagination,
          "total_pages",
          sales.pagination?.total_pages
        );
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      } finally {
        this.changeIsCalled(false);
      }
    },
  },
};
</script>
