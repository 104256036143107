<template>
  <AlertsContainerWrapper class="p-6 space-y-4">
    <section class="w-full space-y-6">
      <filter-wrapper
        @apply="getItems"
        @reset="handleReset"
        ref="filterWrapper"
      >
        <div>
          <t-datepicker
            :placeholder="`From - To`"
            multiple
            :monthsPerView="2"
            v-model="pagination.date"
            range
            :clearable="false"
            userFormat="Y-m-d"
            :weekStart="6"
          />
        </div>
      </filter-wrapper>
      <Table
        :title="$t('wastages')"
        :records="`${pagination.total || 0} `"
        :pagination="pagination"
        :total_pages="pagination.total_pages"
        v-model="pagination.page"
        has_export
        :exportParams="pagination"
        :fields="fields"
      >
        <template #actions>
          <div>
            <button
              class="inline-flex gap-2 items-center py-2 px-3 rounded bg-transparent text-sm text-gray-600"
              @click.prevent="wastage_active = !wastage_active"
            >
              <i class="fa-solid fa-plus fa-sm"></i>
              <span>{{ $t("add") }}</span>
            </button>
          </div>
        </template>
        <template #head>
          <th>{{ $t("product") }}</th>
          <th>{{ $t("wastage") }}</th>
          <th>{{ $t("value") }}</th>
        </template>
        <template #tbody v-if="!load">
          <TableLoad :rows="3" v-for="i in 4" :key="i" />
        </template>
        <template #tbody v-else-if="load && items.length">
          <tr v-for="(item, index) in items" :key="index">
            <td>
              <router-link
                :to="
                  hasPermission('edit inventory')
                    ? {
                        name: 'UpdateInventoryProduct',
                        params: {
                          slug: item.id,
                        },
                      }
                    : '#'
                "
                class="flex items-center gap-3"
              >
                <div>
                  <div class="w-10 h-10">
                    <img
                      :src="
                        item.image || require('@/assets/images/placeholder.png')
                      "
                      @error="
                        $event.target.src = require('@/assets/images/placeholder.png')
                      "
                      alt="product image"
                      class="w-full h-full rounded-md border object-cover border-gray-100"
                    />
                  </div>
                </div>
                <div class="space-y-1 max-w-sm whitespace-normal">
                  <p class="line-clamp-2">
                    {{ item.product?.["name_" + $i18n.locale] || "N/A" }}
                  </p>
                </div>
              </router-link>
            </td>
            <td>{{ item.wastage?.["name_" + $i18n.locale] || "N/A" }}</td>
            <td>{{ GenerateUnit(item.value || 0) }}</td>
          </tr>
        </template>

        <template #tbody v-else>
          <tr>
            <td colspan="100%" class="!text-center">
              {{ $t("no-data") }}
            </td>
          </tr>
        </template>
      </Table>
      <Modal
        :title="$t('manual-wastage')"
        v-if="wastage_active"
        @close="wastage_active = $event"
      >
        <template>
          <form class="space-y-4 p-4">
            <div class="form-group space-y-3">
              <label>{{ $t("product") }}</label>
              <t-rich-select
                placeholder="Search in inventory products"
                :text-attribute="'name_' + $i18n.locale"
                value-attribute="id"
                :clearable="true"
                :closeOnSelect="true"
                :fetch-options="getInventoryProducts"
                v-model="wastage_data.product_id"
              />
            </div>
            <div class="form-group space-y-3">
              <label for="wastage_id"> {{ $t("wastage") }} </label>
              <t-rich-select
                :options="wastages"
                placeholder="Select"
                :text-attribute="'name_' + $i18n.locale"
                value-attribute="id"
                :clearable="true"
                :closeOnSelect="true"
                v-model="wastage_data.wastage_id"
              />
              <p class="error">
                {{ (wastage_errors["wastage_id"] || []).join(" ") }}
              </p>
            </div>
            <div class="form-group space-y-3">
              <label for="weight">
                Weight
                {{ inventoryProduct?.unit_type === "weight" ? "(KG)" : "" }}
              </label>
              <input
                type="number"
                name="weight"
                id="weight"
                v-model="wastage_data.value"
                placeholder="1"
                min="1"
                step="any"
              />
              <p class="error">
                {{ (wastage_errors["value"] || []).join(" ") }}
              </p>
            </div>
            <button-with-loader
              btnClass="w-auto py-2 px-4 rounded bg-primary
                text-white text-xs"
              :disabled="wastage_disabled"
              @click="addWastage"
              >{{ $t("save-changes") }}</button-with-loader
            >
          </form>
        </template>
      </Modal>
    </section>
  </AlertsContainerWrapper>
</template>
<script>
import AnalyticsContainerWrapper from "@/components/AnalyticsContainerWrapper.vue";
import AlertsContainerWrapper from "@/components/Inventory/AlertsContainerWrapper.vue";
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";
import FilterWrapper from "@/components/basics/FilterWrapper.vue";
import Modal from "@/components/basics/Modal.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "Wastages",
  data() {
    return {
      load: false,
      items: [],
      currentPage: 1,
      rows: 0,
      totalPages: 0,
      perPage: 1,
      active: false,
      product_id: null,
      delete_disabled: false,
      status_disabled: [],
      error: "",
      search_key: "",
      search_disabled: false,
      item_status: {
        1: "bg-sky-500/10 text-sky-500",
        0: "bg-red-500/10 text-red-500",
      },
      add_data: {
        is_inventory: 1,
      },
      add_errors: {},
      add_error: null,
      add_disabled: false,
      show_active: false,
      status: ["all", "active", "not active"],
      pagination: {
        is_active: null,
        page: 1,
        sort_key: null,
        sort_type: "asc",
      },
      disables: {},
      indexes: [],
      wastage_data: {},
      wastage_errors: {},
      wastage_active: false,
      wastage_disabled: false,
      wastages: [],
      inventory_products: [],
      fields: [],
    };
  },
  async created() {
    this.$set(this.pagination, "date", [
      this.$route.query.date ||
        this.$moment().add(-1, "days").format("YYYY-MM-DD"),
      this.$route.query.date || this.$moment().format("YYYY-MM-DD"),
    ]);
    await Promise.all([this.getItems(), this.getWastages()]);
  },
  watch: {
    "pagination.page"() {
      this.getItems();
    },
    is_called(val, _) {
      if (val) {
        this.getItems();
      }
    },
    wastage_active(val) {
      if (!val) {
        this.wastage_data = {};
        this.wastage_errors = {};
      }
    },
  },
  computed: {
    ...mapGetters("table", ["search", "is_called"]),
    inventoryProduct() {
      return this.inventory_products.find(
        (e) => e.id == this.wastage_data?.product_id
      );
    },
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("table", ["changeIsCalled"]),
    async getWastages() {
      try {
        const { result } = await this.getRoute("wastages");
        this.wastages = result.wastages;
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async getItems() {
      try {
        const { total, total_pages, date, ...others } = this.pagination;
        const user = this.$store.state.user;

        const { result } = await this.getRoute({
          name: "invoices/wastage_tracks",
          options: {
            ...others,
            search_key: this.search || this.$route.query.search,
            vendor_id: user.user_type === "vendor" ? user?.id : null,
            // store_id: user.user_type === "cashier" ? user?.id : null,
            store_id: this.store_id,
            from: date?.[0],
            to: date?.[1],
          },
        });

        const { data, pagination } = result.wastage_tracks;
        this.fields = result.export_fields;

        this.items = data;
        this.$set(this.pagination, "total", pagination.total);
        this.$set(this.pagination, "total_pages", pagination.total_pages);
      } catch (error) {
        console.log(error);
        // this.createAlert(result?.message, type);
      } finally {
        const filterWrapper = this.$refs.filterWrapper;
        filterWrapper?.resetAll();

        this.load = true;
        this.changeIsCalled(false);
      }
    },
    handleReset() {
      const { page, ...others } = this.pagination;

      for (const key in others) {
        const item = others[key];
        if (typeof item === "number") {
          this.$set(this.pagination, key, 0);
        } else if (Array.isArray(item)) {
          this.$set(this.pagination, key, []);
        } else {
          this.$set(this.pagination, key, null);
        }
      }

      this.getItems();
    },
    async getInventoryProducts(q) {
      try {
        const { result } = await this.getRoute({
          name: "products",
          options: {
            is_purchasable: 1,
            search_key: q,
            page: 1,
            store_id: this.store_id,
            unit_type: "weight",
          },
        });
        const products = result.products.data;
        if (!products.length) return { results: [] };
        this.inventory_products = products;
        return { results: products };
      } catch (error) {
        console.log(error);
      }
    },
    async addWastage() {
      try {
        this.wastage_disabled = true;
        this.wastage_errors = {};
        const { data } = await this.axios.post("products/add_wastage", {
          ...this.wastage_data,
          // product_id: this.productId,
        });
        this.getItems();
        this.wastage_data = {};
        this.wastage_active = false;
        this.createAlert(data.message);
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.wastage_errors = responseError;
        } else {
          this.createAlert(responseError, "error");
        }
      } finally {
        this.wastage_disabled = false;

        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    },
  },
  components: {
    Modal,
    ButtonWithLoader,
    AnalyticsContainerWrapper,
    FilterWrapper,
    AlertsContainerWrapper,
  },
};
</script>
