export default async function auth({ next, store, to, from }) {
  const { payment, id, payment_option } = to.query;
  if (payment && id && payment_option) {
    return next();
  } else {
    if (from)
      return next({
        path: from.path,
      });
    return next({
      name: "PageNotFound",
    });
  }
}
