<template>
  <div class="p-6">
    <AlertsContainerWrapper class="space-y-6">
      <filter-wrapper
        @apply="getItems"
        @reset="handleReset"
        ref="filterWrapper"
      >
        <div>
          <t-datepicker
            :placeholder="`From - To`"
            multiple
            :monthsPerView="2"
            v-model="pagination.date"
            range
            :clearable="false"
            userFormat="Y-m-d"
            :weekStart="6"
          />
        </div>
      </filter-wrapper>
      <!-- <cards-container :cards="filterCards" :load="!load"></cards-container> -->
      <Table
        :title="$t('purchase-orders')"
        :records="`${pagination.total || 0} `"
        :pagination="pagination"
        :total_pages="pagination.total_pages"
        v-model="pagination.page"
      >
        <template #actions>
          <div v-if="!hasTrialDays && hasPermission('add purchase_orders')">
            <router-link
              to="/inventory-actions/orders/new"
              class="py-1.5 px-3 text-sm flex items-center gap-2 text-gray-600 font-medium rtl:font-semibold"
            >
              <i class="fa-solid fa-plus"></i>
              <span>{{ $t("add") }}</span>
            </router-link>
          </div>
        </template>
        <!-- <template #filter>
          <ul class="flex items-center">
            <li v-for="(item, index) in status" :key="index">
              <button
                class="
                  py-4
                  px-6
                  text-center
                  font-medium
                  rtl:font-semibold
                  text-sm
                  capitalize
                "
                @click.prevent="
                  changeFilterStatus(item === 'all' ? null : item, index)
                "
                :class="{
                  'active-tab':
                    pagination.status === (item === 'all' ? null : item),
                }"
                :disabled="disables[index]"
              >
                <spinner
                  size="w-4 h-4 inline-block"
                  class="mr-2 rtl:mr-0 rtl:ml-2"
                  v-if="disables[index]"
                />
                {{ item }}
              </button>
            </li>
          </ul>
        </template> -->
        <template #head>
          <!-- <th>Salesman</th> -->
          <th>#</th>
          <th>{{ $t("supplier") }}</th>
          <th>{{ $t("location") }}</th>
          <th>{{ $t("products") }}</th>
          <th>{{ $t("total") }}</th>
          <th>{{ $t("status") }}</th>
          <!-- <th>Payment option</th> -->
          <!-- <th>Latest update</th> -->
          <th>{{ $t("actions") }}</th>
        </template>
        <template #tbody v-if="!load">
          <TableLoad :rows="7" v-for="i in 4" :key="i" />
        </template>
        <template #tbody v-else-if="load && items.length">
          <tr v-for="(item, index) in items" :key="index">
            <!-- <td>
              <p>{{ (item.salesman || {}).name || "-" }}</p>
              <a
                :href="'tel:' + (item.salesman || {}).mobile"
                v-if="(item.salesman || {}).mobile"
                class="table text-primary text-xs"
                >{{ (item.salesman || {}).mobile || "-" }}</a
              >
            </td> -->
            <td>
              <router-link
                :to="
                  hasPermission('show purchase_orders')
                    ? `/inventory-actions/orders/${item.id}`
                    : '#'
                "
                class="text-blue-600"
              >
                {{ item.id }}
              </router-link>
            </td>
            <td>
              <!-- <p>{{ (item.warehouse || {})["name_" + $i18n.locale] || "-" }}</p> -->
              {{ (item.supplier || {}).name || "N/A" }}
            </td>
            <td>
              <p>{{ (item.store || {})["name_" + $i18n.locale] || "-" }}</p>
            </td>

            <td>{{ item.products.length }}</td>
            <td>{{ item.total }} SAR</td>
            <td>
              <div>
                <ul class="flex gap-8 whitespace-nowrap w-full items-start">
                  <li
                    class="flex items-center justify-center flex-col text-center"
                    v-for="(track, idx) in item.tracking"
                    :key="idx"
                    v-tippy
                    :content="
                      track.created_at
                        ? $moment(track.created_at).format('lll')
                        : 'N/A'
                    "
                  >
                    <span
                      class="flex items-center justify-center w-6 h-6 rounded-full shrink-0"
                      :class="
                        track.created_at
                          ? 'bg-primary text-white'
                          : 'bg-gray-200 text-gray-500'
                      "
                    >
                      <i
                        class="fa-solid fa-check fa-xs"
                        v-if="track.created_at"
                      ></i>
                    </span>
                    <div class="text-center mt-2 space-y-1">
                      <p class="capitalize font-medium text-slate-600 text-xs">
                        {{ track["status_" + $i18n.locale] }}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </td>
            <!-- <td>
              <div class="flex items-center gap-2">
                <div v-if="item.payment_option">
                  <img
                    :src="getPaymentImage(item.payment_option)"
                    alt="payment option"
                    width="20"
                    height="15"
                  />
                </div>
                <div class="text-sm text-gray-600 capitalize">
                  {{ item.payment_option || "N/A" }}
                </div>
              </div>
            </td>

            <td v-html="createTime(item.updated_at)"></td> -->

            <td>
              <div class="actions-group">
                <div v-if="hasPermission('show purchase_orders')">
                  <router-link
                    :to="`/inventory-actions/orders/${item.id}`"
                    class="w-8 h-8 bg-sky-500 text-white rounded flex items-center justify-center"
                  >
                    <i class="fa-solid fa-eye"></i>
                  </router-link>
                </div>
              </div>
            </td>
          </tr>
        </template>

        <template #tbody v-else>
          <tr>
            <td colspan="100%" class="!text-center">
              {{ $t("no-data") }}
            </td>
          </tr>
        </template>
      </Table>
    </AlertsContainerWrapper>
  </div>
</template>

<script>
import AlertsContainerWrapper from "@/components/Inventory/AlertsContainerWrapper.vue";
import CardsContainer from "@/components/basics/CardsContainer.vue";
import FilterWrapper from "@/components/basics/FilterWrapper.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "Orders",
  components: {
    CardsContainer,
    AlertsContainerWrapper,
    FilterWrapper,
  },
  data() {
    return {
      items: [],
      load: false,
      cards: [
        {
          title: "Created",
          key: "created_orders_count",
          icon: "fa-solid fa-hourglass",
          value: 0,
          style: {
            icon: "text-sky-500",
            parent: "bg-sky-500/20",
          },
        },
        {
          title: "Accepted",
          key: "accepted_orders_count",
          icon: "fa-solid fa-check",
          value: 0,
          style: {
            icon: "text-gray-500",
            parent: "bg-gray-500/20",
          },
        },
        {
          title: "In transit",
          key: "in_transit_orders_count",
          icon: "fa-solid fa-route",
          value: 0,
          style: {
            icon: "text-yellow-500",
            parent: "bg-yellow-500/20",
          },
        },
        {
          title: "Delivered",
          key: "delivered_orders_count",
          icon: "fa-solid fa-check-double",
          value: 0,
          style: {
            icon: "text-gray-500",
            parent: "bg-gray-500/20",
          },
        },
        // {
        //   title: "Total",
        //   key: "total_count",
        //   icon: "fa-solid fa-dollar-sign",
        //   value: 0,
        //   style: {
        //     icon: "text-teal-500",
        //     parent: "bg-teal-500/20",
        //   },
        // },
      ],
      // item_status: {
      //   new: "bg-sky-500/10 text-sky-500",
      //   accepted: "bg-gray-500/10 text-gray-500",
      //   delivered: "bg-gray-500/10 text-gray-500",
      //   in_transit: "bg-yellow-500/10 text-yellow-500",
      //   prepared: "bg-sky-700/10 text-sky-700",
      //   hold: "bg-gray-500/10 text-gray-500",
      //   cancelled: "bg-red-600/10 text-red-600",
      //   refunded: "bg-red-700/10 text-red-700",
      // },
      // new,preparing'ready,delivering'hold,cancelled,refunded,completed
      status: ["all", "created", "accepted", "in_transit", "delivered"],
      item_status: {
        created: "bg-sky-500/10 text-sky-500",
        accepted: "bg-gray-500/10 text-gray-500",
        cancelled: "bg-red-600/10 text-red-600",
        closed: "bg-red-600/10 text-red-600",
        delivered: "bg-gray-500/10 text-gray-500",
        in_transit: "bg-yellow-500/10 text-yellow-500",
      },
      pagination: {
        page: 1,
        total: 0,
        total_pages: 0,
        status: null,
        from: this.$route.query.date,
        to: this.$route.query.date,
      },
      disables: {},
      summary: {},
    };
  },
  async created() {
    this.$set(this.pagination, "date", [
      this.$route.query.date ||
        this.$moment().add(-1, "days").format("YYYY-MM-DD"),
      this.$moment().format("YYYY-MM-DD"),
    ]);
    Promise.all([await this.getItems()]).finally(() => (this.load = true));
  },
  watch: {
    is_called(val, _) {
      if (val) {
        this.getItems();
      }
    },
  },
  computed: {
    ...mapGetters("table", ["search", "is_called"]),
    isInTracking() {
      return (ev) =>
        this.status.filter((e) => e !== "all").findIndex((e) => e === ev);
    },
    filterCards() {
      return this.cards.map((e) => {
        return {
          ...e,
          value: this.summary[e.key]
            ? this.summary[e.key] +
              " ( " +
              this.summary[e.key.replace(/_count/gi, "_total")] +
              " ) "
            : 0,
        };
      });
    },
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("table", ["changeIsCalled"]),
    async getItems() {
      try {
        const { total, total_pages, date, ...others } = this.pagination;
        const { result } = await this.getRoute({
          name: "orders",
          options: {
            ...others,
            search_key: this.search,
            from: date?.[0],
            to: date?.[1],
            store_id: this.store_id,
            order_type: "purchase",
          },
        });
        const { orders, summary } = result;
        this.items = orders.data;
        this.$set(this.pagination, "total", orders.pagination?.total);
        this.summary = summary;
        this.$set(
          this.pagination,
          "total_pages",
          orders.pagination?.total_pages
        );
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      } finally {
        this.changeIsCalled(false);
        const filterWrapper = this.$refs.filterWrapper;
        filterWrapper?.resetAll();
      }
    },
    handleReset() {
      const { page, ...others } = this.pagination;

      for (const key in others) {
        const item = others[key];
        if (typeof item === "number") {
          this.$set(this.pagination, key, 0);
        } else if (Array.isArray(item)) {
          this.$set(this.pagination, key, []);
        } else {
          this.$set(this.pagination, key, null);
        }
      }

      this.getItems();
    },
    async closeItem(id) {
      this.$set(this.disables, `close_${id}`, true);
      try {
        const { isConfirmed } = await this.$swal.fire({
          theme: "bulma",
          title: "Are you sure you want to close this order?",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          showCancelButton: true,
        });
        if (!isConfirmed) return;

        const { data } = await this.axios.post(`orders/close/${id}`);
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async cancelOrders(id) {
      this.$set(this.disables, `cancel_${id}`, true);
      try {
        const { isConfirmed } = await this.$swal.fire({
          theme: "bulma",
          title: "Are you sure you want to cancel this order?",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          showCancelButton: true,
        });
        if (!isConfirmed) return;
        const { data } = await this.axios.post(`orders/cancel/${id}`);
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    getPaymentImage(image) {
      try {
        return require(`@/assets/images/${image}.png`);
      } catch (error) {
        return require(`@/assets/images/cash.png`);
      }
    },
    changeFilterStatus(status, index) {
      this.$set(this.disables, index, true);
      this.$set(this.pagination, "status", status);
      this.getItems().finally(() => {
        this.disables = {};
      });
    },
  },
};
</script>
