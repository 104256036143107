<template>
  <fragment>
    <tr>
      <td class="!px-0">
        <button
          class="w-10 text-gray-600 flex items-center justify-center"
          @click.prevent="show_active = !show_active"
          v-show="!!item.sub_accounts?.length"
        >
          <i class="fa-solid fa-angle-down" v-if="show_active"></i>
          <span v-else>
            <i class="fa-solid fa-angle-right rtl:hidden"></i>
            <i class="fa-solid fa-angle-left hidden rtl:inline"></i>
          </span>
        </button>
      </td>
      <td
        :style="{
          'padding-inline-start': item.padding + 'px',
        }"
      >
        {{ item.code }} - {{ item.name }}
      </td>
      <td>{{ item.type || "-" }}</td>
      <td>{{ generateCurrency(item.debit || 0) }}</td>
      <td>{{ generateCurrency(item.credit || 0) }}</td>
      <td>{{ generateCurrency(item.balance || 0) }}</td>

      <td>
        <div class="actions-group items-center">
          <div>
            <router-link
              :to="'/accounting/chart/' + item.id + '/details'"
              class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded flex items-center justify-center"
            >
              <i class="fa-solid fa-eye"></i>
            </router-link>
          </div>
        </div>
      </td>
    </tr>
    <template v-if="show_active">
      <LedgerItemTree
        v-for="account in accounts"
        :key="account.id"
        :item="account"
      />
    </template>
  </fragment>
</template>

<script>
import AddAccount from "./AddAccount.vue";
import UpdateAccount from "./UpdateAccount.vue";
export default {
  components: { AddAccount, UpdateAccount },
  name: "LedgerItemTree",
  props: {
    item: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      show_active: true,
    };
  },
  computed: {
    accounts() {
      const data = this.item.sub_accounts || [];
      return data.map((e) => ({
        ...e,
        padding: this.item.padding ? 2 * this.item.padding : 16,
      }));
    },
  },
};
</script>
