<template>
  <sub-pages-header :title="$t('new-opening-balances')" :center="false">
    <template #action>
      <button-with-loader
        :disabled="add_disabled"
        btnClass="bg-primary py-2 px-4 rounded text-xs font-medium rtl:font-semibold text-white"
        @click="addItem"
        v-show="allowed"
      >
        {{ $t("save-changes") }}
      </button-with-loader>
    </template>
    <form class="space-y-4">
      <card :title="$t('basic-info')">
        <div class="form-group space-y-3">
          <label>{{ $t("date") }}</label>
          <input type="date" v-model="add_data.date" />
          <p class="form-error">{{ add_errors["date"] }}</p>
        </div>
        <div class="form-group space-y-3">
          <label>{{ $t("notes") }}</label>
          <textarea v-model="add_data.notes" />
          <p class="form-error">{{ add_errors["notes"] }}</p>
        </div>
      </card>
      <card :title="$t('transactions')">
        <div class="grid overflow-auto">
          <table class="styled-table border border-gray-200 rounded">
            <thead>
              <tr>
                <th>{{ $t("account") }}</th>
                <th>{{ $t("debit") }}</th>
                <th>{{ $t("credit") }}</th>
                <th>{{ $t("notes") }}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(transaction, index) in add_data.transactions"
                :key="index"
              >
                <td>
                  <button
                    class="text-gray-600 text-sm text-center flex items-center justify-center gap-2"
                    type="button"
                    @click.prevent="openAddAccount(index)"
                  >
                    <span
                      class="flex items-center justify-center gap-2"
                      v-if="transaction.account"
                    >
                      <i class="fa-solid fa-pen"></i>
                      <span>{{ transaction.account?.name }}</span>
                    </span>
                    <span class="flex items-center justify-center gap-2" v-else>
                      <i class="fa-solid fa-plus"></i>
                      <span>{{ $t("new-account") }}</span>
                    </span>
                  </button>
                  <p class="form-error">
                    {{ add_errors[`transactions.${index}.account_id`] }}
                  </p>
                </td>
                <td>
                  <div class="form-group">
                    <input
                      type="number"
                      placeholder="0.00"
                      class="!min-w-fit"
                      v-model.number="transaction.debit"
                      :disabled="!!transaction.credit"
                      @blur="
                        $event.target.value = !!transaction.credit
                          ? null
                          : $event.target.value
                      "
                    />
                    <p class="form-error">
                      {{ add_errors[`transactions.${index}.debit`] }}
                    </p>
                  </div>
                </td>
                <td>
                  <div class="form-group">
                    <input
                      type="number"
                      placeholder="0.00"
                      class="!min-w-fit"
                      v-model.number="transaction.credit"
                      :disabled="!!transaction.debit"
                      @blur="
                        $event.target.value = !!transaction.debit
                          ? null
                          : $event.target.value
                      "
                    />
                    <p class="form-error">
                      {{ add_errors[`transactions.${index}.credit`] }}
                    </p>
                  </div>
                </td>
                <td>
                  <div class="form-group">
                    <input
                      type="text"
                      class="!min-w-fit"
                      v-model="transaction.notes"
                    />
                    <p class="form-error">
                      {{ add_errors[`transactions.${index}.notes`] }}
                    </p>
                  </div>
                </td>
                <td>
                  <button
                    type="button"
                    v-if="index + 1 === add_data.transactions.length"
                    @click.prevent="
                      () => {
                        add_data.transactions.push({
                          account_id: null,
                          debit: null,
                          credit: null,
                          notes: null,
                        });
                      }
                    "
                  >
                    <i class="fa-solid fa-plus fa-lg"></i>
                  </button>
                  <button
                    type="button"
                    v-else
                    @click.prevent="
                      () => {
                        add_data.transactions.splice(index, 1);
                      }
                    "
                  >
                    <i class="fa-solid fa-trash fa-lg text-red-500"></i>
                  </button>
                </td>

                <Modal
                  :title="$t('new-account')"
                  @close="closeAddAccount(index)"
                  v-if="show_modals['account-transaction-' + index]"
                >
                  <div class="space-y-4 p-6">
                    <div class="form-group">
                      <t-rich-select
                        :fetch-options="getAccounts"
                        placeholder="Select"
                        text-attribute="name"
                        value-attribute="id"
                        :clearable="true"
                        :closeOnSelect="true"
                        v-model="transaction.parent_id"
                      />
                      <!-- :ref="'account-transaction-' + index" -->
                    </div>
                    <div class="form-group">
                      <t-rich-select
                        :options="filterAccounts(transaction.parent_id)"
                        placeholder="Select"
                        text-attribute="name"
                        value-attribute="id"
                        :clearable="true"
                        :closeOnSelect="true"
                        v-model="transaction.account_id"
                        :ref="'account-transaction-' + index"
                        @change="onAccountSelect($event, index)"
                      />
                      <p class="form-error">
                        {{ add_errors[`transactions.${index}.account_id`] }}
                      </p>
                    </div>
                    <button-with-loader
                      btnClass="bg-primary py-2 px-4 rounded text-xs font-medium rtl:font-semibold text-white"
                      @click="closeAddAccount(index)"
                    >
                      {{ $t("save-changes") }}
                    </button-with-loader>
                  </div>
                </Modal>
              </tr>
              <tr>
                <td>
                  <p class="text-end">{{ $t("total") }}:</p>
                </td>
                <td>
                  {{ generateCurrency(totalDebits || 0) }}
                </td>
                <td>{{ generateCurrency(totalCredits || 0) }}</td>
                <td colspan="100%"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </card>
    </form>
  </sub-pages-header>
</template>

<script>
import Card from "@/components/Card.vue";
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";
import Modal from "@/components/basics/Modal.vue";
import SubPagesHeader from "@/components/basics/SubPagesHeader.vue";

import { serialize } from "object-to-formdata";
import { mapActions } from "vuex";

export default {
  components: {
    Card,
    Modal,
    ButtonWithLoader,
    SubPagesHeader,
  },
  name: "NewOpeningBalances",
  data() {
    return {
      add_data: {
        transactions: [
          {
            account_id: null,
            debit: null,
            credit: null,
            notes: null,
          },
        ],
      },
      accounts: [],
      add_errors: {},
      add_disabled: false,
      show_modals: {},
    };
  },
  created() {
    this.getAccounts();
  },
  computed: {
    totalDebits() {
      let total = 0;
      const transactions = this.add_data.transactions;
      const debits = transactions.filter((e) => e.debit);

      debits.forEach((item) => {
        total += item.debit || 0;
      });
      return total;
    },
    totalCredits() {
      let total = 0;
      const transactions = this.add_data.transactions;
      const credits = transactions.filter((e) => e.credit);
      credits.forEach((item) => {
        total += item.credit || 0;
      });
      return total;
    },
    allowed() {
      const samePrices = !!(this.totalDebits && this.totalCredits)
        ? this.totalDebits === this.totalCredits
        : false;

      return samePrices;
    },
    filterAccounts() {
      return (id) => {
        const accounts = this.accounts || [];
        const account = accounts.find((acc) => acc.id == id);
        return account?.sub_accounts || [];
      };
    },
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    async getAccounts(q) {
      try {
        const { result } = await this.getRoute({
          name: "accounts/opening",
          options: {
            search_key: q,
            page: 1,
            is_details: 1,
          },
        });
        this.accounts = result.accounts || [];
        return { results: result.accounts || [] };
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async addItem() {
      try {
        this.add_disabled = true;
        const fd = serialize(
          { ...this.add_data, store_id: this.store_id },
          {
            indices: true,
            booleansAsIntegers: true,
            nullsAsUndefineds: true,
          }
        );
        fd.append("ref_type", "opening");
        const { data } = await this.axios.post("journal_entries/add", fd);
        this.createAlert(data.message);
        this.$router.push("/accounting/journal-entries");
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.createAlert(responseError, "error");
        }
      } finally {
        this.add_disabled = false;
      }
    },
    openAddAccount(index) {
      // this["account-transaction-" + index] = true;
      this.$set(this.show_modals, "account-transaction-" + index, true);
    },
    closeAddAccount(index) {
      this.$set(this.show_modals, "account-transaction-" + index, false);
    },
    async onAccountSelect(value, index) {
      const transactions = this.add_data.transactions;
      const { result } = await this.getRoute("accounts/" + value);
      this.$set(transactions[index], "account", result?.account || {});
    },
  },
};
</script>
