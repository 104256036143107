import Vue from "vue";

export default async function auth(ctx) {
  const { next, store, to, from, $cookies } = ctx;
  const token = store.state.token || $cookies.get("token");

  if (!token) {
    if (
      ![
        "Login",
        "Register",
        "ForgotPassword",
        "ResetPassword",
        "Pricing",
      ].includes(to.name)
    ) {
      return next({
        name: "Login",
        query: {
          redirect: [
            "Login",
            "Register",
            "ForgotPassword",
            "ResetPassword",
          ].includes(from.name)
            ? "/"
            : from.fullPath,
        },
      });
    }
    return next();
  }

  if (
    ["Login", "Register", "ForgotPassword", "ResetPassword"].includes(to.name)
  ) {
    return next({
      path: "/",
    });
  }
  store.commit("SET_TOKEN", token);

  await fetchProfile({ ...ctx, token });

  return next();
}

async function fetchProfile(ctx) {
  const { store, token, $cookies, next } = ctx;
  try {
    const { result } = await store.dispatch("getting/getRoute", {
      name: "profile",
    });
    const generateCartKey = `${result.profile.user_type}_${result.profile.id}`;
    if (!store.getters["Cart/cart"](generateCartKey)) {
      store.commit("Cart/GENERATE_CART", generateCartKey);
    }
    if (!store.getters["warehouseCart/wareCart"](generateCartKey)) {
      store.commit("warehouseCart/GENERATE_WARE_CART", generateCartKey);
    }

    store.commit("SET_USER_DATA", { ...result.profile, ...result });

    store.commit("SET_TOKEN", token);

    // setup pusher to get real time replies from tickets
    listenToChannel({
      ...ctx,
      id: result.profile.id,
    });
  } catch (err) {
    const status = err.status;
    console.log(status);
    // if (+status === 401) {

    // }
    store.commit("SET_USER_DATA", null);
    store.commit("SET_TOKEN", null);
    $cookies.remove("token");
    return next({
      name: "Login",
    });
    // const res = err?.response;
  }
}
function listenToChannel({ store, $cookies, id }) {
  try {
    const root = new Vue();
    const echo = root.$echo;

    echo.connector.pusher.config.auth.headers["Authorization"] =
      "Bearer " + store.state.token || $cookies.get("token");

    const channel = echo?.private(`user-${id}-channel`);

    channel?.listen(".tickets", ({ data }) => {
      const reply = data.replay;
      // save current reply to chat module to append it inside current ticket
      store.commit("chat/UPDATE_MESSAGE", data);

      // push notification to current client via Notification API
      root.checkPermissions({
        title: "New message from support",
        body: reply.body,
      });
    });
  } catch (error) {
    console.log(error);
  }
}
