<template>
  <form class="w-full space-y-6 p-6" v-if="load">
    <card>
      <p
        class="text-center font-semibold"
        v-if="error || success"
        :class="error ? 'text-red-500' : 'text-primary'"
      >
        {{ error || success }}
      </p>
      <figure class="space-y-4">
        <p class="text-sm font-medium rtl:font-semibold">{{ $t("logo") }}</p>
        <slide-transition>
          <figcaption v-if="add_data.logo" class="relative table">
            <img
              :src="
                add_data.logo
                  ? typeof add_data.logo === 'object'
                    ? convertImage(add_data.logo)
                    : add_data.logo
                  : require('@/assets/images/placeholder.png')
              "
              @error="
                (e) =>
                  (e.target.src = require('@/assets/images/placeholder.png'))
              "
              alt="Invoice logo"
              class="w-auto max-h-[7rem] max-w-[5rem] object-contain"
            />
            <button
              class="w-6 h-6 rounded-full flex items-center justify-center bg-red-500 text-white absolute top-0 right-0 rtl:right-auto rtl:left-0 ring-2 ring-white"
              type="button"
              @click.prevent="() => clearImage('add_data')"
            >
              <i class="fa-solid fa-xmark fa-sm"></i>
            </button>
          </figcaption>
        </slide-transition>
        <blockquote class="space-y-2">
          <label class="block">
            <span class="sr-only">Choose image</span>
            <input
              type="file"
              accept="image/*"
              class="custom-file"
              ref="uploader"
              @change="(e) => $set(add_data, 'logo', e.target.files[0])"
            />
          </label>
          <p class="error" v-if="add_errors.logo">
            {{ add_errors.logo.join(" ") }}
          </p>
        </blockquote>
      </figure>
      <div class="w-full space-y-3">
        <label
          for="commercial_register_num"
          class="font-medium rtl:font-semibold text-gray-600 table text-sm"
        >
          {{ $t("cr-number") }}
        </label>
        <input
          type="text"
          name="commercial_register_num"
          id="commercial_register_num"
          class="w-full py-3 px-4 rounded-md bg-gray-100 placeholder:text-gray-500"
          v-model="add_data.commercial_register_num"
        />
        <p class="error" v-if="add_errors.commercial_register_num">
          {{ (add_errors.commercial_register_num || []).join(" ") }}
        </p>
      </div>
      <div class="w-full space-y-3">
        <label
          for="tax_num"
          class="font-medium rtl:font-semibold text-gray-600 table text-sm"
        >
          {{ $t("tax-number") }}
        </label>
        <input
          type="text"
          name="tax_num"
          id="tax_num"
          class="w-full py-3 px-4 rounded-md bg-gray-100 placeholder:text-gray-500"
          v-model="add_data.tax_num"
        />
        <p class="error" v-if="add_errors.tax_num">
          {{ (add_errors.tax_num || []).join(" ") }}
        </p>
      </div>
      <div class="w-full space-y-3">
        <label
          for="notes"
          class="font-medium rtl:font-semibold text-gray-600 table text-sm"
        >
          {{ $t("notes") }}
        </label>
        <input
          type="text"
          name="notes"
          id="notes"
          class="w-full py-3 px-4 rounded-md bg-gray-100 placeholder:text-gray-500"
          v-model="add_data.notes"
        />
        <p class="error" v-if="add_errors.notes">
          {{ (add_errors.notes || []).join(" ") }}
        </p>
      </div>
      <div class="w-full space-y-3">
        <label
          for="comment"
          class="font-medium rtl:font-semibold text-gray-600 table text-sm"
        >
          {{ $t("comment") }}
        </label>
        <input
          type="text"
          name="comment"
          id="comment"
          class="w-full py-3 px-4 rounded-md bg-gray-100 placeholder:text-gray-500"
          v-model="add_data.comment"
        />
        <p class="error" v-if="add_errors.comment">
          {{ (add_errors.comment || []).join(" ") }}
        </p>
      </div>
    </card>
    <card>
      <div class="space-y-4">
        <p class="font-semibold text-sm">{{ $t("print-layout") }}</p>
        <div class="flex flex-wrap gap-3">
          <div>
            <input
              type="radio"
              name="print_layout"
              id="thermal"
              value="thermal"
              hidden
              class="peer"
              v-model="add_data.print_layout"
            />
            <label
              for="thermal"
              class="flex items-center text-gray-600 text-sm gap-3 border border-gray-300 transition peer-checked:border-primary peer-checked:text-primary p-3 rounded cursor-pointer font-medium flex-[fit-content]"
            >
              <i class="fa-solid fa-receipt fa-lg"></i>
              <span>{{ $t("thermal") }}</span>
            </label>
          </div>
          <div>
            <input
              type="radio"
              name="print_layout"
              id="invoice"
              value="invoice"
              hidden
              class="peer"
              v-model="add_data.print_layout"
            />
            <label
              for="invoice"
              class="flex items-center text-gray-600 text-sm gap-3 border border-gray-300 transition peer-checked:border-primary peer-checked:text-primary p-3 rounded cursor-pointer font-medium flex-[fit-content]"
            >
              <i class="fa-solid fa-file fa-lg"></i>
              <span>{{ $t("invoice-a4") }}</span>
            </label>
          </div>
        </div>

        <p class="font-semibold text-sm">{{ $t("language") }}</p>

        <div class="flex flex-wrap gap-3">
          <div>
            <input
              type="radio"
              name="print_language"
              id="ar"
              value="ar"
              hidden
              class="peer"
              v-model="print_language"
            />
            <label
              for="ar"
              class="flex items-center text-gray-600 text-sm gap-3 border border-gray-300 transition peer-checked:border-primary peer-checked:text-primary p-3 rounded cursor-pointer font-medium flex-[fit-content]"
            >
              <span>Arabic - عربي</span>
            </label>
          </div>
          <div>
            <input
              type="radio"
              name="print_language"
              id="en"
              value="en"
              hidden
              class="peer"
              v-model="add_data.print_language"
            />
            <label
              for="en"
              class="flex items-center text-gray-600 text-sm gap-3 border border-gray-300 transition peer-checked:border-primary peer-checked:text-primary p-3 rounded cursor-pointer font-medium flex-[fit-content]"
            >
              <span>English - انجليزي</span>
            </label>
          </div>
        </div>

        <p class="font-semibold text-sm">{{ $t("header") }}</p>
        <ul class="space-y-3">
          <li v-for="item in header_items" :key="item.key">
            <div class="form-group-with-check">
              <input
                type="checkbox"
                :id="item.key"
                data-type="switch"
                v-model="add_data.header"
                :value="item.key"
              />
              <label :for="item.key" class="cursor-pointer text-sm">
                <span>{{ $t(item.title) }}</span></label
              >
            </div>
          </li>
        </ul>
        <p class="font-semibold text-sm">{{ $t("item-content") }}</p>
        <ul class="space-y-3">
          <li v-for="item in item_content_items" :key="item.key">
            <div class="form-group-with-check">
              <input
                type="checkbox"
                :id="item.key"
                data-type="switch"
                v-model="add_data.item_content"
                :value="item.key"
              />
              <label :for="item.key" class="cursor-pointer text-sm">
                <span>{{ $t(item.title) }}</span></label
              >
            </div>
          </li>
        </ul>
        <p class="font-semibold text-sm">{{ $t("footer") }}</p>
        <ul class="space-y-3">
          <li v-for="item in footer_items" :key="item.key">
            <div class="form-group-with-check">
              <input
                type="checkbox"
                :id="item.key"
                data-type="switch"
                v-model="add_data.footer"
                :value="item.key"
              />
              <label :for="item.key" class="cursor-pointer text-sm">
                <span>{{ $t(item.title) }}</span></label
              >
            </div>
          </li>
        </ul>
      </div>
    </card>

    <button-with-loader
      btnClass="w-auto py-3 px-4 rounded bg-primary
          text-white text-sm"
      :disabled="disabled"
      @click="updateInvoice"
      v-if="hasPermission('edit receipts')"
      >{{ $t("save-changes") }}</button-with-loader
    >
  </form>
  <div class="p-6" v-else>
    <card>
      <div class="space-y-5">
        <div
          class="w-full flex items-center flex-wrap gap-3 sm:gap-0 form-group"
          v-for="i in 3"
          :key="i"
        >
          <div class="w-full space-y-3">
            <div class="w-1/5 h-4 rounded bg-gray-200 animate-pulse"></div>
            <div class="w-full h-10 rounded bg-gray-200 animate-pulse"></div>
          </div>
        </div>
      </div>
    </card>
  </div>
</template>
<script>
import Card from "@/components/Card.vue";
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";
import { mapActions } from "vuex";
export default {
  components: { ButtonWithLoader, Card },
  name: "Receipt",
  data() {
    return {
      add_data: {
        print_layout: "thermal",
      },
      load: false,
      add_errors: {},
      error: "",
      success: "",
      disabled: false,

      fonts: {
        thermal: [10, 11, 12, 13],
        invoice: [11, 12, 13, 14, 15, 16],
      },
      header_items: [
        {
          title: "logo",
          key: "company_logo",
        },
        {
          title: "cr-number",
          key: "cr_no",
        },
        {
          title: "location-details",
          key: "location_details",
        },
        {
          title: "invoice-number",
          key: "invoice_no",
        },
        {
          title: "customer-name",
          key: "customer_name",
        },
        {
          title: "customer-mobile",
          key: "customer_mobile",
        },

        {
          title: "customer-address",
          key: "customer_address",
        },
      ],
      item_content_items: [
        {
          title: "sku-barcode",
          key: "sku_barcode",
        },
        {
          title: "price",
          key: "include_price",
        },
        {
          title: "total-inclusive-tax",
          key: "total_inclusive_tax",
        },
        {
          title: "total-exclusive-tax",
          key: "total_exclusive_tax",
        },
        {
          title: "tax-amount",
          key: "tax_amount",
        },
        {
          title: "discount",
          key: "discount_amount",
        },
        {
          title: "include-weight-unit",
          key: "include_weight",
        },
      ],
      footer_items: [
        {
          title: "total",
          key: "total_items",
        },
        {
          title: "hide-discount",
          key: "show_hide_discount",
        },
        {
          title: "sales-note",
          key: "sales_note",
        },
        {
          title: "qr-code",
          key: "qr_code",
        },
        {
          title: "comment",
          key: "comment",
        },
      ],
    };
  },
  created() {
    this.getInvoice();
  },

  methods: {
    ...mapActions("getting", ["getRoute"]),
    async getInvoice() {
      try {
        const { result } = await this.getRoute("invoice_settings");
        this.add_data = {
          ...result,
          print_layout: result.print_layout || "thermal",
          header: result.header || [],
          item_content: result.item_content || [],
          footer: result.footer || [],
          print_language: result.print_language || this.$i18n.locale,
        };
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
        this.$router.push("/");
      } finally {
        this.load = true;
      }
    },

    async updateInvoice() {
      try {
        this.disabled = true;
        this.add_errors = {};
        this.add_error = {};
        this.error = null;
        let fd = new FormData();

        for (const key in this.add_data) {
          const item = this.add_data[key];
          if (item) {
            if (key === "logo") {
              if (typeof item === "object") {
                fd.append(key, item, item.name);
              } else {
                fd.delete(key);
              }
            } else if (Array.isArray(item)) {
              item.forEach((str, idx) => {
                fd.append(key + "[" + idx + "]", str);
              });
            } else {
              fd.append(key, item);
            }
          }
        }

        const { data } = await this.axios.post("invoice_settings/update", fd);
        // console.log
        this.add_data = data.result;
        this.add_error = {};
        this.error = null;
        this.createAlert(data?.message);
        this.getInvoice();
      } catch (error) {
        const err = error?.response;
        console.log(error);
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.error = responseError;
        }
      } finally {
        this.disabled = false;
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    },
    uploadMainImage(e) {
      e.preventDefault();
      this.add_data.logo = e.target.files[0];
    },
    convertImage(e) {
      return URL.createObjectURL(e);
    },

    clearImage(type) {
      const uploader = this.$refs.uploader;
      if (!uploader) return;
      uploader.value = null;
      this.$set(this[type], "logo", null);
    },
  },
};
</script>
