<template>
  <div class="p-6">
    <section class="py-10" v-if="load">
      <spinner
        size="w-6 h-6"
        class="table align-middle text-gray-600 mx-auto"
      />
    </section>
    <section class="space-y-4" v-else>
      <filter-wrapper
        @apply="getItems"
        @reset="handleReset"
        ref="filterWrapper"
      >
        <div>
          <t-datepicker
            :placeholder="`From - To`"
            multiple
            :monthsPerView="2"
            v-model="pagination.date"
            range
            :clearable="false"
            userFormat="Y-m-d"
            :weekStart="6"
          />
        </div>
      </filter-wrapper>
      <global-chart :charts="charts" />
      <!--  <section class="w-full grid grid-cols-1 lg:grid-cols-2 gap-4">
        <figure class="w-full ring-1 ring-gray-200 bg-white rounded p-4">
          <div id="chartBar">
            <apexchart
              type="bar"
              height="350"
              :options="{
                ...barChart,

                ...globalOptions,
              }"
              :series="series"
            ></apexchart>
          </div>
        </figure>
        <figure class="w-full ring-1 ring-gray-200 bg-white rounded p-4">
          <div id="chartBar">
            <apexchart
              type="radialBar"
              height="350"
              :options="{
                ...radialBarChart,

                ...globalOptions,
              }"
              :series="[40, 100, 50]"
            ></apexchart>
          </div>
        </figure>
      </section> -->
      <!-- summary cards -->
      <div class="w-full grid grid-cols-1 sm:grid-cols-2 gap-4">
        <figure class="w-full bg-white rounded ring-1 ring-gray-200">
          <header class="p-4 border-b border-b-gray-100">
            <p
              class="text-sm font-semibold rtl:font-bold text-center text-primary"
            >
              {{ $t("sales-summary") }}
            </p>
          </header>
          <blockquote class="px-4 py-2">
            <ul class="list-none w-full divide-y divide-gray-100 space-y-2">
              <li
                class="grid grid-cols-2 pt-3 pb-1"
                v-for="(item, index) in sales_summary"
                :key="index"
              >
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    {{ $t(item.title) }}
                  </p>
                </div>
                <div class="w-full">
                  <p
                    class="text-sm text-gray-600 font-semibold rtl:font-bold text-right rtl:text-left"
                  >
                    <span
                      >{{ item.value }}
                      <span class="text-xs" v-if="!/count/.test(item.key)"
                        >SAR</span
                      >
                    </span>
                  </p>
                </div>
              </li>
            </ul>
          </blockquote>
        </figure>
        <figure class="w-full bg-white rounded ring-1 ring-gray-200">
          <header class="p-4 border-b border-b-gray-100">
            <p
              class="text-sm font-semibold rtl:font-bold text-center text-primary"
            >
              {{ $t("payment-method") }}
            </p>
          </header>
          <blockquote class="px-4 py-2">
            <ul class="list-none w-full divide-y divide-gray-100 space-y-2">
              <li
                class="grid grid-cols-2 pt-3 pb-1"
                v-for="(item, index) in payment_methods"
                :key="index"
              >
                <div class="w-full">
                  <p class="text-sm text-gray-600 font-semibold rtl:font-bold">
                    {{ $t(item.title) }}
                  </p>
                </div>
                <div class="w-full">
                  <p
                    class="text-sm text-gray-600 font-semibold rtl:font-bold text-right rtl:text-left"
                  >
                    <span
                      >{{ item.value.toFixed(2) }}
                      <span class="text-xs">SAR</span>
                    </span>
                  </p>
                </div>
              </li>
            </ul>
          </blockquote>
        </figure>
      </div>
      <!-- summary cards -->
      <!-- cards down -->
      <div class="w-full grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div class="ring-1 ring-gray-200 bg-white rounded overflow-hidden">
          <header
            class="p-4 border-b border-b-gray-100 flex justify-between gap-2"
          >
            <p class="text-base font-semibold rtl:font-bold text-gray-600">
              {{ $t("best-selling-products") }}
            </p>
            <router-link
              to="/analytics/invoices/products"
              class="text-sm font-semibold rtl:font-bold text-primary"
            >
              {{ $t("view-all") }}
            </router-link>
          </header>
          <div class="max-h-[30rem] overflow-y-auto p-2">
            <ul class="divide-y divide-gray-100">
              <li v-for="(product, i) in items.top_products" :key="i">
                <div class="flex gap-4 p-4">
                  <span
                    class="shrink-0 flex items-center justify-center bg-gray-100 w-10 h-10 text-gray-600 rounded-full overflow-hidden"
                  >
                    <img
                      :src="
                        product.image ||
                        require('@/assets/images/placeholder.png')
                      "
                      @error="
                        $event.target.src = require('@/assets/images/placeholder.png')
                      "
                      alt="product image"
                      class="w-full h-full object-cover"
                    />
                    <!-- <i class="fa-solid fa-shopping-cart"></i> -->
                  </span>
                  <div class="shrink-0 flex-1 space-y-1">
                    <p
                      class="text-sm font-semibold rtl:font-bold text-gray-600"
                    >
                      {{ product["name_" + $i18n.locale] }}
                    </p>
                    <p class="text-xs text-gray-500">
                      - {{ (product.store || {})["name_" + $i18n.locale] }}
                    </p>

                    <p class="text-xs text-gray-500">
                      -
                      <span v-if="product.unit_type === 'weight'">
                        {{ GenerateUnit(product.sold || 0) }}
                      </span>
                      <span v-else> {{ product.sold || 0 }} Piece </span>
                    </p>
                  </div>
                  <p class="text-sm text-primary font-bold">
                    {{ (product.total_price || 0).toFixed(2) }} SAR
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="ring-1 ring-gray-200 bg-white rounded overflow-hidden">
          <header
            class="p-4 border-b border-b-gray-100 flex justify-between gap-2"
          >
            <p class="text-base font-semibold rtl:font-bold text-gray-600">
              {{ $t("most-popular-customers") }}
            </p>
            <router-link
              to="/customers"
              class="text-sm font-semibold rtl:font-bold text-primary"
            >
              {{ $t("view-all") }}
            </router-link>
          </header>
          <div class="max-h-[30rem] overflow-y-auto p-2">
            <ul class="divide-y divide-gray-100">
              <li v-for="(customer, i) in items.top_customers" :key="i">
                <router-link
                  :to="{
                    path: '/customers',
                    query: {
                      search: customer.name,
                    },
                  }"
                  class="flex gap-4 p-4"
                >
                  <span
                    class="shrink-0 flex items-center justify-center bg-gray-100 w-10 h-10 text-gray-600 rounded-full"
                  >
                    <i class="fa-solid fa-user"></i>
                  </span>
                  <div class="shrink-0 flex-1">
                    <p
                      class="text-sm font-semibold rtl:font-bold text-gray-600"
                    >
                      {{ customer.name }}
                    </p>
                    <a
                      class="text-xs text-primary table"
                      :href="'tel:+' + customer.mobile"
                    >
                      {{ customer.mobile }}
                    </a>
                  </div>
                  <span class="shrink-0">
                    <i class="fa-solid fa-angle-right rtl:hidden"></i>
                    <i class="fa-solid fa-angle-right hidden rtl:inline"></i>
                  </span>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- cards down -->
    </section>
  </div>
</template>

<script>
import AnalyticsContainerWrapper from "@/components/AnalyticsContainerWrapper.vue";
import FilterWrapper from "@/components/basics/FilterWrapper.vue";
import GlobalChart from "@/components/charts/GlobalChart.vue";
import { mapActions, mapMutations } from "vuex";
export default {
  components: { AnalyticsContainerWrapper, FilterWrapper, GlobalChart },
  name: "Analytics",
  data() {
    return {
      series: [
        {
          name: "Last week",
          data: [31, 40, 28, 51, 42, 109, 100, 50, 70, 80, 80, 10],
        },
        {
          name: "This week",
          data: [11, 32, 45, 32, 34, 52, 41, 70, 80, 10, 500, 50],
        },
      ],
      globalOptions: {
        dataLabels: {
          enabled: false,
          formatter: function (value, { seriesIndex, dataPointIndex, w }) {
            return w.config.series[seriesIndex].data[dataPointIndex];
          },
        },
        tooltip: {
          y: {
            formatter: function (val, { w }) {
              return val;
            },
          },
          style: {
            fontSize: "12px",
            fontFamily: "Montserrat, Cairo",
          },
          x: {
            show: false,
          },
          marker: {
            show: false,
          },
        },
        xaxis: {
          labels: {
            style: {
              fontSize: "12px",
              fontFamily: "Montserrat, Cairo",
              fontWeight: "bold",
            },
          },
          axisBorder: {
            show: true,
            height: 1,
            width: "100%",
            offsetX: 0,
          },
          axisTicks: {
            show: false,
          },
        },
        theme: {
          palette: "palette8",
        },
      },
      chartOptions: {
        chart: {
          type: "line",
          height: 350,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        stroke: {
          curve: "smooth",
        },
        dataLabels: {
          enabled: false,
        },
        title: {
          text: "Sales",
          style: {
            fontSize: "24px",
            fontFamily: "Montserrat, Cairo",
            fontWeight: "bold",
          },
        },
        xaxis: {
          type: "category",
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        // labels: series.monthDataSeries1.dates,
        yaxis: {
          opposite: false,
          labels: {
            show: true,
            align: "left",
            rotate: -45,
            style: {
              fontSize: "12px",
              fontFamily: "Montserrat, Cairo",
              fontWeight: "bold",
            },
            offsetX: -5,
            offsetY: 10,
          },
        },
        legend: {
          horizontalAlign: "left",
        },
        grid: {
          show: true,
          position: "back",
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
          padding: {
            right: 0,
            left: 0,
            top: 0,
            bottom: 0,
          },
        },
        legend: {
          position: "right",
          offsetY: 20,
          markers: {
            radius: 5,
            width: 10,
            height: 10,
          },
          itemMargin: {
            // horizontal: 10,
            vertical: 5,
          },
        },
        responsive: [
          {
            breakpoint: 678,
            options: {
              cart: {
                height: "auto",
              },
              legend: {
                position: "bottom",
                offsetY: 0,

                itemMargin: {
                  horizontal: 10,
                  vertical: 0,
                },
              },
            },
          },
        ],
      },
      barChart: {
        chart: {
          type: "bar",
          height: 350,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        stroke: {
          curve: "smooth",
        },
        dataLabels: {
          enabled: false,
        },
        title: {
          text: "Inventory",
          style: {
            fontSize: "24px",
            fontFamily: "Montserrat, Cairo",
            fontWeight: "bold",
          },
        },
        xaxis: {
          type: "category",
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        // labels: series.monthDataSeries1.dates,
        yaxis: {
          opposite: false,
          labels: {
            show: true,
            align: "left",
            rotate: -45,
            style: {
              fontSize: "12px",
              fontFamily: "Montserrat, Cairo",
              fontWeight: "bold",
            },
            offsetX: -5,
            offsetY: 10,
          },
        },
        legend: {
          horizontalAlign: "left",
        },
        grid: {
          show: true,
          position: "back",
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
          padding: {
            right: 0,
            left: 0,
            top: 0,
            bottom: 0,
          },
        },
        legend: {
          position: "bottom",
          markers: {
            radius: 5,
            width: 10,
            height: 10,
          },
          itemMargin: {
            // horizontal: 10,
            vertical: 5,
          },
        },
        responsive: [
          {
            breakpoint: 678,
            options: {
              cart: {
                height: "auto",
              },
              legend: {
                position: "bottom",
                offsetY: 0,

                itemMargin: {
                  horizontal: 10,
                  vertical: 0,
                },
              },
            },
          },
        ],
      },
      radialBarChart: {
        chart: {
          type: "radialbar",
          height: 350,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        labels: ["Sale", "Distribute", "Returns"],
        plotOptions: {
          radialBar: {
            dataLabels: {
              name: {
                fontSize: "22px",
              },
              value: {
                fontSize: "16px",
              },
              total: {
                show: true,
                label: "Total",
                formatter: function (w) {
                  // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                  return 249;
                },
              },
            },
          },
        },

        responsive: [
          {
            breakpoint: 678,
            options: {
              cart: {
                height: "auto",
              },
            },
          },
        ],
      },
      sales_summary: [
        // {
        //   title: "Hold orders",
        //   key: "hold",
        //   value: 0,
        //   count: 0,
        // },
        // {
        //   title: "Cancelled orders",
        //   key: "cancelled",
        //   value: 0,
        //   count: 0,
        // },
        // {
        //   title: "Refunds orders",
        //   key: "refunded",
        //   value: 0,
        //   count: 0,
        // },
        {
          title: "incomes",
          key: "income",
          value: 0,
          count: 0,
        },
        {
          title: "invoices",
          key: "invoices_count",
          value: 0,
          count: 0,
        },
        {
          title: "products",
          key: "products_count",
          value: 0,
          count: 0,
        },
        {
          title: "vat",
          key: "vat",
          value: 0,
          count: 0,
        },
        {
          title: "discounts",
          key: "discounts",
          value: 0,
          count: 0,
        },
      ],
      payment_methods: [
        {
          title: "card-amount",
          key: "card_payments",
          value: 0,
          count: 0,
        },
        {
          title: "cash-amount",
          key: "cash_payments",
          value: 0,
          count: 0,
        },
      ],
      inventory_alerts: [
        {
          name: null,

          count: 100,
        },
        {
          name: null,

          count: 20,
        },
        {
          name: null,

          count: 200,
        },
        {
          name: null,

          count: 100,
        },
        {
          name: null,

          count: 40,
        },
        {
          name: null,

          count: 10,
        },
        {
          name: null,

          count: 100,
        },
      ],
      stores_summary: [
        {
          name: null,
          count: 100,
        },
        {
          name: null,

          count: 20,
        },
        {
          name: null,

          count: 200,
        },
        {
          name: null,

          count: 100,
        },
        {
          name: null,

          count: 40,
        },
        {
          name: null,

          count: 10,
        },
        {
          name: null,

          count: 100,
        },
      ],
      pagination: {},
      items: {},
      charts: [],
      load: true,
    };
  },
  created() {
    this.$set(this.pagination, "date", [
      this.$moment().add(-1, "days").format("YYYY-MM-DD"),
      this.$moment().format("YYYY-MM-DD"),
    ]);
    this.getItems();
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("table", ["changeIsCalled"]),

    async getItems() {
      try {
        const { date } = this.pagination;
        const { result } = await this.getRoute({
          name: "analytics",
          options: {
            from: date?.[0],
            to: date?.[1],
            store_id: this.store_id,
          },
        });
        this.items = result;
        this.charts = result.charts;
        this.sales_summary = this.sales_summary.map((ev) => ({
          ...ev,
          value: result?.sales_summary?.[ev.key] || 0,
        }));
        this.payment_methods = this.payment_methods.map((ev) => ({
          ...ev,
          value: result?.sales_summary?.[ev.key] || 0,
        }));
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      } finally {
        this.load = false;
        const filterWrapper = this.$refs.filterWrapper;
        filterWrapper?.resetAll();
      }
    },

    handleReset() {
      const { page, ...others } = this.pagination;

      for (const key in others) {
        const item = others[key];
        if (typeof item === "number") {
          this.$set(this.pagination, key, 0);
        } else if (Array.isArray(item)) {
          this.$set(this.pagination, key, []);
        } else {
          this.$set(this.pagination, key, null);
        }
      }

      this.getItems();
    },
  },
};
</script>
