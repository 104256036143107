var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-6"},[_c('Table',{attrs:{"title":_vm.$t('incomes'),"records":`${_vm.pagination.total || 0} `,"pagination":_vm.pagination,"total_pages":_vm.pagination.total_pages},scopedSlots:_vm._u([{key:"head",fn:function(){return [_c('th',[_vm._v(_vm._s(_vm.$t('day')))]),_c('th',[_vm._v(_vm._s(_vm.$t('total')))]),_c('th',[_vm._v(_vm._s(_vm.$t('qty')))]),_c('th',[_vm._v(_vm._s(_vm.$t('weight')))]),_c('th',[_vm._v(_vm._s(_vm.$t('card-amount')))]),_c('th',[_vm._v(_vm._s(_vm.$t('cash-amount')))]),_c('th',[_vm._v(_vm._s(_vm.$t('invoices')))]),_c('th',[_vm._v(_vm._s(_vm.$t('products')))])]},proxy:true},(!_vm.load)?{key:"tbody",fn:function(){return _vm._l((4),function(i){return _c('TableLoad',{key:i,attrs:{"rows":8}})})},proxy:true}:(_vm.load && _vm.items.length)?{key:"tbody",fn:function(){return _vm._l((_vm.items),function(item,index){return _c('tr',{key:index},[_c('td',{staticClass:"space-y-1"},[_c('p',{staticClass:"text-sm font-semibold rtl:font-bold"},[_vm._v(_vm._s(item.day))]),_c('p',{staticClass:"text-xs font-medium rtl:font-semibold"},[_vm._v(" "+_vm._s(_vm._f("moment")(item.date,"DD-MM-YYYY"))+" ")])]),_c('td',[_vm._v(_vm._s(item.income)+" SAR")]),_c('td',[_vm._v(_vm._s(item.total_qty || 0)+" Piece(s)")]),_c('td',[_vm._v(_vm._s(_vm.GenerateUnit(item.total_weight || 0)))]),_c('td',[_vm._v(_vm._s(item.card_total || 0)+" SAR")]),_c('td',[_vm._v(_vm._s(item.cash_total || 0)+" SAR")]),_c('td',[(_vm.hasPermission('show invoice_sales'))?_c('router-link',{directives:[{name:"tippy",rawName:"v-tippy"}],staticClass:"w-8 h-8 rounded bg-sky-600 text-white flex items-center justify-center text-sm font-semibold",attrs:{"to":{
              path: '/analytics/invoices',
              query: {
                date: item.date,
                from: 'incomes',
              },
            },"content":"View invoices"}},[_vm._v(" "+_vm._s(item.invoices_count)+" ")]):_c('span',[_vm._v("-")])],1),_c('td',[(_vm.hasPermission('show item_sales'))?_c('router-link',{directives:[{name:"tippy",rawName:"v-tippy"}],staticClass:"w-8 h-8 rounded bg-sky-600 text-white flex items-center justify-center text-sm font-semibold",attrs:{"to":{
              path: '/analytics/invoices/products',
              query: {
                date: item.date,
                from: 'incomes',
              },
            },"content":"View products"}},[_vm._v(" "+_vm._s(item.products_count)+" ")]):_c('span',[_vm._v("-")])],1)])})},proxy:true}:{key:"tbody",fn:function(){return [_c('tr',[_c('td',{staticClass:"!text-center",attrs:{"colspan":"100%"}},[_vm._v(_vm._s(_vm.$t('no-data')))])])]},proxy:true}],null,true),model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }