<template>
  <div class="p-6">
    <ul class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
      <li v-for="(page, index) in pages" :key="index">
        <PagesCard :page="page" />
      </li>
    </ul>
  </div>
</template>
<script>
import PagesCard from "@/components/PagesCard.vue";

export default {
  computed: {
    pages() {
      const items = [
        {
          title: this.$t("oos-alerts"),
          content: this.$t("oos-alerts-content"),
          icon: "fa-solid fa-info-circle",
          url: "/analytics/alerts",
          style: "bg-white text-black",
          access: "show oos_alerts",
        },
        {
          title: this.$t("item-sales"),
          content: this.$t("item-sales-content"),
          icon: "fa-solid fa-layer-group",
          url: "/analytics/invoices/products",
          style: "bg-white text-black",
          access: "show item_sales",
        },
        {
          title: this.$t("categories"),
          content: this.$t("categories-content"),
          icon: "fa-solid fa-cubes",
          url: "/analytics/invoices/categories",
          style: "bg-white text-black",
          access: "show invoice_categories",
        },
        {
          title: this.$t("inventory"),
          content: this.$t("inventory-reports-content"),

          icon: "fa-solid fa-layer-group",
          url: "/analytics/invoices/inventory",
          style: "bg-white text-black",
          access: "show invoice_inventory",
        },
        {
          title: this.$t("refunds"),
          content: this.$t("refunds-content"),
          icon: "fa-solid fa-hand-holding-dollar",
          url: "/analytics/invoices/refunds",
          style: "bg-white text-black",
          access: "show invoice_refunds",
        },
        {
          title: this.$t("ledger"),
          content: this.$t("ledger-content"),

          icon: "fa-solid fa-book-open-reader",
          url: "/analytics/ledger",
          style: "bg-white text-black",
          access: "show ledger_report",
        },
      ];
      return items;
    },
  },
  name: "ReportsPage",
  components: { PagesCard },
};
</script>
