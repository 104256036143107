<template>
  <div>
    <slide-transition>
      <select-cashier-device v-if="!getDevice?.id" />

      <!-- <open-the-safe v-else-if="getDevice?.id && !getTheOpenSafe.amount" /> -->

      <POSSales v-else />
    </slide-transition>
  </div>
</template>

<script>
import OpenTheSafe from "@/components/pos_system/OpenTheSafe.vue";
import POSSales from "@/components/pos_system/POSSales.vue";
import SelectCashierDevice from "@/components/pos_system/SelectCashierDevice.vue";
import { mapGetters } from "vuex";
export default {
  components: { SelectCashierDevice, OpenTheSafe, POSSales },

  name: "POSSystem",
  computed: {
    ...mapGetters("pos_system", ["getDevice", "getTheOpenSafe"]),
  },
};
</script>
