<template>
  <div class="flex gap-4 py-3 group-first:pt-0 group-last:pb-0">
    <img
      class="w-10 h-10 rounded-full object-cover bg-gray-200 shrink-0"
      :src="product.image || require('@/assets/images/placeholder.png')"
      @error="$event.target.src = require('@/assets/images/placeholder.png')"
      :alt="product['name_' + $i18n.locale]"
    />
    <div class="flex-1">
      <p class="font-bold text-sm">
        {{ generateCurrency(product.price || 0) }} {{ product.qty }}
        {{ product.unit_type === "weight" ? "KG" : "Piece" }}
      </p>
      <p class="text-sm text-gray-500 line-clamp-1">
        {{ product["name_" + $i18n.locale] }}
      </p>

      <button
        class="text-red-500 font-medium text-xs mt-1"
        type="button"
        @click.prevent="onProductRemove"
      >
        Remove
      </button>
    </div>
    <div class="shrink-0 space-y-2">
      <controllable
        v-model="product.qty"
        @input="updateProductQTY"
        :max="product.tracking_stock ? product.stock : 0"
        :disabled="
          product.tracking_stock
            ? product.qty > product.stock || product.qty < 1
            : false
        "
        :type="product.unit_type"
      />
      <p class="text-xs font-medium text-end">
        {{ generateCurrency(product.qty * product.price) }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import Controllable from "../Controllable.vue";

export default {
  components: { Controllable },
  name: "SideProductCard",
  props: {
    product: {
      type: Object,
      default: {},
    },
  },

  methods: {
    ...mapMutations("pos_system", ["removeProduct", "updateProduct"]),
    onProductRemove() {
      this.removeProduct(this.product.id);
    },
    async updateProductQTY(value) {
      // if(this)
      this.updateProduct({
        id: this.product?.id,
        qty: value,
      });
    },
  },
};
</script>
