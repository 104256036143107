<template>
  <div
    class="flex items-center justify-center gap-2 bg-gray-200 py-2 px-4 rounded-full text-sm text-gray-600"
  >
    <span class="shrink-0">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <g transform="translate(0 24) scale(1 -1)">
          <path
            fill="currentColor"
            d="M12 2A10 10 0 1 0 22 12A10 10 0 0 0 12 2Zm0 18a8 8 0 1 1 8-8A8 8 0 0 1 12 20Z"
            opacity=".5"
          />
          <path
            fill="currentColor"
            d="M20 12h2A10 10 0 0 0 12 2V4A8 8 0 0 1 20 12Z"
          >
            <animateTransform
              attributeName="transform"
              dur="0.5s"
              from="0 12 12"
              repeatCount="indefinite"
              to="360 12 12"
              type="rotate"
            />
          </path>
        </g>
      </svg>
    </span>
    <span>Loading...</span>
  </div>
</template>

<script>
export default {
  name: "LoadingSnack",
};
</script>
