<template>
  <!-- Use the `open` state to conditionally change the direction of an icon. -->
  <div class="w-full">
    <div class="flex items-start gap-4">
      <!-- <div class="form-group-with-check shrink-0 mt-3">
        <input
          type="checkbox"
          @input="$emit('change', $event.target.value)"
          :value="value"
        />
      </div> -->
      <div class="flex-1">
        <button
          type="button"
          class="py-2 px-3 rounded border border-gray-200 bg-gray-50 flex items-center justify-between w-full gap-3 text-start"
          @click.self="show = !show"
        >
          <p class="flex-1 text-sm font-semibold pointer-events-none">
            {{ title }}
          </p>
          <slot name="action"></slot>

          <i
            class="fa-solid fa-sm pointer-events-none"
            :class="show ? 'fa-angle-up' : 'fa-angle-down'"
          ></i>
        </button>
        <slide-transition
          ><div class="py-4 grid" v-show="show"><slot></slot></div
        ></slide-transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Expanded",
  props: {
    title: { type: String, default: undefined },
    value: { type: [String, Array, Number], default: undefined },
    checked: { type: Boolean, default: false },
  },
  data() {
    return {
      show: true,
    };
  },
};
</script>
