<template>
  <div>
    <ul class="list-none space-y-2">
      <li class="block w-full" v-for="(item, index) in items" :key="index">
        <div
          class="table max-w-[80%] space-y-2"
          :class="
            item.own_reply
              ? '[margin-inline-start:auto]'
              : '[margin-inline-end:auto]'
          "
        >
          <div
            class="table w-auto p-2 rounded"
            v-tippy
            :content="item.created_at | moment('from')"
            :class="
              item.own_reply
                ? 'bg-primary text-white [margin-inline-start:auto]'
                : 'border border-gray-200 [margin-inline-end:auto]'
            "
          >
            <p class="font-medium rtl:font-semibold text-sm">
              {{ item.body }}
            </p>
          </div>
          <div
            class="table"
            :class="
              item.own_reply
                ? '[margin-inline-start:auto]'
                : '[margin-inline-end:auto]'
            "
            v-if="item.attachments.length"
          >
            <div class="inline-flex items-center gap-2 flex-wrap">
              <div v-for="(attachment, idx) in item.attachments" :key="idx">
                <a
                  class="table p-2 rounded bg-slate-200"
                  :href="attachment.path"
                  v-tippy
                  :content="attachment.mime"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p class="font-medium rtl:font-semibold text-sm">
                    {{ attachment.name }} {{ generateSize(attachment.size) }}
                  </p>
                </a>
              </div>
            </div>
          </div>
          <p
            class="font-medium rtl:font-semibold text-xs text-gray-500 table"
            :class="
              item.own_reply
                ? '[padding-inline-start:0.5rem] [margin-inline-end:auto]'
                : '[padding-inline-end:0.5rem] [margin-inline-start:auto]'
            "
            v-if="item.last_msg"
          >
            {{ item.created_at | moment("h:mmA") }}
          </p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "MessagesPreview",
  props: {
    items: {
      type: Array,
      default: Array,
    },
  },
  //   computed: {
  //     lastReply() {
  //       return (current, previous) => {
  //         const ownReply = current.own_reply;
  //         if (!previous && this.items.length === 1) return true;
  //         if(ownReply !==)
  //       };
  //     },
  //   },
  computed: {
    generateSize() {
      return (size) => {
        return new Intl.NumberFormat("en", {
          notation: "compact",
        }).format(Math.floor(size));
      };
    },
  },
};
</script>