<template>
  <div>
    <modal
      :title="$t('add')"
      size="!max-w-screen-lg"
      @close="hide"
      v-show="active"
    >
      <template>
        <div class="space-y-2 p-6">
          <div class="form-group flex-1 space-y-3">
            <div class="flex items-center gap-4 justify-between">
              <label for="ref_no"> {{ $t("ref-no") }} </label>
              <a
                href="javascript:void(0)"
                class="text-teal-600 font-semibold flex items-center justify-start gap-2 text-xs"
                @click.prevent="ref_no = Date.now()"
              >
                <i class="fa-solid fa-sync fa-sm"></i>
                <span>{{ $t("generate") }}</span>
              </a>
            </div>
            <input
              type="text"
              name="ref_no"
              id="ref_no"
              v-model="ref_no"
              placeholder="..."
            />
            <p class="error">
              {{ (add_errors["ref_no"] || []).join(" ") }}
            </p>
          </div>
          <div class="form-group space-y-3">
            <label for="store_id">
              {{ $t("from") }} | {{ $t("location") }}
            </label>
            <input
              type="text"
              disabled
              readonly
              :value="store['name_' + $i18n.locale]"
            />
            <p class="error">
              {{ (add_errors["store_id"] || []).join(" ") }}
            </p>
          </div>
          <div class="form-group space-y-3">
            <label for="to_store_id">
              {{ $t("to") }} | {{ $t("location") }}
            </label>
            <t-rich-select
              :options="stores"
              placeholder="Select"
              :text-attribute="'name_' + $i18n.locale"
              value-attribute="id"
              :clearable="true"
              :closeOnSelect="false"
              v-model="to_store_id"
            />
            <p class="error">
              {{ (add_errors["to_store_id"] || []).join(" ") }}
            </p>
          </div>
          <div class="form-group space-y-3">
            <label for="product_id"> {{ $t("product") }} </label>
            <t-rich-select
              :fetch-options="getProducts"
              placeholder="Select"
              :text-attribute="'name_' + $i18n.locale"
              value-attribute="id"
              :clearable="true"
              :closeOnSelect="false"
              @change="handleOtherProductsChange"
              v-model="product_id"
              multiple
            />
            <p class="error">
              {{ (add_errors["products"] || []).join(" ") }}
            </p>
          </div>
          <table class="styled-table border border-gray-200">
            <thead>
              <tr>
                <th>#</th>
                <th>{{ $t("product") }}</th>
                <th>{{ $t("price") }}</th>
                <th>{{ $t("weight") }}</th>
                <th>{{ $t("qty") }}</th>
                <th>{{ $t("actions") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-show="!products.length">
                <td colspan="100%">
                  <p class="text-sm text-center text-gray-600 font-medium">
                    {{ $t("no-data") }}
                  </p>
                </td>
              </tr>
              <tr v-for="(product, index) in products" :key="product.id">
                <td>{{ index + 1 }}</td>
                <td>
                  <p class="max-w-sm min-w-[15rem] line-clamp-1">
                    {{ product.product?.["name_" + $i18n.locale] }}
                  </p>
                  <p class="error">
                    {{
                      (add_errors["products." + index + ".id"] || []).join(" ")
                    }}
                  </p>
                </td>
                <td>
                  <!-- <Controllable v-model="product.price" /> -->
                  <p class="text-gray-800 font-semibold text-sm">
                    {{ product.price }} SAR
                  </p>
                </td>
                <td>
                  <Controllable
                    v-model="product.weight"
                    v-if="product.product?.unit_type === 'weight'"
                  />
                  <span v-else>N/A</span>
                  <p class="error">
                    {{
                      (add_errors["products." + index + ".weight"] || []).join(
                        " "
                      )
                    }}
                  </p>
                </td>

                <td>
                  <Controllable v-model="product.qty" />
                  <p class="error">
                    {{
                      (add_errors["products." + index + ".qty"] || []).join(" ")
                    }}
                  </p>
                </td>
                <td>
                  <button
                    type="button"
                    class="py-2 px-3 rounded bg-red-500 text-white font-medium text-sm inline-flex items-center gap-2"
                    @click.prevent="deleteProduct(product.id)"
                  >
                    <i class="fa-solid fa-trash fa-sm"></i>
                    <span>{{ $t("delete") }}</span>
                  </button>
                </td>
              </tr>
              <tr>
                <td colspan="100%"></td>
                <td>
                  <p class="text-gray-800 font-semibold text-sm">
                    {{ $t("total") }} {{ getTotal }} SAR
                  </p>
                </td>
                <td colspan="100%"></td>
              </tr>
            </tbody>
          </table>
          <button
            type="button"
            class="py-2 px-3 rounded bg-primary text-white font-medium text-sm inline-flex items-center gap-2"
            @click.prevent="onTransferSubmit"
            :disabled="add_disabled"
          >
            <i class="fa-solid fa-check fa-sm"></i>
            <span>{{ $t("save-changes") }}</span>
          </button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Card from "../Card.vue";
import Controllable from "../Controllable.vue";
import Expanded from "../Expanded.vue";
import Modal from "../basics/Modal.vue";
export default {
  components: { Modal, Expanded, Card, Controllable },
  name: "NewTransfer",

  props: {
    refetch: {
      type: Function,
      default: () => Promise,
    },
  },
  data() {
    return {
      products: [],
      refund_data: {},
      add_disabled: false,
      add_errors: {},
      active: false,
      ref_no: Date.now(),
      list: [],
      stores: [],
      product_id: [],
      to_store_id: null,
      store: null,
    };
  },
  computed: {
    getTotal() {
      let total = 0;
      const products = this.products;

      for (const product of products) {
        const { price = 0, weight = 0, qty = 0 } = product;
        const { unit_type } = product.product;
        if (unit_type === "weight") {
          total += Number(price * weight);
          continue;
        }
        total += Number(price * qty);
      }

      return parseFloat(total.toFixed(2));
    },
  },
  async created() {
    await Promise.all([this.getStores()]);
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    async getProducts(q) {
      try {
        const { result } = await this.getRoute({
          name: "products",
          options: {
            search_key: q,
            page: 1,
            store_id: this.store_id,
            // unit_type: "weight",
          },
        });
        const products = result.products.data;

        this.list = products;
        return { results: products };
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async getStores() {
      try {
        const { result } = await this.getRoute("stores");
        const id = this.store_id;
        this.store = result.stores?.find((s) => s.id == id);
        const stores = result.stores?.filter((e) => e.id !== id);
        this.stores = stores;
        if (stores.length === 1) this.to_store_id = stores[0]?.id;
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    handleOtherProductsChange(values, ...raw) {
      console.log(raw);
      const products = values.map((e) => {
        const product = this.list.find((p) => p.id == e);
        const included = this.products.find((p) => p.id == e);
        const newProduct = this.generateProduct(product);
        return included ? included : newProduct;
      });

      this.products = products;

      //   const checked = this.products.find((e) => e.id == value);
      //   const concatProducts = [...this.products, item];
      //   const filterProducts = this.products.filter((e) => e.id !== value);

      //   if (checked) {
      //     this.products = concatProducts;
      //     return this.createAlert("Product added");
      //   }
      //   this.products = filterProducts;
    },
    generateProduct(product) {
      const {
        name_ar,
        name_en,
        unit_type,
        weight = 1,
        qty,
        price,
        id,
      } = product;
      const item = {
        id,
        product: { name_ar, name_en, unit_type, weight, qty, price },
        qty: 1,
        price,
        ...(unit_type === "weight" && { weight }),
      };
      return item;
    },
    deleteProduct(id) {
      const filterProducts = this.products.filter((e) => e.id !== id);
      this.product_id = this.product_id.filter((e) => e !== id);
      this.products = filterProducts;
    },
    hide() {
      this.add_data = {};
      this.products = [];
      this.list = [];
      this.product_id = [];
      this.to_store_id = null;

      this.active = false;
    },
    show() {
      this.active = true;
    },
    async onTransferSubmit() {
      try {
        this.add_disabled = true;
        const products = this.products.map((e) => {
          const { product, price, ...others } = e;
          return others;
        });
        const order = {
          store_id: this.store_id,
          ref_no: this.ref_no,
          products,
          order_type: "transfer",
          to_store_id: this.to_store_id,
        };
        const { data } = await this.axios.post("orders/new", order);
        this.createAlert(data.message);
        // this.$router.push("/inventory-actions/transfers");
        await this.refetch();
        this.hide();
      } catch (error) {
        const err = error?.response?.data;
        const message = err.message;
        if (message instanceof Object) {
          this.add_errors = message;
          return;
        }
        this.createAlert(message, "error");
      } finally {
        this.add_disabled = false;
      }
    },
  },
};
</script>
