<template>
  <modal title="Image uploader" v-if="active" @close="hide">
    <div class="p-6 space-y-4">
      <label
        class="p-4 rounded-lg border border-gray-200 flex items-center justify-center"
        :for="generateHash('ImageUploader' + Date.now())"
      >
        <p class="text-base text-gray-400 text-center">
          <i class="fa-solid fa-paperclip"></i>
          <span class="text-sky-600 font-semibold"> Add file </span>
          <span>or drop files here</span>
        </p>
      </label>
      <input
        type="file"
        :id="generateHash('ImageUploader' + Date.now())"
        hidden
        @change.prevent="onFileChange"
        accept="image/*"
      />
      <slide-transition>
        <ul class="space-y-2" v-show="files.length">
          <li v-for="(file, index) in files" :key="index">
            <div class="flex items-center gap-3">
              <p class="text-sm text-gray-400 flex-1 line-clamp-1">
                {{ file.name }}
              </p>
              <p class="text-sm text-gray-400 shrink-0">
                {{ generateFileSize(file.size) }}
              </p>
              <button class="shrink-0">
                <i class="fa-solid fa-trash text-red-500 fa-sm"></i>
              </button>
            </div>
          </li>
        </ul>
      </slide-transition>
      <div class="flex items-center justify-end gap-3">
        <button-with-loader
          btnClass="w-auto py-2 px-4 rounded bg-gray-100
      text-gray-600 text-xs"
          type="button"
          @click="hide"
          >Cancel</button-with-loader
        >
        <button-with-loader
          btnClass="w-auto py-2 px-4 rounded bg-primary
      text-white text-xs "
          type="submit"
          @click="saveChanges"
          >Save changes</button-with-loader
        >
      </div>
    </div>
  </modal>
</template>

<script>
import short from "shorthash";
import Modal from "./basics/Modal.vue";
import ButtonWithLoader from "./basics/ButtonWithLoader.vue";

export default {
  components: { Modal, ButtonWithLoader },
  name: "ImageUploader",
  props: { multiple: { type: Boolean, default: false } },
  data() {
    return {
      files: [],
      active: false,
    };
  },
  watch: {
    active(val) {
      if (!val) {
        this.files = [];
      }
    },
  },
  methods: {
    generateHash() {
      return (key) => short.unique(key);
    },
    onFileChange(e) {
      const files = e.target.files;
      if (!this.multiple) {
        this.files = [files[0]];
        return;
      }

      this.files = files;
    },
    show() {
      this.active = true;
    },
    hide() {
      this.active = false;
    },
    saveChanges() {
      // const result;
      const files = this.files;
      const isMultiple = this.multiple;
      this.$emit("change", isMultiple ? files : files[0] || null);
      this.hide();
    },
  },
};
</script>
