<template>
  <div class="p-6">
    <Table
      :title="$t('roles')"
      :records="`${pagination.total || 0} `"
      :pagination="pagination"
      :total_pages="pagination.total_pages"
      v-model="pagination.page"
    >
      <template #actions>
        <div v-if="hasPermission('add roles')">
          <button
            class="py-1.5 px-3 text-sm flex items-center gap-2 text-gray-600 font-medium rtl:font-semibold"
            @click.prevent="add_active = !add_active"
          >
            <i class="fa-solid fa-plus"></i>
            <span>{{ $t("add") }}</span>
          </button>
        </div>
      </template>
      <!-- <template #filter>
        <ul class="flex items-center">
          <li v-for="(item, index) in status" :key="index">
            <button
              class="py-4 px-6 text-center font-medium rtl:font-semibold text-sm capitalize"
              @click.prevent="
                changeFilterStatus(item === 'all' ? null : item, index)
              "
              :class="{
                'active-tab':
                  pagination.is_active === (item === 'all' ? null : item),
              }"
              :disabled="disables[index]"
            >
              <spinner
                size="w-4 h-4 inline-block"
                class="mr-2 rtl:mr-0 rtl:ml-2"
                v-if="disables[index]"
              />
              {{ item }}
            </button>
          </li>
        </ul>
      </template> -->
      <template #head>
        <th>{{ $t("name") }}</th>
        <!-- <th>Status</th> -->
        <th>{{ $t("latest-update") }}</th>
        <th>{{ $t("actions") }}</th>
      </template>
      <template #tbody v-if="!load">
        <TableLoad :rows="3" v-for="i in 4" :key="i" />
      </template>
      <template #tbody v-else-if="load && items.length">
        <tr v-for="(item, index) in items" :key="index">
          <td>
            {{ item.name }}
          </td>

          <td v-html="createTime(item.updated_at || item.created_at)"></td>

          <td>
            <div class="actions-group">
              <!-- <div v-if="hasPermission('update roles')">
                <button
                  class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded"
                  @click.prevent="changeStatus(item.id)"
                  :content="'Change status'"
                  v-tippy
                  :disabled="disables[`status_${item.id}`]"
                >
                  <spinner
                    size="w-4 h-4 inline-block"
                    v-if="disables[`status_${item.id}`]"
                  />
                  <i class="fa-solid fa-right-left" v-else></i>
                </button>
              </div> -->
              <div v-if="hasPermission('edit roles')">
                <button
                  class="w-8 h-8 bg-sky-500 text-white rounded"
                  @click.prevent="showUpdate(item)"
                  :content="'Update'"
                  v-tippy
                >
                  <i class="fa-solid fa-pen"></i>
                </button>
              </div>
              <div v-if="hasPermission('delete roles')">
                <button
                  class="p-2 px-3 text-red-500 font-medium rtl:font-semibold"
                  @click.prevent="deleteItem(item.id)"
                  :disabled="disables[`delete_${item.id}`]"
                >
                  <spinner
                    size="w-4 h-4 inline-block"
                    v-if="disables[`delete_${item.id}`]"
                  />
                  <span v-else> {{ $t("delete") }} </span>
                </button>
              </div>
            </div>
          </td>
        </tr>
      </template>

      <template #tbody v-else>
        <tr>
          <td colspan="100%" class="!text-center">{{ $t("no-data") }}</td>
        </tr>
      </template>
    </Table>
    <Modal
      title="New Role"
      v-if="add_active"
      @close="add_active = $event"
      size="!max-w-screen-md"
    >
      <template>
        <form class="space-y-4 p-5">
          <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div class="form-group space-y-3">
              <label for="name"> {{ $t("name") }} | {{ $t("en") }} </label>
              <input
                type="text"
                name="name"
                id="name"
                v-model="add_data.name"
                v-ltr
              />
              <p class="error">
                {{ (add_errors["name"] || []).join(" ") }}
              </p>
            </div>
            <div class="form-group space-y-3">
              <label for="alt_name">{{ $t("name") }} | {{ $t("ar") }}</label>
              <input
                type="text"
                name="alt_name"
                id="alt_name"
                v-model="add_data.alt_name"
                v-rtl
              />
              <p class="error">
                {{ (add_errors["alt_name"] || []).join(" ") }}
              </p>
            </div>
          </div>
          <!-- <p class="text-sm text-gray-600 font-medium">Permissions</p> -->
          <div class="overflow-x-auto grid">
            <table class="styled-table">
              <thead>
                <tr>
                  <th></th>
                  <th v-for="(item, index) in access" :key="index">
                    {{ item }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-for="permission in permissions">
                  <tr v-if="permission.children">
                    <td colspan="100%" class="!text-center">
                      {{ permission.name }}
                    </td>
                  </tr>
                  <tr v-else>
                    <td>{{ permission.name }}</td>

                    <td
                      v-for="(_access, idx) in access"
                      :key="idx + permission.key"
                    >
                      <div
                        class="checkbox"
                        v-if="permission.access.includes(_access)"
                      >
                        <input
                          type="checkbox"
                          :value="`${_access} ${permission.key}`"
                          :data-key="permission.key"
                          :data-access-key="_access"
                          @change="(e) => handlerChange(e, 'add_data')"
                          v-model="add_data.permissions"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr
                    v-for="(child, index) in permission.children"
                    :key="index"
                  >
                    <td>{{ child.name }}</td>

                    <td v-for="(_access, idx) in access" :key="idx + child.key">
                      <div
                        class="checkbox"
                        v-if="child.access.includes(_access)"
                      >
                        <input
                          type="checkbox"
                          :value="`${_access} ${child.key}`"
                          :data-key="child.key"
                          :data-access-key="_access"
                          @change="(e) => handlerChange(e, 'add_data')"
                          v-model="add_data.permissions"
                        />
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <p class="error">
            {{ (add_errors["permissions"] || []).join(" ") }}
          </p>
          <div>
            <button-with-loader
              :disabled="add_disabled"
              btnClass="bg-primary py-2 px-4 rounded text-xs font-medium rtl:font-semibold text-white"
              @click="addItem"
            >
              Submit
            </button-with-loader>
          </div>
        </form>
      </template>
    </Modal>
    <Modal
      title="Update Role"
      v-if="edit_active"
      @close="edit_active = $event"
      size="!max-w-screen-md"
    >
      <template>
        <form class="space-y-4 p-5">
          <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div class="form-group space-y-3">
              <label for="name"> {{ $t("name") }} | {{ $t("en") }} </label>
              <input
                type="text"
                name="name"
                id="name"
                v-model="edit_data.name"
                v-ltr
              />
              <p class="error">
                {{ (edit_errors["name"] || []).join(" ") }}
              </p>
            </div>
            <div class="form-group space-y-3">
              <label for="alt_name">{{ $t("name") }} | {{ $t("ar") }}</label>
              <input
                type="text"
                name="alt_name"
                id="alt_name"
                v-model="edit_data.alt_name"
                v-rtl
              />
              <p class="error">
                {{ (edit_errors["alt_name"] || []).join(" ") }}
              </p>
            </div>
          </div>
          <!-- <p class="text-sm text-gray-600 font-medium">Permissions</p> -->
          <div class="overflow-x-auto grid">
            <table class="styled-table">
              <thead>
                <tr>
                  <th></th>
                  <th v-for="(item, index) in access" :key="index">
                    {{ item }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-for="permission in permissions">
                  <tr v-if="permission.children">
                    <td colspan="100%" class="!text-center">
                      {{ permission.name }}
                    </td>
                  </tr>
                  <tr v-else>
                    <td>{{ permission.name }}</td>

                    <td
                      v-for="(_access, idx) in access"
                      :key="idx + permission.key"
                    >
                      <div
                        class="checkbox"
                        v-if="permission.access.includes(_access)"
                      >
                        <input
                          type="checkbox"
                          :value="`${_access} ${permission.key}`"
                          :data-key="permission.key"
                          :data-access-key="_access"
                          @change="(e) => handlerChange(e, 'edit_data')"
                          v-model="edit_data.permissions"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr
                    v-for="(child, index) in permission.children"
                    :key="index"
                  >
                    <td>{{ child.name }}</td>

                    <td v-for="(_access, idx) in access" :key="idx + child.key">
                      <div
                        class="checkbox"
                        v-if="child.access.includes(_access)"
                      >
                        <input
                          type="checkbox"
                          :value="`${_access} ${child.key}`"
                          :data-key="child.key"
                          :data-access-key="_access"
                          @change="(e) => handlerChange(e, 'edit_data')"
                          v-model="edit_data.permissions"
                        />
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <p class="error">
            {{ (edit_errors["permissions"] || []).join(" ") }}
          </p>
          <div>
            <button-with-loader
              :disabled="edit_disabled"
              btnClass="bg-primary py-2 px-4 rounded text-xs font-medium rtl:font-semibold text-white"
              @click="editItem"
            >
              Save changes
            </button-with-loader>
          </div>
        </form>
      </template>
    </Modal>
  </div>
</template>

<script>
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";
import Modal from "@/components/basics/Modal.vue";
import Table from "@/components/basics/Table.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  components: { Modal, ButtonWithLoader, Table },
  name: "Roles",
  data() {
    return {
      add_data: {
        permissions: ["show support", "add support"],
      },
      add_errors: {},
      add_error: null,
      add_disabled: false,
      add_active: false,
      edit_data: {
        permissions: [],
      },
      edit_errors: {},
      edit_error: null,
      edit_disabled: false,
      edit_active: false,
      access: ["show", "add", "edit", "update", "delete"],
      permissions: [
        {
          name: "Dashboard",
          key: "dashboard",
          access: ["show"],
        },
        {
          name: "Notifications",
          key: "notifications",
          access: ["show"],
        },
        {
          name: "Inventory",
          key: "inventory",
          access: ["show", "add", "edit", "update", "delete"],
        },
        {
          name: "POS System",
          key: "pos_system",
          access: ["show"],
        },
        {
          name: "Analytics",
          children: [
            {
              name: "Summary",
              key: "summary",
              access: ["show"],
            },
            {
              name: "Reports",
              key: "reports",
              access: ["show"],
            },
            {
              name: "OOS Alerts",
              key: "oos_alerts",
              access: ["show"],
            },
            {
              name: "Invoice sales",
              key: "invoice_sales",
              access: ["show", "update"],
            },
            {
              name: "Item sales",
              key: "item_sales",
              access: ["show"],
            },
            {
              name: "Invoice categories",
              key: "invoice_categories",
              access: ["show"],
            },
            {
              name: "Invoice inventory",
              key: "invoice_inventory",
              access: ["show"],
            },
            {
              name: "Invoice refunds",
              key: "invoice_refunds",
              access: ["show"],
            },
            {
              name: "Incomes",
              key: "incomes",
              access: ["show"],
            },
            {
              name: "Customers",
              key: "customers",
              access: ["show", "add", "edit", "update", "delete"],
            },
            {
              name: "Customer groups",
              key: "customer_groups",
              access: ["show", "add", "edit", "update", "delete"],
            },
          ],
        },
        {
          name: "Accounting",
          children: [
            {
              name: "Chart of accounts",
              key: "accounting_chart",
              access: ["show", "add", "edit", "update", "delete"],
            },

            {
              name: "Journal entires",
              key: "accounting_entries",
              access: ["show", "add", "edit", "update", "delete"],
            },
            {
              name: "Cost centers",
              key: "accounting_centers",
              access: ["show", "add", "edit", "update", "delete"],
            },
          ],
        },

        {
          name: "Inventory actions",
          children: [
            {
              name: "Purchase orders",
              key: "purchase_orders",
              access: ["show", "add", "update"],
            },
            {
              name: "Approve PO",
              key: "approve_purchase_orders",
              access: ["update"],
            },
            {
              name: "Receive PO",
              key: "receive_purchase_orders",
              access: ["update"],
            },
            {
              name: "Cancel PO",
              key: "cancel_purchase_orders",
              access: ["update"],
            },
            {
              name: "Refunds",
              key: "refund_orders",
              access: ["show", "add"],
            },
            {
              name: "Approve refunds",
              key: "approve_refund_orders",
              access: ["update"],
            },
            // {
            //   name: "Receive refunds",
            //   key: "receive_refund_orders",
            //   access: ["update"],
            // },

            {
              name: "Transfers",
              key: "transfer_orders",
              access: ["show", "add"],
            },
            {
              name: "Approve transfers",
              key: "approve_transfer_orders",
              access: ["update"],
            },
            {
              name: "Receive transfers",
              key: "receive_transfer_orders",
              access: ["update"],
            },
            {
              name: "Processing tracks",
              key: "processing_tracks",
              access: ["show", "add"],
            },
            {
              name: "Wastages",
              key: "inventory_wastages",
              access: ["show", "add"],
            },
          ],
        },
        {
          name: "Settings",
          children: [
            {
              name: "Categories group",
              key: "categories_group",
              access: ["show", "add", "edit", "update", "delete"],
            },
            {
              name: "Categories",
              key: "categories",
              access: ["show", "add", "edit", "update", "delete"],
            },
            {
              name: "Sub categories",
              key: "sub_categories",
              access: ["show", "add", "edit", "update", "delete"],
            },

            {
              name: "Suppliers",
              key: "suppliers",
              access: ["show", "add", "edit", "update", "delete"],
            },
            {
              name: "Brands",
              key: "brands",
              access: ["show", "add", "edit", "update", "delete"],
            },
            {
              name: "Wastages",
              key: "wastages",
              access: ["show", "add", "edit", "update", "delete"],
            },
            {
              name: "Locations",
              key: "locations",
              access: ["show", "add", "edit", "update", "delete"],
            },

            {
              name: "POS devices",
              key: "pos_devices",
              access: ["show", "add", "edit", "update", "delete"],
            },
            {
              name: "Users",
              key: "users",
              access: ["show", "add", "edit", "update", "delete"],
            },
            {
              name: "Receipts",
              key: "receipts",
              access: ["show", "update", "edit"],
            },
            {
              name: "Taxes",
              key: "taxes",
              access: ["show", "add", "edit", "update", "delete"],
            },
            {
              name: "Stock count",
              key: "stock_count",
              access: ["show", "add"],
            },
            {
              name: "Sales settings",
              key: "sales_settings",
              access: ["show", "edit"],
            },
            {
              name: "Expenses",
              key: "expenses",
              access: ["show", "add", "edit", "update", "delete"],
            },
            {
              name: "Discounts",
              key: "discounts",
              access: ["show", "add"],
            },
            // {
            //   name: "Custom order types",
            //   key: "custom_order_types",
            //   access: ["show", "add", "edit", "update", "delete"],
            // },

            {
              name: "Safe track",
              key: "safe_track",
              access: ["show"],
            },
            {
              name: "Email configuration",
              key: "email_configuration",
              access: ["show"],
            },
            {
              name: "Roles",
              key: "roles",
              access: ["show", "add", "edit", "update", "delete"],
            },
          ],
        },
      ],
      // item_status: {
      //   1: "bg-sky-500/10 text-sky-500",
      //   0: "bg-red-500/10 text-red-500",
      // },
      // status: ["all", "active", "not active"],
      pagination: {
        is_active: null,
        page: 1,
        sort_key: null,
        sort_type: "asc",
      },
      disables: {},
      items: [],
      load: true,
    };
  },
  created() {
    this.getItems();
  },
  watch: {
    "pagination.page"() {
      this.getItems();
    },
    is_called(val, _) {
      if (val) {
        this.getItems();
      }
    },
    add_active(val, _) {
      if (!val) {
        // const uploader = this.$refs.uploader;
        this.add_data = { permissions: [] };
        this.add_errors = {};
        // uploader.value = null;
      }
    },
  },
  computed: {
    ...mapGetters("table", ["search", "is_called"]),
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("table", ["changeIsCalled"]),
    async getItems() {
      try {
        const { total, total_pages, ...others } = this.pagination;

        const { result } = await this.getRoute({
          name: "roles",
          options: {
            ...others,
            is_active: others.is_active
              ? others.is_active === "active"
                ? 1
                : 0
              : null,
            search_key: this.search,
            store_id: this.store_id,
          },
        });
        const { data, pagination } = result.roles;
        this.items = data;
        this.$set(this.pagination, "total", pagination.total);
        this.$set(this.pagination, "total_pages", pagination.total_pages);
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      } finally {
        this.load = true;
        this.changeIsCalled(false);
      }
    },
    async changeStatus(id) {
      this.$set(this.disables, `status_${id}`, true);
      try {
        const { data } = await this.axios.post(`roles/update_is_active/${id}`);
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.add_errors = res?.data?.message;
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async deleteItem(id) {
      this.$set(this.disables, `delete_${id}`, true);
      try {
        const { data } = await this.axios.post(`roles/delete/${id}`);
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.add_errors = res?.data?.message;
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async addItem() {
      try {
        this.add_disabled = true;
        this.add_errors = {};

        const { data } = await this.axios.post("roles/add", this.add_data);
        this.add_data = {};
        this.add_active = false;
        this.createAlert(data?.message);
        this.getItems();
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.error = responseError;
        }
      } finally {
        this.add_disabled = false;
      }
    },
    async editItem() {
      try {
        this.edit_disabled = true;
        this.edit_errors = {};

        const { data } = await this.axios.post("roles/update", this.edit_data);
        this.edit_data = {};
        this.edit_active = false;
        this.createAlert(data?.message);
        this.getItems();
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.edit_errors = responseError;
        } else {
          this.error = responseError;
        }
      } finally {
        this.edit_disabled = false;
      }
    },
    handlerChange(ev, key) {
      let result;
      const { value, checked, dataset } = ev.target;
      const permissions = this[key].permissions || [];

      if (checked) {
        const hasShow = permissions.some((e) => /show/gi.test(e));
        const { key: _key, accessKey } = dataset;

        if (!hasShow) {
          result = [...permissions, "show " + _key, value];
          this.$set(this[key], "permissions", [...new Set(result)]);
          return;
        }

        result = [...permissions, value];
        this.$set(this[key], "permissions", [...new Set(result)]);
        // this[key] = { ...this[key], permissions: [...new Set(result)] };
      } else {
        this.$set(
          this[key],
          "permissions",
          permissions.filter((e) => e !== value)
        );
      }
    },
    showUpdate(item) {
      this.edit_data = item;
      this.edit_active = true;
      this.edit_errors = {};
    },
  },
};
</script>
