<template>
  <div>
    <div
      class="
        w-full
        max-w-sm
        bg-white
        h-full
        fixed
        top-0
        right-0
        z-20
        hidden
        transition
        cart-container
      "
      ref="cart"
      @click="closeCart"
    >
      <div class="sticky top-0 h-screen flex flex-col z-[5] bg-white">
        <div>
          <header
            class="
              w-full
              border-b border-gray-100
              p-4
              flex
              items-center
              justify-between
            "
          >
            <h3
              class="text-sm text-gray-800 font-semibold rtl:font-bold truncate"
            >
              POS cart
            </h3>
          </header>
        </div>
        <div class="grid flex-1 p-4 w-full overflow-y-auto">
          <div class="">
            <h3
              class="text-sm text-gray-600 font-semibold rtl:font-bold"
              v-if="false"
            >
              Bills
            </h3>
            <div class="gird mt-2 mb-4" v-if="false">
              <div class="flex flex-wrap gap-3">
                <div>
                  <button
                    class="
                      p-2
                      px-3
                      rounded-md
                      bg-primary
                      text-white text-xs
                      font-medium
                      rtl:font-semibold
                    "
                    @click.prevent="addNewInvoice"
                  >
                    <i class="fa-solid fa-plus"></i>
                  </button>
                </div>
                <div v-for="(bill, idx) in invoices" :key="idx">
                  <button
                    class="
                      p-2
                      px-3
                      rounded-md
                      text-xs
                      font-medium
                      rtl:font-semibold
                    "
                    :class="{
                      'bg-primary text-white': currentInvoice === idx,
                      'bg-gray-100 text-gray-500': currentInvoice !== idx,
                    }"
                    @click.prevent="currentInvoice = idx"
                  >
                    #{{ idx + 1 }}
                  </button>
                </div>
              </div>
            </div>
            <div class="">
              <div
                class="
                  flex
                  items-start
                  gap-3
                  mt-4
                  border-b border-gray-100
                  py-4
                "
                v-for="(item, index) in cart || []"
                :key="index"
              >
                <div>
                  <div class="w-14 h-14">
                    <img
                      :src="
                        item.image || require('@/assets/images/placeholder.png')
                      "
                      @error="
                        $event.target.src = require('@/assets/images/placeholder.png')
                      "
                      alt="profile image"
                      class="
                        w-full
                        h-full
                        object-cover
                        rounded-md
                        border border-gray-200
                      "
                    />
                  </div>
                </div>
                <div class="flex-1">
                  <div class="flex items-center gap-2">
                    <div class="flex-1">
                      <h3
                        class="
                          text-sm text-gray-800
                          font-semibold
                          rtl:font-bold
                          line-clamp-2
                        "
                      >
                        {{ item.name_en }}
                      </h3>
                    </div>
                    <div class="self-start">
                      <button
                        class="
                          p-1
                          text-xs text-red-500
                          font-medium
                          rtl:font-semibold
                        "
                        @click.prevent="deleteItem(item.id)"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                  <p
                    class="
                      text-xs text-gray-600
                      font-medium
                      rtl:font-semibold
                      mt-1
                    "
                  >
                    {{
                      Number(
                        item.price *
                          (item.qty || typeof item.qty === "number"
                            ? item.qty
                            : item.weight)
                      ).toFixed(2)
                    }}
                    SAR
                  </p>
                  <div class="mt-2 table">
                    <div class="flex items-center gap-2">
                      <div>
                        <button
                          class="w-8 h-8 rounded bg-primary text-white"
                          @click="controlItemQty('increase', item.id)"
                        >
                          <i class="fa-solid fa-plus text-xs"></i>
                        </button>
                      </div>
                      <div>
                        <input
                          type="number"
                          name="proitem"
                          id="proitem"
                          class="
                            w-14
                            text-xs
                            pr-0
                            rtl:pl-0 rtl:pr-4
                            px-4
                            h-8
                            rounded
                            bg-gray-100
                          "
                          v-model="
                            item[
                              item.qty || typeof item.qty === 'number'
                                ? 'qty'
                                : 'weight'
                            ]
                          "
                        />
                      </div>
                      <div>
                        <button
                          class="w-8 h-8 rounded bg-primary text-white"
                          @click="controlItemQty('decrease', item.id)"
                        >
                          <i class="fa-solid fa-minus text-xs"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer class="w-full p-4 pb-0">
          <table class="w-full">
            <thead class="border-dashed border-b-2 border-gray-200">
              <tr>
                <th
                  class="
                    text-base text-gray-800 text-left
                    font-medium
                    rtl:font-semibold
                    py-2
                    px-4
                  "
                >
                  Products count
                </th>
                <th
                  class="
                    text-gray-400 text-sm text-right
                    font-medium
                    rtl:font-semibold
                    py-2
                    px-4
                  "
                >
                  {{ (cart || []).length }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  class="
                    text-base text-gray-800 text-left
                    font-medium
                    rtl:font-semibold
                    py-2
                    px-4
                  "
                >
                  total
                </td>
                <td
                  class="
                    text-gray-400 text-sm text-right
                    font-medium
                    rtl:font-semibold
                    py-2
                    px-4
                  "
                >
                  {{ getTotal() }}
                </td>
              </tr>
            </tbody>
          </table>
          <div class="w-full p-4 border-t border-gray-200">
            <button
              class="
                w-full
                py-3
                px-4
                rounded-md
                bg-primary
                text-white text-center
                font-medium
                rtl:font-semibold
                text-sm
              "
              @click.prevent="openDoOrder"
              :disabled="!(cart || []).length"
            >
              Checkout {{ getTotal() }} SAR
            </button>
          </div>
        </footer>
      </div>
    </div>
    <Modal
      v-if="customer_active"
      :title="`Checkout - ${getTotal() || 0} SAR`"
      @close="
        (e) => {
          customer_active = e;
          add_data = {
            payment_method: 'CASH',
          };
          add_errors = {};
        }
      "
    >
      <template>
        <div class="w-full space-y-4 p-6">
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="add_errors.customer_name">
                {{ add_errors.customer_name.join(" ") }}
              </p>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Name"
                required
                autocomplete="off"
                v-model="add_data.customer_name"
              />
              <label for="name">Customer name </label>
            </div>
          </div>
          <div class="w-full space-y-3">
            <label
              for="mobile"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              Customer mobile
            </label>
            <vue-tel-input
              id="mobile"
              ref="customerMobile"
              v-model="add_data.customer_mobile"
              @open="handleOpen('customerMobile')"
            ></vue-tel-input>
            <span class="error">
              {{ (add_errors.customer_mobile || []).join(" ") }}
            </span>
          </div>
          <div class="w-full">
            <p class="text-gray-600 font-medium rtl:font-semibold text-sm">
              Payment:
            </p>
            <div class="flex gap-2 flex-wrap items-center mt-4">
              <div
                v-for="(item, index) in payments"
                :key="index"
                class="checkitem"
              >
                <input
                  type="radio"
                  name="payments"
                  :id="item"
                  hidden
                  class="hidden"
                  :value="item.toUpperCase()"
                  v-model="add_data.payment_method"
                />
                <label :for="item" class="space-y-2 border-none w-20 h-12">
                  <img
                    :src="getPaymentImage(item)"
                    alt="payment item"
                    class="w-full h-full object-contain table mx-auto"
                  />
                </label>
              </div>
            </div>
            <p class="error">
              {{ (add_errors.payment_method || []).join(" ") }}
            </p>
          </div>
          <div
            class="flex items-start gap-3 mt-4 border-b border-gray-100 py-4"
            v-for="(item, index) in cart || []"
            :key="index"
          >
            <div>
              <div class="w-14 h-14">
                <img
                  :src="
                    item.image || require('@/assets/images/placeholder.png')
                  "
                  @error="
                    $event.target.src = require('@/assets/images/placeholder.png')
                  "
                  alt="profile image"
                  class="
                    w-full
                    h-full
                    object-cover
                    rounded-md
                    border border-gray-200
                  "
                />
              </div>
            </div>
            <div class="flex-1">
              <div class="flex items-center gap-2">
                <div class="flex-1">
                  <h3
                    class="
                      text-sm text-gray-800
                      font-semibold
                      rtl:font-bold
                      line-clamp-2
                    "
                  >
                    {{ item.name_en }}
                  </h3>
                </div>
                <div class="self-start">
                  <button
                    class="
                      p-1
                      text-xs text-red-500
                      font-medium
                      rtl:font-semibold
                    "
                    @click.prevent="deleteItem(item.id)"
                  >
                    Delete
                  </button>
                </div>
              </div>
              <p
                class="text-xs text-gray-600 font-medium rtl:font-semibold mt-1"
              >
                {{
                  Number(
                    item.price *
                      (item.qty || typeof item.qty === "number"
                        ? item.qty
                        : item.weight)
                  ).toFixed(2)
                }}
                SAR
              </p>
              <p class="error mt-2" v-if="add_errors[`products.${index}.id`]">
                {{ (add_errors[`products.${index}.id`] || []).join(" ") }}
              </p>
            </div>
          </div>
          <table class="w-full styled-table">
            <tbody>
              <tr class="!border-dashed !border-b-2 border-gray-200">
                <th>Products count</th>
                <th class="text-gray-400">
                  {{ (cart || []).length }}
                </th>
              </tr>
              <tr class="bg-transparent">
                <td>total</td>
                <td class="text-gray-400">
                  {{ getTotal() }}
                </td>
              </tr>
            </tbody>
          </table>
          <div class="flex items-start justify-start gap-2 flex-wrap">
            <div class="flex-1">
              <button
                class="
                  w-full
                  py-3
                  px-4
                  text-sm
                  font-medium
                  rtl:font-semibold
                  text-white
                  bg-primary
                  rounded-md
                "
                :disabled="add_disabled"
                @click.prevent="doOrder"
              >
                <i class="fa-solid fa-spinner fa-spin" v-if="add_disabled"></i>
                Pay
              </button>
            </div>
            <div class="flex-1">
              <button
                class="
                  w-full
                  py-3
                  px-4
                  text-sm
                  font-medium
                  rtl:font-semibold
                  bg-gray-200
                  text-gray-600
                  rounded-md
                "
                @click.prevent="
                  () => {
                    customer_active = false;
                    add_data = {
                      payment_method: 'CASH',
                    };
                    add_errors = {};
                  }
                "
              >
                cancel
              </button>
            </div>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from "./Modal.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "PosCart",
  data() {
    return {
      customer_active: false,
      add_data: {
        payment_method: "CASH",
        products: [],
      },
      add_errors: {},
      add_disabled: false,
      payments: ["cash", "visa", "mada"],
      category_search_disabled: [],
      stores: [],
      suppliers: [],
    };
  },

  computed: {
    ...mapGetters("Cart", {
      globalCart: "cart",
    }),

    cart() {
      const user = this.$store.state.user;
      const store_id = parseInt(localStorage.getItem("store")) || null;

      return (
        this.globalCart(`${user.user_type}_${user.id}`)?.filter(
          (e) => e.store?.id === store_id
        ) || []
      );
    },
    user() {
      return this.$store.state.user || {};
    },
  },
  methods: {
    ...mapActions("Cart", ["addItemsInsideCart", "removeItemFromCart"]),
    ...mapActions("getting", ["getRoute"]),

    ...mapMutations("Cart", ["GENERATE_CART"]),

    addToCart(id) {
      const product = this.products.find((e) => e.id === id);
      if (!product) {
        this.createAlert("Invalid product", "error");
        return;
      }

      const currentCart = this.cart;
      const currentProduct = currentCart?.find((e) => e.id === id);
      // Check the quantity of the product before adding it to the cart
      if (currentProduct) {
        if (currentProduct.qty === +product.qty) {
          this.createAlert(`Max quantity is ${product.qty}`, "info");
          return;
        }
      }
      this.addItemsInsideCart({ product, id }).then(() => {
        this.createAlert(
          currentProduct
            ? "Increased new quantity"
            : "Added to cart successfully"
        );
      });
    },
    controlItemQty(type, id) {
      const product = this.cart.find((e) => e.id === id);
      if (!product) {
        this.createAlert("Invalid product", "error");
        return;
      }

      const currentCart = this.cart;
      const currentProduct = currentCart?.find((e) => e.id === id);
      // Check the quantity of the product before adding it to the cart

      // increase
      // decrease
      if (type === "increase") {
        if (currentProduct) {
          if (product.is_meat) {
            if (currentProduct.weight === +product.actual_weight) {
              this.createAlert(
                `Max weight is ${product.actual_weight}`,
                "info"
              );
              return;
            }

            this.addItemsInsideCart({
              product: {
                ...product,
                weight: currentProduct ? currentProduct.weight + 1 : 1,
              },
              id,
            });
            return;
          }
          if (currentProduct.qty >= +product.actual_qty) {
            this.createAlert(`Max quantity is ${product.actual_qty}`, "info");
            return;
          }
        }
        this.addItemsInsideCart({
          product: {
            ...product,
            qty: currentProduct ? currentProduct.qty + 1 : 1,
          },
          id,
        });

        return;
      } else if (type === "decrease") {
        if (currentProduct) {
          if (product.is_meat) {
            if (currentProduct.weight <= 1) {
              this.createAlert(`Min weight is 1`, "info");
              return;
            }
          }

          if (currentProduct.qty <= 1) {
            this.createAlert(`Min quantity is 1`, "info");
            return;
          }
        }

        this.addItemsInsideCart({
          product: {
            ...product,
            type: "decrease",
          },
          id,
        });
      }
    },
    deleteItem(id) {
      const product = this.cart.find((e) => e.id === id);
      if (!product) {
        this.createAlert("Invalid product", "error");
        return;
      }
      this.removeItemFromCart(id).then((result) => this.createAlert(result));
    },
    getTotal() {
      let total = 0;
      this.cart &&
        this.cart.forEach((product) => {
          total +=
            Number(product.price) *
            (product.qty || typeof product.qty === "number"
              ? product.qty
              : product.weight);
        });
      return total.toFixed(2);
    },
    openDoOrder() {
      this.add_data.products = this.cart;
      this.customer_active = true;
    },
    doOrder() {
      this.add_disabled = true;
      this.add_errors = {};

      let fd = new FormData();

      Object.keys(this.add_data).forEach((key) => {
        if (key === "products") {
          this.cart.forEach((product, idx) => {
            const keys = ["id", "qty", "weight"];
            for (const product_key of keys) {
              if (Object.prototype.hasOwnProperty.call(product, product_key)) {
                fd.append(
                  `${key}[${idx}][${product_key}]`,
                  product[product_key]
                );
              }
            }
          });
        } else if (this.add_data[key]) {
          fd.append(key, this.add_data[key]);
        }
      });

      const store = parseInt(localStorage.getItem("store")) || null;
      fd.append("store_id", store);

      return this.axios
        .post("invoices/new", fd)
        .then(({ data }) => {
          const user = this.$store.state.user;
          this.createAlert(data.message);
          this.GENERATE_CART(`${user.user_type}_${user.id}`);
          this.customer_active = false;
          this.add_data = {
            products: [],
            payment_method: "",
          };
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            if (typeof message === "object") {
              this.add_errors = message;
            } else {
              this.createAlert(message, "error");
            }
          }
        })
        .finally(() => {
          this.add_disabled = false;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },
    openCloseCart() {
      this.$refs.cart.classList.toggle("!block");
    },
    closeCart() {
      window.addEventListener("click", (e) => {
        if (e.target === this.$refs.cart) {
          this.$refs.cart.classList.remove("!block");
        }
      });
    },
    searchByCategory(i) {
      this.$set(this.category_search_disabled, i, true);
      this.getProducts().finally(() => {
        this.category_search_disabled.splice(i, 1);
      });
    },
    getPaymentImage(image) {
      try {
        return require(`@/assets/images/${image}.png`);
      } catch (error) {
        return null;
      }
    },
  },
  components: { Modal },
};
</script>