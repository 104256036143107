<template>
  <sub-pages-header :title="$t('add-new')" :center="false">
    <template #action>
      <button-with-loader
        :disabled="add_disabled"
        btnClass="bg-primary py-2 px-4 rounded text-xs font-medium rtl:font-semibold text-white"
        @click="addItem"
        v-show="!!(add_data.name || add_data.alt_name)"
      >
        {{ $t("save-changes") }}
      </button-with-loader>
    </template>
    <form class="space-y-4">
      <card title="Basic information">
        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div class="form-group space-y-3">
            <label>{{ $t("name") }} | {{ $t("ar") }}</label>
            <input type="text" v-model="add_data.name" />
            <p class="form-error">{{ add_errors["name"] }}</p>
          </div>
          <div class="form-group space-y-3">
            <label>{{ $t("name") }} | {{ $t("en") }}</label>
            <input type="text" v-model="add_data.alt_name" />
            <p class="form-error">{{ add_errors["alt_name"] }}</p>
          </div>
        </div>
        <div class="form-group space-y-3">
          <label>{{ $t("ref-no") }}</label>
          <input type="text" v-model="add_data.ref_no" />
          <p class="form-error">{{ add_errors["ref_no"] }}</p>
        </div>
        <div class="form-group space-y-3">
          <label>{{ $t("date") }}</label>
          <input type="date" v-model="add_data.expense_date" />
          <p class="form-error">{{ add_errors["expense_date"] }}</p>
        </div>
        <div class="form-group space-y-3">
          <label>{{ $t("notes") }}</label>
          <textarea v-model="add_data.notes" />
          <p class="form-error">{{ add_errors["notes"] }}</p>
        </div>
        <div class="form-group space-y-3">
          <label>{{ $t("account") }}</label>

          <t-rich-select
            :fetch-options="getAccounts"
            placeholder="Select"
            :text-attribute="'name'"
            value-attribute="id"
            :clearable="true"
            :closeOnSelect="true"
            v-model="add_data.account_id"
          />
          <p class="form-error">
            {{ add_errors["account_id"] }}
          </p>
        </div>
        <div class="form-group space-y-3">
          <label>{{ $t("cost-center") }}</label>

          <t-rich-select
            :fetch-options="getCostCenters"
            placeholder="Select"
            :text-attribute="'name'"
            value-attribute="id"
            :clearable="true"
            :closeOnSelect="true"
            v-model="add_data.cost_center_id"
          />
          <p class="form-error">
            {{ add_errors["cost_center_id"] }}
          </p>
        </div>
        <div class="form-group space-y-3">
          <label>{{ $t("locations") }}</label>

          <t-rich-select
            :options="stores"
            placeholder="Select"
            :text-attribute="'name_' + $i18n.locale"
            value-attribute="id"
            :clearable="true"
            :closeOnSelect="true"
            multiple
            v-model="add_data.store_ids"
          />
          <p class="form-error">
            {{ add_errors["store_ids"] }}
          </p>
        </div>
      </card>
      <card title="Prices & taxes">
        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div class="form-group space-y-3">
            <label>{{ $t("cash-amount") }}</label>
            <input
              type="number"
              v-model="add_data.cash_amount"
              placeholder="0.00"
            />
            <p class="form-error">{{ add_errors["cash_amount"] }}</p>
          </div>
          <div class="form-group space-y-3">
            <label>{{ $t("card-amount") }}</label>
            <input
              type="number"
              v-model="add_data.card_amount"
              placeholder="0.00"
            />
            <p class="form-error">{{ add_errors["card_amount"] }}</p>
          </div>
        </div>
        <div class="form-group-with-check">
          <input
            type="checkbox"
            data-type="switch"
            id="includes_vat"
            v-model="includes_vat"
            :true-value="1"
            :false-value="0"
          />
          <label for="includes_vat">{{ $t("taxable") }}</label>
        </div>

        <slide-transition>
          <div class="space-y-4" v-if="!!includes_vat">
            <div class="form-group space-y-3">
              <label>Tax type</label>
              <select class="custom-select" v-model="add_data.tax_type_id">
                <option value="" disabled selected>-- select --</option>
                <option v-for="tax in taxes" :key="tax.id" :value="tax.id">
                  {{ tax["name_ar"] }} - {{ tax["name_en"] }}
                </option>
              </select>
              <p class="form-error">{{ add_errors["tax_type_id"] }}</p>
            </div>
            <div class="form-group-with-check">
              <input
                type="checkbox"
                data-type="switch"
                id="tax_included"
                v-model="add_data.tax_included"
                :true-value="1"
                :false-value="0"
              />
              <label for="tax_included">{{ $t("tax-inclusive") }}</label>
            </div>

            <!-- <ul
              class="space-y-3 p-5 bg-gray-100 -mx-5 border-t border-t-gray-200"
            >
              <li>
                <div class="flex items-center justify-between gap-3">
                  <p class="text-sm font-medium text-gray-600">Subtotal</p>
                  <p class="text-sm font-medium">
                    {{ generateCurrency(getAmounts?.subtotal || 0) }}
                  </p>
                </div>
              </li>
              <li>
                <div class="flex items-center justify-between gap-3">
                  <p class="text-sm font-medium text-gray-600">Tax</p>
                  <p class="text-sm font-medium">
                    {{ generateCurrency(getAmounts?.tax || 0) }}
                  </p>
                </div>
              </li>
              <li>
                <div
                  class="flex items-center justify-between gap-3 pt-3 border-t border-t-gray-200"
                >
                  <p class="font-medium">Total amount</p>
                  <p class="font-medium">
                    {{ generateCurrency(getAmounts?.total || 0) }}
                  </p>
                </div>
              </li>
            </ul> -->
          </div>
        </slide-transition>
      </card>

      <card :title="$t('attachments')">
        <attachments-uploader multiple v-model="add_data.attachments" />
      </card>
    </form>
  </sub-pages-header>
</template>

<script>
import AttachmentsUploader from "@/components/AttachmentsUploader.vue";
import Card from "@/components/Card.vue";
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";
import Modal from "@/components/basics/Modal.vue";
import SubPagesHeader from "@/components/basics/SubPagesHeader.vue";

import { serialize } from "object-to-formdata";
import { mapActions } from "vuex";

export default {
  components: {
    Card,
    Modal,
    ButtonWithLoader,
    AttachmentsUploader,
    SubPagesHeader,
  },
  name: "NewExpense",
  data() {
    return {
      add_data: {
        ref_no: Date.now(),
        tax_type_id: "",
      },
      accounts: [],
      add_errors: {},
      add_disabled: false,
      show_modals: {},
      taxes: [],
      includes_vat: 1,
      stores: [],
    };
  },
  created() {
    this.getAccounts();
    this.getTaxes();
    this.getStores();
  },
  computed: {
    getAmounts() {
      let tax = 0,
        total = 0,
        subtotal = 0;
      const taxType = this.taxes.find((e) => e.id == this.add_data.tax_type_id);
      const isIncludes = !!this.add_data.tax_included;

      if (!taxType) return;

      const amount = this.add_data.card_amount + this.add_data.cash_amount;
      tax = taxType?.percentage_value / 100;

      tax = isIncludes
        ? amount - amount / (taxType?.percentage_value * 100)
        : amount * tax;
      subtotal = isIncludes ? amount - tax : amount;

      total = isIncludes ? amount : subtotal + tax;

      return { total, subtotal, tax };
    },
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    async getAccounts(q) {
      try {
        const { result } = await this.getRoute({
          name: "accounts/expenses",
          options: {
            search_key: q,
          },
        });

        return { results: result.accounts || [] };
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async getCostCenters(q) {
      try {
        const { result } = await this.getRoute({
          name: "cost_center_groups",
          options: {
            search_key: q,
            is_active: 1,
          },
        });

        return {
          results: new Array().concat(
            ...(result.cost_center_groups || []).map(
              (e) => e.cost_centers || []
            )
          ),
        };
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async getStores() {
      try {
        const { result } = await this.getRoute({
          name: "stores",
          options: {
            is_active: 1,
          },
        });

        this.stores = result.stores || [];
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async getTaxes() {
      try {
        const { result } = await this.getRoute({
          name: "tax_types",
          options: {
            is_active: 1,
          },
        });

        this.taxes = result.tax_types;
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async addItem() {
      try {
        this.add_disabled = true;
        const fd = serialize(
          { ...this.add_data, store_id: this.store_id },
          {
            indices: true,
            booleansAsIntegers: true,
            nullsAsUndefineds: true,
          }
        );
        const { data } = await this.axios.post("expenses/add", fd);
        this.createAlert(data.message);
        this.$router.push("/expenses");
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.createAlert(responseError, "error");
        }
      } finally {
        this.add_disabled = false;
      }
    },
  },
};
</script>
