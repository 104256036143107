<template>
  <div>
    <wrapper>
      <form class="w-full max-w-xl mx-auto space-y-4 mt-10">
        <div class="w-full max-w-[6rem] mx-auto">
          <img
            src="@/assets/images/logo.jpg"
            alt="Auth logo"
            class="w-full h-auto"
          />
        </div>
        <h1 class="text-primary text-4xl capitalize text-center font-bold">
          {{ $t("forgot-password") }}
        </h1>
        <p
          class="text-gray-600 text-center font-medium rtl:font-semibold text-sm"
        >
          {{ $t("forgot-password-content") }}
        </p>
        <div class="w-full space-y-3 !mt-10">
          <p class="text-red-500 font-semibold text-center" v-if="error">
            {{ error }}
          </p>
          <label
            for="email"
            class="font-medium rtl:font-semibold text-gray-600 text-sm"
          >
            {{ $t("email") }}
          </label>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="example@example.com"
            class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium rounded text-sm"
            autocomplete="off"
            v-model="add_data.email"
            v-email
          />
          <span class="error">
            {{ (add_errors.email || []).join(" ") }}
          </span>
        </div>
        <div class="w-full space-y-4">
          <button-with-loader
            btnClass="w-full py-3 px-4 rounded bg-primary
          text-white"
            :disabled="timer || send_disabled"
            @click="sendCode"
            >Send verification code
            <vue-countdown
              :time="timer * 1000"
              v-slot="{ seconds }"
              v-if="timer"
            >
              <span class="text-xs font-semibold">({{ seconds }})</span>
            </vue-countdown>
          </button-with-loader>

          <p
            class="text-gray-600 text-center font-medium rtl:font-semibold text-sm"
          >
            {{ $t("have-code-verify-code") }}
            <router-link
              to="/account/reset-password"
              class="text-primary underline"
            >
              {{ $t("verify-now") }}</router-link
            >
          </p>
          <p
            class="text-gray-600 text-center font-medium rtl:font-semibold text-sm"
          >
            {{ $t("dont-have-an-account") }}
            <router-link to="/account/register" class="text-primary underline">
              {{ $t("sign-up") }}
            </router-link>
          </p>
        </div>
      </form>
    </wrapper>
  </div>
</template>
<script>
import Wrapper from "@/components/auth/Wrapper.vue";
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";
export default {
  components: { Wrapper, ButtonWithLoader },
  name: "ForgotPassword",
  data() {
    return {
      send_disabled: false,
      resend_disabled: false,
      add_data: {
        email: "",
      },
      add_errors: {},
      error: "",
      timer: 0,
    };
  },
  methods: {
    async sendCode() {
      this.send_disabled = true;
      try {
        const { data } = await this.axios.post(
          "reset_password/send",
          this.add_data
        );
        localStorage.setItem("email", this.add_data.email);
        this.createAlert(data.message);
        this.$router.push("/account/reset-password");
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.error = responseError;
        }
      } finally {
        this.send_disabled = false;
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    },
    async resendCode() {
      this.resend_disabled = true;
      try {
        const { data } = await this.axios.post("resend_verify_vendor");

        this.createAlert(data.message);
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.error = responseError;
        }
      } finally {
        this.resend_disabled = false;
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    },
  },
};
</script>
