<template>
  <div class="space-y-4">
    <p class="text-sm text-gray-600">
      {{ name }}
    </p>
    <div
      class="space-y-2 rounded-lg border border-gray-200 overflow-hidden"
      v-for="(item, index) in packages"
      :key="index"
    >
      <div
        class="p-4 border-b-gray-200 flex items-center gap-4 justify-between"
        :class="show_details[index] == index ? 'border-b' : 'border-b-0'"
      >
        <div class="form-group-with-check">
          <input
            type="checkbox"
            :name="generateHash(new Date().getTime().toString())"
            :id="generateHash(new Date().getTime().toString())"
            :value="index"
            v-model="show_details"
          />
          <label
            :for="generateHash(new Date().getTime().toString())"
            class="block"
          >
            {{ item["name_" + $i18n.locale] }}
          </label>
        </div>
        <!-- <button
              class="shrink-0 py-1.5 px-3 rounded bg-red-500 text-white text-xs inline-flex gap-2 items-center"
              @click.prevent="$emit('delete')"
            >
              <i class="fa-solid fa-trash fa-xs align-middle"></i>
              <span> Remove</span>
            </button> -->
      </div>
      <slide-transition>
        <div class="space-y-4 p-4" v-show="show_details[index] == index">
          <prices
            :purchasable="item.is_purchasable"
            :sellable="item.is_sellable"
            :item="price_item"
            :add_errors="{}"
          />
        </div>
      </slide-transition>
    </div>
  </div>
</template>

<script>
import short from "shorthash";
import Prices from "../Prices.vue";
export default {
  components: { Prices },
  name: "StorePrices",
  props: ["add_errors", "name", "packages", "store", "stores"],
  data() {
    return {
      prices: [],
      items: [],
      show_details: [],
      priceInfo: {
        cost_price_includes_vat: 1,
        sale_price_includes_vat: 1,
      },
    };
  },
  computed: {
    generateHash() {
      return (key) => short.unique(key);
    },
    price_item() {
      const storeId = this.store?.id;

      const prices = new Array().concat(...this.items.map((e) => e.prices));
      const price = prices.find((e) => e.store_id == storeId);

      return price;
    },
  },
  watch: {
    packages(val) {
      this.items = val;
      this.updateItemsPrices(this.stores);
    },
    stores(val) {
      this.updateItemsPrices(val);
    },
  },

  mounted() {
    this.updateItemsPrices(this.stores);
  },

  methods: {
    updateItemsPrices(data) {
      const storeIds = data?.map((e) => e.id);
      const newItems = this.items.map((e) => {
        const prices = storeIds.map((s) => {
          const price = e.prices.find((p) => s == p.store_id);
          return price ? price : { ...this.priceInfo, store_id: s };
        });

        return { ...e, prices };
      });

      this.items = newItems;
    },
  },
};
</script>

