import directive from "@/directives/index";
import ClickOutside from "vue-click-outside";
import { mapGetters } from "vuex";
export default {
  created() {
    const locale = this.$cookies.get("locale");
    if (!locale) return;
    this.appendLangElement(locale || "en");
  },
  computed: {
    ...mapGetters("auth", ["getUser"]),
    getApproxPrice() {
      return (actual_price, actual_qty, actual_weight) => {
        const price = parseInt(+actual_price) || 1;
        const qty = parseInt(+actual_qty) || 1;
        const weight = parseInt(+actual_weight) || 1;

        return qty * weight * price;
      };
    },
    GenerateUnit() {
      return (weight = 0) => {
        return new Intl.NumberFormat("en-sa", {
          unit: weight < 1 ? "gram" : "kilogram",
          style: "unit",
          // notation: "compact",
        })
          .format(weight < 1 ? weight * 1000 : weight)

          .toUpperCase();
      };
    },
    getProductsWeights() {
      return (cart) => {
        const products = cart.filter((e) => e.is_meat);
        let total = 0;

        for (const product of products) {
          total += +(product.delivered_weight || product.approx_weight) || 0;
        }
        return total;
      };
    },
    isMeat() {
      return (item) => {
        // const store_module = localStorage.getItem("store_module");
        // const keys = ["weight", "approx_weight"];
        // let result;
        // if (store_module === "butchery") {
        //   result = true;
        //   return result;
        // }

        // for (const key of keys) {
        //   result = false;

        //   if (Object.prototype.hasOwnProperty.call(item, key)) {
        //     result = true;
        //   }
        // }

        return item.unit_type === "weight";
      };
    },
    isMeatWithType() {
      // return item.unit_type === "weight";
      return (item, key) => item.unit_type === "weight";
    },
    hasTrialDays() {
      let result = false;
      const user = this.$store.getters.getUserInfo || {};
      const hasTrial = Object.keys(user).some((e) => /trial/gi.test(e));
      if (hasTrial) {
        result = this.$moment(new Date()).isBefore(user.trial_end_date);
      }
      return result;
    },
    userType() {
      return this.$store.getters["getUserInfo"].user_type;
    },
    hasPermission() {
      return (access) => {
        const user = this.$store.state.user;
        const permissions = user.permissions;

        if (/delete/gi.test(access)) return false;

        if (!permissions) return true;

        if (access instanceof Array) {
          const has = permissions?.some((e) => access.includes(e));
          return has;
        } else {
          return permissions?.includes(access);
        }
      };
    },
    route() {
      return (access) => {
        const routes = this.$router.options?.routes;
        const user = this.$store.state.user;
        const permissions = user.permissions;
        const filterPermissions = access
          ? permissions?.filter((e) => access.includes(e))
          : permissions;
        const route = routes.find((e) =>
          e.meta?.can?.some((c) => filterPermissions?.includes(c))
        );

        return route?.path;
      };
    },
    store_id: {
      get() {
        const location = this.$route.query?.location;
        const user = this.$store.state.user;
        const store = parseInt(localStorage.getItem("store")) || null;
        if (location) return location;
        if (["cashier"].includes(user?.user_type)) return user?.id;
        return store;
      },
      set(val) {
        const store = this.items.find((e) => e.id == val);
        localStorage.setItem("store", val);
        localStorage.setItem("store_module", store?.module?.module_type);
        localStorage.setItem("module", JSON.stringify(store?.module));
        this.createAlert("Location updated successfully");
        window.location.reload();
        // return val;
      },
    },
  },
  mounted() {},
  directives: {
    ClickOutside,
    ...directive,
  },
  methods: {
    GeneratePriceWithoutCode(number) {
      return new Intl.NumberFormat("en-SA").format(number);
    },
    generateFileSize(size = 0) {
      const options = {
        compactDisplay: "short",
        notation: "compact",
        style: "decimal",
      };
      return new Intl.NumberFormat("en-SA", options).format(size);
    },
    multiAngle(icons) {
      icons.forEach((icon) => {
        const regex = /fa-angle-(right|left)/g;
        if (regex.test(icon.className)) {
          icon.className = icon.className.replace(regex, "fa-angle-down");
        } else {
          icon.className = icon.className.replace(
            "fa-angle-down",
            `fa-angle-${
              icon.classList.contains("rtl:block") ? "left" : "right"
            }`
          );
        }
      });
    },
    createImage(file) {
      return URL.createObjectURL(file);
    },
    handleOpen(ref) {
      setTimeout(() => {
        this.$refs[ref].$el.querySelector(
          ".vti__dropdown-list"
        ).style.width = `${this.$refs[ref].$el.offsetWidth}px`;
      }, 5);
    },
    refresh() {
      // Object.keys(this).forEach((e) => {
      //   const functions = e.match(/\bget/gi)
      //   if (functions) {
      //     this.$store.commit('SET_LOAD', true)
      //     this[e]().finally(() => this.$store.commit('SET_LOAD', false))
      //   }
      // })
    },
    openFilter(e) {
      const filterBox = this.$refs.filter;
      const filterIcon = this.$refs.filterIcon;
      if (filterBox.classList.contains("hidden")) {
        filterBox.classList.replace("hidden", "grid");
        filterIcon.classList.replace("fa-angle-down", "fa-angle-up");
      } else {
        filterBox.classList.replace("grid", "hidden");
        filterIcon.classList.replace("fa-angle-up", "fa-angle-down");
      }
    },
    getCurrentDate(_day, idx) {
      const date = new Date();
      let anotherDate = this.addDays(date, idx === 0 ? -1 : idx - 1);
      // console.log(anotherDate)
      return `${anotherDate.getDate()}-${anotherDate.getFullYear()}`;
    },
    addDays(date, days) {
      var result = new Date(date);
      result.setDate(date.getDate() + days);
      return result;
    },
    changeLimitText(count, _content) {
      return `+ ${count}`;
    },
    // countdown transform
    transformTimer(props) {
      Object.entries(props).forEach(([key, value]) => {
        // Adds leading zero
        const digits = value < 10 ? `0${value}` : value;

        props[key] = `${digits}`;
      });

      return props;
    },
    // create alert with options
    createAlert(content, type) {
      // this.$noty.show(content).setType((type ??= 'success'))
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: false,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: (type ??= "success"),
        title: content,
      });
    },
    changeWidth(e) {
      let type = localStorage.getItem("window");
      const containers = document.querySelectorAll(".aside-move");

      containers.forEach((elem) => {
        if (!type) {
          localStorage.setItem("window", "full");
          return;
        }
        if (type === "full") {
          localStorage.setItem("window", "center");
          elem.classList.add("xl:container");
          this.$store.commit("SET_WIDTH", "center");
        } else {
          localStorage.setItem("window", "full");
          elem.classList.remove("xl:container");
          this.$store.commit("SET_WIDTH", "full");
        }
      });
    },
    textPasswordType(e) {
      e.preventDefault();
      let input = e.currentTarget.parentElement.querySelector("input");
      if (input.type === "password") {
        input.setAttribute("type", "text");
        e.currentTarget
          .querySelector("i")
          .classList.replace("fa-eye", "fa-eye-slash");
      } else {
        input.setAttribute("type", "password");
        e.currentTarget
          .querySelector("i")
          .classList.replace("fa-eye-slash", "fa-eye");
      }
    },
    genPassword(el) {
      var chars =
        "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      var passwordLength = 12;
      var password = "";
      for (var i = 0; i <= passwordLength; i++) {
        var randomNumber = Math.floor(Math.random() * chars.length);
        password += chars.substring(randomNumber, randomNumber + 1);
      }
      this.$refs[el].value = password;
    },
    convertImage(file) {
      try {
        const url = URL.createObjectURL(file);

        return url; // return blob file preview :)
      } catch (error) {
        this.createAlert(
          "There is something went wrong while converting current file :/",
          "error"
        );
      }
    },
    changeLang(lang) {
      const locale = this.$cookies.get("locale");
      this.$cookies.set("locale", lang || locale || "en");
      this.appendLangElement(lang || locale || "en");
    },
    appendLangElement(lang) {
      this.$i18n.locale = lang;
      const html = document.documentElement,
        htmlAttrs = {
          lang: lang,
          dir: this.$i18n.locale === "ar" ? "rtl" : "ltr",
        };
      Object.entries(htmlAttrs).forEach(([key, value]) => {
        html.setAttribute(key, value);
      });
    },
    logout() {
      this.$cookies.remove("token");
      this.$store.commit("root/SET_HANDOVER", false);
      localStorage.clear();
      location.reload();
    },
    createTime(time) {
      return time
        ? `<p class="font-medium rtl:font-semibold">${this.$moment(time).format(
            "h:mmA"
          )}</p>
       <p class="text-xs text-gray-600 font-medium rtl:font-semibold">${this.$moment(
         time
       ).format("DD dddd MMM YYYY")}</p>`
        : "N/A";
    },
    checkPermissions(options) {
      if (!("Notification" in window)) {
        alert("This browser does not support desktop notification");
      } else if (Notification.permission === "granted") {
        this.Notify(options);
      } else if (Notification.permission !== "denied") {
        Notification.requestPermission(function (permission) {
          if (!("permission" in Notification)) {
            Notification.permission = permission;
          }

          if (permission === "granted") {
            // this.Notify(options);
            window.location.reload();
          }
        });
      } else {
        this.$store.commit("root/CHANGE_NOTIFICATIONS_ENABLED", false);
      }
    },
    Notify(options) {
      const audio = new Audio("/alert.wav");
      let notification = null;
      audio.play();
      notification = new Notification(options.title, {
        ...options,
        icon: require("@/assets/logo.png"),
        tag: "notify",
      });
      notification.addEventListener("close", () => {
        notification = null;
      });
      this.$store.commit("root/CHANGE_NOTIFICATIONS_ENABLED", true);
    },
    getApproxTotalPrice(items) {
      let total = 0;
      total = items
        .map((e) =>
          this.getApproxPrice(
            e.price,
            e.weight ? 0 : e.qty,
            e.weight ? e.weight : e.approx_weight
          )
        )
        .reduce((a, b) => a + b, 0);
      return total;
    },
    async ExportItems(params, pathname) {
      this.export_disabled = true;
      try {
        const { total, total_pages, page, date, from, to, ...others } = params;
        const path = /export_excel/gi.test(pathname)
          ? pathname
          : pathname + "/export_excel";
        const { data } = await this.axios.get(path, {
          params: {
            ...others,
            from: date?.[0] || from,
            to: date?.[1] || to,
            store_id: this.store_id,
          },
          responseType: "blob",
          headers: {
            "Content-type": "blob",
          },
        });
        let blob = new Blob([data]);
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${pathname}-${new Date().getTime()}.xlsx`;
        link.click();
      } catch (err) {
        console.log(err);
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        // this.createAlert(res?.data?.message, 'error')
        this.createAlert(
          "You cannot export current table at that moment, please try again or contact us through live chat.",
          "error"
        );
      } finally {
        this.export_disabled = false;
      }
    },
    async ExportPDF(params, pathname) {
      try {
        this.download_disabled = true;
        const { total, total_pages, page, date, from, to, ...others } = params;
        const path = /export_pdf/gi.test(pathname)
          ? pathname
          : pathname + "/export_pdf";

        const { data } = await this.axios.post(
          path,
          {},
          {
            params: {
              ...others,
              from: date?.[0] || from,
              to: date?.[1] || to,
              store_id: this.store_id,
            },
            responseType: "blob",
            headers: {
              "Content-type": "blob",
            },
          }
        );
        let blob = new Blob([data]);
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${new Date().getTime()}.pdf`;
        link.click();
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        this.createAlert(responseError, "error");
      } finally {
        this.download_disabled = false;
      }
    },
    generateSomeDigests(length = 1) {
      const min = Number("1".repeat(length));
      const max = Number("9".repeat(length));
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    generateCurrency(value = 0) {
      return new Intl.NumberFormat("en-SA", {
        style: "currency",
        currency: "SAR",
      }).format(+value);
    },
    getDiscountValue(amount = 0, discount = 0, type) {
      let result = 0;
      if (type === "fixed") {
        result = amount - discount;
      } else {
        result = amount - amount * (discount / 100);
      }
      return parseFloat(result.toFixed(2));
    },
  },
};
