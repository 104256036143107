import { Buffer } from "buffer";

export default {
  methods: {
    getTLVForValue(num, value) {
      const tagNum = Buffer.from([num], "utf-8");
      const tagValueLength = Buffer.from([value?.length], "utf-8");
      const tagValue = Buffer.from(value, "utf-8");
      const bufferArray = [tagNum, tagValueLength, tagValue];
      return Buffer.concat(bufferArray);
    },
    generateQRCode(name, vatNumber, invoiceAmount, invoiceVat) {
      const seller = this.getTLVForValue("1", name);
      const vat = this.getTLVForValue("2", vatNumber);
      const timestamp = this.getTLVForValue("3", new Date().toISOString());
      const amount = this.getTLVForValue("4", invoiceAmount);
      const vatAmount = this.getTLVForValue("5", invoiceVat);

      const QRBufferConcat = [seller, vat, timestamp, amount, vatAmount];

      const base64 = Buffer.concat(QRBufferConcat).toString("base64");
      return base64;
    },
  },
};
