<template>
  <div class="p-6">
    <AnalyticsContainerWrapper class="space-y-6">
      <!-- <cards-container :cards="cards" :load="!load"></cards-container> -->
      <filter-wrapper
        @apply="getItems"
        @reset="handleReset"
        ref="filterWrapper"
      >
        <div>
          <t-datepicker
            :placeholder="`From - To`"
            multiple
            :monthsPerView="2"
            v-model="pagination.date"
            range
            :clearable="false"
            userFormat="Y-m-d"
            :weekStart="6"
          />
        </div>
      </filter-wrapper>
      <div class="grid grid-wrapper gap-4">
        <SingleCard
          :load="load"
          v-for="(card, index) in filterCards"
          :key="index"
          :item="card"
        />
      </div>
      <Table
        :title="$t('sales')"
        :records="`${pagination.total || 0} `"
        :pagination="pagination"
        :exportParams="pagination"
        :total_pages="pagination.total_pages"
        v-model="pagination.page"
        has_export
        has_download_pdf
        :fields="fields"
      >
        <template #head>
          <th>#</th>
          <th>{{ $t("invoice-no") }}</th>
          <th>{{ $t("user") }}</th>
          <th>{{ $t("products") }}</th>
          <th v-if="!queries.type">{{ $t("subtotal") }}</th>
          <th v-if="!queries.type || queries.type === 'discount'">
            {{ $t("discount") }}
          </th>
          <th v-if="!queries.type || queries.type === 'vat'">
            {{ $t("vat") }}
          </th>
          <th v-if="['cash', 'card'].includes(queries.type)">
            {{ $t("cash-amount") }}
          </th>
          <th v-if="['cash', 'card'].includes(queries.type)">
            {{ $t("card-amount") }}
          </th>
          <th v-if="queries.type === 'extra_discount'">
            {{ $t("extra-discount") }}
          </th>
          <th>{{ $t("total") }}</th>
          <!-- <th>Payment option</th> -->
          <th>{{ $t("invoice-date") }}</th>
          <th>{{ $t("created-date") }}</th>
          <th>{{ $t("actions") }}</th>
        </template>
        <template #tbody v-if="!load">
          <TableLoad
            :rows="
              !queries.type
                ? 10
                : ['cash', 'card'].includes(queries.type)
                ? 9
                : ['discount', 'vat', 'extra_discount'].includes(queries.type)
                ? 8
                : 9
            "
            v-for="i in 4"
            :key="i"
          />
        </template>
        <template #tbody v-else-if="load && items.length">
          <tr v-for="(item, index) in items" :key="index">
            <!-- <td>
              <p>{{ item.customer_name || "-" }}</p>
              <a
                :href="`tel:${item.customer_mobile}`"
                class="text-sky-600 text-sm"
                v-if="item.customer_mobile"
                >{{ item.customer_mobile }}</a
              >
              <p v-else>-</p>
            </td> -->
            <td>
              <router-link
                :to="`/analytics/invoices/${item.id}`"
                class="text-sky-600"
              >
                {{ item.id }}
              </router-link>
            </td>
            <td>
              {{ item.invoice_no }}
            </td>
            <td>
              <p>{{ (item.user || {}).name || "-" }}</p>
              <a
                :href="`tel:${(item.user || {}).mobile}`"
                class="text-sky-600 text-sm"
                >{{ (item.user || {}).mobile || "-" }}</a
              >
            </td>
            <td>{{ item.products.length }}</td>
            <!-- <td>{{ item.total }} SAR</td> -->
            <td v-if="!queries.type">{{ item.sub_total }} SAR</td>
            <td v-if="!queries.type || queries.type === 'discount'">
              {{ item.discount }} SAR
            </td>
            <td v-if="!queries.type || queries.type === 'vat'">
              {{ item.vat }} SAR
            </td>
            <td v-if="['cash', 'card'].includes(queries.type)">
              {{ item.cash_amount }} SAR
            </td>
            <td v-if="['cash', 'card'].includes(queries.type)">
              {{ item.card_amount }} SAR
            </td>
            <td v-if="queries.type === 'extra_discount'">
              {{ item.extra_discount }} SAR
            </td>

            <td>
              <div class="flex items-center gap-2">
                <div>{{ item.total }} SAR</div>
                <!-- <div v-if="item.payment_method">
                  <img
                    :src="getPaymentImage(item.payment_method)"
                    alt="payment option"
                    width="20"
                    height="15"
                  />
                </div> -->
              </div>
            </td>
            <!-- <td>
              <div class="flex items-center gap-2">
                <div v-if="item.payment_method">
                  <img
                    :src="getPaymentImage(item.payment_method)"
                    alt="payment option"
                    width="20"
                    height="15"
                  />
                </div>
                <div>{{ item.payment_method }}</div>
              </div>
            </td> -->

            <td v-html="createTime(item.invoice_date)"></td>
            <td v-html="createTime(item.created_at)"></td>

            <td>
              <div class="actions-group">
                <!-- <div>
                  <button
                    class="w-8 h-8 bg-red-500/10 text-red-500 rounded group"
                    @click.prevent="refundItem(item.id)"
                    :content="'Refund products'"
                    v-tippy
                    :disabled="disables[`refund_${item.id}`]"
                  >
                    <spinner
                      size="w-4 h-4 inline-block"
                      v-if="disables[`refund_${item.id}`]"
                    />
                    <i
                      class="fa-solid fa-rotate-left group-hover:-rotate-90 transition-transform duration-200"
                      v-else
                    ></i>
                  </button>
                </div> -->
                <div>
                  <button
                    class="py-1.5 px-3 text-sm flex items-center gap-2 text-gray-600 font-medium rtl:font-semibold ring-1 ring-gray-200 bg-white rounded"
                    @click.prevent="ExportPDF(item.id)"
                    :disabled="disables['download_pdf_' + item.id]"
                  >
                    <spinner
                      size="w-4 h-4 inline-block"
                      v-if="disables['download_pdf_' + item.id]"
                    />
                    <i class="fa-solid fa-file-pdf" v-else></i>

                    <span>{{ $t("download") }}</span>
                  </button>
                </div>
                <div>
                  <router-link
                    :to="`/analytics/invoices/${item.id}`"
                    class="w-8 h-8 bg-sky-500 text-white rounded flex items-center justify-center"
                  >
                    <i class="fa-solid fa-eye"></i>
                  </router-link>
                </div>
              </div>
            </td>
          </tr>
        </template>

        <template #tbody v-else>
          <tr>
            <td colspan="100%" class="!text-center">
              {{ $t("no-data") }}
            </td>
          </tr>
        </template>
      </Table>
    </AnalyticsContainerWrapper>
  </div>
</template>

<script>
import AnalyticsContainerWrapper from "@/components/AnalyticsContainerWrapper.vue";
import CardsContainer from "@/components/basics/CardsContainer.vue";
import FilterWrapper from "@/components/basics/FilterWrapper.vue";
import SingleCard from "@/components/basics/SingleCard.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "Invoices",
  components: {
    CardsContainer,
    AnalyticsContainerWrapper,
    FilterWrapper,
    SingleCard,
  },
  data() {
    return {
      items: [],
      load: false,
      cards: [
        {
          title: this.$t("cash-amount"),
          key: "cash_amount",
          icon: "fa-solid fa-wallet",
          value: 0,
          sign: "SAR",
          style: {
            icon: "text-sky-600",
            parent: "bg-sky-600/20",
          },
          url: this.$route.path + "?type=cash",
        },
        {
          title: this.$t("card-amount"),
          key: "card_amount",
          icon: "fa-solid fa-credit-card",
          value: 0,
          sign: "SAR",
          style: {
            icon: "text-sky-600",
            parent: "bg-sky-600/20",
          },
          url: this.$route.path + "?type=card",
        },
        {
          title: this.$t("extra-discount"),
          key: "extra_discount",
          icon: "fa-solid fa-percent",
          value: 0,
          sign: "SAR",
          style: {
            icon: "text-sky-600",
            parent: "bg-sky-600/20",
          },
          url: this.$route.path + "?type=extra_discount",
        },
        {
          title: this.$t("discount"),
          key: "discount",
          icon: "fa-solid fa-percent",
          value: 0,
          sign: "SAR",
          style: {
            icon: "text-sky-600",
            parent: "bg-sky-600/20",
          },
          url: this.$route.path + "?type=discount",
        },
        {
          title: this.$t("vat"),
          key: "vat",
          icon: "fa-solid fa-receipt",
          value: 0,
          sign: "SAR",
          style: {
            icon: "text-sky-600",
            parent: "bg-sky-600/20",
          },
          url: this.$route.path + "?type=vat",
        },
      ],
      pagination: {
        page: 1,
        total: 0,
        total_pages: 0,
      },
      disables: {},
      summary: {},
      fields: [],
    };
  },
  async created() {
    this.$set(this.pagination, "date", [
      this.$route.query.date ||
        this.$moment().add(-1, "days").format("YYYY-MM-DD"),
      this.$route.query.date || this.$moment().format("YYYY-MM-DD"),
    ]);
    Promise.all([await this.getItems()]).finally(() => (this.load = true));
  },
  watch: {
    is_called(val, _) {
      if (val) {
        this.getItems();
      }
    },
    "pagination.page"() {
      this.getItems();
    },
    $route() {
      this.getItems();
    },
  },
  computed: {
    ...mapGetters("table", ["search", "is_called"]),
    queries() {
      return this.$route.query;
    },
    filterCards() {
      return this.cards.map((card) => {
        const summaryValue = this.summary[card.key];
        if (summaryValue) {
          return {
            ...card,
            value: summaryValue,
          };
        }
        return card;
      });
    },
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("table", ["changeIsCalled"]),
    async getItems() {
      try {
        const { total, total_pages, date, ...others } = this.pagination;
        const type = this.queries?.type;
        const typeQueries = type
          ? {
              payment_method: ["cash", "card"].includes(type)
                ? type
                : undefined,
              has_extra_discount: type === "extra_discount" ? 1 : undefined,
              has_discount: type === "discount" ? 1 : undefined,
              has_vat: type === "vat" ? 1 : undefined,
            }
          : {};
        const { result } = await this.getRoute({
          name: "invoices",
          options: {
            ...others,
            search_key: this.search,
            store_id: this.store_id,
            from: date?.[0],
            to: date?.[1],
            ...typeQueries,
          },
        });
        const { data, pagination } = result.invoices;
        this.fields = result.export_fields;
        this.items = data;
        this.summary = result.summary || {};
        this.$set(this.pagination, "total", pagination?.total);
        this.$set(this.pagination, "total_pages", pagination?.total_pages);
        //this.$set(this.pagination,)
        this.pagination = Object.assign({}, this.pagination, typeQueries);
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      } finally {
        const filterWrapper = this.$refs.filterWrapper;
        filterWrapper?.resetAll();
        this.changeIsCalled(false);
      }
    },
    async refundItem(itemId) {
      try {
        const item = this.items.find((e) => e.id === itemId);
        this.$set(this.disables, `refund_${itemId}`, true);

        const { data } = await this.axios.post("invoices/refund", {
          invoice_id: itemId,
          products: item.products.map(({ id, qty }) => {
            return {
              id,
              qty,
            };
          }),
        });
        this.createAlert(data.message);
        this.getItems();
      } catch (error) {
        console.log(error);
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.createAlert(responseError, "error");
        }
      } finally {
        this.disables = {};
      }
    },
    async ExportPDF(itemId) {
      try {
        this.$set(this.disables, `download_pdf_${itemId}`, true);

        const { data } = await this.axios.post(
          "invoices/download/" + itemId,
          {},
          {
            responseType: "blob",
            headers: {
              "Content-type": "blob",
            },
          }
        );
        let blob = new Blob([data]);
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${itemId}-${new Date().getTime()}.pdf`;
        link.click();
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        this.createAlert(responseError, "error");
      } finally {
        this.disables = {};
      }
    },
    getPaymentImage(image) {
      try {
        return require(`@/assets/images/${image.toLowerCase()}.png`);
      } catch (error) {
        return require(`@/assets/images/cash.png`);
      }
    },
    handleReset() {
      const { page, ...others } = this.pagination;

      for (const key in others) {
        const item = others[key];
        if (typeof item === "number") {
          this.$set(this.pagination, key, 0);
        } else if (Array.isArray(item)) {
          this.$set(this.pagination, key, []);
        } else {
          this.$set(this.pagination, key, null);
        }
      }

      this.getItems();
    },
  },
};
</script>
