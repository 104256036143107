<template>
  <div>
    <AlertsContainerWrapper class="p-6">
      <section class="w-full space-y-6">
        <Table
          :title="$t('suppliers')"
          :records="`${items.length} `"
          :pagination="pagination"
          :total_pages="pagination.total_pages"
          v-model="pagination.page"
        >
          <template #actions>
            <div v-if="hasPermission('add suppliers')">
              <button
                class="py-1.5 px-3 text-sm flex items-center gap-2 text-gray-600 font-medium rtl:font-semibold"
                @click.prevent="add_active = !add_active"
              >
                <i class="fa-solid fa-plus"></i>
                <span>{{ $t("add") }}</span>
              </button>
            </div>
          </template>
          <template #filter>
            <ul class="flex items-center">
              <li v-for="(item, index) in status" :key="index">
                <button
                  class="py-4 px-6 text-center font-medium rtl:font-semibold text-sm capitalize"
                  @click.prevent="
                    changeFilterStatus(item === 'all' ? null : item, index)
                  "
                  :class="{
                    'active-tab':
                      pagination.is_active === (item === 'all' ? null : item),
                  }"
                  :disabled="disables[index]"
                >
                  <spinner
                    size="w-4 h-4 inline-block"
                    class="mr-2 rtl:mr-0 rtl:ml-2"
                    v-if="disables[index]"
                  />
                  <!-- {{ $t(item.replace(/\s/g, "_")) }} -->
                  {{ item }}
                </button>
              </li>
            </ul>
          </template>
          <template #head>
            <th>{{ $t("name") }}</th>
            <th>{{ $t("mobile") }}</th>
            <th>{{ $t("email") }}</th>
            <th>{{ $t("orders-count") }}</th>
            <th>{{ $t("products") }}</th>
            <th>{{ $t("status") }}</th>
            <th>{{ $t("latest-update") }}</th>
            <th>{{ $t("actions") }}</th>
          </template>
          <template #tbody v-if="!load">
            <TableLoad :rows="8" v-for="i in 4" :key="i" />
          </template>
          <template #tbody v-else-if="load && items.length">
            <tr v-for="(item, index) in items" :key="index">
              <td>
                <div class="flex items-center gap-3">
                  <div class="w-10 h-10">
                    <img
                      :src="
                        item.image || require('@/assets/images/placeholder.png')
                      "
                      @error="
                        $event.target.src = require('@/assets/images/placeholder.png')
                      "
                      alt="item image"
                      class="w-full h-full rounded-md border object-cover border-gray-100"
                    />
                  </div>
                  <span
                    class="inline-block line-clamp-2 max-w-sm whitespace-normal"
                  >
                    {{ item.name }}
                  </span>
                </div>
              </td>
              <td>
                <a :href="`tel:${item.mobile}`" class="text-sky-600 text-sm">{{
                  item.mobile
                }}</a>
              </td>
              <td>
                <a
                  :href="`mailto:${item.email}`"
                  class="text-sky-600 text-sm"
                  >{{ item.email }}</a
                >
              </td>

              <td>
                {{ item.orders_count || 0 }}
              </td>
              <td>
                {{ item.products_count || 0 }}
              </td>
              <td>
                <p
                  class="status capitalize"
                  :class="item_status[item.is_active]"
                >
                  {{ item.is_active === 1 ? $t("active") : $t("inactive") }}
                </p>
              </td>

              <td v-html="createTime(item.updated_at || item.created_at)"></td>

              <td>
                <div class="actions-group">
                  <div v-if="hasPermission('update suppliers')">
                    <button
                      class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded"
                      @click.prevent="changeStatus(item.id)"
                      :content="'Change status'"
                      v-tippy
                      :disabled="disables[`status_${item.id}`]"
                    >
                      <spinner
                        size="w-4 h-4 inline-block"
                        v-if="disables[`status_${item.id}`]"
                      />
                      <i class="fa-solid fa-right-left" v-else></i>
                    </button>
                  </div>
                  <div v-if="hasPermission('edit suppliers')">
                    <button
                      class="w-8 h-8 bg-sky-500 text-white rounded"
                      @click.prevent="showUpdate(item.id)"
                      :content="'Update'"
                      v-tippy
                    >
                      <i class="fa-solid fa-pen"></i>
                    </button>
                  </div>
                  <div v-if="hasPermission('delete suppliers')">
                    <button
                      class="p-2 px-3 text-red-500 font-medium rtl:font-semibold"
                      @click.prevent="deleteItem(item.id)"
                      :disabled="disables[`delete_${item.id}`]"
                    >
                      <spinner
                        size="w-4 h-4 inline-block"
                        v-if="disables[`delete_${item.id}`]"
                      />
                      <span v-else> {{ $t("delete") }} </span>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </template>

          <template #tbody v-else>
            <tr>
              <td colspan="100%" class="!text-center">
                {{ $t("no-data") }}
              </td>
            </tr>
          </template>
        </Table>
      </section>
      <Modal
        :title="$t('add')"
        v-if="add_active"
        @close="
          (e) => {
            add_active = e;
          }
        "
      >
        <template>
          <form class="p-6 space-y-4">
            <figure class="space-y-4 hidden">
              <slide-transition>
                <figcaption v-if="add_data.image" class="relative table">
                  <img
                    :src="
                      add_data.image
                        ? convertImage(add_data.image)
                        : require('@/assets/images/placeholder.png')
                    "
                    @error="
                      (e) =>
                        (e.target.src = require('@/assets/images/placeholder.png'))
                    "
                    alt="branch image"
                    class="w-auto max-h-[7rem] max-w-[5rem] object-contain"
                  />
                  <button
                    class="w-6 h-6 rounded-full flex items-center justify-center bg-red-500 text-white absolute top-0 right-0 rtl:right-auto rtl:left-0 ring-2 ring-white"
                    type="button"
                    @click.prevent="() => clearImage('add_data')"
                  >
                    <i class="fa-solid fa-xmark fa-sm"></i>
                  </button>
                </figcaption>
              </slide-transition>
              <blockquote class="space-y-2">
                <label class="block">
                  <span class="sr-only">Choose image</span>
                  <input
                    type="file"
                    accept="image/*"
                    class="custom-file"
                    ref="uploader"
                    @change="(e) => $set(add_data, 'image', e.target.files[0])"
                  />
                </label>
                <p class="error" v-if="add_errors.image">
                  {{ add_errors.image.join(" ") }}
                </p>
              </blockquote>
            </figure>
            <div class="form-group">
              <div class="with-label">
                <p class="error" v-if="add_errors.name">
                  {{ add_errors.name.join(" ") }}
                </p>
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Name"
                  required
                  autocomplete="off"
                  v-model="add_data.name"
                />
                <label for="name">{{ $t("name") }} </label>
              </div>
            </div>
            <div class="w-full space-y-3">
              <label
                for="email"
                class="font-medium rtl:font-semibold text-gray-600 text-sm"
              >
                {{ $t("email") }}
              </label>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="example@example.com"
                class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium rounded text-sm"
                autocomplete="off"
                v-model="add_data.email"
              />
              <span class="error">
                {{ (add_errors.email || []).join(" ") }}
              </span>
            </div>
            <div class="w-full space-y-3">
              <label
                for="mobile"
                class="font-medium rtl:font-semibold text-gray-600 text-sm"
              >
                {{ $t("mobile") }}
              </label>
              <vue-tel-input
                id="mobile"
                ref="customerMobile"
                v-model="add_data.mobile"
                @open="handleOpen('customerMobile')"
              ></vue-tel-input>
              <span class="error">
                {{ (add_errors.mobile || []).join(" ") }}
              </span>
            </div>

            <div>
              <button-with-loader
                :disabled="add_disabled"
                btnClass="bg-primary py-2 px-4 rounded text-xs font-medium rtl:font-semibold text-white"
                @click="addItem"
              >
                {{ $t("submit") }}
              </button-with-loader>
            </div>
          </form>
        </template>
      </Modal>
      <Modal
        :title="JSON.parse(JSON.stringify(edit_data.name))"
        v-if="edit_active"
        @close="
          (e) => {
            edit_active = e;
          }
        "
      >
        <template>
          <form class="p-6 space-y-4">
            <figure class="space-y-4 hidden">
              <slide-transition>
                <figcaption v-if="edit_data.image" class="relative table">
                  <img
                    :src="
                      edit_data.image
                        ? typeof edit_data.image === 'object'
                          ? convertImage(edit_data.image)
                          : edit_data.image
                        : require('@/assets/images/placeholder.png')
                    "
                    @error="
                      (e) =>
                        (e.target.src = require('@/assets/images/placeholder.png'))
                    "
                    alt="branch image"
                    class="w-auto max-h-[7rem] max-w-[5rem] object-contain"
                  />
                  <button
                    class="w-6 h-6 rounded-full flex items-center justify-center bg-red-500 text-white absolute top-0 right-0 rtl:right-auto rtl:left-0 ring-2 ring-white"
                    type="button"
                    @click.prevent="() => clearImage('edit_data')"
                  >
                    <i class="fa-solid fa-xmark fa-sm"></i>
                  </button>
                </figcaption>
              </slide-transition>
              <blockquote class="space-y-2">
                <label class="block">
                  <span class="sr-only">Choose image</span>
                  <input
                    type="file"
                    accept="image/*"
                    class="custom-file"
                    ref="uploader"
                    @change="(e) => $set(edit_data, 'image', e.target.files[0])"
                  />
                </label>
                <p class="error" v-if="edit_errors.image">
                  {{ edit_errors.image.join(" ") }}
                </p>
              </blockquote>
            </figure>
            <div class="form-group">
              <div class="with-label">
                <p class="error" v-if="edit_errors.name">
                  {{ edit_errors.name.join(" ") }}
                </p>
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Name"
                  required
                  autocomplete="off"
                  v-model="edit_data.name"
                />
                <label for="name">{{ $t("name") }} </label>
              </div>
            </div>
            <div class="w-full space-y-3">
              <label
                for="email"
                class="font-medium rtl:font-semibold text-gray-600 text-sm"
              >
                {{ $t("email") }}
              </label>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="example@example.com"
                class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium rounded text-sm"
                autocomplete="off"
                v-model="edit_data.email"
              />
              <span class="error">
                {{ (edit_errors.email || []).join(" ") }}
              </span>
            </div>
            <div class="w-full space-y-3">
              <label
                for="mobile"
                class="font-medium rtl:font-semibold text-gray-600 text-sm"
              >
                {{ $t("mobile") }}
              </label>
              <vue-tel-input
                id="mobile"
                ref="customerMobile"
                v-model="edit_data.mobile"
                @open="handleOpen('customerMobile')"
              ></vue-tel-input>
              <span class="error">
                {{ (edit_errors.mobile || []).join(" ") }}
              </span>
            </div>

            <div>
              <button-with-loader
                :disabled="edit_disabled"
                btnClass="bg-primary py-2 px-4 rounded text-xs font-medium rtl:font-semibold text-white"
                @click="editItem"
              >
                {{ $t("save-changes") }}
              </button-with-loader>
            </div>
          </form>
        </template>
      </Modal>
    </AlertsContainerWrapper>
  </div>
</template>
<script>
import AlertsContainerWrapper from "@/components/Inventory/AlertsContainerWrapper.vue";
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";
import Modal from "@/components/basics/Modal.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "Suppliers",
  data() {
    return {
      load: false,
      items: [],
      add_data: {
        user_type: "supplier",
      },
      add_errors: {},
      add_disabled: false,
      add_active: false,

      edit_data: {},
      edit_errors: {},
      edit_disabled: false,
      edit_active: false,

      item_status: {
        1: "bg-sky-500/10 text-sky-500",
        0: "bg-red-500/10 text-red-500",
      },
      status: ["all", "active", "not active"],
      pagination: {
        is_active: "active",
        page: 1,
        sort_key: null,
        sort_type: "asc",
      },
      disables: {},
    };
  },
  async created() {
    Promise.all([await this.getItems()]).finally(() => (this.load = true));
  },
  watch: {
    "pagination.page"() {
      this.getItems();
    },
    is_called(val, _) {
      if (val) {
        this.getItems();
      }
    },
    add_active(val, _) {
      if (!val) {
        const uploader = this.$refs.uploader;
        this.add_data = {};
        this.add_errors = {};
        uploader.value = null;
      }
    },
  },
  computed: {
    ...mapGetters("table", ["search", "is_called"]),
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("table", ["changeIsCalled"]),

    async getItems() {
      try {
        const { total, total_pages, ...others } = this.pagination;

        const { result } = await this.getRoute({
          name: "users",
          options: {
            ...others,
            is_active: others.is_active
              ? others.is_active === "active"
                ? 1
                : 0
              : null,
            search_key: this.search,
            user_type: "supplier",
            store_id: this.store_id,
          },
        });
        const { data, pagination } = result.users;
        this.items = data;
        this.$set(this.pagination, "total", pagination.total);
        this.$set(this.pagination, "total_pages", pagination.total_pages);
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      } finally {
        this.changeIsCalled(false);
      }
    },
    async changeStatus(id) {
      this.$set(this.disables, `status_${id}`, true);
      try {
        const { data } = await this.axios.post(`users/update_is_active/${id}`);
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.add_errors = res?.data?.message;
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async deleteItem(id) {
      this.$set(this.disables, `delete_${id}`, true);
      try {
        const { data } = await this.axios.post(`users/delete/${id}`);
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.add_errors = res?.data?.message;
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async addItem() {
      try {
        this.add_disabled = true;
        this.add_errors = {};
        let fd = new FormData();

        for (const key in this.add_data) {
          const item = this.add_data[key];
          if (["branch_id"].includes(key)) {
            fd.append(key, item?.id);
          } else if (key === "image") {
            if (item) {
              fd.append(key, item, item?.name);
            }
          } else if (key === "mobile") {
            fd.append(key, item?.replace(/[+|\s|()|-]/g, ""));
          } else {
            fd.append(key, item);
          }
        }
        fd.append("user_type", "supplier");

        const { data } = await this.axios.post("users/add", fd);
        this.add_data = {};
        this.add_active = false;
        this.$refs.uploader.value = null;
        this.createAlert(data?.message);
        this.getItems();
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.error = responseError;
        }
      } finally {
        this.add_disabled = false;
      }
    },
    async editItem() {
      try {
        this.edit_disabled = true;
        this.edit_errors = {};
        let fd = new FormData();

        for (const key in this.edit_data) {
          const item = this.edit_data[key];
          if (["branch_id"].includes(key)) {
            fd.append(key, item?.id);
          } else if (key === "image") {
            if (item && typeof item === "object") {
              fd.append(key, item, item?.name);
            } else {
              fd.delete(key);
            }
          } else if (key === "mobile") {
            fd.append(key, item?.replace(/[+|\s|()|-]/g, ""));
          } else {
            fd.append(key, item);
          }
        }
        fd.append("user_type", "supplier");

        const { data } = await this.axios.post("users/update", fd);
        this.edit_data = {};
        this.edit_active = false;
        this.$refs.uploader.value = null;
        this.createAlert(data?.message);
        this.getItems();
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.edit_errors = responseError;
        } else {
          this.error = responseError;
        }
      } finally {
        this.edit_disabled = false;
      }
    },
    clearImage(type) {
      const uploader = this.$refs.uploader;
      if (!uploader) return;
      uploader.value = null;
      this.$set(this[type], "image", null);
    },
    showUpdate(id) {
      const item = this.items.find((e) => e.id === id);
      if (!item) {
        this.createAlert("Item does not existed", "info");
        return;
      }
      this.edit_data = {
        ...item,
        branch_id: item?.branch,
      };
      this.edit_active = true;
    },

    sortTable(key) {
      if (this.pagination.sort_key != key) {
        this.pagination.sort_type = "asc";
      } else {
        this.pagination.sort_type =
          this.pagination.sort_type == "asc" ? "desc" : "asc";
      }
      this.pagination.sort_key = key;
      this.getItems().finally(() => {
        this.createAlert(`Table sorted by ${key}`);
      });
    },

    changeFilterStatus(status, index) {
      this.$set(this.disables, index, true);
      this.$set(this.pagination, "is_active", status);
      this.getItems().finally(() => {
        this.disables = {};
      });
    },
  },
  components: { Modal, ButtonWithLoader, AlertsContainerWrapper },
};
</script>
