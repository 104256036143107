<template>
  <div class="w-full bg-white rounded-xl">
    <header
      class="p-4 border-b border-gray-100 flex flex-col sm:flex-row gap-4 sm:items-center justify-start"
    >
      <div class="flex-1">
        <h1 class="text-primary font-semibold rtl:font-bold">Discounts</h1>
        <p class="text-xs text-gray-500 font-medium rtl:font-semibold">
          Control your discounts
        </p>
      </div>
      <div>
        <button
          class="py-2 px-4 text-sm bg-blue-500 text-white font-medium rtl:font-semibold rounded-md"
          @click="add_active = true"
        >
          Add new discount
        </button>
      </div>
    </header>
    <div class="w-full p-5">
      <div class="w-full grid gap-4 sm:grid-cols-2 lg:grid-cols-3">
        <div class="w-full flex items-start gap-2" v-for="d in 6" :key="d">
          <div>
            <div
              class="p-6 sm:p-8 bg-gray-500 flex items-center flex-col justify-center rounded-md bg-image relative"
            >
              <h1 class="text-3xl font-bold text-white">
                10 <span class="text-sm">%</span>
              </h1>
              <p class="text-sm text-gray-300 text-center">Fixed</p>
            </div>
          </div>
          <div class="flex-1 p-2 space-y-1">
            <h1 class="text-primary font-semibold rtl:font-bold !mb-2">
              Discount name
            </h1>
            <p class="text-xs text-gray-600 font-medium rtl:font-semibold">
              Fixed
            </p>

            <p class="text-xs text-gray-500 font-medium" dir="ltr">
              {{ new Date() | moment("h:mma . MMMM Do YYYY") }}
            </p>
            <div class="flex items-start justify-start gap-2 flex-wrap !mt-4">
              <div>
                <button
                  class="w-full p-2 px-3 text-xs font-medium rtl:font-semibold text-white bg-primary rounded-md"
                  @click="edit_active = true"
                >
                  Edit
                </button>
              </div>
              <div>
                <button
                  class="w-full p-2 px-3 text-xs font-medium rtl:font-semibold bg-gray-200 text-red-500 rounded-md"
                >
                  <i class="fa-solid fa-trash"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal :close="false" v-if="add_active" @close="add_active = $event">
      <template #default>
        <form class="w-full">
          <div class="w-full space-y-3">
            <label
              for="number"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              Discount type
            </label>
            <select
              name="discount_type"
              id="discount_type"
              v-model="add_data.discount_type"
              class="custom-select"
            >
              <option value="" selected disabled>-- Select --</option>
              <option value="percent">Percent</option>
              <option value="fixed">Fixed</option>
            </select>
            <p class="error">
              {{ (add_errors.discount_type || []).join(" ") }}
            </p>
          </div>
          <div class="w-full space-y-3">
            <label
              for="number"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              Discount value
            </label>
            <input
              type="number"
              name="number"
              id="number"
              min="0"
              max="100"
              placeholder="example example"
              class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium rounded"
              autocomplete="off"
              v-model="add_data.discount_value"
            />
            <p class="error">
              {{ (add_errors.discount_value || []).join(" ") }}
            </p>
          </div>
          <div class="w-full space-y-3">
            <label
              for="start_at"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              Start at
            </label>
            <input
              type="date"
              name="start_at"
              id="start_at"
              placeholder="example@example.com"
              class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium rounded"
              autocomplete="off"
              v-model="add_data.start_at"
            />
            <p class="error">
              {{ (add_errors.start_at || []).join(" ") }}
            </p>
          </div>
          <div class="w-full space-y-3">
            <label
              for="end_at"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              End at
            </label>
            <input
              type="date"
              name="end_at"
              id="end_at"
              placeholder="example@example.com"
              class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium rounded"
              autocomplete="off"
              v-model="add_data.end_at"
            />
            <p class="error">
              {{ (add_errors.end_at || []).join(" ") }}
            </p>
          </div>

          <div
            class="mt-8 flex items-center justify-center flex-wrap gap-2 w-full"
          >
            <div class="flex-1">
              <button
                class="py-2.5 px-6 rounded-xl bg-primary text-white font-medium rtl:font-semibold text-sm w-full"
                :disabled="add_disabled"
              >
                <i class="fa-solid fa-spinner fa-spin" v-if="add_disabled"></i>
                Add
              </button>
            </div>
            <div class="flex-1">
              <button
                class="py-2.5 px-6 rounded-xl bg-gray-200 text-gray-600 font-medium rtl:font-semibold text-sm w-full"
                @click.prevent="add_active = false"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </template>
    </Modal>
    <Modal :close="false" v-if="edit_active" @close="edit_active = $event">
      <template #default>
        <form class="w-full">
          <div class="w-full space-y-3">
            <label
              for="number"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              Discount type
            </label>
            <select
              name="discount_type"
              id="discount_type"
              v-model="edit_data.discount_type"
              class="custom-select"
            >
              <option value="" selected disabled>-- Select --</option>
              <option value="percent">Percent</option>
              <option value="fixed">Fixed</option>
            </select>
            <p class="error">
              {{ (edit_errors.discount_type || []).join(" ") }}
            </p>
          </div>
          <div class="w-full space-y-3">
            <label
              for="number"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              Discount value
            </label>
            <input
              type="number"
              name="number"
              id="number"
              min="0"
              max="100"
              placeholder="example example"
              class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium rounded"
              autocomplete="off"
              v-model="edit_data.discount_value"
            />
            <p class="error">
              {{ (edit_errors.discount_value || []).join(" ") }}
            </p>
          </div>
          <div class="w-full space-y-3">
            <label
              for="start_at"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              Start at
            </label>
            <input
              type="date"
              name="start_at"
              id="start_at"
              placeholder="example@example.com"
              class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium rounded"
              autocomplete="off"
              v-model="edit_data.start_at"
            />
            <p class="error">
              {{ (edit_errors.start_at || []).join(" ") }}
            </p>
          </div>
          <div class="w-full space-y-3">
            <label
              for="end_at"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              End at
            </label>
            <input
              type="date"
              name="end_at"
              id="end_at"
              placeholder="example@example.com"
              class="w-full bg-gray-100 py-3 px-4 placeholder:text-gray-500 font-medium rounded"
              autocomplete="off"
              v-model="edit_data.end_at"
            />
            <p class="error">
              {{ (edit_errors.end_at || []).join(" ") }}
            </p>
          </div>

          <div
            class="mt-8 flex items-center justify-center flex-wrap gap-2 w-full"
          >
            <div class="flex-1">
              <button
                class="py-2.5 px-6 rounded-xl bg-primary text-white font-medium rtl:font-semibold text-sm w-full"
                :disabled="edit_disabled"
              >
                <i class="fa-solid fa-spinner fa-spin" v-if="edit_disabled"></i>
                Edit
              </button>
            </div>
            <div class="flex-1">
              <button
                class="py-2.5 px-6 rounded-xl bg-gray-200 text-gray-600 font-medium rtl:font-semibold text-sm w-full"
                @click.prevent="edit_active = false"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </template>
    </Modal>
  </div>
</template>
<script>
import Modal from "@/components/basics/Modal.vue";
export default {
  name: "DiscountSettings",
  components: { Modal },
  data() {
    return {
      add_disabled: false,
      add_data: {
        discount_type: "",
      },
      add_errors: {},
      add_active: false,
      edit_disabled: false,
      edit_data: {
        discount_type: "",
      },
      edit_errors: {},
      edit_active: false,
    };
  },
};
</script>
