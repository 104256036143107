<template>
  <div class="min-h-screen xl:flex flex-row justify-between">
    <side-bar :active="active" ref="sideBar" @close="active = $event" />
    <div class="flex-1 sidebar-move min-h-screen flex flex-col">
      <Navbar />
      <div class="flex-1">
        <slot />
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from "@/components/layouts/Footer.vue";
import Navbar from "@/components/layouts/Navbar.vue";
import SideBar from "@/components/layouts/SideBar.vue";
export default {
  name: "AppWrapper",
  components: {
    SideBar,
    Footer,
    Navbar,
  },
  data() {
    return {
      active: false,
    };
  },
};
</script>
