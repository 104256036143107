<template>
  <div>
    <div class="grid">
      <Tabs link_type="link" :tabs="tabs" />
    </div>
    <slot></slot>
  </div>
</template>

<script>
import Tabs from "../basics/Tabs.vue";

export default {
  name: "CategorizationsContainerWrapper",
  components: { Tabs },
  computed: {
    tabs() {
      const items = [
        {
          title: this.$t("categories-group"),
          url: "/settings/categorizations",
          permissions: "show categories_group",
        },
        {
          title: this.$t("categories"),
          url: "/settings/categorizations/categories",
          permissions: "show categories",
        },
        {
          title: this.$t("sub-categories"),
          url: "/settings/categorizations/sub-categories",
          permissions: "show sub_categories",
        },
      ];

      return items.filter((ev) => this.hasPermission(ev.permissions));
    },
  },
};
</script>
