<template>
  <div>
    <div class="ring-1 ring-gray-200 bg-white rounded overflow-hidden">
      <ul class="flex divide-x divide-gray-200 overflow-x-auto">
        <li
          class="shrink-0 flex-1 whitespace-nowrap"
          v-for="(tab, index) in tabs"
          :key="index"
        >
          <a
            href="#"
            class="flex items-center justify-center flex-col text-gray-600 text-lg font-bold px-6 py-4 border-b border-b-gray-200"
            :class="
              tab.name === current_chart_tab
                ? 'bg-transparent border-b-2 border-b-primary'
                : 'bg-gray-100'
            "
            @click.prevent="current_chart_tab = tab.name"
          >
            <span class="block font-bold">
              {{ collectTotal(tab.name) }}
            </span>
            <span class="text-xs block font-medium">{{ tab.title }}</span>
          </a>
        </li>
      </ul>
      <div class="p-4">
        <apexchart
          type="area"
          height="350"
          :options="{
            ...globalOptions,
            ...chartOptions,
          }"
          :series="series"
        ></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GlobalChart",
  props: {
    charts: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      series: [
        {
          name: this.$t("this-week"),
          data: [],
        },
        {
          name: this.$t("last-week"),
          data: [],
        },
      ],
      globalOptions: {
        dataLabels: {
          enabled: false,
          formatter: function (value, { seriesIndex, dataPointIndex, w }) {
            return w.config.series[seriesIndex].data[dataPointIndex];
          },
        },
        tooltip: {
          y: {
            formatter: function (val, { w }) {
              return val;
            },
          },
          style: {
            fontSize: "12px",
            fontFamily: "Montserrat, Cairo",
          },
          x: {
            show: false,
          },
          marker: {
            show: false,
          },
        },
        xaxis: {
          labels: {
            style: {
              fontSize: "12px",
              fontFamily: "Montserrat, Cairo",
              fontWeight: "bold",
            },
          },
          axisBorder: {
            show: true,
            height: 1,
            width: "100%",
            offsetX: 0,
          },
          axisTicks: {
            show: false,
          },
        },
        theme: {
          palette: "palette8",
        },
      },
      chartOptions: {
        chart: {
          type: "area",
          height: 350,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        stroke: {
          curve: "smooth",
        },
        dataLabels: {
          enabled: true,
        },

        xaxis: {
          type: "category",
          categories: [],
          labels: {
            show: true,
            rotate: -45,
            rotateAlways: false,
            hideOverlappingLabels: true,
            showDuplicates: true,
            trim: false,
          },
        },
        // labels: series.monthDataSeries1.dates,
        yaxis: {
          opposite: false,
          labels: {
            show: true,
            align: "left",
            rotate: -45,
            style: {
              fontSize: "12px",
              fontFamily: "Montserrat, Cairo",
              fontWeight: "bold",
            },
            offsetX: -5,
            offsetY: 10,
          },
        },

        grid: {
          show: true,
          position: "back",
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
          padding: {
            right: 0,
            left: 0,
            top: 0,
            bottom: 0,
          },
        },
        legend: {
          position: "right",
          offsetY: 20,
          horizontalAlign: "left",
          markers: {
            radius: 5,
            width: 10,
            height: 10,
          },
          itemMargin: {
            // horizontal: 10,
            vertical: 5,
          },
        },
        responsive: [
          {
            breakpoint: 678,
            options: {
              cart: {
                height: "auto",
              },
              legend: {
                position: "bottom",
                offsetY: 0,

                itemMargin: {
                  horizontal: 10,
                  vertical: 0,
                },
              },
            },
          },
        ],
      },
      tabs: [
        {
          title: this.$t("purchase-orders"),
          name: "orders",
        },
        {
          title: this.$t("sales"),
          name: "invoices",
        },
      ],
      current_chart_tab: "invoices",
    };
  },
  watch: {
    current_chart_tab() {
      this.DisplayCharts();
    },
  },
  created() {
    this.DisplayCharts();
  },
  computed: {
    collectTotal() {
      return (tab) => {
        const mapData = this.charts.map(
          (c) => c[tab]?.current_week + c[tab]?.past_week
        );
        return mapData.reduce((a, b) => a + b, 0);
      };
    },
  },
  methods: {
    DisplayCharts() {
      let charts = this.charts;
      // const charts = {
      //   current_week: "",
      //   past_week: "",
      // };

      this.$set(
        this.series[0],
        "data",
        charts?.map((chart) => chart?.[this.current_chart_tab]?.current_week)
      );
      this.$set(
        this.series[1],
        "data",
        charts?.map((chart) => chart?.[this.current_chart_tab]?.past_week)
      );
      this.$set(
        this.chartOptions.xaxis,
        "categories",
        charts?.map((chart) => chart.day)
      );
    },
  },
};
</script>
