import HandOverModal from "@/components/basics/HandOverModal";

const plugin = {
  install(Vue) {
    Vue.component("hand-over-modal", HandOverModal);
    Vue.prototype.$handover = HandOverModal.methods;
  },
};

export default plugin;
