<template>
  <router-link :to="page.url" class="w-full inline-flex gap-4 items-center">
    <span
      class="w-14 h-14 rounded-full flex items-center justify-center shrink-0 shadow-2xl shadow-gray-600/30"
      :class="page.style"
    >
      <i class="fa-md" :class="page.icon"></i>
    </span>
    <div class="flex-1 shrink-0 space-y-1">
      <p class="text-base font-semibold text-gray-600">
        {{ page.title }}
      </p>
      <p class="text-sm font-medium text-gray-600" v-if="page.content">
        {{ page.content }}
      </p>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "PagesCard",
  props: {
    page: {
      type: Object,
      default: {},
    },
  },
};
</script>
