<template>
  <li class="space-y-2">
    <div class="flex justify-between gap-3 items-center">
      <button
        type="button"
        class="font-medium shrink-0"
        @click.prevent="show_active = !show_active"
      >
        <slot name="trigger"></slot>
      </button>
      <router-link
        :to="'/accounting/chart/' + id + '/details'"
        class="text-blue-500 hover:underline text-sm whitespace-nowrap"
      >
        <span>Details</span>
      </router-link>
    </div>
    <slide-transition>
      <ul class="tree space-y-3" v-show="show_active">
        <slot></slot>
      </ul>
    </slide-transition>
  </li>
</template>

<script>
export default {
  name: "TreeButton",
  props: { id: { type: [String, Number], default: undefined } },
  data() {
    return {
      show_active: false,
    };
  },
};
</script>
