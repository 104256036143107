<template>
  <div class="space-y-4">
    <SinglePackage
      :item="package_item"
      :add_errors="{}"
      v-for="(package_item, index) in items"
      :key="index"
      @delete="$emit('deletePackage', index)"
      v-model="items[index]"
    />
    <p
      v-if="!items.length"
      class="text-sm font-medium text-center text-gray-600"
    >
      There are no packages added to this product, press on new package button
      above to add new.
    </p>
  </div>
</template>

<script>
import short from "shorthash";
import Prices from "../Prices.vue";
import SinglePackage from "./Package.vue";
export default {
  components: { Prices, SinglePackage },
  name: "PackageComponent",
  props: ["add_errors", "packages"],
  data() {
    return {
      items: [...(this.packages || [])],
      item: {
        image: null,
        cost_price_includes_vat: 1,
        is_purchasable: 1,
        is_sellable: 1,
        sale_price_includes_vat: 1,
        ...this.item,
      },
      show_details: true,
    };
  },
  watch: {
    packages(val) {
      this.items = val;
    },
    items(val) {
      this.$emit("input", this.items);
    },
  },
  computed: {
    generateHash() {
      return (key) => short.unique(key);
    },
  },

  methods: {},
};
</script>

