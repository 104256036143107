<template>
  <div class="p-6">
    <personal-account />
  </div>
</template>

<script>
import PersonalAccount from "@/components/settings/PersonalAccount.vue";
export default {
  components: { PersonalAccount },
  name: "Profile",
};
</script>