<template>
  <div
    class="py-3 px-4 rounded bg-gray-50 border border-gray-200 flex items-center gap-4"
  >
    <p class="text-sm text-gray-600 flex-1 line-clamp-1">
      {{ title }}
    </p>
    <slot name="flag"></slot>
    <div class="inline-flex gap-2 items-center" v-if="hasEdit || hasDelete">
      <button
        type="button"
        v-if="hasEdit"
        class="w-7 h-7 flex items-center justify-center bg-sky-100 rounded"
        v-tippy
        content="Edit"
        @click.prevent="$emit('edit')"
      >
        <i class="fa-solid fa-pen fa-xs text-sky-500"></i>
      </button>
      <button
        type="button"
        v-if="hasDelete"
        class="w-7 h-7 flex items-center justify-center bg-red-100 rounded"
        v-tippy
        content="Delete"
        @click.prevent="$emit('delete')"
      >
        <i class="fa-solid fa-trash fa-xs text-red-500"></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardView",
  props: {
    hasEdit: { type: Boolean, default: false },
    hasDelete: { type: Boolean, default: false },
    title: { type: String, default: undefined },
  },
};
</script>
