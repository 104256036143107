<template>
  <div class="p-6 space-y-6">
    <Table
      :title="$t('taxes')"
      :records="`${pagination.total || 0} `"
      :pagination="pagination"
      :total_pages="pagination.total_pages"
      v-model="pagination.page"
    >
      <!-- <template #actions>
        <div>
          <button
            class="py-1.5 px-3 text-sm flex items-center gap-2 text-gray-600 font-medium rtl:font-semibold"
            @click.prevent="add_active = !add_active"
          >
            <i class="fa-solid fa-plus"></i>
            <span>New type</span>
          </button>
        </div>
      </template> -->
      <template #filter>
        <ul class="flex items-center">
          <li v-for="(item, index) in status" :key="index">
            <button
              class="py-4 px-6 text-center font-medium rtl:font-semibold text-sm capitalize"
              @click.prevent="
                changeFilterStatus(item === 'all' ? null : item, index)
              "
              :class="{
                'active-tab':
                  pagination.is_active === (item === 'all' ? null : item),
              }"
              :disabled="disables[index]"
            >
              <spinner
                size="w-4 h-4 inline-block"
                class="mr-2 rtl:mr-0 rtl:ml-2"
                v-if="disables[index]"
              />
              <!-- {{ $t(item.replace(/\s/g, "_")) }} -->
              {{ item }}
            </button>
          </li>
        </ul>
      </template>
      <template #head>
        <th>{{ $t("name") }}</th>
        <th>{{ $t("fixed-value") }}</th>
        <th>{{ $t("percentage-value") }}</th>
        <th>{{ $t("status") }}</th>
        <th>{{ $t("latest-update") }}</th>
        <th>{{ $t("actions") }}</th>
      </template>
      <template #tbody v-if="!load">
        <TableLoad :rows="6" v-for="i in 4" :key="i" />
      </template>
      <template #tbody v-else-if="load && items.length">
        <tr v-for="(item, index) in items" :key="index">
          <td>
            {{ item["name_" + $i18n.locale] }}
          </td>
          <td>
            {{ item.fixed_value }}
          </td>
          <td>
            {{ item.percentage_value }}
          </td>

          <td>
            <p class="status capitalize" :class="item_status[item.is_active]">
              {{ item.is_active === 1 ? $t("active") : $t("inactive") }}
            </p>
          </td>

          <td v-html="createTime(item.updated_at || item.created_at)"></td>

          <td>
            <div class="actions-group">
              <div class="py-2 px-4" v-if="hasPermission('update taxes')">
                <a
                  href="javascript:void(0)"
                  @click.prevent="
                    () => {
                      changeStatus(item.id).finally(() => hide());
                    }
                  "
                  :disabled="disables[`status_${item.id}`]"
                  class="inline-flex gap-4 text-gray-600 items-center text-sm"
                >
                  <span class="shrink-0">
                    <spinner
                      size="w-4 h-4 inline-block"
                      v-if="disables[`status_${item.id}`]"
                    />

                    <i class="fa-solid fa-right-left" v-else></i>
                  </span>
                  <p class="font-semibold flex-1">Change status</p>
                </a>
              </div>
              <div class="py-2 px-4" v-if="hasPermission('update taxes')">
                <a
                  href="javascript:void(0)"
                  @click.prevent="
                    () => {
                      showUpdateProducts(item.id);
                    }
                  "
                  class="inline-flex gap-4 text-gray-600 items-center text-sm"
                >
                  <span class="shrink-0">
                    <i class="fa-solid fa-cubes"></i>
                  </span>
                  <p class="font-semibold flex-1">{{ $t("products") }}</p>
                </a>
              </div>
            </div>
          </td>
        </tr>
      </template>

      <template #tbody v-else>
        <tr>
          <td colspan="100%" class="!text-center">{{ $t("no-data") }}</td>
        </tr>
      </template>
    </Table>
    <Modal
      :title="$t('add')"
      v-if="add_active"
      @close="
        (e) => {
          add_active = e;
        }
      "
    >
      <template>
        <form class="p-6 space-y-4">
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="add_errors.name_en">
                {{ add_errors.name_en.join(" ") }}
              </p>
              <input
                type="text"
                name="name_en"
                id="name_en"
                placeholder="Name"
                required
                autocomplete="off"
                v-model="add_data.name_en"
                v-ltr
              />
              <label for="name_en">{{ $t("name") }} | {{ $t("en") }}</label>
            </div>
          </div>

          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="add_errors.name_ar">
                {{ add_errors.name_ar.join(" ") }}
              </p>

              <input
                type="text"
                name="name_ar"
                id="name_ar"
                placeholder="الاسم"
                required
                autocomplete="off"
                v-model="add_data.name_ar"
                v-rtl
              />
              <label for="name_ar">{{ $t("name") }} | {{ $t("ar") }}</label>
            </div>
          </div>
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="add_errors.fixed_value">
                {{ add_errors.fixed_value.join(" ") }}
              </p>
              <input
                type="number"
                name="fixed_value"
                id="fixed_value"
                placeholder="0"
                required
                autocomplete="off"
                v-model="add_data.fixed_value"
                min="0"
              />
              <label for="fixed_value">{{ $t("fixed-value") }}</label>
            </div>
          </div>
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="add_errors.percentage_value">
                {{ add_errors.percentage_value.join(" ") }}
              </p>
              <input
                type="number"
                name="percentage_value"
                id="percentage_value"
                placeholder="0"
                required
                autocomplete="off"
                v-model="add_data.percentage_value"
                min="0"
                max="100"
              />
              <label for="percentage_value">{{ $t("percentage-value") }}</label>
            </div>
          </div>

          <div>
            <button-with-loader
              :disabled="add_disabled"
              btnClass="bg-primary py-2 px-4 rounded text-xs font-medium rtl:font-semibold text-white"
              @click="addItem"
            >
              {{ $t("submit") }}
            </button-with-loader>
          </div>
        </form>
      </template>
    </Modal>
    <Modal
      :title="JSON.parse(JSON.stringify(edit_data['name_' + $i18n.locale]))"
      v-if="edit_active"
      @close="
        (e) => {
          edit_active = e;
        }
      "
    >
      <template>
        <form class="p-6 space-y-4">
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="edit_errors.name_en">
                {{ edit_errors.name_en.join(" ") }}
              </p>
              <input
                type="text"
                name="name_en"
                id="name_en"
                placeholder="Name"
                required
                autocomplete="off"
                v-model="edit_data.name_en"
                v-ltr
              />
              <label for="name_en">{{ $t("name") }} | {{ $t("en") }}</label>
            </div>
          </div>

          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="edit_errors.name_ar">
                {{ edit_errors.name_ar.join(" ") }}
              </p>

              <input
                type="text"
                name="name_ar"
                id="name_ar"
                placeholder="الاسم"
                required
                autocomplete="off"
                v-model="edit_data.name_ar"
                v-rtl
              />
              <label for="name_ar">{{ $t("name") }} | {{ $t("ar") }}</label>
            </div>
          </div>
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="edit_errors.fixed_value">
                {{ edit_errors.fixed_value.join(" ") }}
              </p>
              <input
                type="number"
                name="fixed_value"
                id="fixed_value"
                placeholder="0"
                required
                autocomplete="off"
                v-model="edit_data.fixed_value"
                min="0"
              />
              <label for="fixed_value">{{ $t("fixed-value") }}</label>
            </div>
          </div>
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="edit_errors.percentage_value">
                {{ edit_errors.percentage_value.join(" ") }}
              </p>
              <input
                type="number"
                name="percentage_value"
                id="percentage_value"
                placeholder="0"
                required
                autocomplete="off"
                v-model="edit_data.percentage_value"
                min="0"
                max="100"
              />
              <label for="percentage_value">{{ $t("percentage-value") }}</label>
            </div>
          </div>

          <div>
            <button-with-loader
              :disabled="edit_disabled"
              btnClass="bg-primary py-2 px-4 rounded text-xs font-medium rtl:font-semibold text-white"
              @click="editItem"
            >
              {{ $t("save-changes") }}
            </button-with-loader>
          </div>
        </form>
      </template>
    </Modal>
    <Modal
      :title="$t('products')"
      v-if="add_products_show"
      @close="
        (e) => {
          add_products_show = e;
        }
      "
    >
      <template>
        <form class="p-6 space-y-4">
          <div class="form-group">
            <div class="with-label">
              <p class="error" v-if="add_products_errors['product_ids']">
                {{ add_products_errors["product_ids"].join(" ") }}
              </p>
              <select-with-limit
                :options="products"
                placeholder="Select"
                :text="'name_' + $i18n.locale"
                id="id"
                v-model="add_products_data.product_ids"
              />
              <label for="product_ids">{{ $t("products") }}</label>
            </div>
          </div>
          <div>
            <button-with-loader
              :disabled="add_products_disabled"
              btnClass="bg-primary py-2 px-4 rounded text-xs font-medium rtl:font-semibold text-white"
              @click="updateProducts"
            >
              {{ $t("save-changes") }}
            </button-with-loader>
          </div>
        </form>
      </template>
    </Modal>
  </div>
</template>
<script>
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";
import Modal from "@/components/basics/Modal.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "Taxes",
  data() {
    return {
      load: false,
      items: [],
      modules: [],
      add_data: {},
      add_errors: {},
      add_disabled: false,
      add_active: false,

      edit_data: {},
      edit_errors: {},
      edit_disabled: false,
      edit_active: false,

      item_status: {
        1: "bg-sky-500/10 text-sky-500",
        0: "bg-red-500/10 text-red-500",
      },
      status: ["all", "active", "not active"],
      pagination: {
        is_active: null,
        page: 1,
        sort_key: null,
        sort_type: "asc",
      },
      disables: {},
      add_products_data: {
        product_ids: [],
        tax_type_id: null,
      },
      add_products_errors: {},
      add_products_error: null,
      add_products_disabled: false,
      add_products_show: false,
      indexes: [],
      products: [],
    };
  },
  async created() {
    await Promise.all([this.getItems(), this.getProducts()]).finally(
      () => (this.load = true)
    );
  },
  watch: {
    "pagination.page"() {
      this.getItems();
    },
    is_called(val, _) {
      if (val) {
        this.getItems();
      }
    },
    add_active(val, _) {
      if (!val) {
        this.add_data = {};
        this.add_errors = {};
      }
    },
  },
  computed: {
    ...mapGetters("table", ["search", "is_called"]),
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("table", ["changeIsCalled"]),

    async getItems() {
      try {
        const { total, total_pages, ...others } = this.pagination;

        const { result } = await this.getRoute({
          name: "tax_types",
          options: {
            ...others,
            is_active: others.is_active
              ? others.is_active === "active"
                ? 1
                : 0
              : null,
            search_key: this.search,
          },
        });
        const { data, pagination } = result.tax_types;
        this.items = data;
        this.$set(this.pagination, "total", pagination.total);
        this.$set(this.pagination, "total_pages", pagination.total_pages);
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      } finally {
        this.changeIsCalled(false);
      }
    },
    async getProducts() {
      try {
        const { result } = await this.getRoute({
          name: "products",
          options: { is_inventory: 0 },
        });
        this.products = result.products;
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async updateProducts() {
      try {
        this.add_products_disabled = true;
        this.add_products_errors = {};

        const { data } = await this.axios.post(
          "tax_types/update_products",
          this.add_products_data
        );
        this.add_products_data = {};
        this.add_products_show = false;
        this.createAlert(data?.message);

        this.getItems();
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_products_errors = responseError;
        } else {
          this.error = responseError;
        }
      } finally {
        this.add_products_disabled = false;
      }
    },
    showUpdateProducts(id) {
      const item = this.items.find((e) => e.id === id);
      if (!item) {
        this.createAlert("Item does not existed", "info");
        return;
      }
      this.add_products_data = {
        tax_type_id: id,
        product_ids: item?.products?.map((e) => e.id),
      };
      this.add_products_show = true;
    },
    async changeStatus(id) {
      this.$set(this.disables, `status_${id}`, true);
      try {
        const { data } = await this.axios.post(
          `tax_types/update_is_active/${id}`
        );
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.add_errors = res?.data?.message;
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async deleteItem(id) {
      this.$set(this.disables, `delete_${id}`, true);
      try {
        const { data } = await this.axios.post(`tax_types/delete/${id}`);
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.add_errors = res?.data?.message;
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async addItem() {
      try {
        this.add_disabled = true;
        this.add_errors = {};

        const { data } = await this.axios.post("tax_types/add", this.add_data);
        this.add_data = {};
        this.add_active = false;
        this.createAlert(data?.message);
        this.getItems();
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.error = responseError;
        }
      } finally {
        this.add_disabled = false;
      }
    },
    async editItem() {
      try {
        this.edit_disabled = true;
        this.edit_errors = {};

        const { data } = await this.axios.post(
          "tax_types/update",
          this.edit_data
        );
        this.edit_data = {};
        this.edit_active = false;
        this.createAlert(data?.message);
        this.getItems();
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.edit_errors = responseError;
        } else {
          this.error = responseError;
        }
      } finally {
        this.edit_disabled = false;
      }
    },
    clearImage(type) {
      const uploader = this.$refs.uploader;
      if (!uploader) return;
      uploader.value = null;
      this.$set(this[type], "image", null);
    },
    showUpdate(id) {
      const item = this.items.find((e) => e.id === id);
      if (!item) {
        this.createAlert("Item does not existed", "info");
        return;
      }
      this.edit_data = {
        ...item,
        module_ids: item?.modules?.map((e) => e.id),
      };
      this.edit_active = true;
    },

    sortTable(key) {
      if (this.pagination.sort_key != key) {
        this.pagination.sort_type = "asc";
      } else {
        this.pagination.sort_type =
          this.pagination.sort_type == "asc" ? "desc" : "asc";
      }
      this.pagination.sort_key = key;
      this.getItems().finally(() => {
        this.createAlert(`Table sorted by ${key}`);
      });
    },

    changeFilterStatus(status, index) {
      this.$set(this.disables, index, true);
      this.$set(this.pagination, "is_active", status);
      this.getItems().finally(() => {
        this.disables = {};
      });
    },
  },
  components: { Modal, ButtonWithLoader },
};
</script>
