<template>
  <div>
    <div class="min-h-screen hidden lg:flex">
      <div
        class="flex-1 w-full max-w-sm bg-gray-100 min-h-screen border-e border-e-gray-200"
      >
        <div
          class="sticky top-0 z-10 min-h-screen max-h-screen flex flex-col divide-y divide-gray-200"
        >
          <header class="py-4 px-6">
            <div class="space-y-4">
              <div class="flex items-center gap-4">
                <button
                  type="button"
                  @click.prevent="$router.go(-1)"
                  class="text-gray-600 text-sm w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center"
                >
                  <i class="fa-solid fa-arrow-left rtl:hidden"></i>
                  <i class="fa-solid fa-arrow-right hidden rtl:inline"></i>
                </button>
                <p class="text-sm font-medium text-gray-600">
                  {{ $t("customer-info") }}
                </p>
              </div>

              <div class="form-group">
                <input
                  type="text"
                  placeholder="Full name"
                  class="!bg-white"
                  v-model="customer_name"
                />
                <p class="form-error">{{ add_errors["customer_name"] }}</p>
              </div>
              <div class="form-group">
                <input
                  type="tel"
                  placeholder="Mobile number"
                  class="!bg-white"
                  v-model="customer_mobile"
                />
                <p class="form-error">{{ add_errors["customer_mobile"] }}</p>
              </div>
            </div>
          </header>

          <div class="flex-1 overflow-y-auto py-4 px-6">
            <ul class="divide-y divide-gray-200">
              <li
                v-for="product in getProducts"
                :key="product.id"
                class="group"
              >
                <side-product-card :product="product" />
              </li>
              <li v-if="!getProducts.length">
                <empty-card :content="$t('no-data')" />
              </li>
            </ul>
          </div>
          <slide-transition>
            <div
              class="flex justify-between gap-4 px-6 py-2"
              v-if="!!getProducts.length"
            >
              <p class="text-sm font-medium text-gray-600">Total</p>

              <p class="text-sm font-bold">
                {{ generateCurrency(getTotal) }}
              </p>
            </div>
          </slide-transition>

          <slide-transition>
            <footer class="px-6 py-4" v-show="!isDisabled">
              <button
                class="w-full bg-primary text-white py-3 px-4 rounded text-sm"
                type="button"
                @click.prevent="show_modal = true"
              >
                <span>{{ $t("complete-process") }}</span>
              </button>
            </footer>
          </slide-transition>
        </div>
      </div>

      <div
        class="flex-1 w-full flex flex-col min-h-screen max-h-screen divide-y divide-gray-200"
      >
        <header class="space-y-6 grid bg-white">
          <div class="space-y-2 text-center p-6 pb-0">
            <p class="text-3xl font-bold">{{ $t("sales-screen") }}</p>
            <p class="text-base text-gray-500">
              {{ $t("sales-screen-content") }}
            </p>
          </div>
          <div class="p-6 py-0">
            <div class="flex gap-4 items-center">
              <div class="flex-1">
                <searchbox v-model="search_key" />
              </div>
              <slide-transition>
                <button
                  type="button"
                  class="text-red-500 font-medium text-sm"
                  v-show="!!(search_key || category_id)"
                  @click.prevent="clearAll"
                >
                  <span>{{ $t("clear") }}</span>
                </button>
              </slide-transition>
            </div>
          </div>
          <ul
            class="flex overflow-x-auto items-center w-full whitespace-nowrap"
          >
            <li
              v-for="category in categories"
              :key="category.id"
              class="shrink-0"
            >
              <input
                type="radio"
                name="categories"
                :id="category.id"
                :value="category.id"
                v-model="category_id"
                class="peer"
                hidden
                @change.prevent="getItems({ category_id: $event.target.value })"
              />
              <label
                :for="category.id"
                class="cursor-pointer py-2 px-3 text-gray-500 peer-checked:text-white peer-checked:bg-primary peer-checked:font-bold text-sm transition flex items-center gap-3 w-full rounded"
              >
                <img
                  class="w-8 h-8 rounded-full object-cover shrink-0"
                  width="32"
                  height="32"
                  :src="
                    category.image || require('@/assets/images/placeholder.png')
                  "
                  @error="
                    $event.target.src = require('@/assets/images/placeholder.png')
                  "
                  :alt="category['name_' + $i18n.locale]"
                />
                <p>{{ category["name_" + $i18n.locale] }}</p>
              </label>
            </li>
          </ul>
        </header>
        <div class="flex-1 overflow-y-auto p-6 relative">
          <div
            class="absolute inset-0 flex items-center justify-center z-20"
            v-if="!updated"
          >
            <loading-snack />
          </div>
          <div
            class="grid grid-cols-[repeat(auto-fill,minmax(15em,1fr))] gap-4"
          >
            <product-card
              v-for="product in items"
              :product="product"
              :key="product.id"
            />
          </div>
          <slide-transition>
            <empty-card
              v-if="!items.length"
              :content="$t('no-data')"
              icon="fa-solid fa-eye-slash"
            />
          </slide-transition>
        </div>
        <footer class="px-6 py-4">
          <pagination
            :pagination="pagination"
            @input="
              getItems({
                search_key,
                category_id,
                page: $event,
              })
            "
            :disabled="!updated"
          />
        </footer>
      </div>

      <modal
        :title="$t('complete-process')"
        v-show="show_modal"
        @close="show_modal = false"
      >
        <div class="space-y-4 p-6">
          <div class="form-group space-y-3">
            <label>{{ $t("invoice-date") }}</label>
            <input type="date" v-model="invoice_date" />
            <p class="form-error">{{ add_errors["invoice_date"] }}</p>
          </div>
          <p class="text-sm font-medium text-gray-600">{{ $t("payments") }}</p>
          <ul class="divide-y divide-gray-200">
            <li
              v-for="payment in payments"
              :key="payment.key"
              class="py-3 first:pt-0 last:pb-0"
            >
              <div class="flex items-center gap-4">
                <input
                  type="radio"
                  name="payment_option"
                  :value="payment.key"
                  :id="payment.key"
                  class="shrink-0"
                  v-model="payment_method"
                />
                <label :for="payment.key" class="flex-1 block cursor-pointer">
                  <p class="font-semibold">{{ $t(payment.title) }}</p>
                  <p class="text-sm text-gray-500">
                    {{ $t(payment.content) }}
                  </p>
                </label>
                <p class="font-semibold text-sm">
                  {{ generateCurrency(getTotal) }}
                </p>
              </div>
            </li>
          </ul>
          <button-with-loader
            btnClass="w-full bg-primary text-white py-3 px-4 rounded text-sm"
            :disabled="add_disabled"
            @click="generateNewInvoice"
          >
            <span>{{ $t("submit") }}</span>
          </button-with-loader>
        </div>
      </modal>
    </div>

    <div class="block lg:hidden">
      <EmptyCard icon="fa-solid fa-ban" :content="$t('pos-min-screen-size')" />
    </div>
  </div>
</template>

<script>
import { pick } from "lodash";
import { mapActions, mapGetters } from "vuex";
import ButtonWithLoader from "../basics/ButtonWithLoader.vue";
import EmptyCard from "../basics/EmptyCard.vue";
import LoadingSnack from "../basics/LoadingSnack.vue";
import Modal from "../basics/Modal.vue";
import Pagination from "../basics/Pagination.vue";
import Searchbox from "../basics/Searchbox.vue";
import ProductCard from "./ProductCard.vue";
import SideProductCard from "./SideProductCard.vue";

export default {
  components: {
    ButtonWithLoader,
    SideProductCard,
    Searchbox,
    ProductCard,
    EmptyCard,
    LoadingSnack,
    Pagination,
    Modal,
  },
  name: "POSSales",
  data() {
    return {
      payments: [
        {
          title: "cash",
          content: "pay-cash-content",
          key: "CASH",
        },
        {
          title: "card",
          content: "pay-card-content",
          key: "CARD",
        },
      ],
      payment_method: "CASH",
      category_id: null,
      categories: [],
      items: [],
      pagination: {},
      load: true,
      updated: true,
      search_key: null,
      add_errors: {},
      customer_name: null,
      customer_mobile: null,
      add_disabled: false,
      invoice_date: new Date().toISOString().substring(0, 10),
      show_modal: false,
    };
  },
  created() {
    this.load = true;
    Promise.all([this.getCategories(), this.getItems()]).finally(() => {
      this.load = false;
    });
  },
  computed: {
    ...mapGetters("pos_system", ["getProducts", "getDevice", "getTotal"]),
    isDisabled() {
      let disabled = false;
      const customer_name = this.customer_name;
      const customer_mobile = this.customer_mobile;
      const payment_method = this.payment_method;
      const { customer_name_is_required, customer_mobile_is_required } =
        this.getDevice;

      if (!!(!!customer_name_is_required && !customer_name)) {
        disabled = true;
      }

      if (!!(!!customer_mobile_is_required && !customer_mobile)) {
        disabled = true;
      }

      if (!this.getProducts.length) {
        disabled = true;
      }

      if (!payment_method) {
        disabled = true;
      }

      return disabled;
    },
  },
  watch: {
    search_key(val) {
      if (val) {
        this.getItems({ search_key: val });
      }
    },
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    async getCategories() {
      try {
        const { result } = await this.getRoute({
          name: "categories",
          options: {
            store_id: this.store_id,
          },
        });
        const categories = result.categories;
        this.categories = categories;
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async getItems(params) {
      try {
        this.updated = false;
        const paginate = pick(params, ["search_key", "page", "category_id"]);
        const { result } = await this.getRoute({
          name: "products",
          options: {
            is_sellable: 1,
            is_active: 1,
            page: 1,
            // store_id: this.store_id,
            ...paginate,
          },
        });
        const { data, pagination } = result.products;
        this.items = data;
        this.pagination = pagination;
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      } finally {
        this.updated = true;
      }
    },
    clearAll() {
      this.search_key = null;
      this.category_id = null;
      this.getItems();
    },
    async generateNewInvoice() {
      // console.log("invoices");
      try {
        this.add_disabled = true;
        const customer_name = this.customer_name;
        const customer_mobile = this.customer_mobile;
        const products = this.getProducts?.map((product) => ({
          id: product.id,
          ...(product.unit_type == "weight"
            ? { weight: product.qty }
            : { qty: product.qty }),
        }));
        const payment_method = this.payment_method;
        const { customer_name_is_required, customer_mobile_is_required } =
          this.getDevice;

        if (!!(!!customer_name_is_required && !customer_name)) {
          return this.createAlert("Customer name is required", "error");
        }

        if (!!(!!customer_mobile_is_required && !customer_mobile)) {
          return this.createAlert(
            "Customer mobile number is required",
            "error"
          );
        }

        const { data } = await this.axios.post("invoices/new", {
          products,
          customer_mobile,
          customer_name,
          payment_method,
          store_id: this.store_id,
          invoice_date: this.invoice_date,
        });

        this.createAlert(data.message);
        window.location.reload();
      } catch (error) {
        console.log(error);
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.createAlert(responseError, "error");
        }
      } finally {
        this.add_disabled = false;
      }
    },
  },
};
</script>
