<template>
  <div v-if="active">
    <modal>
      <template>
        <form class="p-6 space-y-4">
          <div class="form-group space-y-2">
            <div class="with-label">
              <input
                type="number"
                name="amount"
                id="amount"
                placeholder="1 SAR"
                required
                v-model="add_data.amount"
              />
              <label for="amount">Amount</label>
            </div>
            <p class="error" v-if="add_errors.amount">
              {{ add_errors.amount.join(" ") }}
            </p>
          </div>
          <div
            class="w-full space-y-3"
            v-if="$store.state.user.user_type === 'vendor'"
          >
            <label
              for="store"
              class="font-medium rtl:font-semibold text-gray-600 text-sm"
            >
              Store
            </label>
            <multiselect
              v-model="add_data.store_id"
              :placeholder="'search'"
              :label="`name_${$i18n.locale}`"
              track-by="id"
              :options="stores"
              :hideSelected="true"
              :clearOnSelect="true"
              :disabled="stores.length === 0"
            ></multiselect>
            <span class="error">
              {{ (add_errors.store_id || []).join(" ") }}
            </span>
          </div>
          <div class="form-group space-y-2">
            <div class="with-label">
              <textarea
                name="notes"
                id="notes"
                placeholder="Type your notes"
                v-model="add_data.notes"
              ></textarea>
              <label for="notes">Notes</label>
            </div>
          </div>
          <div class="actions-group !mt-6">
            <div>
              <button-with-loader
                btnClass="w-auto table text-white bg-primary rounded py-3 px-4 text-sm"
                @click="handleOpenSafe"
                :disabled="add_disabled"
              >
                Save changes
              </button-with-loader>
            </div>
          </div>
        </form>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from "./Modal.vue";
export default {
  components: { Modal },
  name: "HandOverModal",
  data() {
    return {
      add_disabled: false,
      add_data: {
        track_type: "open",
        store_id: null,
      },
      add_errors: {},
      active: false,
    };
  },

  methods: {
    async handleOpenSafe() {
      try {
        this.add_disabled = true;
        this.add_errors = {};
        const { data } = await this.axios.post("safe_tracks/open_handover", {
          ...this.add_data,
          store_id: this.add_data.store_id
            ? this.add_data.store_id?.id
            : this.$store.state.user?.id,
        });
        console.log(data);
        this.SET_HANDOVER(false);
        this.is_handover = false;
        this.add_data = {};
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.createAlert(responseError, "error");
        }
      } finally {
        this.add_disabled = false;
      }
    },
    show(type) {
      this.active = true;
      this.add_errors = {};
      this.add_data = {
        store_id: null,
        track_type: type,
      };
    },
    hide(type) {
      this.active = true;
      this.add_errors = {};
      this.add_data = {
        store_id: null,
        track_type: type,
      };
    },
  },
};
</script>
