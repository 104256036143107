<template>
  <div>
    <router-view v-if="!$store.state.token"></router-view>
    <div v-else>
      <router-view v-if="withoutLayout"></router-view>
      <AppWrapper v-else>
        <!-- trial days card -->

        <TrialDaysCounter />
        <!-- ./trial days card -->
        <router-view />
        <!-- pos cart -->
        <PosCart ref="posCart" />
        <!-- ./pos cart -->
      </AppWrapper>
    </div>
  </div>
</template>

<script>
import TrialDaysCounter from "@/components/TrialDaysCounter.vue";
import Modal from "@/components/basics/Modal.vue";
import Footer from "@/components/layouts/Footer.vue";
import Header from "@/components/layouts/Header.vue";
import SideBar from "@/components/layouts/SideBar.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import AppWrapper from "./components/AppWrapper.vue";
import ButtonWithLoader from "./components/basics/ButtonWithLoader.vue";
import PosCart from "./components/basics/PosCart.vue";
export default {
  components: {
    Header,
    Footer,
    Modal,
    ButtonWithLoader,
    SideBar,
    PosCart,
    TrialDaysCounter,
    AppWrapper,
  },
  data() {
    return {
      active: false,
      store_id: parseInt(localStorage.getItem("store")) || null,
      stores: [],
      percentage: 0,
    };
  },
  watch: {
    "$route.name"() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      document.title = this.$route.meta.title;
      this.resetPagination();
      this.updateSearch(undefined);
    },
    getMessage(val) {
      if (val) {
        this.checkPermissions({
          title: "New reply from ticket",
          body: val.body,
        });
      }
    },
  },
  filters: {
    handleDate(value) {
      const hour = new Date(value).getHours();
      if (hour >= 12 && hour < 17) {
        return "Good afternoon";
      } else if (hour < 12) {
        return "Good morning";
      } else if (hour >= 17) {
        return "Good evening";
      } else {
        return "Good evening";
      }
      // return hour;
    },
  },
  computed: {
    ...mapGetters("root", ["is_handover", "is_first", "enabled_notifications"]),

    ...mapGetters({
      getUser: "getUserInfo",
      getMessage: "chat/getMessage",
    }),
    ...mapGetters("Cart", {
      globalCart: "cart",
    }),
    user() {
      return this.$store.state.user || {};
    },
    cart() {
      const user = this.$store.state.user;
      const store_id = parseInt(localStorage.getItem("store")) || null;

      return (
        this.globalCart(`${user.user_type}_${user.id}`)?.filter(
          (e) => e.store?.id === store_id
        ) || []
      );
    },
    day() {
      return this.$moment(new Date(this.user?.trial_end_date)).diff(
        this.$moment(new Date()),
        "days",
        true
      );
    },
    TotalDays() {
      return this.$moment(new Date(this.user?.trial_end_date)).diff(
        this.$moment(this.user?.trial_start_date),
        "days",
        true
      );
    },
    isAfter() {
      const date =
        new Date(this.user?.trial_end_date).getTime() - new Date().getTime();
      return isNaN(date) ? false : date > 0;
    },
    fullPath() {
      let result;
      const path = this.$route.path;

      console.log(path);

      result = path
        .split("/")
        .filter((e) => e)
        .map((e) => '<a href="' + e + '">' + e + "</a>")
        .join("/");

      return result;
    },
    withoutLayout() {
      return [
        "UpdateProduct",
        "CreateProduct",
        "NewJournalEntries",
        "ViewJournalEntryDetails",
        "UpdateJournalEntries",
        "POSSystem",
        "AddExpenses",
        "InvoiceTemplates",
        "NewOpeningBalances",
        "UpdateInventoryProduct",
        "CreateInventoryProduct",
        "CreateBundleProduct",
        "UpdateBundleProduct",
        "OrderDetails",
        "TransferOrderDetails",
        "RefundOrderDetails",
        "InvoiceDetails",
        "ItemProducts",
      ].includes(this.$route.name);
    },
  },
  async created() {
    const token = this.$store.state.token || this.$cookies.get("token");
    if (token) {
      await this.getStores();
      // Promise.all([await this.getStores()]);
      // if (this.is_handover) {
      // this.$handover.show("open");
      // console.log(this.$handover);
      // }
    }
  },

  methods: {
    ...mapMutations("root", ["SET_HANDOVER", "SET_IS_FIRST"]),
    ...mapMutations("chat", ["UPDATE_MESSAGE"]),
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("table", ["resetPagination", "updateSearch"]),
    openWaPopup() {
      document.querySelector(".wa-box").classList.toggle("hidden");
    },
    openBar() {
      this.active = true;
    },
    async getStores() {
      try {
        const { result } = await this.getRoute("stores");
        this.stores = result.stores;
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    openCart() {
      this.$refs.posCart?.openCloseCart();
    },
    openCloseSideBar() {
      const sidebar = this.$refs.sideBar?.$el;
      if (!sidebar) return;
      if (sidebar.classList.contains("opacity-0")) {
        sidebar.classList.remove(
          ...["opacity-0", "pointer-events-none", "invisible", "left-full"]
        );
      } else {
        sidebar.classList.add(
          ...["opacity-0", "pointer-events-none", "invisible", "left-full"]
        );
      }
    },
    async getPercentage() {
      let percentage = await new Promise((resolve) => {
        let interval;
        interval = setInterval(() => {
          let result = 0;
          const totalDays = Math.floor(this.TotalDays);
          const remainingDays = Math.floor(this.day);
          result = (remainingDays / totalDays) * 100;
          if (result === 0) {
            clearInterval(interval);
            return;
          }

          resolve(result);
        }, 1000);
      });
      this.percentage = percentage;
    },
    handleProgress(e) {
      let keys = {},
        needed_keys = ["days", "hours", "minutes", "seconds"],
        done = false;

      for (const key of Object.keys(e).filter((k) => needed_keys.includes(k))) {
        keys[key] = e[key];
      }

      done = Object.values(keys).every((k) => k === 0);
      if (!done) return;
      window.location.reload();
    },
  },
};
</script>
