<template>
  <sub-pages-header :title="details.date || '-'" :center="false">
    <div class="mx-auto table" v-if="load">
      <spinner />
    </div>
    <div class="space-y-4" v-else>
      <card :title="$t('basic-info')">
        <div class="space-y-3">
          <div class="grid grid-cols-1 sm:grid-cols-2 gap-3">
            <div class="space-y-1">
              <p class="text-sm text-gray-500">{{ $t("date") }}</p>
              <p>{{ details.date || "1" }}</p>
            </div>
            <div class="space-y-1">
              <p class="text-sm text-gray-500">{{ $t("total") }}</p>
              <p>{{ generateCurrency(details.total_amount || 0) }}</p>
            </div>
          </div>
          <div class="space-y-1">
            <p class="text-sm text-gray-500">{{ $t("notes") }}</p>
            <p>
              {{ details.notes || "-" }}
            </p>
          </div>
        </div>
      </card>
      <card :title="$t('transactions')">
        <div class="grid overflow-auto">
          <table class="styled-table border border-gray-200 rounded">
            <thead>
              <tr>
                <th>{{ $t("account") }}</th>
                <th>{{ $t("debit") }}</th>
                <th>{{ $t("credit") }}</th>
                <th>{{ $t("notes") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(transaction, index) in details.transactions"
                :key="index"
              >
                <td>{{ transaction.account?.name || "-" }}</td>
                <td>{{ generateCurrency(transaction.debit || 0) }}</td>
                <td>{{ generateCurrency(transaction.credit || 0) }}</td>
                <td>{{ transaction.notes || "-" }}</td>
              </tr>
              <tr v-if="!details.transactions.length">
                <td colspan="100%">
                  <p class="text-center text-sm text-gray-600">
                    {{ $t("no-data") }}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </card>
      <card :title="$t('attachments')">
        <ul class="space-y-2 divide-y divide-gray-200">
          <li
            class="py-3 first:pt-0 last:pb-0"
            v-for="(attachment, index) in details.attachments"
            :key="index"
          >
            <div class="flex justify-between gap-4">
              <div class="grid">
                <p class="text-sm text-gray-600 truncate">
                  {{ attachment }}
                </p>
              </div>
              <a
                :href="attachment"
                target="_black"
                class="text-sm text-blue-600 hover:underline font-medium"
              >
                View
              </a>
            </div>
          </li>
          <li v-if="!details.attachments?.length">
            <p class="text-center text-sm text-gray-600">
              {{ $t("no-data") }}
            </p>
          </li>
        </ul>
      </card>
    </div>
  </sub-pages-header>
</template>

<script>
import SubPagesHeader from "@/components/basics/SubPagesHeader.vue";
import Card from "@/components/Card.vue";
import { mapActions } from "vuex";

export default {
  components: { Card, SubPagesHeader },
  name: "ViewJournalEntryDetails",
  data() {
    return {
      details: {},
      load: true,
      id: this.$route.params.id,
    };
  },
  created() {
    this.getDetails();
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    async getDetails() {
      try {
        this.load = true;
        const { result } = await this.getRoute({
          name: "journal_entries/" + this.id,
        });

        this.details = result.journal_entry || {};
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      } finally {
        this.load = false;
      }
    },
  },
};
</script>
