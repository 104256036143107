<template>
  <section class="w-full p-6 space-y-6">
    <Table
      :title="$t('products')"
      :records="`${pagination.total || 0} `"
      :pagination="pagination"
      :total_pages="pagination.total_pages"
      v-model="pagination.page"
      :fields="fields"
      has_export
      :exportParams="pagination"
    >
      <template #actions>
        <div v-if="hasPermission('add inventory')">
          <t-dropdown
            :classes="{
              dropdown:
                'absolute right-0 rtl:left-0 rtl:right-auto w-screen !max-w-fit rounded ring-1 ring-gray-200 mt-2 overflow-hidden !top-full !bottom-auto bg-white whitespace-nowrap',
            }"
          >
            <div
              slot="trigger"
              slot-scope="{
                mousedownHandler,
                focusHandler,
                blurHandler,
                keydownHandler,
              }"
            >
              <button
                class="text-sm flex items-center gap-2 text-gray-600 font-medium rtl:font-semibold"
                aria-label="Actions"
                aria-haspopup="true"
                @mousedown="mousedownHandler"
                @focus="focusHandler"
                @blur="blurHandler"
                @keydown="keydownHandler"
              >
                <i class="fa-solid fa-ellipsis-vertical"></i>
                <span>{{ $t("more") }}</span>
              </button>
            </div>

            <div slot-scope="{ hide }" class="!w-full !right-0 !left-0">
              <ul
                class="list-none ring-1 ring-gray-300 divide-y divide-gray-200 w-full"
              >
                <li class="py-2 px-4">
                  <router-link
                    to="/inventory-setup/create?type=simple"
                    class="text-sm flex items-center gap-2 text-gray-600 font-medium rtl:font-semibold"
                  >
                    <i class="fa-solid fa-plus"></i>
                    <span>{{ $t("simple-product") }}</span>
                  </router-link>
                </li>
                <li class="py-2 px-4">
                  <router-link
                    to="/inventory-setup/create?type=bundle"
                    class="text-sm flex items-center gap-2 text-gray-600 font-medium rtl:font-semibold"
                  >
                    <i class="fa-solid fa-cubes"></i>
                    <span>{{ $t("product-with-bundle") }}</span>
                  </router-link>
                </li>

                <li class="py-2 px-4">
                  <router-link
                    to="/inventory-setup/create?type=variable"
                    class="text-sm flex items-center gap-2 text-gray-600 font-medium rtl:font-semibold"
                  >
                    <i class="fa-solid fa-circle-dot"></i>
                    <span>{{ $t("product-with-options") }}</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </t-dropdown>
        </div>
      </template>
      <template #filter>
        <ul class="flex items-center">
          <li v-for="(item, index) in status" :key="index">
            <button
              class="py-4 px-6 text-center font-medium rtl:font-semibold text-sm capitalize"
              @click.prevent="
                changeFilterStatus(item === 'all' ? null : item, index)
              "
              :class="{
                'active-tab':
                  pagination.is_active === (item === 'all' ? null : item),
              }"
              :disabled="disables[index]"
            >
              <spinner
                size="w-4 h-4 inline-block"
                class="mr-2 rtl:mr-0 rtl:ml-2"
                v-if="disables[index]"
              />
              {{ item }}
            </button>
          </li>
        </ul>
      </template>
      <template #head>
        <th>{{ $t("product") }}</th>
        <th>{{ $t("price") }}</th>
        <th>{{ $t("stock") }}</th>
        <th>{{ $t("purchasable") }}</th>
        <th>{{ $t("sellable") }}</th>
        <th>{{ $t("category") }}</th>
        <th>{{ $t("status") }}</th>
        <th>{{ $t("latest-update") }}</th>
        <th>{{ $t("actions") }}</th>
      </template>
      <template #tbody v-if="!load">
        <TableLoad :rows="9" v-for="i in 4" :key="i" />
      </template>
      <template #tbody v-else-if="load && items.length">
        <tr v-for="(product, index) in items" :key="index">
          <td>
            <router-link
              :to="
                hasPermission('edit inventory')
                  ? {
                      name: 'UpdateInventoryProduct',
                      params: {
                        slug: product.main_product?.id,
                      },
                    }
                  : '#'
              "
              class="flex items-center gap-3"
            >
              <div>
                <div class="w-10 h-10">
                  <img
                    :src="
                      product.image ||
                      require('@/assets/images/placeholder.png')
                    "
                    @error="
                      $event.target.src = require('@/assets/images/placeholder.png')
                    "
                    alt="product image"
                    class="w-full h-full rounded-md border object-cover border-gray-100"
                  />
                </div>
              </div>
              <div class="flex-1 max-w-fit">
                <p class="line-clamp-2 whitespace-normal text-sky-600">
                  {{ product["name_" + $i18n.locale] }}
                </p>
                <p v-if="product.sku">{{ product.sku }}</p>
              </div>
            </router-link>
          </td>

          <td>
            <span
              :class="product.discount_price ? 'line-through text-red-500' : ''"
              >{{ generateCurrency(product.price) }}</span
            >
            <span v-if="product.discount_price">
              {{ generateCurrency(product.discount_price || 0) }}</span
            >
          </td>

          <!-- <td>{{ product.barcode || "-" }}</td> -->
          <td>
            <span v-if="!product.tracking_stock">UNLIMITED</span>
            <span v-else>
              <span v-if="product.unit_type === 'weight'">
                {{ GenerateUnit(product.stock || 0) }}
              </span>
              <span v-else> {{ product.stock || 0 }} Piece </span>
            </span>
          </td>
          <td>{{ product.is_purchasable ? "Yes" : "No" }}</td>

          <td>{{ product.is_sellable ? "Yes" : "No" }}</td>

          <td>
            {{ (product.category || {})["name_" + $i18n.locale] || "-" }}
          </td>

          <td>
            <p
              class="status capitalize"
              :class="item_status[product.is_active]"
            >
              {{ product.is_active === 1 ? $t("active") : $t("inactive") }}
            </p>
          </td>

          <td
            v-html="createTime(product.updated_at || product.created_at)"
          ></td>

          <td>
            <a
              href="javascript:void(0)"
              @click.prevent="
                () => {
                  changeStatus(product.id).finally(() => hide());
                }
              "
              :disabled="disables[`status_${product.id}`]"
              class="inline-flex gap-4 items-center text-sm bg-sky-50 text-sky-500 rounded py-2 px-3"
              v-tippy
              content="Change status"
            >
              <span class="shrink-0">
                <spinner
                  size="w-4 h-4 inline-block"
                  v-if="disables[`status_${product.id}`]"
                />

                <i class="fa-solid fa-right-left" v-else></i>
              </span>
            </a>
          </td>
        </tr>
      </template>

      <template #tbody v-else>
        <tr>
          <td colspan="100%" class="!text-center">{{ $t("no-data") }}</td>
        </tr>
      </template>
    </Table>

    <Modal
      :title="$t('processing')"
      v-if="process_active"
      @close="process_active = $event"
    >
      <template>
        <form class="p-6 space-y-4">
          <ul class="space-y-4">
            <li v-if="!(process_data.to_products || []).length">
              <p
                class="text-sm text-center italic text-gray-600 font-medium"
                v-if="!process_data?.to_products?.length"
              >
                {{ $t("no-data") }}
              </p>
            </li>
            <li
              v-for="item in process_data.to_products || []"
              :key="item.id"
              v-else
            >
              <div class="flex items-center gap-4">
                <img
                  :src="
                    item.image || require('@/assets/images/placeholder.png')
                  "
                  @error="
                    $event.target.src = require('@/assets/images/placeholder.png')
                  "
                  class="w-10 h-10 rounded-full object-cover shrink-0"
                  alt="product image"
                />
                <div class="flex-1">
                  <p class="text-sm font-medium line-clamp-2">
                    {{ item["name_" + $i18n.locale] }}
                  </p>
                </div>
                <div class="form-group">
                  <input
                    type="number"
                    min="1"
                    placeholder="Value"
                    v-model="item.value"
                    @blur="
                      item.unit_type === 'weight'
                        ? undefined
                        : (item.value = Math.floor(item.value))
                    "
                    autocomplete="off"
                    step="any"
                  />
                </div>
              </div>
            </li>
            <li>
              <div class="form-group space-y-3">
                <label for="product_id"> {{ $t("other-products") }} </label>
                <t-rich-select
                  :fetch-options="getOtherProducts"
                  placeholder="Select"
                  :text-attribute="'name_' + $i18n.locale"
                  value-attribute="id"
                  :clearable="true"
                  :closeOnSelect="true"
                  @change="handleOtherProductsChange"
                />
              </div>
            </li>
            <li>
              <details class="w-full border border-gray-200 rounded p-4">
                <summary class="text-sm font-semibold text-gray-600">
                  {{ $t("wastages") }}
                </summary>
                <ul class="list-none space-y-4 mt-4">
                  <li
                    class="space-y-4"
                    v-for="(wastage, index) in process_data.wastages"
                    :key="index"
                  >
                    <div class="form-group space-y-3">
                      <label for="wastage_id"> Wastage </label>
                      <t-rich-select
                        :options="filterWastages"
                        placeholder="Select"
                        :text-attribute="'name_' + $i18n.locale"
                        value-attribute="id"
                        :clearable="true"
                        :closeOnSelect="true"
                        v-model="process_data.wastages[index].id"
                      />
                      <p class="error">
                        {{
                          (process_errors[`wastages.${index}.id`] || []).join(
                            " "
                          )
                        }}
                      </p>
                    </div>
                    <div class="form-group space-y-3">
                      <label for="weight"> {{ $t("weight") }} (KG) </label>
                      <div class="flex items-stretch gap-3">
                        <input
                          type="number"
                          name="weight"
                          id="weight"
                          v-model="process_data.wastages[index].value"
                          placeholder="1"
                          min="1"
                          class="flex-1"
                        />
                        <button
                          class="w-10 rounded bg-red-500 text-white shrink-0"
                          :disabled="process_data.wastages.length <= 1"
                          @click.prevent="removeCurrentItem(index)"
                        >
                          <i class="fa-solid fa-minus fa-sm"></i>
                        </button>
                      </div>
                      <p class="error">
                        {{
                          (
                            process_errors[`wastages.${index}.value`] || []
                          ).join(" ")
                        }}
                      </p>
                    </div>
                  </li>
                  <li
                    v-if="
                      (
                        process_data.wastages[
                          process_data.wastages.length - 1
                        ] || {}
                      ).id || filterWastages.length
                    "
                  >
                    <button
                      class="bg-teal-600 text-white text-xs rounded font-semibold py-2 px-4"
                      @click.prevent="addNewItem"
                    >
                      {{ $t("add") }}
                    </button>
                  </li>
                </ul>
              </details>
            </li>
          </ul>
          <p
            class="error"
            v-html="Object.values(process_errors).join('<br />')"
          ></p>
          <button-with-loader
            btnClass="w-auto py-2 px-4 rounded bg-primary
          text-white text-xs [margin-inline-start:auto]"
            :disabled="process_disabled"
            @click="updateProductProcess"
            v-if="process_data?.to_products?.length"
            >Save changes</button-with-loader
          >
        </form>
      </template>
    </Modal>
    <Modal title="Sync items" v-if="sync_active" @close="sync_active = $event">
      <template>
        <form class="space-y-4">
          <p
            class="text-sm text-center italic text-gray-600 font-medium p-6"
            v-if="!sync_products?.length"
          >
            {{ $t("no-data") }}
          </p>

          <ul class="divide-y divide-gray-100" v-else>
            <li class="py-3 px-6">
              <div class="flex gap-4">
                <div class="form-group flex-1">
                  <div class="with-icon">
                    <input
                      type="text"
                      name="search"
                      id="search"
                      placeholder="Search.."
                      class="!py-3 ring-1 ring-gray-200 !rounded"
                      v-model.trim="sync_search_key"
                    />
                    <span class="input-icon">
                      <i class="fa-solid fa-search"></i>
                    </span>
                  </div>
                </div>
                <button
                  class="py-3 px-4 rounded bg-gray-200 text-gray-600 text-sm"
                  @click.prevent="selectAllItemsToSync"
                >
                  <span
                    >{{ $t("select-all") }} ({{
                      filterSyncProducts.length
                    }})</span
                  >
                </button>
              </div>
            </li>
            <li
              v-for="item in filterSyncProducts"
              :key="item.id"
              class="py-3 px-6"
            >
              <div class="checkbox items-center gap-6">
                <input
                  type="checkbox"
                  :id="item.id + 'product'"
                  :name="item.id + 'product'"
                  :value="item.id"
                  v-model="sync_data.item_ids"
                />
                <label
                  :for="item.id + 'product'"
                  class="inline-flex items-center gap-3"
                >
                  <img
                    :src="
                      item.image || require('@/assets/images/placeholder.png')
                    "
                    @error="
                      $event.target.src = require('@/assets/images/placeholder.png')
                    "
                    class="w-10 h-10 rounded-full object-cover shrink-0"
                    alt="product image"
                  />
                  <div class="flex-1">
                    <p class="text-sm font-medium line-clamp-2">
                      {{ item["name_" + $i18n.locale] }}
                    </p>
                  </div>
                </label>
              </div>
            </li>
            <li v-if="!filterSyncProducts.length" class="py-3 px-6">
              <p class="text-sm text-center italic text-gray-600 font-medium">
                {{ $t("no-data") }}
              </p>
            </li>
          </ul>
          <p
            class="error p-6"
            v-html="Object.values(sync_errors).join('<br />')"
            v-if="Object.values(sync_errors).length"
          ></p>
          <div class="p-6 pt-2">
            <button-with-loader
              btnClass="w-auto py-2 px-4 rounded bg-primary
          text-white text-xs [margin-inline-start:auto]"
              :disabled="sync_disabled"
              @click="SaveSyncItems"
              >{{ $t("save-changes") }}</button-with-loader
            >
          </div>
        </form>
      </template>
    </Modal>
    <Modal title="Add stock" v-if="show_active" @close="show_active = $event">
      <template>
        <form class="p-6 space-y-4">
          <div class="form-group space-y-3">
            <label for="value">{{ $t("value") }}</label>
            <input
              type="number"
              min="1"
              placeholder="1"
              v-model="add_data.value"
              autocomplete="off"
            />
            <p
              class="error"
              v-html="Object.values(add_errors).join('<br />')"
            ></p>
          </div>
          <button-with-loader
            btnClass="w-auto py-2 px-4 rounded bg-primary
          text-white text-xs [margin-inline-start:auto]"
            :disabled="add_disabled"
            @click="addValue"
            >{{ $t("save-changes") }}</button-with-loader
          >
        </form>
      </template>
    </Modal>
    <transition>
      <Modal
        :title="$t('manual-wastages')"
        v-if="wastage_active"
        @close="wastage_active = $event"
      >
        <template>
          <form class="space-y-4 p-4">
            <div class="form-group space-y-3">
              <label for="wastage_id"> {{ $t("wastage") }} </label>
              <t-rich-select
                :options="wastages"
                placeholder="Select"
                :text-attribute="'name_' + $i18n.locale"
                value-attribute="id"
                :clearable="true"
                :closeOnSelect="true"
                v-model="wastage_data.wastage_id"
              />
              <p class="error">
                {{ (wastage_errors["wastage_id"] || []).join(" ") }}
              </p>
            </div>
            <div class="form-group space-y-3">
              <label for="weight"> {{ $t("weight") }} (KG) </label>
              <input
                type="number"
                name="weight"
                id="weight"
                v-model="wastage_data.value"
                placeholder="1"
                min="1"
              />
              <p class="error">
                {{ (wastage_errors["value"] || []).join(" ") }}
              </p>
            </div>
            <button-with-loader
              btnClass="w-auto py-2 px-4 rounded bg-primary
                text-white text-xs"
              :disabled="wastage_disabled"
              @click="addWastage"
              >{{ $t("save-changes") }}</button-with-loader
            >
          </form>
        </template>
      </Modal>
    </transition>
  </section>
</template>
<script>
import ContainerWrapper from "@/components/Inventory/ContainerWrapper.vue";
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";
import Modal from "@/components/basics/Modal.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "Stock",
  data() {
    return {
      load: false,
      items: [],
      currentPage: 1,
      rows: 0,
      totalPages: 0,
      perPage: 1,
      active: false,
      product_id: null,
      delete_disabled: false,
      status_disabled: [],
      error: "",
      search_key: "",
      search_disabled: false,
      item_status: {
        1: "bg-sky-500/10 text-sky-500",
        0: "bg-red-500/10 text-red-500",
      },
      add_data: {
        is_inventory: 1,
      },
      add_errors: {},
      add_error: null,
      add_disabled: false,
      show_active: false,
      status: ["all", "active", "not active"],
      pagination: {
        is_active: null,
        page: 1,
        sort_key: null,
        sort_type: "asc",
      },
      disables: {},
      indexes: [],
      wastage_data: {},
      wastage_errors: {},
      wastage_active: false,
      wastage_disabled: false,
      wastages: [],
      process_data: {},
      process_errors: {},
      process_active: false,
      process_disabled: false,
      sync_data: {
        item_ids: [],
        inventory_product_id: null,
      },
      sync_errors: {},
      sync_active: false,
      sync_disabled: false,
      sync_search_key: null,
      products: [],
      fields: [],
      sync_products: [],
      process_products: [],
    };
  },
  async created() {
    await Promise.all([this.getItems(), this.getWastages()]);
  },
  watch: {
    "pagination.page"() {
      this.getItems();
    },
    is_called(val, _) {
      if (val) {
        this.getItems();
      }
    },
    process_active() {
      this.process_errors = {};
      this.process_disabled = false;
    },
    sync_active(val) {
      if (val) return;
      this.sync_errors = {};
      this.sync_data = {};
      this.sync_products = [];
      this.sync_search_key = null;
      this.sync_disabled = false;
    },
  },
  computed: {
    ...mapGetters("table", ["search", "is_called"]),
    filterWastages() {
      const selectedWastages = this.process_data.wastages;
      const mapWastages = selectedWastages.map((e) => e.id);
      const result = this.wastages.filter((e) => !mapWastages.includes(e.id));

      return result;
    },
    filterSyncProducts() {
      return this.sync_products.filter((product) => {
        const search = this.sync_search_key;
        const name = product["name_" + this.$i18n.locale];
        if (!!search && name) {
          return new RegExp("^" + search, "gi").test(name);
        }
        return true;
      });
    },
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("table", ["changeIsCalled"]),
    async getWastages() {
      try {
        const { result } = await this.getRoute("wastages");
        this.wastages = result.wastages;
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async getSyncProducts(item) {
      try {
        const { id, product_type } = item;
        this.$set(this.disables, "sync-" + id, true);
        const { result } = await this.getRoute({
          name: "products",
          options: {
            is_inventory: 0,
            is_bundle: 0,
            product_type,
            not_linked_inventory_product_id: id,
          },
        });
        this.sync_products = result.products;
        this.sync_data = {
          item_ids: [],
          inventory_product_id: id,
        };
        this.sync_active = true;
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      } finally {
        this.disables = {};
      }
    },
    async getOtherProducts(q) {
      try {
        const productID = this.process_data.product_id;
        const { id, product_type } = this.items.find((e) => e.id == productID);
        const { result } = await this.getRoute({
          name: "products",
          options: {
            is_inventory: 0,
            is_bundle: 0,
            search_key: q,
            page: 1,
            not_linked_inventory_product_id: id,
            product_type,
          },
        });
        const products = result.products.data;
        this.process_products = products;
        return { results: products };
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    handleOtherProductsChange(id) {
      const item = this.process_products?.find((ev) => ev.id == id);
      if (item) {
        const processProducts = this.process_data.to_products;
        const product = processProducts?.find((ev) => ev.id == id);
        const obj = {
          id,
          name_ar: item.name_ar,
          name_en: item.name_en,
          value: null,
          image: item.image,
          product_type: item.unit_type,
        };
        if (!product) {
          this.$set(this.process_data, "to_products", [
            ...processProducts,
            obj,
          ]);
          return;
        }
      }
    },
    async getItems() {
      try {
        const { total, total_pages, ...others } = this.pagination;
        const user = this.$store.state.user;

        const { result } = await this.getRoute({
          name: "products",
          options: {
            ...others,
            is_active: others.is_active
              ? others.is_active === "active"
                ? 1
                : 0
              : null,
            search_key: this.search || this.$route.query.search,
            vendor_id: user.user_type === "vendor" ? user?.id : null,
            // store_id: user.user_type === "cashier" ? user?.id : null,
            store_id: this.store_id,
            is_inventory: 1,
            is_bundle: 0,
          },
        });

        const { data, pagination } = result.products;
        this.fields = result.export_fields;

        this.indexes = Array(data.length)
          .fill()
          .map((_, i) => i + 1)
          .sort(() => -1);
        this.items = data;
        this.$set(this.pagination, "total", pagination.total);
        this.$set(this.pagination, "total_pages", pagination.total_pages);
      } catch (error) {
        console.log(error);
        // this.createAlert(result?.message, type);
      } finally {
        this.load = true;
        this.changeIsCalled(false);
      }
    },
    async updateProductProcess() {
      this.process_disabled = true;
      try {
        const body = this.process_data;
        const wastages = body?.wastages?.filter((ev) =>
          Object.values(ev).every((e) => e)
        );
        const products = body?.to_products?.filter((e) => e.value);
        const { data } = await this.axios.post("products/processing", {
          ...body,
          to_products: products?.length ? products : undefined,
          wastages: wastages?.length ? wastages : undefined,
        });
        this.createAlert(data.message);
        this.getItems();
        this.process_active = false;
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.process_errors = responseError;
        } else {
          this.createAlert(responseError, "error");
        }
      } finally {
        this.process_disabled = false;
      }
    },
    async addValue() {
      this.add_disabled = true;
      try {
        const { data } = await this.axios.post(
          "products/add_stock",
          this.add_data
        );
        this.createAlert(data.message);
        this.getItems();
        this.show_active = false;
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.createAlert(responseError, "error");
        }
      } finally {
        this.add_disabled = false;
      }
    },
    async SaveSyncItems() {
      this.sync_disabled = true;
      try {
        const { data } = await this.axios.post(
          "products/sync_items",
          this.sync_data
        );
        this.createAlert(data.message);
        this.getItems();
        this.sync_active = false;
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.sync_errors = responseError;
        } else {
          this.createAlert(responseError, "error");
        }
      } finally {
        this.sync_disabled = false;
      }
    },
    async changeStatus(id) {
      this.$set(this.disables, `status_${id}`, true);
      try {
        const { data } = await this.axios.post(
          `products/update_is_active/${id}`
        );
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async GetRelatedProducts({ id, product_type }) {
      this.$set(this.disables, `related_${id}`, true);
      try {
        const { result } = await this.getRoute({
          name: "products",
          options: {
            inventory_product_id: id,
            is_inventory: 0,
            product_type,
          },
        });
        // console.log(result);
        this.products = result.products;
        this.process_active = true;
        this.process_errors = {};
        this.process_data = {
          to_products: result.products.map((e) => ({
            name_ar: e.name_ar,
            name_en: e.name_en,
            id: e.id,
            value: null,
            image: e.image,
            product_type: e.unit_type,
          })),
          product_id: id,
          wastages: [{ id: null, value: null }],
        };
      } catch (err) {
        const res = err?.response;
        console.log(err);
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async deleteItem(id) {
      this.$set(this.disables, `delete_${id}`, true);
      try {
        const { data } = await this.axios.post(`products/delete/${id}`);
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    sortTable(key) {
      if (this.pagination.sort_key != key) {
        this.pagination.sort_type = "asc";
      } else {
        this.pagination.sort_type =
          this.pagination.sort_type == "asc" ? "desc" : "asc";
      }
      this.pagination.sort_key = key;
      this.getItems().finally(() => {
        this.createAlert(`Table sorted by ${key}`);
      });
    },
    addValues(id) {
      this.add_data = {
        product_id: id,
        value: null,
      };
      this.show_active = true;
      this.add_errors = {};
      this.add_error = null;
      this.add_disabled = false;
    },
    async addWastage() {
      this.wastage_disabled = true;
      this.wastage_errors = {};

      try {
        const { data } = await this.axios.post("products/add_wastage", {
          ...this.wastage_data,
          // product_id: this.productId,
        });
        this.getItems();
        this.wastage_data = {};
        this.wastage_active = false;
        this.createAlert(data.message);
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.wastage_errors = responseError;
        } else {
          this.createAlert(responseError, "error");
        }
      } finally {
        this.wastage_disabled = false;

        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    },
    openWastage(id) {
      this.wastage_data = {
        product_id: id,
      };
      this.wastage_errors = {};
      this.wastage_active = true;
    },

    changeFilterStatus(status, index) {
      this.$set(this.disables, index, true);
      this.$set(this.pagination, "is_active", status);
      this.getItems().finally(() => {
        this.disables = {};
      });
    },
    addNewItem() {
      if (!this.filterWastages.length) {
        this.createAlert("Wastages are not enough to add new one.", "error");
        return;
      }
      this.process_data.wastages.push({
        id: null,
        value: null,
      });
    },
    removeCurrentItem(index) {
      if (index === 0 && this.process_data.wastages.length === 1) {
        this.createAlert("One wastage at least should be added!!", "error");
        return;
      }
      this.process_data.wastages.splice(index, 1);
    },
    selectAllItemsToSync() {
      const items = this.filterSyncProducts;
      const ids = items?.map((e) => e.id);
      if (!!items.length) {
        this.$set(this.sync_data, "item_ids", ids);
      }
    },
  },
  components: { Modal, ContainerWrapper, ButtonWithLoader },
};
</script>
