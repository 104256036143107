<template>
  <transition enter-active-class="enter-modal" leave-active-class="leave-modal">
    <section
      ref="sweetAlert"
      class="w-screen py-16 px-8 bg-gray-800/25 overflow-y-auto h-screen fixed inset-0 z-30 sm:block !m-0"
    >
      <main
        class="w-full sm:max-w-xl bg-white block mx-auto rounded-md relative"
        :class="size"
      >
        <header
          v-if="title"
          class="p-4 border-b border-gray-100 flex items-center gap-2 w-full"
        >
          <div class="flex-1">
            <p class="text-gray-600 font-semibold rtl:font-bold">
              {{ title }}
            </p>
          </div>
          <div>
            <button
              class="py-1 p-3 text-gray-600"
              @click.prevent="$emit('close', false)"
            >
              <i class="fa-solid fa-times"></i>
            </button>
          </div>
        </header>

        <main class="w-full h-auto">
          <slot name="default"></slot>
        </main>
      </main>
    </section>
  </transition>
</template>
<script>
export default {
  name: "ModalComponent",
  props: {
    hasClose: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: null,
    },
  },
  mounted() {
    // window.addEventListener('click', (e) => {
    //   if (e.target === this.$refs.sweetAlert) {
    //     this.$emit('close', false)
    //   }
    // })
  },
};
</script>
<style scope>
.enter-modal,
.leave-modal {
  transition: 0.1s linear;
  -webkit-transition: 0.1s linear;
  -moz-transition: 0.1s linear;
}
</style>
