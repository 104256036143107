export default {
  namespaced: true,
  state: {
    global_cart: {},
  },

  getters: {
    wareCart: (state) => (id) => state.global_cart[id],
  },
  mutations: {
    SET_WARE_ITEMS(state, data) {
      state.global_cart = data;
    },
    GENERATE_WARE_CART(state, key) {
      Object.assign(state.global_cart, {
        [key]: [],
      });
    },
  },
  actions: {
    addItemsInsideWareCart(
      { commit, getters, dispatch, state },
      { product, id }
    ) {
      return new Promise(async (resolve) => {
        const user = this.state.user;
        const cartKey = `${user.user_type}_${user.id}`;
        const findProduct = getters.wareCart(cartKey)?.find((e) => e.id === id);
        const cloneUserCart = JSON.parse(
          JSON.stringify(getters.wareCart(cartKey))
        );

        if (!findProduct) {
          const newProduct = await dispatch(
            "generateProduct",
            JSON.parse(
              JSON.stringify({
                ...product,
              })
            )
          );
          commit("SET_WARE_ITEMS", {
            ...state.global_cart,
            [cartKey]: [...cloneUserCart, newProduct],
          });
          resolve();
          return;
        }

        commit("SET_WARE_ITEMS", {
          ...state.global_cart,
          [cartKey]: cloneUserCart.map((e) => {
            if (e.id === id) {
              return {
                ...e,
                qty: product.type ? e.qty - 1 : e.qty + 1,
              };
            } else {
              return e;
            }
          }),
        });

        console.log(product, cloneUserCart, cartKey);

        resolve();
      });
      // console.log(findProduct);
      /* ---------------------*/
    },
    removeItemFromWareCart({ commit, state, getters }, id) {
      return new Promise((resolve) => {
        const user = this.state.user;
        const cartKey = `${user.user_type}_${user.id}`;
        const cloneUserCart = JSON.parse(
          JSON.stringify(getters.wareCart(cartKey))
        );

        commit("SET_WARE_ITEMS", {
          ...state.global_cart,
          [cartKey]: cloneUserCart.filter((e) => e.id !== id),
        });
        resolve("Product deleted successfully");
      });
    },
    generateProduct(_, product) {
      return new Promise((resolve) => {
        let result = {};
        const keys = [
          "id",
          "name_ar",
          "name_en",
          "image",
          "qty",
          "barcode",
          "price",
          "sku",
          "store",
          "approx_weight",
          "weight",
          "is_meat",
        ];

        for (const key in product) {
          if (keys.includes(key)) {
            result[key] = product[key];
          }
        }

        // if (result.is_meat) {
        //   Object.assign(result, {
        //     weight: 1,
        //     actual_weight: result.weight || result.approx_weight,
        //     is_meat: true,
        //   });
        // } else {
        Object.assign(result, {
          qty: 1,
          actual_qty: result.qty,
          // is_meat: false,
        });
        // }

        resolve(result);
      });
    },
    generateNewWareCart({ commit }, id) {
      return new Promise((resolve) => {
        commit("GENERATE_WARE_CART", id);
        resolve();
      });
    },
  },
  modules: {},
};
