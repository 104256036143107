<template>
  <div>
    <container-wrapper>
      <figure
        class="
          w-full
          bg-white
          rounded-xl
          flex flex-col
          min-h-[calc(100vh-200px)]
          max-h-[calc(100vh-200px)]
        "
      >
        <div>
          <header class="w-full border-b border-gray-100 p-4">
            <div class="flex items-center" v-if="!load">
              <div>
                <div
                  class="w-10 h-10 rounded-full bg-gray-200 animate-pulse"
                ></div>
              </div>
              <div class="px-4 space-y-2 flex-1">
                <div
                  class="
                    w-full
                    max-w-[10rem]
                    h-4
                    rounded
                    bg-gray-200
                    animate-pulse
                  "
                ></div>
                <div
                  class="w-full max-w-xs h-3 rounded bg-gray-200 animate-pulse"
                ></div>
              </div>
            </div>
            <div v-else>
              <div class="flex items-center gap-2">
                <div class="flex-1">
                  <h1 class="font-semibold rtl:font-bold line-clamp-1">
                    {{ item.subject || "-" }}
                  </h1>

                  <p
                    class="
                      text-xs
                      font-medium
                      rtl:font-semibold
                      text-gray-600
                      !mt-2
                    "
                  >
                    <span class="text-primary">{{ item.status }}</span>
                    •
                    <span>
                      {{
                        item.updated_at || item.created_at | moment("from")
                      }}</span
                    >
                  </p>
                </div>
                <div v-if="item.status !== 'closed'">
                  <button-with-loader
                    class="
                      py-1.5
                      px-2
                      text-red-500
                      rounded
                      bg-red-500/20
                      text-sm
                      font-medium
                      rtl:font-semibold
                    "
                    :disabled="close_disabled"
                    @click="closeTicket"
                  >
                    Close
                  </button-with-loader>
                </div>
              </div>
            </div>
          </header>
        </div>
        <div class="flex-1 p-5 overflow-y-auto" data-type="chatBox">
          <div class="table m-auto py-20" v-if="!replies.length">
            <div class="flex items-center justify-center flex-col gap-6">
              <div>
                <div
                  class="
                    w-28
                    h-28
                    flex
                    items-center
                    justify-center
                    bg-gray-100
                    rounded-full
                    text-gray-500
                  "
                >
                  <i class="fa-solid fa-message fa-2xl"></i>
                </div>
              </div>
              <div class="flex-1 space-y-2 text-center">
                <h1 class="text-lg text-primary font-semibold rtl:font-bold">
                  There are no messages
                </h1>
                <p
                  class="
                    text-sm text-gray-400
                    max-w-xl
                    font-medium
                    rtl:font-semibold
                  "
                >
                  Click on the ticket from the other side if there are any
                  tickets available to complete the discussion and conversation.
                </p>
              </div>
            </div>
          </div>
          <messages-preview :items="replies" />
        </div>

        <div v-if="item.status === 'open'">
          <footer class="border-t border-t-gray-100 flex items-start gap-4 p-4">
            <div>
              <button class="w-8 h-8" @click="show_more = !show_more">
                <i class="fa-solid fa-plus fa-sm text-gray-600"></i>
              </button>
            </div>
            <div class="flex-1 space-y-3">
              <div class="form-group">
                <textarea
                  name="message"
                  id="message"
                  placeholder="Type message"
                  v-model="add_data.body"
                ></textarea>
                <p class="error" v-if="add_errors.body">
                  {{ add_errors.body.join("") }}
                </p>
              </div>
              <slide-transition>
                <ul class="flex items-center gap-4 flex-wrap" v-if="show_more">
                  <li>
                    <label
                      for="images"
                      class="
                        flex
                        items-center
                        justify-center
                        gap-3
                        flex-col
                        cursor-pointer
                      "
                    >
                      <div
                        class="
                          w-10
                          h-10
                          rounded-full
                          ring-1 ring-gray-200
                          flex
                          items-center
                          justify-center
                          text-gray-600
                          mx-auto
                        "
                      >
                        <i class="fa-solid fa-camera"></i>
                      </div>
                      <p
                        class="
                          text-xs
                          font-semibold
                          rtl:font-bold
                          text-gray-600 text-center
                          line-clamp-1
                        "
                      >
                        Images
                      </p>
                      <input
                        type="file"
                        name="images"
                        id="images"
                        accept="image/*"
                        hidden
                        aria-hidden="true"
                        class="hidden"
                        multiple
                        ref="images_updater"
                        @change.prevent="handleAttachmentsAndImagesUploader"
                      />
                    </label>
                  </li>
                  <li>
                    <label
                      for="attachments"
                      class="
                        flex
                        items-center
                        justify-center
                        gap-3
                        flex-col
                        cursor-pointer
                      "
                    >
                      <div
                        class="
                          w-10
                          h-10
                          rounded-full
                          ring-1 ring-gray-200
                          flex
                          items-center
                          justify-center
                          text-gray-600
                          mx-auto
                        "
                      >
                        <i class="fa-solid fa-link"></i>
                      </div>
                      <p
                        class="
                          text-xs
                          font-semibold
                          rtl:font-bold
                          text-gray-600 text-center
                          line-clamp-1
                        "
                      >
                        Attachments
                      </p>
                      <input
                        type="file"
                        name="attachments"
                        id="attachments"
                        hidden
                        aria-hidden="true"
                        class="hidden"
                        multiple
                        ref="attachments_updater"
                        @change.prevent="handleAttachmentsAndImagesUploader"
                      />
                    </label>
                  </li>
                </ul>
              </slide-transition>
              <slide-transition>
                <div
                  class="block !mt-6 w-auto"
                  v-if="add_data.attachments.length"
                >
                  <div class="flex items-start gap-4 flex-wrap">
                    <div
                      v-for="(img, imgIdx) in add_data.attachments"
                      :key="imgIdx"
                      class="flex items-center gap-2"
                    >
                      <div class="rounded ring-2 ring-gray-300 py-1 px-2">
                        <span class="text-xs font-medium">{{ img.name }}</span>
                      </div>
                      <button
                        class="text-red-500 text-sm"
                        @click.prevent="add_data.attachments.splice(imgIdx, 1)"
                      >
                        <i class="fa-solid fa-trash"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </slide-transition>
            </div>
            <div>
              <button-with-loader
                class="
                  w-8
                  h-8
                  text-white
                  rounded-full
                  bg-indigo-600
                  flex
                  items-center
                  justify-center
                "
                :disabled="add_disabled"
                @click="addReply"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="w-4 h-4"
                  v-if="!add_disabled"
                >
                  <path
                    d="M3.105 2.289a.75.75 0 00-.826.95l1.414 4.925A1.5 1.5 0 005.135 9.25h6.115a.75.75 0 010 1.5H5.135a1.5 1.5 0 00-1.442 1.086l-1.414 4.926a.75.75 0 00.826.95 28.896 28.896 0 0015.293-7.154.75.75 0 000-1.115A28.897 28.897 0 003.105 2.289z"
                  />
                </svg>
              </button-with-loader>
            </div>
          </footer>
        </div>
        <div v-else>
          <ul class="p-4 border-t border-t-gray-200">
            <li v-if="item.status === 'closed'">
              <p
                class="
                  font-medium
                  rtl:font-semibold
                  text-sm text-center text-gray-600
                "
              >
                Current ticket closed so you can't send any message again, if
                your problem or question didn't resolve yet open new ticket
              </p>
            </li>
            <li v-else-if="item.status === 'resolved'">
              <p
                class="
                  font-medium
                  rtl:font-semibold
                  text-sm text-center text-gray-600
                "
              >
                Your problem or question resolved by one of our customer
                support, if you have a new problem or question open new ticket
              </p>
            </li>
          </ul>
        </div>
      </figure>
    </container-wrapper>
  </div>
</template>

<script>
import ContainerWrapper from "@/components/support/ContainerWrapper.vue";
import SlideTransition from "@/components/basics/SlideTransition.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";
import MessagesPreview from "@/components/support/MessagesPreview.vue";
export default {
  components: {
    ContainerWrapper,
    SlideTransition,
    ButtonWithLoader,
    MessagesPreview,
  },
  name: "SingleTicket",
  data() {
    return {
      show_more: false,
      replies: [],
      item: {},
      load: false,
      add_data: {
        attachments: [],
        id: this.$route.params.id || null,
      },
      add_errors: {},
      add_disabled: false,
      close_disabled: false,
    };
  },
  computed: {
    ...mapGetters({
      getUser: "getUserInfo",
      getMessage: "chat/getMessage",
    }),
    ticketId() {
      return this.$route.params.id;
    },
  },
  watch: {
    ticketId() {
      this.add_data = {
        attachments: [],
        id: this.ticketId,
      };
      this.add_errors = {};
      this.load = false;
      this.item = {};
      this.replies = [];
      this.getItems();
    },
    getMessage(val) {
      if (val) {
        const { replay, ticket_id } = val;
        if (ticket_id === this.ticketId) {
          this.replies.push(replay);
          const chat = document.querySelector('[data-type="chatBox"]');
          if (chat) {
            setTimeout(() => {
              if (this.replies.length > 0) {
                chat.scrollTop = chat.scrollHeight;
              }
            }, 5);
          }
          this.UPDATE_MESSAGE(null);
        }
      }
    },
  },
  created() {
    this.getItems();
  },

  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("chat", ["UPDATE_MESSAGE"]),
    async getItems() {
      try {
        const { result } = await this.getRoute(`tickets/${this.ticketId}`);
        const { replies, ...other } = result.ticket;
        this.item = other;
        this.replies = replies || [];
      } catch (error) {
        this.$router.push("/support");
      } finally {
        this.load = true;
        const chat = document.querySelector('[data-type="chatBox"]');
        if (chat) {
          setTimeout(() => {
            if (this.replies.length > 0) {
              chat.scrollTop = chat.scrollHeight;
            }
          }, 5);
        }
      }
    },
    async addReply() {
      try {
        this.add_disabled = true;
        this.add_errors = {};
        let fd = new FormData();

        for (const key in this.add_data) {
          const item = this.add_data[key];
          if (key === "attachments") {
            for (const attach in item) {
              if (item[attach]) {
                fd.append(`${key}[${attach}]`, item[attach], item[attach].name);
              }
            }
          } else {
            fd.append(key, item);
          }
        }

        const { data } = await this.axios.post("tickets/add_reply", fd);
        this.add_data = {
          attachments: [],
          id: this.ticketId,
        };
        this.add_active = false;
        if (this.add_data.attachments.length) {
          this.$refs.attachments_updater.value = null;
          this.$refs.images_uploader.value = null;
        }
        this.createAlert(data?.message);
        this.getItems();
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.add_errors = responseError;
        } else {
          this.error = responseError;
        }
      } finally {
        this.add_disabled = false;
      }
    },
    handleAttachmentsAndImagesUploader(e) {
      const files = e.target.files;
      let result = [];

      if (!files.length) {
        this.createAlert(
          "There are no files selected to display and send them to server, try again later",
          "info"
        );
        return;
      }

      for (const file of files) {
        result.push(file);
      }
      this.add_data.attachments = new Array().concat(
        this.add_data.attachments,
        result
      );
      // remove current uploader value after add it value inside attachments
      e.target.value = null;
    },
    async closeTicket() {
      this.close_disabled = true;
      try {
        const { data } = await this.axios.post(
          `tickets/close/${this.ticketId}`
        );
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.close_disabled = false;
      }
    },
  },
};
</script>
