<template>
  <div class="p-6">
    <Table
      :title="$t('journal-entries')"
      :records="`${pagination.total || 0} `"
      :pagination="pagination"
      :total_pages="pagination.total_pages"
      v-model="pagination.page"
    >
      <template #actions>
        <div v-if="hasPermission('add accounting_entries')">
          <router-link
            to="/accounting/journal-entries/create"
            class="py-1.5 px-3 text-sm flex items-center gap-2 text-gray-600 font-medium rtl:font-semibold"
          >
            <i class="fa-solid fa-plus"></i>
            <span>{{ $t("new-journal-entry") }}</span>
          </router-link>
        </div>
        <div v-if="hasPermission('add accounting_entries')">
          <router-link
            to="/accounting/journal-entries/opening"
            class="py-1.5 px-3 text-sm flex items-center gap-2 text-gray-600 font-medium rtl:font-semibold bg-white border border-gray-200"
          >
            <i class="fa-solid fa-plus"></i>
            <span>{{ $t("new-opening-balances") }}</span>
          </router-link>
        </div>
      </template>

      <template #head>
        <th>#</th>
        <th>{{ $t("location") }}</th>
        <th>{{ $t("type") }}</th>
        <th>{{ $t("date") }}</th>
        <th>{{ $t("amount") }}</th>
        <th>{{ $t("latest-update") }}</th>
        <th>{{ $t("actions") }}</th>
      </template>
      <template #tbody v-if="!load">
        <TableLoad :rows="7" v-for="i in 4" :key="i" />
      </template>
      <template #tbody v-else-if="load && items.length">
        <tr v-for="(item, index) in items" :key="index">
          <td>
            <router-link
              :to="
                hasPermission('update accounting_entries')
                  ? '/accounting/journal-entries/' + item.id
                  : '#'
              "
              class="text-blue-600"
              >{{ item.id }}</router-link
            >
          </td>
          <td>
            {{ item.store["name_" + $i18n.locale] || "-" }}
          </td>
          <td>
            {{ item.ref_type }}
          </td>
          <td>
            {{ item.date }}
          </td>
          <td>
            {{ generateCurrency(item.total_amount) }}
          </td>
          <td v-html="createTime(item.updated_at || item.created_at)"></td>

          <td>
            <div class="actions-group">
              <div v-if="hasPermission('update accounting_entries')">
                <router-link
                  :to="'/accounting/journal-entries/update/' + item.id"
                  class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded flex items-center justify-center"
                >
                  <i class="fa-solid fa-pen"></i>
                </router-link>
              </div>
              <div v-if="hasPermission('update accounting_entries')">
                <router-link
                  :to="'/accounting/journal-entries/' + item.id"
                  class="w-8 h-8 bg-sky-500/10 text-sky-500 rounded flex items-center justify-center"
                >
                  <i class="fa-solid fa-eye"></i>
                </router-link>
              </div>
              <div v-if="hasPermission('delete accounting_entries')">
                <button
                  class="p-2 px-3 text-red-500 font-medium rtl:font-semibold"
                  @click.prevent="deleteItem(item.id)"
                  :disabled="disables[`delete_${item.id}`]"
                >
                  <spinner
                    size="w-4 h-4 inline-block"
                    v-if="disables[`delete_${item.id}`]"
                  />
                  <span v-else> {{ $t("delete") }} </span>
                </button>
              </div>
            </div>
          </td>
        </tr>
      </template>

      <template #tbody v-else>
        <tr>
          <td colspan="100%" class="!text-center">{{ $t("no-data") }}</td>
        </tr>
      </template>
    </Table>
  </div>
</template>
<script>
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";
import Modal from "@/components/basics/Modal.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "JournalEntries",
  data() {
    return {
      load: false,
      items: [],

      item_status: {
        1: "bg-sky-500/10 text-sky-500",
        0: "bg-red-500/10 text-red-500",
      },
      status: ["all", "active", "not active"],
      pagination: {
        is_active: null,
        page: 1,
        sort_key: null,
        sort_type: "asc",
      },
      disables: {},
    };
  },
  async created() {
    Promise.all([this.getItems()]).finally(() => (this.load = true));
  },
  watch: {
    "pagination.page"() {
      this.getItems();
    },
    is_called(val, _) {
      if (val) {
        this.getItems();
      }
    },
  },
  computed: {
    ...mapGetters("table", ["search", "is_called"]),
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("table", ["changeIsCalled"]),

    async getItems() {
      try {
        const { total, total_pages, ...others } = this.pagination;

        const { result } = await this.getRoute({
          name: "journal_entries",
          options: {
            ...others,
            is_active: others.is_active
              ? others.is_active === "active"
                ? 1
                : 0
              : null,
            search_key: this.search,
            store_id: this.store_id,
          },
        });
        const { data, pagination } = result.journal_entries;
        this.items = data;
        this.$set(this.pagination, "total", pagination.total);
        this.$set(this.pagination, "total_pages", pagination.total_pages);
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      } finally {
        this.changeIsCalled(false);
      }
    },
    async changeStatus(id) {
      this.$set(this.disables, `status_${id}`, true);
      try {
        const { data } = await this.axios.post(
          `journal_entries/update_is_active/${id}`
        );
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.add_errors = res?.data?.message;
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async deleteItem(id) {
      this.$set(this.disables, `delete_${id}`, true);
      try {
        const { data } = await this.axios.post(`journal_entries/delete/${id}`);
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.add_errors = res?.data?.message;
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },

    sortTable(key) {
      if (this.pagination.sort_key != key) {
        this.pagination.sort_type = "asc";
      } else {
        this.pagination.sort_type =
          this.pagination.sort_type == "asc" ? "desc" : "asc";
      }
      this.pagination.sort_key = key;
      this.getItems().finally(() => {
        this.createAlert(`Table sorted by ${key}`);
      });
    },

    changeFilterStatus(status, index) {
      this.$set(this.disables, index, true);
      this.$set(this.pagination, "is_active", status);
      this.getItems().finally(() => {
        this.disables = {};
      });
    },
  },
  components: { Modal, ButtonWithLoader },
};
</script>
