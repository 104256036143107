<template>
  <div class="p-6">
    <Table
      title="Products"
      :records="`${pagination.total || 0} `"
      :pagination="pagination"
      :total_pages="pagination.total_pages"
      v-model="pagination.page"
      has_export
      has_import
      :exportParams="exportParams"
      :fields="fields"
    >
      <template #actions>
        <div v-if="hasPermission('add items')">
          <router-link
            to="/products/create"
            class="py-1.5 px-3 text-sm flex items-center gap-2 text-gray-600 font-medium rtl:font-semibold"
          >
            <i class="fa-solid fa-plus"></i>
            <span>New product</span>
          </router-link>
        </div>
        <!-- <div>
          <button
            class="py-1.5 px-3 text-sm flex items-center gap-2 text-gray-600 font-medium rtl:font-semibold ring-1 ring-gray-200 bg-white"
            @click.prevent="ShowImportFile"
          >
            <i class="fa-solid fa-cloud-arrow-up"></i>
            <span>Import excel</span>
          </button>
        </div> -->
      </template>
      <template #filter>
        <ul class="flex items-center">
          <li v-for="(item, index) in status" :key="index">
            <button
              class="py-4 px-6 text-center font-medium rtl:font-semibold text-sm capitalize"
              @click.prevent="
                changeFilterStatus(item === 'all' ? null : item, index)
              "
              :class="{
                'active-tab':
                  pagination.is_active === (item === 'all' ? null : item),
              }"
              :disabled="disables[index]"
            >
              <spinner
                size="w-4 h-4 inline-block"
                class="mr-2 rtl:mr-0 rtl:ml-2"
                v-if="disables[index]"
              />
              <!-- {{ $t(item.replace(/\s/g, "_")) }} -->
              {{ item }}
            </button>
          </li>
        </ul>
      </template>
      <template #head>
        <th>Product</th>
        <th>Price (SAR)</th>
        <th>Item code</th>
        <th>Barcode</th>
        <th>Stock</th>
        <th>Available in store</th>
        <th>Discount</th>
        <th>Inventory product</th>
        <th>Category</th>
        <th>Sub categories</th>
        <th>Status</th>
        <th>Actions</th>
      </template>
      <template #tbody v-if="!load">
        <TableLoad :rows="12" v-for="i in 4" :key="i" />
      </template>
      <template #tbody v-else-if="load && items.length">
        <tr v-for="(product, index) in items" :key="index">
          <td>
            <router-link
              :to="
                hasPermission('edit items')
                  ? `/products/${product.id}-${
                      product.name_ar ||
                      product.name_en ||
                      `product-${product.created_at}`
                    }`
                  : ''
              "
              class="flex items-center gap-3"
            >
              <div>
                <div class="w-10 h-10">
                  <img
                    :src="
                      product.image ||
                      require('@/assets/images/placeholder.png')
                    "
                    @error="
                      $event.target.src = require('@/assets/images/placeholder.png')
                    "
                    alt="product image"
                    class="w-full h-full rounded-md border object-cover border-gray-100"
                  />
                </div>
              </div>
              <span
                class="inline-block line-clamp-2 max-w-sm whitespace-normal"
              >
                {{ product["name_" + $i18n.locale] }}
              </span>
            </router-link>
          </td>

          <td>
            <span
              :class="
                product.discount_price ? 'line-through italic text-red-500' : ''
              "
              >{{ product.price }}</span
            >
            {{ " " }}
            <span v-if="product.discount_price" class="font-bold">{{
              product.discount_price
            }}</span>
          </td>

          <td>{{ product.item_code || "-" }}</td>
          <!-- <td>{{ product.sku || "-" }}</td> -->
          <td>{{ product.barcode || "-" }}</td>
          <td>
            <span v-if="product.unit_type === 'weight'">
              {{ GenerateUnit(product.weight || 0) }}
            </span>
            <span v-else> {{ product.qty || 0 }} Piece </span>
          </td>
          <td>
            {{ (product.store || {})["name_" + $i18n.locale] }}
          </td>
          <td>
            <p class="text-red-500" v-if="product.discount">
              {{ (product.discount || {})["name_" + $i18n.locale] }}
            </p>
            <span v-else>-</span>
          </td>
          <td>
            <router-link
              :to="
                hasPermission('edit inventory')
                  ? `/inventory-setup/update/${
                      (product.inventory_product || {}).id
                    }-${
                      (product.inventory_product || {}).name_ar ||
                      (product.inventory_product || {}).name_en ||
                      `product-${(product.inventory_product || {}).created_at}`
                    }`
                  : ''
              "
              class="flex items-center gap-3"
              v-if="product.inventory_product"
            >
              <div>
                <div class="w-10 h-10">
                  <img
                    :src="
                      (product.inventory_product || {}).image ||
                      require('@/assets/images/placeholder.png')
                    "
                    @error="
                      $event.target.src = require('@/assets/images/placeholder.png')
                    "
                    alt="product image"
                    class="w-full h-full rounded-md border object-cover border-gray-100"
                  />
                </div>
              </div>
              <span
                class="inline-block line-clamp-2 max-w-sm whitespace-normal"
              >
                {{ (product.inventory_product || {})["name_" + $i18n.locale] }}
              </span>
            </router-link>
            <span v-else>-</span>
          </td>
          <td>
            {{ (product.category || {})["name_" + $i18n.locale] }}
          </td>
          <td>
            <div class="actions-group !flex-wrap min-w-[15rem]">
              <span
                class="status text-gray-600 bg-gray-100"
                v-for="(sub, index) in product.sub_categories || []"
                :key="index"
                >{{ sub["name_" + $i18n.locale] }}</span
              >

              <span
                class="text-gray-600"
                v-if="!(product.sub_categories || []).length"
                >-</span
              >
            </div>
          </td>
          <td>
            <p
              class="status capitalize"
              :class="item_status[product.is_active]"
            >
              {{ product.is_active === 1 ? $t("active") : $t("inactive") }}
            </p>
          </td>

          <td
            class="table-drop-item relative"
            :style="`--z: ${indexes[index]}`"
          >
            <t-dropdown
              v-if="
                hasPermission('edit items') ||
                hasPermission('update items') ||
                hasPermission('delete items')
              "
            >
              <div
                slot="trigger"
                slot-scope="{
                  mousedownHandler,
                  focusHandler,
                  blurHandler,
                  keydownHandler,
                }"
              >
                <button
                  class="w-10 h-10 rounded-full hover:bg-gray-100 text-gray-600"
                  aria-label="Actions"
                  aria-haspopup="true"
                  @mousedown="mousedownHandler"
                  @focus="focusHandler"
                  @blur="blurHandler"
                  @keydown="keydownHandler"
                >
                  <i class="fa-solid fa-ellipsis"></i>
                </button>
              </div>

              <div slot-scope="{ hide }">
                <ul
                  class="list-none ring-1 ring-gray-300 divide-y divide-gray-100"
                >
                  <li class="py-2 px-4" v-if="hasPermission('update items')">
                    <a
                      href="javascript:void(0)"
                      @click.prevent="
                        () => {
                          changeStatus(product.id).finally(() => hide());
                        }
                      "
                      :disabled="disables[`status_${product.id}`]"
                      class="inline-flex gap-4 text-gray-600 items-center text-sm"
                    >
                      <span class="shrink-0">
                        <spinner
                          size="w-4 h-4 inline-block"
                          v-if="disables[`status_${product.id}`]"
                        />

                        <i class="fa-solid fa-right-left" v-else></i>
                      </span>
                      <p class="font-semibold flex-1">Change status</p>
                    </a>
                  </li>
                  <li class="py-2 px-4" v-if="hasPermission('edit items')">
                    <a
                      href="javascript:void(0)"
                      @click.prevent="
                        () => {
                          ShowProcessingItems(product);
                        }
                      "
                      class="inline-flex gap-4 text-gray-600 items-center text-sm"
                    >
                      <span class="shrink-0">
                        <i class="fa-solid fa-right-left"></i>
                      </span>
                      <p class="font-semibold flex-1">Processing</p>
                    </a>
                  </li>
                  <li class="py-2 px-4" v-if="hasPermission('delete items')">
                    <a
                      href="javascript:void(0)"
                      @click.prevent="
                        () => {
                          deleteItem(product.id).finally(() => hide());
                        }
                      "
                      :disabled="disables[`delete_${product.id}`]"
                      class="inline-flex gap-4 text-gray-600 items-center text-sm"
                    >
                      <span class="shrink-0">
                        <spinner
                          size="w-4 h-4 inline-block"
                          v-if="disables[`delete_${product.id}`]"
                        />

                        <i class="fa-solid fa-trash" v-else></i>
                      </span>
                      <p class="font-semibold flex-1">Delete</p>
                    </a>
                  </li>

                  <li class="py-2 px-4" v-if="hasPermission('update items')">
                    <router-link
                      :to="`/products/${product.id}-${
                        product.name_ar ||
                        product.name_en ||
                        `product-${product.created_at}`
                      }`"
                      @click.prevent="hide"
                      class="inline-flex gap-4 text-gray-600 items-center text-sm"
                    >
                      <i class="fa-solid fa-pen shrink-0"></i>
                      <p class="font-semibold flex-1">Update</p>
                    </router-link>
                  </li>
                </ul>
              </div>
            </t-dropdown>
          </td>
        </tr>
      </template>

      <template #tbody v-else>
        <tr>
          <td colspan="100%" class="!text-center">There are no products</td>
        </tr>
      </template>
    </Table>

    <transition>
      <Modal
        title="Manual wastage"
        v-if="wastage_active"
        @close="wastage_active = $event"
      >
        <template>
          <form class="space-y-4 p-4">
            <div class="form-group space-y-3">
              <label for="wastage_id"> Wastage </label>
              <t-rich-select
                :options="wastages"
                placeholder="Select"
                :text-attribute="'name_' + $i18n.locale"
                value-attribute="id"
                :clearable="true"
                :closeOnSelect="true"
                v-model="wastage_data.wastage_id"
              />
              <p class="error">
                {{ (wastage_errors["wastage_id"] || []).join(" ") }}
              </p>
            </div>
            <div class="form-group space-y-3">
              <label for="weight"> Weight (KG) </label>
              <input
                type="number"
                name="weight"
                id="weight"
                v-model="wastage_data.value"
                placeholder="1"
                min="1"
              />
              <p class="error">
                {{ (wastage_errors["value"] || []).join(" ") }}
              </p>
            </div>
            <button-with-loader
              btnClass="w-auto py-2 px-4 rounded bg-primary
                text-white text-xs"
              :disabled="wastage_disabled"
              @click="addWastage"
              >Save changes</button-with-loader
            >
          </form>
        </template>
      </Modal>
    </transition>
    <Modal
      :title="process_data?.product_id['name_' + $i18n.locale]"
      v-if="process_active"
      @close="process_active = $event"
    >
      <template>
        <form class="p-6 space-y-4">
          <!-- <p
            class="text-sm text-center italic text-gray-600 font-medium"
            v-if="!products?.length"
          >
            There are no to data to display try to add current product inventory
            to one of added items.
          </p> -->

          <ul class="space-y-4">
            <div class="form-group space-y-3 flex-1">
              <label for="inventory_product_id"> Product </label>
              <t-rich-select
                :fetch-options="getProducts"
                placeholder="Select"
                :text-attribute="'name_' + $i18n.locale"
                value-attribute="id"
                :clearable="true"
                :closeOnSelect="true"
                v-model="process_data.to_products[0].id"
              />
              <p class="error">
                {{ (process_errors[`to_products.0.id`] || []).join(" ") }}
              </p>
            </div>
            <slide-transition>
              <div
                class="form-group space-y-3"
                v-if="process_data.to_products[0].id"
              >
                <label for="value"> Value </label>
                <div class="flex items-stretch gap-3">
                  <input
                    type="number"
                    name="value"
                    id="value"
                    v-model="process_data.to_products[0].value"
                    placeholder="1"
                    min="1"
                    @blur="
                      process_data.product_id?.unit_type === 'weight'
                        ? undefined
                        : (process_data.to_products[0].value = Math.floor(
                            process_data.to_products[0].value
                          ))
                    "
                    class="flex-1"
                  />
                </div>
                <p class="error">
                  {{ (process_errors[`to_products.0.value`] || []).join(" ") }}
                </p>
              </div>
            </slide-transition>
            <li>
              <details class="w-full border border-gray-200 rounded p-4">
                <summary class="text-sm font-semibold text-gray-600">
                  Wastages
                </summary>
                <ul class="list-none space-y-4 mt-4">
                  <li
                    class="space-y-4"
                    v-for="(wastage, index) in process_data.wastages"
                    :key="index"
                  >
                    <div class="form-group space-y-3">
                      <label for="wastage_id"> Wastage </label>
                      <t-rich-select
                        :options="filterWastages"
                        placeholder="Select"
                        :text-attribute="'name_' + $i18n.locale"
                        value-attribute="id"
                        :clearable="true"
                        :closeOnSelect="true"
                        v-model="process_data.wastages[index].id"
                      />
                      <p class="error">
                        {{
                          (process_errors[`wastages.${index}.id`] || []).join(
                            " "
                          )
                        }}
                      </p>
                    </div>
                    <div class="form-group space-y-3">
                      <label for="weight"> Weight (KG) </label>
                      <div class="flex items-stretch gap-3">
                        <input
                          type="number"
                          name="weight"
                          id="weight"
                          v-model="process_data.wastages[index].value"
                          placeholder="1"
                          min="1"
                          class="flex-1"
                        />
                        <button
                          class="w-10 rounded bg-red-500 text-white shrink-0"
                          :disabled="process_data.wastages.length <= 1"
                          @click.prevent="removeCurrentItem(index)"
                        >
                          <i class="fa-solid fa-minus fa-sm"></i>
                        </button>
                      </div>
                      <p class="error">
                        {{
                          (
                            process_errors[`wastages.${index}.value`] || []
                          ).join(" ")
                        }}
                      </p>
                    </div>
                  </li>
                  <li
                    v-if="
                      (
                        process_data.wastages[
                          process_data.wastages.length - 1
                        ] || {}
                      ).id || filterWastages.length
                    "
                  >
                    <button
                      class="bg-teal-600 text-white text-xs rounded font-semibold py-2 px-4"
                      @click.prevent="addNewItem"
                    >
                      Add new
                    </button>
                  </li>
                </ul>
              </details>
            </li>
          </ul>
          <p
            class="error"
            v-html="Object.values(process_errors).join('<br />')"
          ></p>
          <button-with-loader
            btnClass="w-auto py-2 px-4 rounded bg-primary
          text-white text-xs [margin-inline-start:auto]"
            :disabled="process_disabled"
            @click="updateProductProcess"
            v-if="process_data?.to_products?.length"
            >Save changes</button-with-loader
          >
        </form>
      </template>
    </Modal>

    <import-file
      ref="importModal"
      @click-event="importData"
      @uploaded-file="
        (e) => {
          file = e.file;
        }
      "
      :disabled="import_disabled"
      :errors="import_errors"
    />
  </div>
</template>
<script>
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";
import Modal from "@/components/basics/Modal.vue";
import ImportFile from "@/components/basics/table/ImportFile.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "ProductsPage",
  data() {
    return {
      load: false,
      items: [],
      currentPage: 1,
      rows: 0,
      totalPages: 0,
      perPage: 1,
      active: false,
      product_id: null,
      delete_disabled: false,
      status_disabled: [],
      error: "",
      search_key: "",
      search_disabled: false,
      item_status: {
        1: "bg-sky-500/10 text-sky-500",
        0: "bg-red-500/10 text-red-500",
      },
      status: ["all", "active", "not active"],
      pagination: {
        is_active: null,
        page: 1,
        sort_key: null,
        sort_type: "asc",
        from: this.$route.query.date,
        to: this.$route.query.date,
      },
      disables: {},
      indexes: [],
      wastage_data: {},
      wastage_errors: {},
      wastage_active: false,
      wastage_disabled: false,
      wastages: [],
      exportParams: {},
      file: null,
      import_disabled: false,
      import_errors: {},
      store_id: parseInt(localStorage.getItem("store")) || null,
      process_data: {},
      process_errors: {},
      process_active: false,
      process_disabled: false,
      fields: [],
    };
  },
  created() {
    this.getItems();
    this.getWastages();
  },
  watch: {
    "pagination.page"() {
      this.getItems();
    },
    is_called(val, _) {
      if (val) {
        this.getItems();
      }
    },
    wastage_active(val) {
      if (!val) {
        this.wastage_data = {};
        this.wastage_errors = {};
      }
    },
  },
  computed: {
    ...mapGetters("table", ["search", "is_called"]),
    filterWastages() {
      const selectedWastages = this.process_data.wastages;
      const mapWastages = selectedWastages.map((e) => e.id);
      const result = this.wastages.filter((e) => !mapWastages.includes(e.id));

      return result;
    },
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("table", ["changeIsCalled"]),
    async getProducts(q) {
      try {
        const product = this.items.find(
          (e) => e.id == this.process_data.product_id?.id
        );
        const { result } = await this.getRoute({
          name: "products",
          options: {
            search_key: q,
            product_type: product.unit_type,
            is_inventory: 0,
            page: 1,
          },
        });
        // this.products = result.products;
        const products = result.products?.data?.filter(
          (e) => e.id !== this.process_data.product_id?.id
        );
        return { results: products };
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async getItems() {
      try {
        let params;
        const { total, total_pages, ...others } = this.pagination;
        const user = this.$store.state.user;
        console.log(user, this.$store.state);

        params = {
          ...others,
          is_active: others.is_active
            ? others.is_active === "active"
              ? 1
              : 0
            : null,
          search_key: this.search || this.$route.query.search,
          vendor_id: user.user_type === "vendor" ? user?.id : null,
          // store_id: user.user_type === "cashier" ? user?.id : null,
          store_id: this.store_id,
          is_inventory: 0,
          is_bundle: 0,
        };

        const { result } = await this.getRoute({
          name: "products",
          options: params,
        });
        this.exportParams = params;
        const { data, pagination } = result.products;
        this.fields = result.export_fields;

        this.items = data;

        this.indexes = Array(data.length)
          .fill()
          .map((_, i) => i + 1)
          .sort(() => -1);

        this.$set(this.pagination, "total", pagination.total);
        this.$set(this.pagination, "total_pages", pagination.total_pages);
      } catch (error) {
        console.log(error);
        // this.createAlert(result?.message, type);
      } finally {
        this.load = true;
        this.changeIsCalled(false);
      }
    },
    async getWastages() {
      try {
        const { result } = await this.getRoute("wastages");
        this.wastages = result.wastages;
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      }
    },
    async changeStatus(id) {
      this.$set(this.disables, `status_${id}`, true);
      try {
        const { data } = await this.axios.post(
          `products/update_is_active/${id}`
        );
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    async deleteItem(id) {
      this.$set(this.disables, `delete_${id}`, true);
      try {
        const { data } = await this.axios.post(`products/delete/${id}`);
        this.createAlert(data.message);
        this.getItems();
      } catch (err) {
        const res = err?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        this.createAlert(res?.data?.message, "error");
      } finally {
        this.disables = {};
      }
    },
    sortTable(key) {
      if (this.pagination.sort_key != key) {
        this.pagination.sort_type = "asc";
      } else {
        this.pagination.sort_type =
          this.pagination.sort_type == "asc" ? "desc" : "asc";
      }
      this.pagination.sort_key = key;
      this.getItems().finally(() => {
        this.createAlert(`Table sorted by ${key}`);
      });
    },

    changeFilterStatus(status, index) {
      this.$set(this.disables, index, true);
      this.$set(this.pagination, "is_active", status);
      this.getItems().finally(() => {
        this.disables = {};
      });
    },
    async addWastage() {
      this.wastage_disabled = true;
      this.wastage_errors = {};

      try {
        const { data } = await this.axios.post("products/add_wastage", {
          ...this.wastage_data,
          product_id: this.productId,
        });
        this.getItems();
        this.wastage_data = {};
        this.wastage_active = false;
        this.createAlert(data.message);
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.wastage_errors = responseError;
        } else {
          this.createAlert(responseError, "error");
        }
      } finally {
        this.wastage_disabled = false;

        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    },
    openWastage(id) {
      this.wastage_data = {
        product_id: id,
      };
      this.wastage_errors = {};
      this.wastage_active = true;
    },
    async importData() {
      this.import_disabled = true;

      try {
        let params,
          fd = new FormData();
        if (!this.file) {
          this.createAlert("The file has not yet been chosen", "error");
          return;
        }

        fd.append("file", this.file, this.file.name);
        const user = this.$store.state.user;
        const { total, total_pages, ...others } = this.pagination;
        params = {
          ...others,
          is_active: others.is_active
            ? others.is_active === "active"
              ? 1
              : 0
            : null,
          search_key: this.search,
          vendor_id: user.user_type === "vendor" ? user?.id : null,
          // store_id: user.user_type === "cashier" ? user?.id : null,
          store_id: this.$route.query.location
            ? this.$route.query.location
            : user.user_type === "cashier"
            ? user?.id
            : null,
        };

        const { data } = await this.axios.post(
          `${this.endpointName}/import?` + new URLSearchParams(params),
          fd
        );
        this.$refs.importModal.hide();
        this.file = null;
        this.changeIsCalled(true);
        this.createAlert(data.message);
      } catch (error) {
        console.log(error);
        const res = error?.response;
        if (!res) {
          this.createAlert(
            "There is something went wrong, please try again later.",
            "error"
          );
          return;
        }
        const message = res?.data?.message;
        if (typeof message === "object") {
          this.import_errors = message;
          return;
        }
        this.createAlert(message, "error");
      } finally {
        this.import_disabled = false;
      }
    },
    ShowImportFile() {
      this.$refs.importModal?.show();
    },
    ShowProcessingItems(item) {
      const { product_type, id } = item;
      const filterProducts = this.items.filter(
        (e) => e.id !== id && e.unit_type === product_type
      );

      this.process_errors = {};
      this.process_data = {
        to_products: [{ id: null, value: null }],
        product_id: item,
        wastages: [{ id: null, value: null }],
      };

      this.process_active = true;
    },
    async updateProductProcess() {
      this.process_disabled = true;
      try {
        const body = this.process_data;
        const wastages = body?.wastages?.filter((ev) =>
          Object.values(ev).every((e) => e)
        );
        const products = body?.to_products?.filter((e) => e.value);
        const { data } = await this.axios.post("products/processing", {
          ...body,
          product_id: body.product_id?.id,
          to_products: products?.length ? products : undefined,
          wastages: wastages?.length ? wastages : undefined,
        });
        this.createAlert(data.message);
        this.getItems();
        this.process_active = false;
      } catch (error) {
        const err = error?.response;
        if (!err || !err?.data) {
          this.createAlert("there is something went wrong", "error");
          return;
        }
        const responseError = err?.data?.message;

        if (typeof responseError === "object") {
          this.process_errors = responseError;
        } else {
          this.createAlert(responseError, "error");
        }
      } finally {
        this.process_disabled = false;
      }
    },
    addNewItem() {
      if (!this.filterWastages.length) {
        this.createAlert("Wastages are not enough to add new one.", "error");
        return;
      }
      this.process_data.wastages.push({
        id: null,
        value: null,
      });
    },
    removeCurrentItem(index) {
      if (index === 0 && this.process_data.wastages.length === 1) {
        this.createAlert("One wastage at least should be added!!", "error");
        return;
      }
      this.process_data.wastages.splice(index, 1);
    },
    ChangeProductProcess(ev, item) {
      const { checked } = ev.target;
      const products = this.process_data.to_products;
      this.process_data.to_products = [item];
    },
  },
  components: { Modal, ButtonWithLoader, ImportFile },
};
</script>
