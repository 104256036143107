<template>
  <div class="p-6">
    <AlertsContainerWrapper class="space-y-6">
      <filter-wrapper
        @apply="getItems"
        @reset="handleReset"
        ref="filterWrapper"
      >
        <div>
          <t-datepicker
            :placeholder="`From - To`"
            multiple
            :monthsPerView="2"
            v-model="pagination.date"
            range
            :clearable="false"
            userFormat="Y-m-d"
            :weekStart="6"
          />
        </div>
      </filter-wrapper>
      <Table
        :title="$t('refunds')"
        :records="`${pagination.total || 0} `"
        :pagination="pagination"
        :total_pages="pagination.total_pages"
        v-model="pagination.page"
      >
        <template #actions>
          <div>
            <button
              class="py-1.5 px-3 text-sm flex items-center gap-2 text-gray-600 font-medium rtl:font-semibold"
              @click.prevent="add_active = true"
            >
              <i class="fa-solid fa-plus"></i>
              <span>{{ $t("add") }}</span>
            </button>
          </div>
        </template>
        <template #head>
          <th>#</th>
          <th>{{ $t("location") }}</th>
          <th>{{ $t("products") }}</th>
          <th>{{ $t("total") }}</th>
          <th>{{ $t("tracking") }}</th>
          <th>{{ $t("actions") }}</th>
        </template>
        <template #tbody v-if="!load">
          <TableLoad :rows="6" v-for="i in 4" :key="i" />
        </template>
        <template #tbody v-else-if="load && items.length">
          <tr v-for="(item, index) in items" :key="index">
            <td>
              <router-link
                :to="
                  hasPermission('show refund_orders')
                    ? `/inventory-actions/refunds/${item.id}`
                    : '#'
                "
                class="text-blue-600"
              >
                {{ item.id }}
              </router-link>
            </td>
            <td>
              <p>{{ (item.store || {})["name_" + $i18n.locale] || "-" }}</p>
            </td>

            <td>{{ item.products.length }}</td>
            <td>{{ item.total }} SAR</td>
            <td>
              <div>
                <ul class="flex gap-8 whitespace-nowrap w-full items-start">
                  <li
                    class="flex items-center justify-center flex-col text-center"
                    v-for="(track, idx) in item.tracking"
                    :key="idx"
                    v-tippy
                    :content="
                      track.created_at
                        ? $moment(track.created_at).format('lll')
                        : 'N/A'
                    "
                  >
                    <span
                      class="flex items-center justify-center w-6 h-6 rounded-full shrink-0"
                      :class="
                        track.created_at
                          ? 'bg-primary text-white'
                          : 'bg-gray-200 text-gray-500'
                      "
                    >
                      <i
                        class="fa-solid fa-check fa-xs"
                        v-if="track.created_at"
                      ></i>
                    </span>
                    <div class="text-center mt-2 space-y-1">
                      <p class="capitalize font-medium text-slate-600 text-xs">
                        {{ track["status_" + $i18n.locale] }}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </td>
            <td>
              <div class="actions-group">
                <div v-if="hasPermission('show refund_orders')">
                  <router-link
                    :to="`/inventory-actions/refunds/${item.id}`"
                    class="w-8 h-8 bg-sky-500 text-white rounded flex items-center justify-center"
                  >
                    <i class="fa-solid fa-eye"></i>
                  </router-link>
                </div>
              </div>
            </td>
          </tr>
        </template>

        <template #tbody v-else>
          <tr>
            <td colspan="100%" class="!text-center">
              {{ $t("no-data") }}
            </td>
          </tr>
        </template>
      </Table>
    </AlertsContainerWrapper>
    <Modal v-if="add_active" @close="add_active = $event" title="New refund">
      <form class="p-6 space-y-4">
        <div class="form-group space-y-3">
          <label for="order_id">{{ $t("order-number") }}</label>
          <input
            type="text"
            name="order_id"
            id="order_id"
            v-model.number="orderId"
          />
        </div>
        <ButtonWithLoader
          btnClass="w-full py-3 px-4 rounded bg-primary text-white text-sm"
          :disabled="add_disabled"
          @click="getSelectedId"
        >
          {{ $t("submit") }}
        </ButtonWithLoader>
      </form>
    </Modal>
    <Refund :item="order" ref="newRefund" />
  </div>
</template>

<script>
import AlertsContainerWrapper from "@/components/Inventory/AlertsContainerWrapper.vue";
import ButtonWithLoader from "@/components/basics/ButtonWithLoader.vue";
import CardsContainer from "@/components/basics/CardsContainer.vue";
import FilterWrapper from "@/components/basics/FilterWrapper.vue";
import Modal from "@/components/basics/Modal.vue";
import Refund from "@/components/orders/Refund.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "refunds",
  components: {
    CardsContainer,
    AlertsContainerWrapper,
    FilterWrapper,
    Modal,
    Refund,
    ButtonWithLoader,
  },
  data() {
    return {
      items: [],
      load: false,
      cards: [
        {
          title: "Customers",
          key: "customers_count",
          icon: "fa-solid fa-user-group",
          value: 0,
          style: {
            icon: "text-sky-600",
            parent: "bg-sky-600/20",
          },
        },
        {
          title: "Products",
          key: "products_count",
          icon: "fa-solid fa-shopping-bag",
          value: 0,
          style: {
            icon: "text-orange-500",
            parent: "bg-orange-500/20",
          },
        },
        {
          title: "Total",
          key: "total_count",
          icon: "fa-solid fa-dollar-sign",
          value: 0,
          style: {
            icon: "text-teal-500",
            parent: "bg-teal-500/20",
          },
        },
      ],
      pagination: {
        page: 1,
        total: 0,
        total_pages: 0,
      },
      add_active: false,
      add_disabled: false,
      orderId: undefined,
      order: {},
    };
  },
  async created() {
    this.$set(this.pagination, "date", [
      this.$route.query.date ||
        this.$moment().add(-1, "days").format("YYYY-MM-DD"),
      this.$moment().format("YYYY-MM-DD"),
    ]);
    await Promise.all([this.getItems()]).finally(() => (this.load = true));
  },
  watch: {
    is_called(val, _) {
      if (val) {
        this.getItems();
      }
    },
  },
  computed: {
    ...mapGetters("table", ["search", "is_called"]),
  },
  methods: {
    ...mapActions("getting", ["getRoute"]),
    ...mapMutations("table", ["changeIsCalled"]),
    async getItems() {
      try {
        const { total, total_pages, date, ...others } = this.pagination;
        const { result } = await this.getRoute({
          name: "orders",
          options: {
            ...others,
            search_key: this.search,
            order_type: "refund",
            from: date?.[0],
            to: date?.[1],
            store_id: this.store_id,
          },
        });
        const { orders } = result;

        this.items = orders.data;
        this.$set(this.pagination, "total", orders.pagination?.total);
        this.$set(
          this.pagination,
          "total_pages",
          orders.pagination?.total_pages
        );
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      } finally {
        this.changeIsCalled(false);
        const filterWrapper = this.$refs.filterWrapper;
        filterWrapper?.resetAll();
      }
    },
    handleReset() {
      const { page, ...others } = this.pagination;

      for (const key in others) {
        const item = others[key];
        if (typeof item === "number") {
          this.$set(this.pagination, key, 0);
        } else if (Array.isArray(item)) {
          this.$set(this.pagination, key, []);
        } else {
          this.$set(this.pagination, key, null);
        }
      }

      this.getItems();
    },
    async getSelectedId() {
      try {
        this.add_disabled = true;
        const { result } = await this.getRoute("orders/" + this.orderId);
        this.order = result || {};
        this.add_active = false;
        this.orderId = undefined;
        this.$refs.newRefund?.show();
      } catch ({ result, type }) {
        this.createAlert(result?.message, type);
      } finally {
        this.add_disabled = false;
      }
    },
  },
};
</script>
